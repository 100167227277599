<div class="bg-white position-relative">
  <ag-grid-angular [suppressCellFocus]="true" style="width: auto;" [style.height]="gridHeight"
    class="ag-theme-material carrier-loadboard-grid" [headerHeight]="0" [rowHeight]="90" [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef" [rowBuffer]="rowBuffer" [rowSelection]="rowSelection" [rowModelType]="rowModelType"
    [cacheBlockSize]="cacheBlockSize" [cacheOverflowSize]="cacheOverflowSize" [rowStyle]="{'max-height': '52px'}"
    [maxConcurrentDatasourceRequests]="maxConcurrentDataSourceRequests"
    [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache" [suppressMenuHide]="true"
    [suppressContextMenu]="true" [suppressRowClickSelection]="true" (gridReady)="onGridReady($event)"
    [rowStyle]="{cursor: 'pointer'}"></ag-grid-angular>
  <div class="no-records-message" style="position: absolute; top: 50%;left: 50%;transform: translate(-50%, -50%);"
    *ngIf="noRecordFound">
    {{ noRecordFoundMsg }}
  </div>
</div>