<div class="d-flex align-items-center justify-content-between mb-4">
    <h3 class="mb-0">
        <span *ngIf="!isPopUp">Activity</span>
    </h3>
    <form class="form-horizontal d-flex" focusInvalidInput novalidate autocomplete="off"
        [formGroup]="activitySearchForm">
        <div class="mr-2">
            <app-select-control [formName]="activitySearchForm" elementId="activityType" [options]="searchActivityTypes"
                [multipleSelection]="false" [filter]="false" [showClear]="false" [isPrimeNgDropdown]="true"
                [isServerSideFilter]="false" styleClass="w-100" [control]="getActivitySearchControl('activityType')"
                controlName="activityType" [isShowLabel]="false" label="Activity Type">
            </app-select-control>
        </div>
        <div class="no-label">
            <app-input-control [formName]="activitySearchForm" elementId="search"
                [control]="getActivitySearchControl('search')" [hideLabel]="true" controlName="search"
                placeholder="Search"></app-input-control>
        </div>
    </form>
</div>

<div class="media pe-1">
    <p-virtualScroller *ngIf="virtualActivities && virtualActivities.length && virtualActivities.length > 0" #vs
        [value]="virtualActivities" scrollHeight="450px" [itemSize]="100" [lazy]="true" [delay]="500"
        (onLazyLoad)="lazyLoadActivities($event)">
        <ng-template let-activity pTemplate="item">
            <div class="d-flex align-items-center"
                [ngClass]="{'flex-row-reverse':activity?.createdBy === getCurrentUserId()}">
                <div class="flex-shrink-0">
                    <span class="bg-light p-2 rounded-5 fw-semibold">{{activity?.createdByUser | nameInitial}}</span>
                </div>
                <div class="flex-grow-1"
                    [ngClass]="{'me-3 text-end':activity?.createdBy === getCurrentUserId(),'ms-3':activity?.createdBy !== getCurrentUserId()}">
                    <p class="p-element" tooltipStyleClass="tooltip-activity" pTooltip="{{activity?.activity}}"
                        tooltipPosition="top" *ngIf="!activity.isActivityEdit">
                        {{activity?.activity}}
                    </p>
                    <div class="no-label" *ngIf="activity.isActivityEdit">
                        <textarea class="form-control resize-none" pInputTextarea [(ngModel)]="activity.activity"
                            [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                    <div>
                        <span
                            *ngIf="isEditDeleteActivityPermitted() && !activity.isActivityEdit && activity?.createdBy === getCurrentUserId()">
                            <span class="material-symbols-outlined text-lg cursor-pointer text-primary"
                                (click)="onActivityEdit(activity)">
                                edit
                            </span>
                            <span class="material-symbols-outlined text-lg cursor-pointer text-danger"
                                (click)="onActivityDelete(activity)">
                                delete
                            </span>
                        </span>
                        <span *ngIf="activity.isActivityEdit">
                            <span class="material-symbols-outlined text-lg cursor-pointer text-primary"
                                *ngIf="activity.isActivityEdit" (click)="onActivitySave(activity)">
                                check_circle
                            </span>
                            <span class="material-symbols-outlined text-lg cursor-pointer text-danger"
                                (click)="onActivityEditCancel(activity)">
                                cancel
                            </span>
                        </span>
                        <span class="badge rounded-pill text-bg-{{activity?.activityType?.toLowerCase()}}">
                            {{activity?.activityType}}
                        </span>
                        <span>
                            {{activity?.createdDate | date:dateFormat}}
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-virtualScroller>
    <div class="alert alert-info text-center" *ngIf="!virtualActivities?.length">
        {{noActivityFound}}
    </div>
</div>

<form class="form-horizontal" focusInvalidInput novalidate autocomplete="off" (keyup)="save($event)"
    [formGroup]="activityForm">
    <div class="d-flex align-items-center justify-content-between mt-4 mb-2">
        <p class="mb-0 text-black">Add an activity</p>
        <div>
            <app-select-control [formName]="activityForm" elementId="activityTypeId" [options]="activityTypes"
                [multipleSelection]="false" [filter]="false" [showClear]="false" [isPrimeNgDropdown]="true"
                [isServerSideFilter]="false" styleClass="w-100" [control]="getActivityControl('activityTypeId')"
                controlName="activityTypeId" [isShowLabel]="false" label="Activity Type">
            </app-select-control>
        </div>
    </div>
    <div class="no-label">
        <app-text-area-control [formName]="activityForm" [hideLabel]="true" elementId="activity"
            [control]="getActivityControl('activity')" controlName="activity" placeholder="Text Goes Here"
            label="Activity Note">
        </app-text-area-control>
    </div>
</form>