import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Constants } from '@app/core/constants/constants';
import { CarrierStakeholderType, CityStateZipSearchKey, FilterFieldType, FilterOperationType } from '@app/core/constants/enums';
import { FormHelper } from '@app/core/helpers/form.helper';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ZipCodeResponseParent } from '@app/core/models/zipcode.model';
import { CarrierEntityModel, CarrierFilter } from '@app/modules/carrier/carrier.model';
import { CarrierService } from '@app/modules/carrier/carrier.service';
import { LoadService } from '@app/modules/load/load.service';
import { LocationService } from '@app/modules/master/location/location.service';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CapacityDetailModel } from '../../capacity.model';
import { CapacityHelper } from '../capacity.helper';
import { CapacityService } from '../../capacity.service';

@Component({
  selector: 'app-capacity-general-detail',
  templateUrl: './capacity-general-detail.component.html',
  styles: [
  ]
})
export class CapacityGeneralDetailComponent implements OnInit {
  zipCodeDocuments: ZipCodeResponseParent[] = [];
  cityDocuments: ZipCodeResponseParent[] = [];
  stateDocuments: ZipCodeResponseParent[] = [];
  mcNumberDocument: any[] = [];
  carrierDispatchers: any[] = [];
  formHelper = FormHelper;
  searchType = CityStateZipSearchKey;
  defaultDispatcher = {
    dispatcherName: Constants.pleaseSelectDefaultText,
    dispatcherId: null,
    email: '',
    cellPhone: '',
    officePhone: '',
    isActive: true,
  };

  @Input() capacityDetail!: CapacityDetailModel;
  @Input() capacityForm!: FormGroup;
  @Output() formSubmit = new EventEmitter<CapacityDetailModel>();
  @Output() formReset = new EventEmitter<CapacityDetailModel>();
  @Input() isEditMode = false;
  @Input() awardDetailId!: number;
  @Input() equipmentSelectList: any[] = [];
  @Input() isAwardCapacity = false;
  @Input() isExistingCapacity = false;
  @Input() isCloneCapacity = false;
  capacityDocuments: { label?: string; items?: CapacityDetailModel[]; }[] = [];
  carrierSearchString = '';

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly locationService: LocationService,
    private readonly carrierService: CarrierService,
    private readonly loadService: LoadService,
    private readonly capacityService: CapacityService
  ) { }

  ngOnInit(): void {
    if (this.capacityForm) {
      if (this.getControl('carrierId').getRawValue()) {
        this.getCarrierDispatchers(this.getControl('carrierId').getRawValue());
      }
    }
  }

  getControl(control: string): AbstractControl {
    return this.capacityForm.controls[control];
  }

  getCapacityScheduleForms() {
    return this.capacityForm.get('capacitySchedules') as any;
  }

  searchByCarrierNumber(searchString: string) {
    const regexp = new RegExp(/^[a-z\d]+$/i);
    const isValid = searchString ? regexp.test(searchString) : true;
    this.getControl('mcdotNumberObj').setErrors(isValid ? null : { invalidalphanumeric: true });
    const carrierRequestModel: CarrierFilter = {
      id: null,
      sortField: null,
      sortOrder: null,
      pageNumber: 1,
      pageSize: Constants.typeAheadSelectListSize,
      carrierFilters: [
        {
          fieldName: 'motorCarrierNumber',
          fieldType: FilterFieldType.Text,
          operation: FilterOperationType.Contains,
          valueOne: searchString,
          valueTwo: null
        },
        {
          fieldName: 'departmentOfTransport',
          fieldType: FilterFieldType.Text,
          operation: FilterOperationType.Contains,
          valueOne: searchString,
          valueTwo: null
        },
      ]
    };
    if (isValid) {
      this.carrierSearchString = searchString;
      this.carrierService.getCarrierSelectList(carrierRequestModel).subscribe((data: ResponseModel<CarrierEntityModel[]>) => {
        if (data.data != null && data.data.length > 0) {
          this.mcNumberDocument = [{ label: Constants.suggestions, items: data.data.map(x => Object.assign({}, x)) }];
        }
        else
          this.mcNumberDocument = [{ label: Constants.noRecordFound, items: [] }].map(x => Object.assign({}, x));
      });
    }
  }

  searchByCarrierName(searchString: string) {
    const carrierRequestModel: CarrierFilter = {
      id: null,
      sortField: null,
      sortOrder: null,
      pageNumber: 1,
      pageSize: Constants.typeAheadSelectListSize,
      carrierFilters: [
        {
          fieldName: 'name',
          fieldType: FilterFieldType.Text,
          operation: FilterOperationType.Contains,
          valueOne: searchString,
          valueTwo: null
        }
      ]
    };
    this.carrierService.getCarrierSelectList(carrierRequestModel).subscribe((data: ResponseModel<CarrierEntityModel[]>) => {
      if (data.data != null && data.data.length > 0) {
        this.mcNumberDocument = [{ label: Constants.suggestions, items: data.data.map(x => Object.assign({}, x)) }];
      }
      else
        this.mcNumberDocument = [{ label: Constants.noRecordFound, items: [] }].map(x => Object.assign({}, x));
    });
  }

  onCarrierSelect(selected: any) {
    if (this.carrierSearchString != null && this.carrierSearchString != '') {
      if (!this.carrierSearchString.toLowerCase().startsWith('mc')) {
        selected.motorCarrierNumber = selected.departmentOfTransport;
        this.getControl('mcdotNumber').setValue(selected.departmentOfTransport);
      } else {
        this.getControl('mcdotNumber').setValue(selected.motorCarrierNumber);
      }
      this.carrierSearchString = '';
    }
    else {
      if (selected.departmentOfTransport != null && selected.departmentOfTransport != '' && (selected.motorCarrierNumber == null || selected.motorCarrierNumber == '')) {
        selected.motorCarrierNumber = selected.departmentOfTransport;
        this.getControl('mcdotNumber').setValue(selected.departmentOfTransport);
      } else {
        this.getControl('mcdotNumber').setValue(selected.motorCarrierNumber);
      }
    }
    const mcdotNumber = selected.motorCarrierNumber || selected.departmentOfTransport;
    this.getControl('mcdotNumberObj').patchValue({ motorCarrierNumber: mcdotNumber });
    this.getControl('carrierId').patchValue(selected.id);
    this.getControl('carrierNameObj').patchValue(selected);
    this.getControl('carrierName').patchValue(selected.name);
    if (this.isEditMode) {
      this.getControl('capacityUniqueId').patchValue(selected.name?.substring(0, 3));
    }
    if (selected.id) {
      this.getCarrierDispatchers(selected.id);
      this.getCarrierStakeholders(selected.id);
      this.getControl('dispatcherName').patchValue(null);
      this.getControl('dispatcherId').patchValue(null);
      this.getControl('phone').patchValue(null);
      this.getControl('email').patchValue(null);
      if (this.getControl('dispatcherId').hasError('required')) {
        this.getControl('dispatcherId').markAsPristine();
        this.getControl('dispatcherId').setErrors(null);
      }
    }
  }

  getCarrierDispatchers(carrierId: number): void {
    this.carrierDispatchers = [];
    this.loadService.getLoadDispatcher(carrierId).subscribe((response) => {
      if (response.data) {
        this.carrierDispatchers = [];
        this.carrierDispatchers = response.data.filter((d) => d.isActive || d.dispatcherId === this.getControl('dispatcherId').getRawValue());
      }
    });
  }

  getCarrierStakeholders(carrierId: number): void {
    this.carrierDispatchers = [];
    this.carrierService.getCarrierStakeholder(carrierId).subscribe((response) => {
      if (response.data) {
        const carrierRepresentative = response.data.carrierStakeholder?.find((d) => d.isActive || d.carrierStakeholderType.name === CarrierStakeholderType.CarrierRep);
        if (carrierRepresentative) {
          this.getControl('carrierRepresentativeId').patchValue(carrierRepresentative.carrierStakeholderId);
          this.getControl('carrierRepresentativeName').patchValue(carrierRepresentative.carrierStakeholderName);
        }
      }
    });
  }

  onDispatcherSelect(selected: any) {
    const dispatcher = this.carrierDispatchers.find((d) => d.dispatcherId === selected.value);
    if (dispatcher) {
      this.getControl('dispatcherName').patchValue(dispatcher.dispatcherName);
      this.getControl('phone').patchValue(dispatcher.cellPhone);
      this.getControl('email').patchValue(dispatcher.email);
    }
  }

  onEquipmentSelect(selected: any) {
    const equipment = this.equipmentSelectList.find((d) => d.equipmentId === selected.value);
    if (equipment) {
      this.getControl('equipmentName').patchValue(equipment.name);
    }
  }

  searchByCityStateZip(event: any, searchType: CityStateZipSearchKey, stateCityControl = ''): void {
    const selectedStateCity = this.getControl(stateCityControl)?.getRawValue() || null;
    this.locationService.getZipCodeSelectList(FormHelper.GetTypeAheadRequestPayload(event.query, searchType, selectedStateCity), searchType === CityStateZipSearchKey.State).subscribe((response) => {
      const items = (response.data != null && response.data.length > 0) ? response.data.map(x => Object.assign({}, x)) : [];
      const label = items.length ? Constants.suggestions : Constants.noRecordFound;
      const responseDocuments = [{ label: label, items: items }];
      switch (searchType) {
        case CityStateZipSearchKey.City: this.cityDocuments = responseDocuments;
          break;
        case CityStateZipSearchKey.State: this.stateDocuments = responseDocuments;
          break;
        case CityStateZipSearchKey.Zip: this.zipCodeDocuments = responseDocuments;
          break;
      }
    });
  }

  onOriginCitySelect(selected: any) {
    this.getControl('originZipObj').patchValue(selected);
    this.patchOriginCityStateZip(selected);
  }

  onOriginStateSelect(selected: any) {
    this.getControl('originState').patchValue(selected.stateCode);
  }

  onOriginZipCodeSelect(selected: any) {
    this.getControl('originCityObj').patchValue(selected);
    this.patchOriginCityStateZip(selected);
  }

  patchOriginCityStateZip(selected: any) {
    this.getControl('originCity').patchValue(selected.city);
    this.getControl('originState').patchValue(selected.stateCode);
    this.getControl('originZip').patchValue(selected.zip);
    this.getControl('originCountry').patchValue(selected.country);
    this.getControl('originStateObj').patchValue(selected);
  }

  onDestinationCitySelect(selected: any) {
    this.getControl('destinationZipObj').patchValue(selected);
    this.patchDestinationCityStateZip(selected);
  }

  onDestinationStateSelect(selected: any) {
    this.getControl('destinationState').patchValue(selected.stateCode);
  }

  onDestinationZipCodeSelect(selected: any) {
    this.getControl('destinationCityObj').patchValue(selected);
    this.patchDestinationCityStateZip(selected);
  }

  patchDestinationCityStateZip(selected: any) {
    this.getControl('destinationCity').patchValue(selected.city);
    this.getControl('destinationState').patchValue(selected.stateCode);
    this.getControl('destinationZip').patchValue(selected.zip);
    this.getControl('destinationCountry').patchValue(selected.country);
    this.getControl('destinationStateObj').patchValue(selected);
  }

  submit(): void {
    if (this.capacityForm.invalid) {
      FormHelper.MarkFormGroupDirty(this.capacityForm);
      return;
    }
    if (this.capacityForm.valid) {
      this.formSubmit.emit(CapacityHelper.GetCapacityFormValue(this.capacityForm));
    }
  }

  reset(): void {
    this.capacityForm.reset();
  }

  cancel(): void {
    if (this.capacityForm.dirty) {
      const message = Constants.cancelEditDetailConfirmation;
      this.confirmationService.confirm({
        message: message,
        header: Constants.confirmation,
        icon: Constants.updateStatusDialogIcon,
        accept: () => {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  searchByCapacityUniqueId(event: any): void {
    this.capacityService.getCapacityUniqueIdTypeAhead(FormHelper.GetTypeAheadRequestPayload(event, 'capacityuniqueid', undefined, 'id')).subscribe((response) => {
      if (response.data != null && response.data.length > 0) {
        const items = (response.data != null && response.data.length > 0) ? response.data.map(x => Object.assign({}, x)) : [];
        const label = items.length ? Constants.suggestions : Constants.noRecordFound;
        const responseDocuments = [{ label: label, items: items }];
        this.capacityDocuments = responseDocuments;
      }
    });
  }

  onCapacityUniqueIdSelect(selected: any) {
    this.capacityService.awardCapacityExists(selected.id, this.awardDetailId).subscribe((response) => {
      if (response.data) {
        this.getControl('capacityUniqueIdObj').setErrors({ duplicateCapacity: true });
      } else {
        this.capacityService.getCapacityDetail(selected.id).subscribe((response) => {
          if (response.data) {
            const capacityDetail = response.data;
            if (capacityDetail.carrierId) {
              this.capacityForm.patchValue(capacityDetail);
              this.getControl('mcdotNumberObj').patchValue({ motorCarrierNumber: capacityDetail.mcdotNumber });
              this.getControl('carrierNameObj').patchValue({ motorCarrierNumber: capacityDetail.mcdotNumber, name: capacityDetail.carrierName });
              this.getControl('originCityObj').patchValue({ city: capacityDetail.originCity });
              this.getControl('originStateObj').patchValue({ stateCode: capacityDetail.originState });
              this.getControl('originZipObj').patchValue({ zip: capacityDetail.originZip });
              this.getControl('destinationCityObj').patchValue({ city: capacityDetail.destinationCity });
              this.getControl('destinationStateObj').patchValue({ stateCode: capacityDetail.destinationState });
              this.getControl('destinationZipObj').patchValue({ zip: capacityDetail.destinationZip });
              this.getControl('capacityStartDate').patchValue(capacityDetail.capacityStartDate ? new Date(capacityDetail.capacityStartDate) : null);
              this.getControl('capacityEndDate').patchValue(capacityDetail.capacityEndDate ? new Date(capacityDetail.capacityEndDate) : null);
              this.getControl('capacitySchedules').patchValue(capacityDetail.capacitySchedules);
            }
          }
        });
      }
    });
  }

  isExistingAwardCapacity(): boolean {
    if (this.isAwardCapacity) {
      if (this.isExistingCapacity) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
