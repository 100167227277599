import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Constants } from '../constants/constants';
import { MessageSeverity } from '../constants/enums';
import { CoreService } from '../services/core.service';
import { SpinnerService } from '../services/spinner.service';
import { ToasterService } from '../services/toaster.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private readonly toastService: ToasterService,
        private readonly coreService: CoreService,
        private readonly spinnerService: SpinnerService,
        private readonly authenticationService: AuthenticationService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    switch (event.body.httpStatus) {
                        case HttpStatusCode.BadRequest:
                            if (event.body.messageSeverity && event.body.messageSeverity === MessageSeverity.Warning) {
                                this.toastService.showWarnMessageWithTitle(event.body.userMessage, Constants.validationTitle);
                            } else {
                                if (request.url.includes(Constants.refreshTokenURL)) {
                                    this.coreService.logOutUser();
                                } else {
                                    this.toastService.showErrorMessage(event.body.userMessage);
                                }
                            }
                            break;
                        case Constants.mcpStatus:
                            this.toastService.showErrorMessage(event.body.userMessage);
                            break;
                        case HttpStatusCode.NotFound:
                            this.toastService.showErrorMessage(event.body.userMessage);
                            break;
                        case HttpStatusCode.Conflict:
                            this.toastService.showErrorMessage(event.body.userMessage);
                            break;
                        case HttpStatusCode.NotAcceptable:
                            if (event.body?.messageSeverity && event.body?.messageSeverity === MessageSeverity.Warning) {
                                this.toastService.showWarnMessage(event.body.userMessage);
                            } else {
                                this.toastService.showErrorMessage(event.body.userMessage);
                            }
                            break;
                        case HttpStatusCode.Forbidden:
                            this.toastService.showErrorMessage(event.body?.userMessage);
                            this.router.navigate(['error/unauthorized']);
                            break;
                        default:
                            break;
                    }
                }
            }),
            catchError((exception: HttpErrorResponse) => {
                this.spinnerService.hide();
                switch (exception.status) {
                    case HttpStatusCode.BadRequest:
                        this.toastService.showErrorMessage(exception.error?.userMessage ?? Constants.badRequest);
                        if (exception.error?.errorMessage?.includes(Constants.tokenExpiredMessage)) {
                            this.coreService.logOutUser();
                        }
                        break;
                    case HttpStatusCode.Unauthorized:
                        return this.handleUnauthorizedError(request, next);
                    case HttpStatusCode.NotFound:
                        this.toastService.showErrorMessage(Constants.notFound);
                        break;
                    case HttpStatusCode.InternalServerError:
                        this.toastService.showErrorMessage(exception.error?.userMessage ?? Constants.internalServerError);
                        break;
                    case HttpStatusCode.NotAcceptable:
                        if (exception.error?.messageSeverity && exception.error?.messageSeverity === MessageSeverity.Warning) {
                            this.toastService.showWarnMessage(exception.error?.userMessage);
                        } else {
                            this.toastService.showErrorMessage(exception.error?.userMessage);
                        }
                        break;
                    case HttpStatusCode.Forbidden:
                        this.toastService.showErrorMessage(exception.error?.userMessage);
                        this.router.navigate(['error/unauthorized']);
                        break;
                    case 0:
                        this.toastService.showErrorMessage(Constants.apiNotWorking);
                        break;
                    default:
                        break;
                }
                return throwError(Constants.errorOccurred);
            })
        );
    }

    handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(
                switchMap((response) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(response);
                    return next.handle(request);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap(() => {
                    return next.handle(request);
                })
            );
        }
    }
}
