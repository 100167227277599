import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations';
import { ConfigurationDetail, ConfigurationFleetDetailModel, ConfigurationGroup, Module } from './configuration.model';
import { Endpoint } from '@app/core/constants/enums';

@Injectable()
export class ConfigurationService {
  administrationEndpoint: string;
  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.administrationEndpoint = this.appConfigService.getEndpoint(Endpoint.Administration);
  }

  getModuleList(): Observable<ResponseModel<Module[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.module.getModuleList}`;
    return this.apiClient.get<ResponseModel<Module[]>>(url);
  }
  getConfigurations(moduleId: number): Observable<ResponseModel<ConfigurationGroup[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.configuration.getConfiguration}`;
    url = String.format(url, moduleId);
    return this.apiClient.get<ResponseModel<ConfigurationGroup[]>>(url);
  }

  saveConfigurationDetail(configurationDetails: ConfigurationDetail[]): Observable<ResponseModel<ConfigurationDetail[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.configuration.updateConfiguration}`;
    return this.apiClient.put<ConfigurationDetail[], ResponseModel<ConfigurationDetail[]>>(url, configurationDetails);
  }

  syncPaymentTermsOrLineItems(isPaymentTerms: boolean): Observable<ResponseModel<any>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.configuration.syncPaymentTermsOrLineItems}`;
    url = String.format(url, isPaymentTerms);
    return this.apiClient.post<any, ResponseModel<any>>(url);
  }

  getConfigurationCache(code: string[]): Observable<ResponseModel<ConfigurationDetail[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.configuration.getConfigurationCache}`;
    return this.apiClient.post<any, ResponseModel<ConfigurationDetail[]>>(url, code);
  }

  getFleetSizeConfigurationList(): Observable<ResponseModel<ConfigurationFleetDetailModel[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.configuration.getAllFleetSizeConfigurations}`;
    return this.apiClient.get<ResponseModel<ConfigurationFleetDetailModel[]>>(url);
  }
  updateFleetSizeConfiguration(payload: ConfigurationFleetDetailModel): Observable<ResponseModel<ConfigurationFleetDetailModel>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.configuration.updateFleetSizeConfiguration}`;
    return this.apiClient.post<ConfigurationFleetDetailModel, ResponseModel<ConfigurationFleetDetailModel>>(url, payload);
  }
}
