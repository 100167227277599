import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations';
import { SsoModel } from './authentication.model';
import { UserDetailModel } from '@app/core/models/user-detail.model';
import { Endpoint } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
  }

  createSsoRequest(): Observable<ResponseModel<string>> {
    const url = `${this.appConfigService.getEndpoint(Endpoint.Identity)}${API_ROUTES.identity.sso.login}`;
    return this.apiClient.get<ResponseModel<string>>(url);
  }

  logOut(): Observable<ResponseModel<boolean>> {
    const url = `${this.appConfigService.getEndpoint(Endpoint.Identity)}${API_ROUTES.identity.sso.logout}`;
    return this.apiClient.get<ResponseModel<boolean>>(url);
  }

  getToken(email?: string): Observable<ResponseModel<SsoModel>> {
    let url = `${this.appConfigService.getEndpoint(Endpoint.Identity)}${API_ROUTES.identity.sso.getToken}`;
    url = String.format(url, email ?? this.appConfigService.byPassEmail);
    return this.apiClient.post<string, ResponseModel<SsoModel>>(url);
  }

  getUserDetails(): Observable<ResponseModel<UserDetailModel>> {
    const url = `${this.appConfigService.getEndpoint(Endpoint.Identity)}${API_ROUTES.identity.userPermission.getUserDetail}`;
    return this.apiClient.get<ResponseModel<UserDetailModel>>(url);
  }

  refreshToken(): Observable<ResponseModel<SsoModel>> {
    let url = `${this.appConfigService.getEndpoint(Endpoint.Identity)}${API_ROUTES.identity.sso.refreshToken}`;
    url = String.format(url, LoginHelper.GetUserDetail().userId);
    return this.apiClient.get<ResponseModel<SsoModel>>(url);
  }
}
