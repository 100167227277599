import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FacilityOperationWeek } from "@app/core/constants/enums";
import { EndDateValidator, NumberLengthValidator, StartDateValidator } from "@app/core/helpers/form.helper";
import { LoginHelper } from "@app/core/helpers/login.helper";
import { CapacityDetailModel } from "../capacity.model";

export class CapacityHelper {
    public static getCapacityForm(formBuilder: FormBuilder, capacityDetail?: CapacityDetailModel): FormGroup {
        return formBuilder.group({
            capacityDetailId: new FormControl(capacityDetail?.capacityDetailId || 0, { updateOn: 'blur', validators: [], nonNullable: true }),
            mcdotNumber: new FormControl(capacityDetail?.mcdotNumber || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            mcdotNumberObj: new FormControl(capacityDetail?.mcdotNumber ? { motorCarrierNumber: capacityDetail.mcdotNumber } : null, { updateOn: 'blur', validators: [Validators.required], nonNullable: true }),
            carrierId: new FormControl({ value: capacityDetail?.carrierId || null, disabled: true }, { updateOn: 'blur', validators: [], nonNullable: true }),
            carrierName: new FormControl({ value: capacityDetail?.carrierName || null, disabled: false }, { updateOn: 'blur', validators: [Validators.maxLength(100)], nonNullable: true }),
            carrierNameObj: new FormControl(capacityDetail?.carrierName ? { motorCarrierNumber: capacityDetail.mcdotNumber, name: capacityDetail?.carrierName } : null, { updateOn: 'blur', validators: [], nonNullable: true }),
            carrierRepresentativeId: new FormControl({ value: capacityDetail?.carrierRepresentativeId || null, disabled: true }, { updateOn: 'blur', validators: [], nonNullable: true }),
            carrierRepresentativeName: new FormControl({ value: capacityDetail?.carrierRepresentativeName || null, disabled: true }, { updateOn: 'blur', validators: [Validators.maxLength(100)], nonNullable: true }),
            dispatcherId: new FormControl(capacityDetail?.dispatcherId || null, { updateOn: 'blur', validators: [Validators.required], nonNullable: true }),
            dispatcherName: new FormControl(capacityDetail?.dispatcherName || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            phone: new FormControl({ value: capacityDetail?.phone || null, disabled: true }, { updateOn: 'blur', validators: [], nonNullable: true }),
            email: new FormControl({ value: capacityDetail?.email || null, disabled: true }, { updateOn: 'blur', validators: [Validators.maxLength(100)], nonNullable: true }),
            originCityObj: new FormControl(capacityDetail?.originCity ? { city: capacityDetail.originCity } : null, { updateOn: 'change', validators: [Validators.required], nonNullable: true }),
            originCity: new FormControl(capacityDetail?.originCity || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            originStateObj: new FormControl(capacityDetail?.originState ? { stateCode: capacityDetail.originState } : null, { updateOn: 'change', validators: [Validators.required], nonNullable: true }),
            originState: new FormControl(capacityDetail?.originState || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            originZipObj: new FormControl(capacityDetail?.originZip ? { zip: capacityDetail.originZip } : null, { updateOn: 'change', validators: [Validators.required], nonNullable: true }),
            originZip: new FormControl(capacityDetail?.originZip || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            originCountry: new FormControl(capacityDetail?.originCountry || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            destinationCityObj: new FormControl(capacityDetail?.destinationCity ? { city: capacityDetail.destinationCity } : null, { updateOn: 'change', validators: [Validators.required], nonNullable: true }),
            destinationCity: new FormControl(capacityDetail?.destinationCity || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            destinationStateObj: new FormControl(capacityDetail?.destinationState ? { stateCode: capacityDetail.destinationState } : null, { updateOn: 'change', validators: [Validators.required], nonNullable: true }),
            destinationState: new FormControl(capacityDetail?.destinationState || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            destinationZipObj: new FormControl(capacityDetail?.destinationZip ? { zip: capacityDetail.destinationZip } : null, { updateOn: 'change', validators: [Validators.required], nonNullable: true }),
            destinationZip: new FormControl(capacityDetail?.destinationZip || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            destinationCountry: new FormControl(capacityDetail?.destinationCountry || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            rates: new FormControl(capacityDetail?.rates || null, { updateOn: 'blur', validators: [Validators.required, NumberLengthValidator(7)], nonNullable: true }),
            includeFSC: new FormControl(capacityDetail?.includeFSC || false, { nonNullable: true }),
            equipmentId: new FormControl(capacityDetail?.equipmentId || null, { updateOn: 'blur', validators: [Validators.required], nonNullable: true }),
            equipmentName: new FormControl(capacityDetail?.equipmentName || null, { updateOn: 'blur', validators: [], nonNullable: true }),
            dropTrailer: new FormControl(capacityDetail?.dropTrailer || false, { nonNullable: true }),
            termService: new FormControl(capacityDetail?.termService || false, { nonNullable: true }),
            totalWeeklyCapacity: new FormControl(capacityDetail?.totalWeeklyCapacity || null, { updateOn: 'blur', validators: [Validators.required, NumberLengthValidator(7)], nonNullable: true }),
            capacityStartDate: new FormControl(capacityDetail?.capacityStartDate ? new Date(capacityDetail.capacityStartDate) : null, { updateOn: 'blur', validators: [StartDateValidator('capacityEndDate')], nonNullable: true }),
            capacityEndDate: new FormControl(capacityDetail?.capacityEndDate ? new Date(capacityDetail.capacityEndDate) : null, { updateOn: 'blur', validators: [EndDateValidator('capacityStartDate')], nonNullable: true }),
            overallCommitment: new FormControl(capacityDetail?.overallCommitment || null, { updateOn: 'blur', validators: [NumberLengthValidator(3), Validators.max(100)], nonNullable: true }),
            capacitySchedules: formBuilder.array(CapacityHelper.GetCapaCityScheduleForms(formBuilder, capacityDetail)),
            createdBy: new FormControl(capacityDetail?.createdBy || LoginHelper.GetUserDetail().userId, { nonNullable: true }),
            createdByUser: new FormControl(capacityDetail?.createdByUser || LoginHelper.GetUserDetail().userName, { nonNullable: true }),
            createdDate: new FormControl(capacityDetail?.createdDate || new Date(), { nonNullable: true }),
            modifiedBy: new FormControl(LoginHelper.GetUserDetail().userId, { nonNullable: true }),
            modifiedByUser: new FormControl(LoginHelper.GetUserDetail().userName, { nonNullable: true }),
            modifiedDate: new FormControl(capacityDetail?.modifiedDate || new Date(), { nonNullable: true }),
            capacityUniqueId: new FormControl({ value: capacityDetail?.capacityUniqueId ? CapacityHelper.GetCapacityIdValue(capacityDetail?.capacityUniqueId, capacityDetail?.capacityDetailId) : undefined, disabled: true }, { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(5)], nonNullable: true }),
            capacityUniqueIdSuffix: new FormControl({ value: capacityDetail?.capacityDetailId ? capacityDetail?.capacityDetailId?.toString().padStart(4, '0') : null, disabled: true }, { updateOn: 'blur', validators: [], nonNullable: true }),
            capacityImplicitExplicitPermission: new FormControl(capacityDetail?.capacityImplicitExplicitPermission || null, { nonNullable: true }),
            capacityStatus: new FormControl(capacityDetail?.capacityStatus || null, { nonNullable: true }),
            capacityUniqueIdObj: new FormControl(null, { validators: [], nonNullable: true }),
            notes: new FormControl(capacityDetail?.notes || null, { validators: [Validators.maxLength(250)], nonNullable: true }),
            awardDetailId: new FormControl(capacityDetail?.awardDetailId || 0, { updateOn: 'blur', validators: [], nonNullable: true }),
            status: new FormControl(capacityDetail?.status || 0, { updateOn: 'blur', validators: [], nonNullable: true })
        });
    }

    public static GetCapaCityScheduleForms(formBuilder: FormBuilder, capacityDetail?: CapacityDetailModel): any[] {
        const capacityOperationWeekArray = Object.values(FacilityOperationWeek);
        const capacityScheduleForms: FormGroup[] = [];
        for (let index = 0; index < 7; index++) {
            const capacitySchedule = capacityDetail?.capacitySchedules[index];
            capacityScheduleForms.push(
                formBuilder.group({
                    capacityScheduleId: new FormControl(capacitySchedule?.capacityScheduleId || 0, { nonNullable: true }),
                    capacityDetailId: new FormControl(capacitySchedule?.capacityDetailId || capacityDetail?.capacityDetailId || 0, { nonNullable: true }),
                    weekDay: new FormControl(capacitySchedule?.weekDay || capacityOperationWeekArray[index], { nonNullable: true }),
                    capacityAvailable: new FormControl(capacitySchedule?.capacityAvailable || true, { nonNullable: true }),
                    capacityPerDay: new FormControl({ value: capacitySchedule?.capacityPerDay || null, disabled: false }, { updateOn: 'blur', validators: [NumberLengthValidator(7)] }),
                    createdBy: new FormControl(capacitySchedule?.createdBy || LoginHelper.GetUserDetail().userId, { nonNullable: true }),
                    createdDate: new FormControl(capacitySchedule?.createdDate || new Date(), { nonNullable: true }),
                    modifiedBy: new FormControl(capacitySchedule?.modifiedBy || LoginHelper.GetUserDetail().userId, { nonNullable: true }),
                    modifiedDate: new FormControl(capacitySchedule?.modifiedDate || new Date(), { nonNullable: true }),
                })
            );
            if (capacitySchedule?.capacityAvailable === null || capacitySchedule?.capacityAvailable === undefined) {
                capacityScheduleForms[index].controls['capacityAvailable'].patchValue(true);
            } else if (capacitySchedule?.capacityAvailable === false) {
                capacityScheduleForms[index].controls['capacityAvailable'].patchValue(false);
            } else {
                capacityScheduleForms[index].controls['capacityAvailable'].patchValue(true);
            }
        }
        return capacityScheduleForms;
    }

    public static EnableDisableCapacityScheduleForm(facilityScheduleForm: FormGroup): void {
        const operational = facilityScheduleForm.controls['capacityAvailable'];
        const capacityPerDay = facilityScheduleForm.controls['capacityPerDay'];

        if (operational.value) {
            capacityPerDay.enable();
            [capacityPerDay].forEach(control => {
                control.setValidators([NumberLengthValidator(7)]);
                control.updateValueAndValidity();
            });
        } else {
            capacityPerDay.disable();
            [capacityPerDay].forEach(control => {
                control.setValidators([NumberLengthValidator(7)]);
                control.updateValueAndValidity();
            });
            capacityPerDay.patchValue(null);
        }
    }

    public static GetCapacityFormValue(capacityForm: FormGroup): any {
        const formValue = capacityForm.getRawValue();
        delete formValue.mcdotNumberObj;
        delete formValue.carrierNameObj;
        delete formValue.originCityObj;
        delete formValue.originStateObj;
        delete formValue.originZipObj;
        delete formValue.destinationCityObj;
        delete formValue.destinationStateObj;
        delete formValue.destinationZipObj;
        return formValue;
    }

    public static GetCapacityIdValue(plNumber: string, capacityDetailId: number): string {
        const awardId = capacityDetailId.toString().padStart(4, '0');
        return plNumber.substring(0, plNumber.indexOf(awardId));
    }
}
