import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@app/core/constants/constants';
import { CustomerScorecard } from '@app/modules/customer/customer.model';
import { CustomerService } from '@app/modules/customer/customer.service';

@Component({
  selector: 'app-customer-scorecard',
  templateUrl: './customer-scorecard.component.html',
  styles: [
  ]
})
export class CustomerScorecardComponent implements OnInit {

  apptOnTimePickPct!: string | null;
  apptOnTimeDeliveryPct!: string | null;
  tenderAcceptancePct!: string | null;
  tendersExpired!: string | null;
  tender!: string | null;
  avgCheckCallTimeliness!: string | null;
  etrackPct!: string | null;
  radOnTimePickPct!: string | null;
  rddOnTimeDeliveryPct!: string | null;
  constants: typeof Constants = Constants;
  @Input() customerScorecard!: CustomerScorecard;
  constructor(
    private readonly customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.apptOnTimePickPct = this.customerScorecard?.apptOnTimePickPct;
    this.apptOnTimeDeliveryPct = this.customerScorecard?.apptOnTimeDeliveryPct;
    this.rddOnTimeDeliveryPct = this.customerScorecard?.rddOnTimeDeliveryPct;
    this.radOnTimePickPct = this.customerScorecard?.radOnTimePickPct;
    this.tenderAcceptancePct = this.customerScorecard?.tenderAcceptancePct;
    this.tendersExpired = this.customerScorecard?.tendersExpired;
    this.avgCheckCallTimeliness = this.customerScorecard?.avgCheckCallTimeliness;
    this.etrackPct = this.customerScorecard?.etrackPct;
  }
}
