import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { Constants } from '@app/core/constants/constants';
import { Endpoint } from '@app/core/constants/enums';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { String } from 'typescript-string-operations';
import { CreditRequestModel, CustomerContactModel, CustomerContactRequestModel, CustomerDetailModel, CustomerDocumentCofigurationModel, CustomerDocumentModel, CustomerDocumentTypeModel, CustomerFilter, CustomerListModel, CustomerLockDetailModel, CustomerModel, CustomerNameResponseModel, CustomerRequirementsModel, CustomerStakeholderModel, CustomerActivityModel, AuditLogsListModel, CustomerAccessorial, CustomerAccessorialModel, CustomerAccessorialList, CustomerAccessorialFilterRequestModel, CustomerScorecard } from './customer.model';
import { SelectListModel } from '@app/core/models/select.list.model';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class CustomerService {
  customerEndpoint!: string;
  identityEndpoint!: string;
  loadEndpoint!: string;
  private headquarterValueSubject = new BehaviorSubject<any>(null);
  headquarterValue$ = this.headquarterValueSubject.asObservable();
  private contactActiveDirtyFlagSubject = new BehaviorSubject<any>(null);
  contactActiveDirtyFlag$ = this.contactActiveDirtyFlagSubject.asObservable();
  private getAccountingFlagSubject = new BehaviorSubject<any>(null);
  getAccountingFlag$ = this.getAccountingFlagSubject.asObservable();
  private getRequirementsFlagSubject = new BehaviorSubject<any>(null);
  getRequirementsFlag$ = this.getRequirementsFlagSubject.asObservable();

  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.customerEndpoint = this.appConfigService.getEndpoint(Endpoint.Customer);
    this.identityEndpoint = this.appConfigService.getEndpoint(Endpoint.Identity);
    this.loadEndpoint = this.appConfigService.getEndpoint(Endpoint.Load);
  }

  setHeadquarterValue(value: any) {
    this.headquarterValueSubject.next(value);
  }

  setContactActiveDirtyFlag(value: any) {
    this.contactActiveDirtyFlagSubject.next(value);
  }

  setAccountingFlag(value: any) {
    this.getAccountingFlagSubject.next(value);
  }

  setRequirementsFlag(value: any) {
    this.getRequirementsFlagSubject.next(value);
  }

  getCustomers(payload: CustomerFilter): Observable<ResponseModel<CustomerListModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerList}`;
    return this.apiClient.post<CustomerFilter, ResponseModel<CustomerListModel>>(url, payload);
  }

  getCustomer(customerId: number): Observable<ResponseModel<CustomerDetailModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerById}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerDetailModel>>(url);
  }

  checkDuplicateCustomerCode(customerCode: string, customerId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.checkDuplicateCustomerCode}`;
    url = String.format(url, encodeURIComponent(customerCode), customerId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  createCustomer(payload: CustomerModel): Observable<ResponseModel<CustomerModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.createCustomer}`;
    return this.apiClient.post<CustomerModel, ResponseModel<CustomerModel>>(url, payload);
  }

  updateCustomer(payload: CustomerModel): Observable<ResponseModel<CustomerModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.updateCustomer}`;
    return this.apiClient.put<CustomerModel, ResponseModel<CustomerModel>>(url, payload);
  }

  updateCustomerStatus(isActivateStatus: boolean, customerId: number, reasonId = 0, fromListing = false): Observable<ResponseModel<CustomerModel>> {
    let url = `${this.customerEndpoint}${fromListing ? API_ROUTES.customer.updateCustomerStatus : API_ROUTES.customer.updateCustomerProfileStatus}`;
    url = String.format(url, isActivateStatus, customerId, reasonId);
    return this.apiClient.put<undefined, ResponseModel<CustomerModel>>(url);
  }

  getCustomerTierSelectList(): Observable<ResponseModel<any[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerTierSelectList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, customerTierId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getDepartmentSelectList(): Observable<ResponseModel<any[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getDepartmentSelectList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  getAccountOwnerSelectList(selectedStakeholders = ''): Observable<ResponseModel<any[]>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getAccountOwnerSelectList}`;

    url = url + (selectedStakeholders ? `?stakeholders=${selectedStakeholders}` : ``);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, UserId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getIndustryTypeSelectList(): Observable<ResponseModel<any[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getIndustryTypePageSelectList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, industryTypeId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getContactTierSelectList(): Observable<ResponseModel<any[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getContactTierSelectList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, contactTierId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  // CustomerContacts service calls

  getCustomerContacts(customerId: number): Observable<ResponseModel<CustomerContactModel[]>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customerContact.getCustomerContactList}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerContactModel[]>>(url);
  }

  addCustomerContact(payload: CustomerContactRequestModel): Observable<ResponseModel<CustomerContactModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customerContact.createCustomerContact}`;
    return this.apiClient.post<CustomerContactRequestModel, ResponseModel<CustomerContactModel>>(url, payload);
  }

  updateCustomerContact(payload: CustomerContactRequestModel): Observable<ResponseModel<CustomerContactModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customerContact.updateCustomerContact}`;
    return this.apiClient.put<CustomerContactRequestModel, ResponseModel<CustomerContactModel>>(url, payload);
  }

  updateStakeholder(payload: CustomerStakeholderModel): Observable<ResponseModel<CustomerStakeholderModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.updateStakeholder}`;
    return this.apiClient.put<CustomerStakeholderModel, ResponseModel<CustomerStakeholderModel>>(url, payload);
  }

  getReasonList(): Observable<ResponseModel<any[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getReasonList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  checkCustomerCredit(customerId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.checkCustomerCredit}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerDetailModel>>(url);
  }

  creditRequest(payload: CreditRequestModel): Observable<ResponseModel<CreditRequestModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.creditRequest}`;
    return this.apiClient.post<CreditRequestModel, ResponseModel<CreditRequestModel>>(url, payload);
  }

  getCustomerAccounting(customerId: number | undefined): Observable<ResponseModel<CustomerDetailModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getAccountingCustomerById}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerDetailModel>>(url);
  }

  getCustomerListByName(customerName: string): Observable<ResponseModel<any[]>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerListByName}`;
    url = String.format(url, customerName);
    return this.apiClient.get<ResponseModel<any[]>>(url)
  }

  getCustomerDetail(customerId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerDetail}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  checkDuplicateCustomerName(customerName: string, customerId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.checkDuplicateCustomerName}`;
    url = String.format(url, encodeURIComponent(customerName), customerId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  getAllDepartmentUsers(departmentCode: string, userIds = ''): Observable<ResponseModel<any>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.user.getUserByDepartmentCode}`;
    url = String.format(url, departmentCode, userIds);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getCustomerStatusList(): Observable<ResponseModel<any[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerStatusSelectList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  getCustomerLoadCount(customerId: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getCustomerLoadCount}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  updateCustomerCredit(customerId: number | undefined): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.updateCustomerCredit}`;
    url = String.format(url, customerId);
    return this.apiClient.put<undefined, ResponseModel<any>>(url);
  }


  lockCustomer(customerId: number): Observable<ResponseModel<CustomerLockDetailModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.lockCustomer}`;
    url = String.format(url, customerId);
    return this.apiClient.post<undefined, ResponseModel<CustomerLockDetailModel>>(url);
  }

  unlockCustomer(customerId: number, isBroadCastOnly: boolean, unlockTimeInSec: number, lockedBy: number): Observable<ResponseModel<string>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.unlockCustomer}`;
    url = String.format(url, customerId, isBroadCastOnly, unlockTimeInSec, isBroadCastOnly ? false : true, lockedBy);
    return this.apiClient.delete<ResponseModel<any>>(url);
  }

  getCustomerLockDetail(customerId: number): Observable<ResponseModel<CustomerLockDetailModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.customerLockDetails}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerLockDetailModel>>(url);
  }

  getCustomerDocumentConfiguration(): Observable<ResponseModel<CustomerDocumentCofigurationModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerDocumentConfiguration}`;
    return this.apiClient.get<ResponseModel<CustomerDocumentCofigurationModel>>(url);
  }

  getCustomerDocumentList(customerId: number): Observable<ResponseModel<CustomerDocumentModel[]>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerDocumentList}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerDocumentModel[]>>(url);
  }

  getCustomerDocumentTypeList(): Observable<ResponseModel<CustomerDocumentTypeModel[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerDocumentTypeList}`;
    return this.apiClient.get<ResponseModel<CustomerDocumentTypeModel[]>>(url);
  }

  downloadCustomerDocument(documentId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.downloadCustomerDocument}`;
    url = String.format(url, documentId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  deleteCustomerDocument(documentId: number, customerId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.deleteCustomerDocument}`;
    url = String.format(url, documentId, customerId);
    return this.apiClient.delete<ResponseModel<any>>(url);
  }

  uploadCustomerDocument(payload: any): Observable<any> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.uploadCustomerDocument}`;
    return this.apiClient.post<any, any>(url, payload);
  }

  uploadFuelSurchargeDocument(payload: any): Observable<any> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.uploadFuelSurchargeDocument}`;
    return this.apiClient.post<any, any>(url, payload);
  }

  downloadFuelSurchargeDocument(documentId: number, customerId: number): Observable<any> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.downloadFuelSurchargeDocument}`;
    url = String.format(url, documentId, customerId);
    return this.apiClient.get<any>(url);
  }
  updateManageFuelSurcharge(customerId: number, canManageFuelSurcharge: boolean): Observable<any> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.updateManageFuelSurcharge}`;
    url = String.format(url, customerId, canManageFuelSurcharge);
    return this.apiClient.put<any, any>(url);
  }
  getCustomerFuelSurchargeList(customerId: number): Observable<any> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerFuelSurchargeList}`;
    url = String.format(url, customerId);
    return this.apiClient.get<any>(url);
  }

  createCustomerActivity(payload: CustomerActivityModel): Observable<ResponseModel<CustomerActivityModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.customerActivity.createCustomerActivity}`;
    return this.apiClient.post<CustomerActivityModel, ResponseModel<CustomerActivityModel>>(url, payload);
  }

  getActivitySelectList(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getActivityTypeSelectList}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList?.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getCustomerActivities(customerId: number): Observable<ResponseModel<CustomerActivityModel[]>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.customerActivity.getCustomerActivityList}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerActivityModel[]>>(url);
  }

  getRequirementsDetails(customerId: number): Observable<ResponseModel<CustomerRequirementsModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerRequirements}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerRequirementsModel>>(url);
  }

  checkCustomerExists(customerName: string): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.checkCustomerExists}`;
    url = String.format(url, encodeURIComponent(customerName));
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  getCustomerSelectList(customerName?: string): Observable<ResponseModel<CustomerNameResponseModel[]>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerSelectList}`;
    url = String.format(url, customerName ?? '');
    return this.apiClient.get<ResponseModel<CustomerNameResponseModel[]>>(url);
  }

  getCustomerAuditLogs(payload: CustomerFilter): Observable<ResponseModel<AuditLogsListModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerAuditLogList}`;
    return this.apiClient.post<CustomerFilter, ResponseModel<AuditLogsListModel>>(url, payload);
  }

  addActivityLog(customerId: number, tabName: string): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.addActivityLog}`;
    url = String.format(url, customerId, encodeURIComponent(tabName));
    return this.apiClient.post<any, any>(url);
  }

  addDocumentLog(customerId: number, documentId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.addDocumentLog}`;
    url = String.format(url, customerId, documentId);
    return this.apiClient.post<any, any>(url);
  }

  getCustomerAccountingForLoad(customerId: number | undefined): Observable<ResponseModel<CustomerDetailModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getAccountingCustomerByIdForLoad}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerDetailModel>>(url);
  }

  getCustomerDetailForLoad(customerId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerDetailForLoad}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  updateCustomerActivity(payload: CustomerActivityModel): Observable<ResponseModel<CustomerActivityModel>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.customerActivity.updateCustomerActivity}`;
    return this.apiClient.put<CustomerActivityModel, ResponseModel<CustomerActivityModel>>(url, payload);
  }

  deleteCustomerActivity(payload: CustomerActivityModel): Observable<ResponseModel<CustomerActivityModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.customerActivity.deleteCustomerActivity}`;
    url = String.format(url, payload.customerActivityId, payload.customerId);
    return this.apiClient.delete<ResponseModel<CustomerActivityModel>>(url);
  }

  saveCustomerAccessorial(payload: CustomerAccessorial, isEdit: boolean): Observable<ResponseModel<CustomerAccessorial>> {
    if (!isEdit) {
      const url = `${this.customerEndpoint}${API_ROUTES.customer.addCustomerAccessorial}`;
      return this.apiClient.post<any, any>(url, payload);
    }
    else {
      const url = `${this.customerEndpoint}${API_ROUTES.customer.updateCustomerAccessorial}`;
      return this.apiClient.put<any, any>(url, payload);
    }
  }

  updateLinehaulAndFuelSurcharge(fuelSurchargeDisplayLabel: string | null, lineHaulChargeDisplayLabel: string | null, customerId: number, isAddButtonPressed: boolean) {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.updateLinehaulAndFuelSurcharge}`;
    url = String.format(url, fuelSurchargeDisplayLabel, lineHaulChargeDisplayLabel, customerId, isAddButtonPressed);
    return this.apiClient.put<CustomerActivityModel, ResponseModel<CustomerActivityModel>>(url);
  }

  getCustomerAccessorialList(payload: CustomerAccessorialFilterRequestModel): Observable<ResponseModel<CustomerAccessorialList>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerAccessorialList}`;
    return this.apiClient.post<CustomerAccessorialFilterRequestModel, ResponseModel<CustomerAccessorialList>>(url, payload);
  }

  updateCustomAccessorialStatus(customerAccessorialId: number, customerId: number, isActive: boolean): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.updateCustomAccessorialStatus}`;
    url = String.format(url, customerAccessorialId, customerId, isActive);
    return this.apiClient.put<CustomerAccessorialFilterRequestModel, ResponseModel<any>>(url);
  }

  checkDuplicateCustomerAccessorialNameCode(accessorialId: number, accessorialName: string, accessorialCode: string, customerId: number, customerAccessorialId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.checkDuplicateCustomerAccessorialNameCode}`;
    url = String.format(url, accessorialId, accessorialName, accessorialCode, customerId, customerAccessorialId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  checkDuplicateCustomerAccessorialCustomName(value: string, customerId: number, customerAccessorialId: number): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.checkDuplicateCustomerAccessorialCustomName}`;
    url = String.format(url, encodeURIComponent(value), customerId, customerAccessorialId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  getCustomerScoreCard(customerId: number): Observable<ResponseModel<CustomerScorecard>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerScoreCard}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerScorecard>>(url);
  }

  checkDuplicateBreakthroughId(customerRequirementsId: number, breakthroughId: string): Observable<ResponseModel<any>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.checkDuplicateBreakthroughId}`;
    url = String.format(url, customerRequirementsId, breakthroughId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  exportCustomerListingToCSV(filterRequest: CustomerFilter): Observable<ResponseModel<any>> {
    const url = `${this.customerEndpoint}${API_ROUTES.customer.sendExportCsvFilePayloadToServiceBus}`;
    filterRequest.pageSize = Constants.maxPageSizeForExportListing;
    filterRequest.pageNumber = 1;
    filterRequest.pageSize = Constants.maxPageSizeForExportListing;
    return this.apiClient.post<CustomerFilter, ResponseModel<any>>(url, filterRequest);
  }

  addActivityLogForZoom(customerId: number, phoneNumber: string): Observable<ResponseModel<any>>{
    let url = `${this.customerEndpoint}${API_ROUTES.customer.addActivityLogForZoom}`;
    url = String.format(url, customerId, phoneNumber);
    return this.apiClient.post<any, any>(url);
  }
}
