<div [formGroup]="formName">
    <label *ngIf="!hideLabel" for="{{ elementId }}" [ngClass]="{'disable':control.disabled}">
        {{ label }}
        <em *ngIf="isRequired" class="asterisk"></em>
        <em *ngIf="iTagData !== ''" class="ml-2 pi pi-info-circle" tooltipEvent="hover|focus" tabindex="0"
            [attr.aria-label]="iTagData">
        </em>
    </label>
    <span class="p-input-icon-right d-block">
        <input class="form-control {{ className }}" [attr.autofocus]="isAutoFocus" [type]="inputType" [id]="elementId"
            [maxlength]="maxLength" [minlength]="minLength" [tabIndex]="tabIndex" name="{{ controlName }}"
            autocomplete="new-password" data-placement="bottom" data-trigger="manual" [placeholder]="placeholder"
            [formControlName]="controlName" [attr.aria-required]="isRequired ? 'true' : null"
            [attr.aria-invalid]="isValid" [attr.aria-describedby]="'err-' + elementId" (blur)="onBlur()"
            (input)="onInput()" (keyup)="onKeyup($event)" />
    </span>

    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</div>