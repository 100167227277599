import { Injectable } from '@angular/core';
import { String } from 'typescript-string-operations';
import { Constants } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  static getMessage(type: string, params: any, label: string): string {
    switch (type) {
      case 'required':
        return String.format(Constants.requiredValidation, label, params);
      case 'maxlength':
        return String.format(Constants.maxLengthValidation, label, params.requiredLength);
      case 'minlength':
        return String.format(Constants.minLengthValidation, label, params.requiredLength);
      case 'max':
        return String.format(Constants.maxValueValidation, label, params.max);
      case 'min':
        return String.format(Constants.minValueValidation, label, params.min);
      case 'duplicatename':
        return String.format(Constants.duplicateValidation, label, params);
      case 'invalidEmail':
        return String.format(Constants.emailValidation, label, params);
      case 'invalidwebsite':
        return String.format(Constants.websiteValidation, label, params);
      case 'invalidalphanumeric':
        return String.format(Constants.alphaNumericValidation, label, params);
      case 'invalidamount':
        return String.format(Constants.amountValidation, label, params);
      case 'invalidTime':
        return Constants.invalidTimeValidation;
      case 'invalidmaxbuy':
        return Constants.maxBuyValidation;
      case 'invalidtargetRate':
        return Constants.targetRateValidation;
      case 'invalidtotalcarrierrate':
        return Constants.carrierRateVsMaxBuy;
      case 'invalidmcumber':
        return Constants.invalidMCNumber;
      case 'dnucarrier':
        return Constants.dnuCarrierValidation;
      case 'invalidbidamount':
        return Constants.approvedBidAmountValidation;
      case 'invalidtoshipdate':
        return Constants.toShipDateValidation;
      case 'invalidtodeliverydate':
        return Constants.toDeliveryDateValidation;
      case 'invalidtotalcarrierrateforbidamount':
        return Constants.loadCarrierApprovedAmount;
      case 'invalidDate':
        return String.format(Constants.invalidDateValidation, label);
      case 'valuenotmatch':
        return String.format(Constants.valueNotMatch, label);
      case 'invalidAppointmentDate':
        return Constants.invalidAppointmentDate;
      case 'invalidAppointmentTime':
        return Constants.invalidAppointmentTime;
      case 'invalidDropDate':
        return Constants.invalidDropDate;
      case 'invalidDueDate':
        return Constants.invalidDueDate;
      case 'invalidBillDueDate':
        return Constants.invalidBillDueDate;
      case 'invalidStartDate':
        return Constants.startDateValidation;
      case 'invalidEndDate':
        return Constants.endDateValidation;
      case 'invalidStartTime':
        return Constants.startTimeValidation;
      case 'invalidEndTime':
        return Constants.endTimeValidation;
      case 'duplicateAccessorialMapping':
        return Constants.duplicateAccessorialMapping;
      case 'htmlCharacters':
        return Constants.invalidTextValidation;
      case 'duplicateCapacity':
        return Constants.duplicateCapacityValidation;
      case 'duplicateFacilityAddress':
        return Constants.facilityAddressDuplicateMessage;
      case 'invalidAdditionalRecipents':
        return String.format(Constants.invalidAdditionalRecipents);
      case 'invalidAllInRate':
        return Constants.invalidAllInRateMessage;
      case 'negativeAccessorial':
        return Constants.negativeAccessorialMessage;
      case 'customerNegativeRate':
        return Constants.customerNegativeRateMessage;
      case 'carrierNegativeRate':
        return Constants.carrierNegativeRateMessage;
      case 'totalCarrierRateValidationTargetRate':
        return Constants.carrierRateVsTargetRate;
      default:
        return '';
    }
  }

}
