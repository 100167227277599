import { SystemUser, UserSession } from "../constants/enums";
import { UserDetailModel } from "../models/user-detail.model";

export class LoginHelper {
    public static User: UserDetailModel;

    // This method will set user detail in local storage.
    public static SetUserDetail(user: UserDetailModel): void {
        LoginHelper.User = user;
        localStorage.setItem(UserSession.User, JSON.stringify(user));
    }

    // This method will get user detail from local storage.
    public static GetUserDetail(): UserDetailModel {
        return this.User ? this.User : JSON.parse(localStorage.getItem(UserSession.User) ?? JSON.parse("null"));
    }

    // This method will check module permission added or not
    public static IsModuleAccessible(moduleName: string, permissionCode: string): boolean {
        const module = LoginHelper.GetUserDetail()?.rolePermissions.filter(
            module => module.permissionModuleCode === moduleName
        );
        if (module?.length > 0) {
            const permission = module.find(m => m.permissionCode === permissionCode);
            return permission ? true : false;
        } else {
            return false;
        }
    }

    // This method is used to check whether passed userName is system user or not
    public static IsSystemUser(userName: any): boolean {
        if (userName && Object.values(SystemUser).includes(userName)) {
            return true;
        }
        return false;
    }
}
