<div [formGroup]="formName">
    <p-checkbox [formControlName]="controlName" [name]="name" [value]="value" [inputId]="elementId"
        (onChange)="onCheckboxChanged($event)" [disabled]="disabled" [binary]="true" [readonly]="readonly"></p-checkbox>
    <label for='{{elementId}}' [ngClass]="{'disable':control.disabled}" class="ms-2 mt-1">
        {{ label }}
        <em *ngIf="isRequired" class="asterisk"></em>
        <em *ngIf="iTagData !== ''" class="ml-2 pi pi-info-circle" tooltipEvent="hover|focus" tabindex="0"
            [attr.aria-label]="iTagData"></em>
    </label>
    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</div>