import { EventEmitter, Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { Constants } from '@app/core/constants/constants';
import { CallerScreen, Endpoint, LoadStatusReasonType } from '@app/core/constants/enums';
import { ResponseModel } from '@app/core/models/api.response.model';
import { LazyList } from '@app/core/models/lazy-list.response.model';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { CheckCallSelectListModel, GlobalSearchSelectListModel, SelectListModel } from '@app/core/models/select.list.model';
import { ZipCodeResponseModel } from '@app/core/models/zipcode.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { SelectListView, defaultSelectListView } from '@app/shared/user-view-add-edit/user-view.model';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { String } from 'typescript-string-operations';
import { ConfigurationDetail } from '../administration/configuration/configuration.model';
import { LoadBidApproveRejectRequestModel, LoadBidsOffersModel } from './load-edit/load-bids-offers/load-bids-offers.model';
import { LoadDocumentModel } from './load-edit/load-documents/load-document.model';
import { AssignedToSelectListModel, LoadIncidentModel, LoadIncidentTypeModel } from './load-edit/load-incident/load-incident.model';
import { LoadLanePricingModel } from './load-edit/load-lane-pricing/load-lane-pricing.model';
import { LoadTruckMatchModel, TMSTruckUpdateStatus, TruckMatchResultModel, TruckMatchResultStatusRequest, TuckMatchPreferredLaneModel, TuckMatchPreferredLaneStatusRequest } from './load-edit/truck-match/truck-match.model';
import { AssignLoadTenderModel, CustomerDetailResponseModel, CustomerResponseModel, DatPostModel, Dispatcher, EDICustomerModel, EdiExchangeHistory, EdiLoadTenderModel, EdiUpdateModel, FacilityResponseModel, FuelSurchargeRateDetailViewModel, GridParams, LoadActivityModel, LoadBoardViewModel, LoadBounceRecoverResponse, LoadBounceRequest, LoadCheckCallEventUpdatesModel, LoadCheckCallViewModel, LoadCloneModel, LoadCustomerViewModel, LoadDetailStakeholderModel, LoadDetailViewModel, LoadLockDetailModel, LoadRateCarrier, LoadRateConfirmation, LoadRateConfirmationESignRequestViewModel, LoadRateConfirmationESignViewModel, LoadSnoozeModel, LoadStatusSummaryModel, LoadTimelineViewModel, MilesRequestModel, PurchaseJournal, RejectEdiLoadTenderModel, SelectListLoadStatusItem, TrackingBoardExceptionSummaryViewModel, TrackingBoardViewModel, triggerViewEventVal } from './load.model';
import { AuditLogListModel, LazyListAuditLogsListModel } from '@app/shared/components/audit-log/audit-log.model';
@Injectable({
  providedIn: 'root'
})
export class LoadService {

  administrationEndpoint!: string;
  customerEndpoint!: string;
  loadEndpoint!: string;
  carrierEndpoint!: string;
  identityEndpoint!: string;
  accountingEndpoint!: string;
  isLoadDirty = false;

  private bidApprovalSubject = new BehaviorSubject<boolean>(true);
  bidApproval$ = this.bidApprovalSubject.asObservable();
  private loadPostUnPost = new BehaviorSubject<any>(null);
  getLoadPostUnPost$ = this.loadPostUnPost.asObservable();
  private paradeCapacityGridSubject = new BehaviorSubject<boolean>(true);
  paradeCapacityGrid$ = this.paradeCapacityGridSubject.asObservable();
  private datCapacityGridSubject = new BehaviorSubject<boolean>(true);
  datCapacityGrid$ = this.datCapacityGridSubject.asObservable();
  private macroPointCapacityGridSubject = new BehaviorSubject<boolean>(true);
  macroPointCapacityGridSubject$ = this.macroPointCapacityGridSubject.asObservable();
  private checkCallGridSubject = new BehaviorSubject<boolean>(true);
  checkCallGrid$ = this.checkCallGridSubject.asObservable();
  private loadBoardViewSubject = new BehaviorSubject<SelectListView>(defaultSelectListView);
  loadBoardViewSubject$ = this.loadBoardViewSubject.asObservable();
  public triggerViewEvent: EventEmitter<any> = new EventEmitter();
  public triggerAddViewEnableEvent: EventEmitter<any> = new EventEmitter();
  private overlayPanelSubject = new BehaviorSubject<number>(0);
  overlayPanelSubject$ = this.overlayPanelSubject.asObservable();
  private triggerViewEventVal = new BehaviorSubject<GridParams>(triggerViewEventVal);
  triggerViewEventVal$ = this.triggerViewEventVal.asObservable();
  private incidentsGridSubject = new BehaviorSubject<boolean>(true);
  incidentsGrid$ = this.incidentsGridSubject.asObservable();
  private loadModeSubject = new Subject<string>();
  loadMode$ = this.loadModeSubject.asObservable();
  public triggerTrackingBoardViewEvent: EventEmitter<any> = new EventEmitter();
  public triggerTrackingBoardAddViewEnableEvent: EventEmitter<any> = new EventEmitter();
  private trackingBoardViewSubject = new BehaviorSubject<SelectListView>(defaultSelectListView);
  trackingBoardViewSubject$ = this.trackingBoardViewSubject.asObservable();
  private triggerTrackingBoardViewEventVal = new BehaviorSubject<GridParams>(triggerViewEventVal);
  triggerTrackingBoardViewEventVal$ = this.triggerTrackingBoardViewEventVal.asObservable();
  private editPanelSubject = new BehaviorSubject<number>(0);
  editPanelSubject$ = this.overlayPanelSubject.asObservable();

  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.carrierEndpoint = this.appConfigService.getEndpoint(Endpoint.Carrier);
    this.loadEndpoint = this.appConfigService.getEndpoint(Endpoint.Load);
    this.administrationEndpoint = this.appConfigService.getEndpoint(Endpoint.Administration);
    this.customerEndpoint = this.appConfigService.getEndpoint(Endpoint.Customer);
    this.identityEndpoint = this.appConfigService.getEndpoint(Endpoint.Identity);
    this.accountingEndpoint = this.appConfigService.getEndpoint(Endpoint.Accounting);
  }

  getStakeholderSelectList(departmentCodes?: string, userIds?: string): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.user.getUserByDepartmentCode}`;
    url = String.format(url, departmentCodes ?? '', userIds || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  getZipCodeSelectList(payload: GridFilterRequestModel, isDistinct = false): Observable<ResponseModel<ZipCodeResponseModel[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.zipCode.getZipCodeSelectList}`;
    url = String.format(url, isDistinct);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<ZipCodeResponseModel[]>>(url, payload);
  }

  getCustomerSelectList(customerName?: string): Observable<ResponseModel<CustomerResponseModel[]>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerSelectList}`;
    url = String.format(url, customerName ?? '');
    return this.apiClient.get<ResponseModel<CustomerResponseModel[]>>(url);
  }

  getCustomerDetail(customerId: number): Observable<ResponseModel<CustomerDetailResponseModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getCustomerDetail}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<CustomerDetailResponseModel>>(url);
  }

  createLoad(payload: LoadDetailViewModel): Observable<ResponseModel<any>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.createLoad}`;
    return this.apiClient.post<LoadDetailViewModel, any>(url, payload);
  }

  updateLoad(payload: LoadDetailViewModel): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.updateLoad}`;
    const editFromAP = sessionStorage.getItem(Constants.loadEditFromAP);
    const editFromAR = sessionStorage.getItem(Constants.loadEditFromAR);
    const editFromAuditReview = sessionStorage.getItem(Constants.loadEditFromAuditReview);

    if (editFromAP) {
      url = String.format(url, editFromAuditReview ? CallerScreen.AuditReview : CallerScreen.PreAP);
    } else if (editFromAR) {
      url = String.format(url, editFromAuditReview ? CallerScreen.AuditReview : CallerScreen.PreAR);
    } else {
      url = String.format(url, CallerScreen.LoadProfile);
    }
    return this.apiClient.post<LoadDetailViewModel, any>(url, payload);
  }

  getQuantityTypeSelectList(quantityTypeId?: number): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.quantityType.getQuantityTypeSelectList}`;
    url = String.format(url, quantityTypeId ? quantityTypeId : '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  getPieceTypeSelectList(pieceTypeId?: number): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.pieceType.getPieceTypeSelectList}`;
    url = String.format(url, pieceTypeId ? pieceTypeId : '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  getFacilitySelectList(payload: GridFilterRequestModel): Observable<ResponseModel<FacilityResponseModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.facility.getFacilitySelectList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<FacilityResponseModel[]>>(url, payload);
  }

  getLoadModeSelectList(loadModeId: string): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadModeSelectList}`;
    url = String.format(url, loadModeId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getLoadTypeSelectList(loadTypeId: string): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadTypeSelectList}`;
    url = String.format(url, loadTypeId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getLoadTarpSizeSelectList(tarpSizeId: string): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadTarpSizeSelectList}`;
    url = String.format(url, tarpSizeId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getLoadReferenceNumberType(referenceNumberTypeTypeIds = []): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getReferenceNumberTypeSelectList}`;
    url = String.format(url, referenceNumberTypeTypeIds);
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList?.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }
  getLoadActivities(loadDetailId: number): Observable<ResponseModel<LoadActivityModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadActivity.getLoadActivityList}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<LoadActivityModel[]>>(url);
  }

  getActivitySelectList(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getActivityTypeSelectList}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  createLoadActivity(payload: LoadActivityModel): Observable<ResponseModel<LoadActivityModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadActivity.createLoadActivity}`;
    return this.apiClient.post<LoadActivityModel, ResponseModel<LoadActivityModel>>(url, payload);
  }

  getRateConfirmation(loadDetailId: number): Observable<ResponseModel<LoadRateConfirmation[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.getRateConfirmationList}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<LoadRateConfirmation[]>>(url);
  }

  getLoadCarriers(loadDetailId: number): Observable<ResponseModel<LoadRateCarrier[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.getLoadCarriersList}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<LoadRateCarrier[]>>(url);
  }

  createRateConfirmation(payload: LoadRateConfirmation): Observable<ResponseModel<string>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.createRateConfirmation}`;
    return this.apiClient.post<LoadRateConfirmation, ResponseModel<string>>(url, payload);
  }

  getLoadDispatcher(loadCarrierId: number): Observable<ResponseModel<Dispatcher[]>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.load.rateConfirmation.getCarrierDispatcherList}`;
    url = String.format(url, loadCarrierId);
    return this.apiClient.get<ResponseModel<Dispatcher[]>>(url).pipe(map(response => {
      const selectList = response.data;
      selectList.unshift({
        dispatcherName: Constants.pleaseSelectDefaultText,
        dispatcherId: null,
        email: '',
        cellPhone: '',
        officePhone: '',
        isActive: true,
      });
      response.data = selectList;
      return response;
    }));
  }

  checkLoadNumberExist(loadDetailId: number | null): Observable<ResponseModel<boolean>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.loadNumberExists}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<boolean>>(url);
  }

  getLoadDetailList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadBoardViewModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadDetailList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadBoardViewModel[]>>>(url, payload);
  }

  getLoadStatusSummary(payload: GridFilterRequestModel, includeCanceled = false, includeClosed = false): Observable<ResponseModel<LoadStatusSummaryModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadStatusSummary}`;
    url = String.format(url, includeCanceled, includeClosed);
    payload = JSON.parse(JSON.stringify(payload));
    payload.sortOrders = [
      {
        sortOrderField: 'loadStatusId',
        sortOrderType: 'asc'
      }];
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LoadStatusSummaryModel[]>>(url, payload);
  }

  getTrackingBoardList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<TrackingBoardViewModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.trackingBoard.getTrackingBoardList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<TrackingBoardViewModel[]>>>(url, payload);
  }

  getTrackingBoardSelectList(): Observable<ResponseModel<TrackingBoardExceptionSummaryViewModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.trackingBoard.getTrackingBoardSelectList}`;
    return this.apiClient.post<undefined, ResponseModel<TrackingBoardExceptionSummaryViewModel>>(url);
  }

  lockLoad(loadDetailId: number, userId: number): Observable<ResponseModel<LoadLockDetailModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.lockLoad}`;
    url = String.format(url, loadDetailId, userId);
    return this.apiClient.post<undefined, ResponseModel<LoadLockDetailModel>>(url);
  }

  unLockLoad(loadDetailId: number, userId: number, unlock: boolean): Observable<ResponseModel<string>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.unlockLoad}`;
    url = String.format(url, loadDetailId, userId, unlock, unlock ? CallerScreen.LoadProfileCancel : '');
    return this.apiClient.post<undefined, ResponseModel<string>>(url);
  }

  getLoadLockDetail(loadDetailId: number): Observable<ResponseModel<LoadLockDetailModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadLockDetails}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<LoadLockDetailModel>>(url);
  }

  getLoadStatusSelectList(): Observable<ResponseModel<SelectListLoadStatusItem[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadStatusSelectList}`;
    return this.apiClient.get<ResponseModel<SelectListLoadStatusItem[]>>(url);
  }

  getLoadDetail(loadDetailId: number, callerScreen = CallerScreen.LoadProfile): Observable<ResponseModel<LoadDetailViewModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadDetail}`;
    url = String.format(url, loadDetailId, callerScreen);
    return this.apiClient.get<ResponseModel<LoadDetailViewModel>>(url);
  }

  getLoadCarrierTrackBy(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrier.getTrackBy}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getReasons(reasonType: LoadStatusReasonType): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrier.getReasons}`;
    url = String.format(url, reasonType);
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  bounceLoad(payload: LoadBounceRequest): Observable<ResponseModel<LoadBounceRecoverResponse>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrier.bounceLoad}`;
    return this.apiClient.post<LoadBounceRequest, ResponseModel<LoadBounceRecoverResponse>>(url, payload);
  }

  recoverLoad(loadDetailId: number, loadCarrierId: number, loadCarrierName: string): Observable<ResponseModel<LoadBounceRecoverResponse>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrier.recoverLoad}`;
    url = String.format(url, loadDetailId, loadCarrierId, loadCarrierName);
    return this.apiClient.post<undefined, ResponseModel<LoadBounceRecoverResponse>>(url);
  }

  getLoadCancelReasonsSelectList(): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadCancelReasonSelectList}`;
    url = String.format(url, Constants.cancelLoad);
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  cancelLoadDetails(loadDetailId: number, description: string, reasonId: number): Observable<ResponseModel<number>> {
    let url = `${this.appConfigService.getEndpoint(Endpoint.Load)}${API_ROUTES.load.loadDetail.cancelLoadDetails}`;
    url = String.format(url, loadDetailId, reasonId, description);
    return this.apiClient.put<undefined, ResponseModel<number>>(url);
  }

  getESignRateConfirmation(loadDetailId: number, loadRateConfirmationGUID: string, encryptedEmail: string): Observable<ResponseModel<LoadRateConfirmationESignViewModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.getRateConfirmation}`;
    url = String.format(url, loadDetailId, loadRateConfirmationGUID, encryptedEmail);
    return this.apiClient.get<ResponseModel<LoadRateConfirmationESignViewModel>>(url);
  }

  updateRateConfirmationEsignDetails(payload: LoadRateConfirmationESignRequestViewModel): Observable<ResponseModel<string>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.updateRateConfirmationEsignDetails}`;
    return this.apiClient.post<LoadRateConfirmationESignRequestViewModel, ResponseModel<string>>(url, payload);
  }

  createLoadCheckCall(payload: LoadCheckCallViewModel, callerScreen: string): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.createLoadCheckCall}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<LoadCheckCallViewModel, any>(url, payload);
  }

  revertLoadCheckCall(loadDetailId: number, callerScreen: string) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.revertLoadCheckCall}`;
    url = String.format(url, loadDetailId, callerScreen);
    return this.apiClient.delete<ResponseModel<LoadCheckCallViewModel>>(url);
  }

  revertAllLoadCheckCall(loadDetailId: number, callerScreen: string) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.revertAllLoadCheckCall}`;
    url = String.format(url, loadDetailId, callerScreen);
    return this.apiClient.delete<ResponseModel<LoadCheckCallViewModel>>(url);
  }

  getLoadCheckCalls(payload: GridFilterRequestModel, callerScreen: string): Observable<ResponseModel<LazyList<LoadCheckCallViewModel[]>>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.getLoadCheckCalls}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadCheckCallViewModel[]>>>(url, payload);
  }

  getLoadCheckCallStatusSelectList(loadCheckCallStatusId?: number): Observable<ResponseModel<CheckCallSelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.getLoadCheckCallSelectList}`;
    url = String.format(url, loadCheckCallStatusId ? loadCheckCallStatusId : '');
    return this.apiClient.get<ResponseModel<CheckCallSelectListModel[]>>(url);
  }

  validateStatus(loadDetailId: number, loadCheckCallStatusId: number) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.validateStatus}`;
    url = String.format(url, loadDetailId, loadCheckCallStatusId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  getTotalCustomerRateForActiveLoads(customerId: number | undefined, loadDetailId?: number): Observable<ResponseModel<number>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getTotalCustomerRateForActiveLoads}`;
    url = String.format(url, customerId, loadDetailId == undefined ? 0 : loadDetailId);
    return this.apiClient.get<ResponseModel<number>>(url);
  }

  createBid(payload: LoadBidsOffersModel): Observable<ResponseModel<LoadBidsOffersModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrierBid.create}`;
    return this.apiClient.post<LoadBidsOffersModel, ResponseModel<LoadBidsOffersModel>>(url, payload);
  }

  bidList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadBidsOffersModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrierBid.list}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadBidsOffersModel[]>>>(url, payload);
  }

  approveRejectBidReasons(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrierBid.getReasons}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  approveRejectCarrierBid(payload: LoadBidApproveRejectRequestModel, isApprove: boolean): Observable<ResponseModel<LoadBidsOffersModel>> {
    const url = `${this.loadEndpoint}${isApprove ? API_ROUTES.load.loadCarrierBid.approve : API_ROUTES.load.loadCarrierBid.reject}`;
    return this.apiClient.post<LoadBidApproveRejectRequestModel, ResponseModel<LoadBidsOffersModel>>(url, payload);
  }

  getApprovedBidAmount(loadDetailId: number, carrierId: number): Observable<ResponseModel<number>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrierBid.getApprovedBidAmount}`;
    url = String.format(url, loadDetailId, carrierId);
    return this.apiClient.get<ResponseModel<number>>(url);
  }

  uploadDocument(payload: FormData, callerScreen = ''): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.document.uploadDocument}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<FormData, ResponseModel<any>>(url, payload);
  }

  getDocumentTypeSelectList(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.document.getDocumentTypeSelectList}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  getDocumentConfigurationDetails(): Observable<ResponseModel<number>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.document.getDocumentConfigurationDetails}`;
    return this.apiClient.get<ResponseModel<number>>(url);
  }

  getDocumentList(loadDetailId: number, callerScreen = ''): Observable<ResponseModel<LoadDocumentModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.document.getDocumentList}`;
    url = String.format(url, loadDetailId, callerScreen);
    return this.apiClient.get<ResponseModel<LoadDocumentModel[]>>(url);
  }

  deleteDocument(id: number, documentPath: string, callerScreen = '', loadDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.document.deleteDocument}`;
    url = String.format(url, id, documentPath, callerScreen, loadDetailId);
    return this.apiClient.delete<ResponseModel<boolean>>(url);
  }

  downloadDocument(documentPath: string, documentId: number, loadDetailId: number, callerScreen = ''): Observable<ResponseModel<string>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.document.downloadDocument}`;
    url = String.format(url, documentPath, documentId, loadDetailId, callerScreen);
    return this.apiClient.get<ResponseModel<string>>(url);
  }
  viewDocumentAuditLog(documentId: number): Observable<ResponseModel<string>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.document.viewDocumentAuditLog}`;
    url = String.format(url, documentId);
    return this.apiClient.post<any, any>(url);
  }
  getMileage(payload: MilesRequestModel): Observable<ResponseModel<number>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.hereMap.getLoadStopMileage}`;
    return this.apiClient.post<MilesRequestModel, ResponseModel<number>>(url, payload);
  }

  setBidApproval(isApproved: boolean) {
    this.bidApprovalSubject.next(isApproved);
  }

  refreshParadeCapacityGrid(isRefresh: boolean) {
    this.paradeCapacityGridSubject.next(isRefresh);
  }

  refreshDatCapacityGrid(isRefresh: boolean) {
    this.datCapacityGridSubject.next(isRefresh);
  }

  refreshMacroPointGrid(isRefresh: boolean) {
    this.macroPointCapacityGridSubject.next(isRefresh);
  }

  refreshCheckCallGrid(isRefresh: boolean) {
    this.checkCallGridSubject.next(isRefresh);
  }

  refreshIncidentsGrid(isRefresh: boolean) {
    this.incidentsGridSubject.next(isRefresh);
  }

  loadModeChanged(loadMode: string) {
    this.loadModeSubject.next(loadMode);
  }

  loadHistoryList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadTruckMatchModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.getLoadHistoryList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadTruckMatchModel[]>>>(url, payload);
  }

  tmsTruckList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadTruckMatchModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.getTMSTruckList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadTruckMatchModel[]>>>(url, payload);
  }

  updateTMSTruckStatus(payload: TMSTruckUpdateStatus): Observable<ResponseModel<TMSTruckUpdateStatus>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.updateTMSTruckStatus}`;
    return this.apiClient.post<TMSTruckUpdateStatus, ResponseModel<TMSTruckUpdateStatus>>(url, payload);
  }

  setLoadBoardDefaultView(loadBoardView: SelectListView) {
    this.loadBoardViewSubject.next(loadBoardView);
  }

  setTrackingBoardDefaultView(trackingBoardView: SelectListView) {
    this.trackingBoardViewSubject.next(trackingBoardView);
  }

  setLoadBoardEnableButton(loadBoardView: any) {
    this.triggerViewEventVal.next(loadBoardView);
  }

  setTrackingBoardEnableButton(trackingBoardView: any) {
    this.triggerTrackingBoardViewEventVal.next(trackingBoardView);
  }

  getLoadIncidentList(payload: GridFilterRequestModel, callerScreen: string): Observable<ResponseModel<LazyList<LoadIncidentModel[]>>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadIncident.getLoadIncidentList}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadIncidentModel[]>>>(url, payload);
  }

  createLoadIncident(loadIncident: LoadIncidentModel, callerScreen: string): Observable<ResponseModel<LoadIncidentModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadIncident.createLoadIncident}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<LoadIncidentModel, ResponseModel<LoadIncidentModel>>(url, loadIncident);
  }

  resolveLoadIncident(loadIncident: LoadIncidentModel, callerScreen: string): Observable<ResponseModel<LoadIncidentModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadIncident.resolveLoadIncident}`;
    url = String.format(url, callerScreen);
    return this.apiClient.put<LoadIncidentModel, ResponseModel<LoadIncidentModel>>(url, loadIncident);
  }

  getIncidentStatusTypeSelectList(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadIncident.getIncidentStatusTypeSelectList}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  getIncidentTypeByIncidentStatusType(loadIncidentStatusTypeId: number): Observable<ResponseModel<LoadIncidentTypeModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadIncident.getIncidentTypeByIncidentStatusType}`;
    url = String.format(url, loadIncidentStatusTypeId);
    return this.apiClient.get<ResponseModel<LoadIncidentTypeModel[]>>(url);
  }

  getAssignToSelectList(userName?: string): Observable<ResponseModel<AssignedToSelectListModel[]>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.user.getUserByDepartmentCodeTypeAhead}`;
    url = String.format(url, '', userName ?? '');
    return this.apiClient.get<ResponseModel<AssignedToSelectListModel[]>>(url);
  }

  getResolutionReasonSelectList(loadIncidentResolutionReasonId?: number): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadIncident.getResolutionReasonSelectList}`;
    url = String.format(url, loadIncidentResolutionReasonId ?? '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  getFilteredTruckMatchResultList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<TruckMatchResultModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.getFilteredTruckMatchResultList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<TruckMatchResultModel[]>>>(url, payload);
  }

  UpdateTruckMatchResultStatus(payload: TruckMatchResultStatusRequest): Observable<ResponseModel<TruckMatchResultStatusRequest>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.updateTruckMatchResultStatus}`;
    return this.apiClient.put<TruckMatchResultStatusRequest, ResponseModel<TruckMatchResultStatusRequest>>(url, payload);
  }

  getCapacityFromParade(loadDetailId: number, isAutoSync: boolean) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.parade.getCapacityFromParade}`;
    url = String.format(url, loadDetailId, isAutoSync);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  getCapacityFromDat(loadDetailId: number, isAutoSync: boolean) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.dat.getCapacityFromDat}`;
    url = String.format(url, loadDetailId, isAutoSync);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  getConfigurationValue(payload: string[]): Observable<ResponseModel<ConfigurationDetail[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.configuration.getConfigurationValue}`;
    return this.apiClient.post<string[], ResponseModel<ConfigurationDetail[]>>(url, payload);
  }
  getDatUserName(userId: number): Observable<ResponseModel<string>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.user.getDatUserName}`;
    url = String.format(url, userId);
    return this.apiClient.get<ResponseModel<string>>(url);
  }

  loadPost(loadPostRequestModel: DatPostModel): Observable<ResponseModel<LoadDetailViewModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.dat.loadPost}`;
    return this.apiClient.post<any, ResponseModel<LoadDetailViewModel>>(url, loadPostRequestModel);
  }

  loadUnPost(loadDetailId: number): Observable<ResponseModel<LoadDetailViewModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.dat.loadUnPost}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.post<any, ResponseModel<LoadDetailViewModel>>(url);
  }

  pararePostToggleVisibility(loadDetailId: number, showOnParadeLoadBoard: boolean): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.parade.pararePostToggleVisibility}`;
    url = String.format(url, loadDetailId, showOnParadeLoadBoard);
    return this.apiClient.post<any, ResponseModel<any>>(url);
  }

  setLoadPostUnPost(value: any) {
    this.loadPostUnPost.next(value);
  }

  getEdiLoadTenderList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<EdiLoadTenderModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.getEdiLoadTenderList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<EdiLoadTenderModel[]>>>(url, payload);
  }

  validateReleaseForBilling(loadDetailId: number): Observable<ResponseModel<null>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.validateReleaseForBilling}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<null>>(url);
  }

  updateLoadStatus(loadDetailId: number, loadStatus: string): Observable<ResponseModel<null>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.updateLoadStatus}`;
    url = String.format(url, loadDetailId, loadStatus);
    return this.apiClient.get<ResponseModel<null>>(url);
  }

  ediLoadTenderRejectReasons(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.getReasons}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  ediLoadTenderReject(payload: RejectEdiLoadTenderModel): Observable<ResponseModel<RejectEdiLoadTenderModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.rejectEdiLoadTender}`;
    return this.apiClient.post<RejectEdiLoadTenderModel, ResponseModel<RejectEdiLoadTenderModel>>(url, payload);
  }

  ediCreateLoad(payload: LoadDetailViewModel): Observable<ResponseModel<any>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.acceptEdiLoadTender}`;
    return this.apiClient.post<LoadDetailViewModel, any>(url, payload);
  }

  getEdiExchangeHistory(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<EdiExchangeHistory[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.getEdiExchangeHistory}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<EdiExchangeHistory[]>>>(url, payload);
  }

  getLoadRateConfirmationList(loadDetailId: number) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.getLoadRateConfirmationList}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  setLoadHighVisibility(loadDetailId: number, highVisibility: boolean): Observable<ResponseModel<number>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.setLoadHighVisibility}`;
    url = String.format(url, loadDetailId, highVisibility);
    return this.apiClient.get<ResponseModel<number>>(url);
  }

  assignEdiLoadTender(payload: AssignLoadTenderModel): Observable<ResponseModel<any>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.assignEdiLoadTender}`;
    return this.apiClient.post<AssignLoadTenderModel, any>(url, payload);
  }

  getPurchaseJournalListForLoad(loadDetailId: number): Observable<ResponseModel<PurchaseJournal[]>> {
    let url = `${this.accountingEndpoint}${API_ROUTES.accounting.purchaseJournal.getPurchaseJournalListForLoad}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<PurchaseJournal[]>>(url);
  }

  loadSnooze(payload: LoadSnoozeModel): Observable<ResponseModel<LoadDetailViewModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadSnooze.createLoadSnooze}`;
    return this.apiClient.post<LoadSnoozeModel, ResponseModel<LoadDetailViewModel>>(url, payload);
  }

  getSnoozedLoadDetail(loadDetailId: number): Observable<ResponseModel<LoadSnoozeModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadSnooze.getSnoozedLoadDetail}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<LoadSnoozeModel>>(url);
  }

  getLoadLanePricingList(payload: GridFilterRequestModel, loadDetailId: number): Observable<ResponseModel<LazyList<LoadLanePricingModel[]>>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.lanePricing.getLoadLanePricingList}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadLanePricingModel[]>>>(url, payload);
  }

  setOverlayPanel(loadIncidentId: number) {
    this.overlayPanelSubject.next(loadIncidentId);
  }

  getLoadTimeLine(loadDetailId: number): Observable<ResponseModel<LoadTimelineViewModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadTimeLine}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<LoadTimelineViewModel>>(url);
  }

  getEdiLoadTenderUpdate(ediLoadTenderId: number): Observable<ResponseModel<EdiUpdateModel[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.getEdiLoadTenderUpdate}`;
    url = String.format(url, ediLoadTenderId);
    return this.apiClient.get<ResponseModel<EdiUpdateModel[]>>(url);
  }

  getLoadAuditLogs(payload: GridFilterRequestModel, loadDetailId: number): Observable<ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.auditLog.getLoadAuditLogList}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>>(url, payload);
  }

  setLoadBoardAuditLog(): Observable<ResponseModel<boolean>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.setLoadBoardAuditLog}`;
    return this.apiClient.get<ResponseModel<boolean>>(url);
  }

  setTrackingBoardAuditLog(): Observable<ResponseModel<boolean>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.trackingBoard.setTrackingBoardAuditLog}`;
    return this.apiClient.get<ResponseModel<boolean>>(url);
  }

  getPaymentTermList(): Observable<ResponseModel<any[]>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.paymentTerm.getPaymentTermSelectList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, paymentTermId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  addActivityLog(subModule: string, sectionName: string, loadDetailId: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.addAuditLog}`;
    url = String.format(url, encodeURIComponent(subModule), encodeURIComponent(sectionName), loadDetailId);
    return this.apiClient.post<any, any>(url);
  }

  assignCapacityAuditLog(loadDetailId: number, carrierCapacity: string, carrierBidId: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCarrierBid.auditLogForAssignCapacity}`;
    url = String.format(url, loadDetailId, carrierCapacity, carrierBidId);
    return this.apiClient.post<any, any>(url);
  }

  getCarrierLoadBoardDetailList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadBoardViewModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.carrierLoadBoard.getCarrierLoadBoard}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadBoardViewModel[]>>>(url, payload);
  }

  addViewActivityLog(): Observable<ResponseModel<any>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.addViewActivityLog}`;
    return this.apiClient.post<any, any>(url);
  }

  rateConfirmationViewPdfActivityLog(rateConfirmationId: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.rateConfirmationAuditLogView}`;
    url = String.format(url, rateConfirmationId);
    return this.apiClient.post<any, any>(url);
  }

  rateConfirmationUserViewActivityLog(loaddetailId: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.rateConfirmation.rateConfirmationUserViewActivityLog}`;
    url = String.format(url, loaddetailId);
    return this.apiClient.post<any, any>(url);
  }

  loadHistoryActivityLog(loaddetailId: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.truckMatchActivityLog}`;
    url = String.format(url, loaddetailId);
    return this.apiClient.post<any, any>(url);
  }

  updateLoadStatusAndCarrierRep(payLoad: LoadDetailStakeholderModel): Observable<ResponseModel<null>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.updateLoadStatusAndCarrierRep}`;
    return this.apiClient.post<LoadDetailStakeholderModel, ResponseModel<null>>(url, payLoad);
  }
  getLoadStakeHolderCarrierOpsRep(loadDetail: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadStakeHolderCarrierOpsRep}`;
    url = String.format(url, loadDetail);
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }
  viewLoadTrailAuditLog(loadDetailId: number): Observable<ResponseModel<string>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.viewLoadTrailAuditLog}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.post<any, any>(url);
  }
  getOpenIncidentCount(loadDetail: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getOpenIncidentCount}`;
    url = String.format(url, loadDetail);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  geTruckMatchCount(loadDetail: number): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.geTruckMatchCount}`;
    url = String.format(url, loadDetail);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  updateLoadIncident(loadIncident: LoadIncidentModel, callerScreen: string): Observable<ResponseModel<LoadIncidentModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadIncident.updateLoadIncident}`;
    url = String.format(url, callerScreen);
    return this.apiClient.put<LoadIncidentModel, ResponseModel<LoadIncidentModel>>(url, loadIncident);
  }

  viewLoadProfileAuditLog(loadDetailId: number): Observable<ResponseModel<string>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.viewLoadProfileAuditLog}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.post<any, any>(url);
  }

  sendMessageToLoadIntegrationTopic(loadDetailId: number, loadEvent: string): Observable<ResponseModel<any>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.sendMessageToLoadIntegrationTopic}`;
    url = String.format(url, loadDetailId, loadEvent);
    return this.apiClient.post<any, any>(url);
  }

  getLoadCustomerDetail(loadDetailId: number): Observable<ResponseModel<LoadCustomerViewModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCustomer.getLoadCustomerDetail}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<LoadCustomerViewModel>>(url);
  }

  getEDIShipmentStatusReasonSelectList(): Observable<ResponseModel<any[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.getEDIShipmentStatusReasonSelectList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  updateLoadActivity(payload: LoadActivityModel): Observable<ResponseModel<LoadActivityModel>> {
    payload.activityType = undefined;
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadActivity.updateLoadActivity}`;
    return this.apiClient.put<LoadActivityModel, ResponseModel<LoadActivityModel>>(url, payload);
  }

  deleteLoadActivity(payload: LoadActivityModel): Observable<ResponseModel<LoadActivityModel>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadActivity.deleteLoadActivity}`;
    url = String.format(url, payload.loadActivityId);
    return this.apiClient.delete<ResponseModel<LoadActivityModel>>(url);
  }

  getCurrentDateTimeByCityState(city: string, state: string): Observable<ResponseModel<Date>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.zipCode.getCurrentDateTimeByCityState}`;
    url = String.format(url, city, state);
    return this.apiClient.get<ResponseModel<Date>>(url);
  }

  getPreferredLaneCapacity(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<TuckMatchPreferredLaneModel[]>>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.truckMatchGetPreferredLaneCapacity}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<TuckMatchPreferredLaneModel[]>>>(url, payload);
  }

  updatePreferredLaneCapacityStatus(payload: TuckMatchPreferredLaneStatusRequest): Observable<ResponseModel<TuckMatchPreferredLaneStatusRequest>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.truckMatch.truckMatchAddUpdateLoadPreferredLane}`;
    return this.apiClient.post<TuckMatchPreferredLaneStatusRequest, ResponseModel<TuckMatchPreferredLaneStatusRequest>>(url, payload);
  }

  getLoadStopByLoadDetailId(loadDetailId: number): Observable<ResponseModel<any[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadStopByLoadDetailId}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  cloneLoad(payload: LoadCloneModel): Observable<ResponseModel<any>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.cloneLoad}`;
    return this.apiClient.post<LoadCloneModel, any>(url, payload);
  }

  generateBulkLoadReleaseRequest(payload: number[]): Observable<ResponseModel<number[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.bulkReleaseLoad}`;
    return this.apiClient.post<number[], ResponseModel<any[]>>(url, payload);
  }

  validateLoadForBulkRelease(payload: number[]): Observable<ResponseModel<number[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.validateLoadForBulkRelease}`;
    return this.apiClient.post<number[], ResponseModel<any[]>>(url, payload);
  }

  globalSearchTypeAhead(payload: GridFilterRequestModel): Observable<ResponseModel<GlobalSearchSelectListModel[]>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.globalSearchTypeAhead}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<GlobalSearchSelectListModel[]>>(url, payload);
  }

  getFuelSurchargeRateDetailFromCustomer(customerId: number, eiaRate: number): Observable<ResponseModel<FuelSurchargeRateDetailViewModel>> {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.customerFuelSurchargeDocument.getFuelSurchargeRateDetail}`;
    url = String.format(url, customerId, eiaRate);
    return this.apiClient.get<ResponseModel<FuelSurchargeRateDetailViewModel>>(url);
  }

  getFuelSurchargeRateDetailFromCarrier(carrierId: number, eiaRate: number): Observable<ResponseModel<FuelSurchargeRateDetailViewModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.carrierFuelSurchargeDocument.getFuelSurchargeRateDetail}`;
    url = String.format(url, carrierId, eiaRate);
    return this.apiClient.get<ResponseModel<FuelSurchargeRateDetailViewModel>>(url);
  }

  getFuelSurchargeRateDetailFromAdministration(eiaRate: number): Observable<ResponseModel<any>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.manageFuelSurchargeDocument.getFuelSurchargeRateDetailFromAdministration}`;
    url = String.format(url, eiaRate);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  exportLoadListingToCSV(payload: GridFilterRequestModel): Observable<ResponseModel<any>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.exportLoadListingToCSV}`;
    payload = JSON.parse(JSON.stringify(payload));
    payload.pageSize = Constants.maxPageSizeForExportListing;
    payload.pageNumber = 1;
    payload.sortOrders = [
      {
        sortOrderField: 'loadStatusId',
        sortOrderType: 'asc'
      }];
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<any>>(url, payload);
  }

  resendCheckCall(ediIntegrationLogId: number, loadDetailId: number, callerScreen: string) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.resendCheckCall}`;
    url = String.format(url, ediIntegrationLogId, loadDetailId, callerScreen);
    return this.apiClient.get<ResponseModel<boolean>>(url);
  }

  getEDICustomerRequirement(customerId: number) {
    let url = `${this.customerEndpoint}${API_ROUTES.customer.getEDIDetailsByCustomerId}`;
    url = String.format(url, customerId);
    return this.apiClient.get<ResponseModel<EDICustomerModel>>(url);
  }

  runLoadQueueOrchestrator(loadDetailId: number) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.runLoadQueueOrchestrator}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.get<ResponseModel<number>>(url);
  }

  sendNotificationToFirstQueueUser(loadDetailId: number): Observable<ResponseModel<null>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadQueue.sendNotificationToFirstQueueUser}`;
    url = String.format(url, loadDetailId);
    return this.apiClient.post<number, ResponseModel<null>>(url);
  }

  getLoadCheckCallEventUpdates(payload: GridFilterRequestModel, callerScreen: string): Observable<ResponseModel<LazyList<LoadCheckCallEventUpdatesModel[]>>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadCheckCall.getLoadCheckCallEventUpdates}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadCheckCallEventUpdatesModel[]>>>(url, payload);
  }

  setEdiPanel(loadDocumentId: number) {
    this.editPanelSubject.next(loadDocumentId);
  }

  updateDocument(document: LoadDocumentModel, callerScreen: string) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.document.updateDocument}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<any, ResponseModel<any>>(url, document);
  }

  ediBulkAccept(payload: EdiLoadTenderModel[]) {
    const url = `${this.loadEndpoint}${API_ROUTES.load.ediLoadTender.ediBulkAccept}`;
    return this.apiClient.post<EdiLoadTenderModel[], ResponseModel<any>>(url, payload);
  }

  addViewQuickViewAuditLog(loaddetailId: number, callerScreen = CallerScreen.LoadBoard): Observable<ResponseModel<boolean>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.addViewQuickViewAuditLog}`;
    url = String.format(url, loaddetailId, callerScreen);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  validateLoadForRevertRelease(loadDetailId: number, callerScreen = CallerScreen.LoadProfile) {
    let url = `${this.accountingEndpoint}${API_ROUTES.accounting.revertRelease.validateLoadForRevertRelease}`;
    url = String.format(url, loadDetailId, callerScreen);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  revertReleasedLoad(loadDetailId: number, callerScreen = CallerScreen.LoadProfile) {
    let url = `${this.accountingEndpoint}${API_ROUTES.accounting.revertRelease.revertReleasedLoad}`;
    url = String.format(url, loadDetailId, callerScreen);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  getCapacityFromMacroPoint(loadDetailId: number, isAutoSync: boolean) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.macroPoint.getCapacityFromMacopoint}`;
    url = String.format(url, loadDetailId, isAutoSync);
    return this.apiClient.get<ResponseModel<any>>(url);
  }
}
