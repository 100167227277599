import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from './core/guards/route.guard';
import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes = [
  {
    path: 'rateconfirmation/:loadDetailId/:loadRateConfirmationGUID/:encryptedEmail',
    loadChildren: () =>
      import('./esign-load-rate-confirmation/esign-load-rate-confirmation.module').then((a) => a.EsignLoadRateConfirmationModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./authentication/authentication.module').then((a) => a.AuthenticationModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./layout/layout.module').then((l) => l.LayoutModule),
    canActivate: [RouteGuard],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
