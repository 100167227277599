import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/core/constants/constants';
import { ButtonText, CallerScreen, UserViews } from '@app/core/constants/enums';
import { FormHelper } from '@app/core/helpers/form.helper';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { ToasterService } from '@app/core/services/toaster.service';
import { GridParams } from '@app/modules/load/load.model';
import { LoadService } from '@app/modules/load/load.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectListView, UserViewModel } from './user-view.model';
import { UserViewService } from './user-view.service';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-user-view-add-edit',
  templateUrl: './user-view-add-edit.component.html',
  styles: [
  ]
})
export class UserViewAddEditComponent implements OnInit {
  viewId!: number;
  views!: SelectListView[];
  sessionStorageValue: any;
  viewForm!: FormGroup;
  myLoadFilter!: any;
  loadStatusFilters!: any;
  regionFilter!: any;
  advancedFilters!: any;
  exceptionFilters!: any;
  sessionTrackingBoardStorageValue!: any;
  gridParam: GridParams =
    {
      filterModel: '',
      columnState: '',
      regionFilter: '',
      myLoadFilter: '',
      loadStatusFilters: ''
    };
  constructor(
    private readonly fb: FormBuilder,
    private readonly service: UserViewService,
    private readonly loadService: LoadService,
    private readonly toastService: ToasterService,
    public ref: DynamicDialogRef,
    private dynamicDialogService: DynamicDialogConfig,
  ) { }

  get getButtonTitle(): string {
    return this.view ? ButtonText.Update : ButtonText.Add;
  }

  get view(): SelectListView {
    return this.dynamicDialogService.data?.viewData;
  }

  get moduleName(): string {
    return this.dynamicDialogService.data?.module;
  }

  ngOnInit(): void {
    this.initForm();
    const viewNameControl = this.viewForm.controls['viewName'];
    viewNameControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.onUserViewNameBlur(value, this.moduleName)
    });
  }

  initForm(): void {
    this.viewForm = this.fb.group({
      viewName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(100)
        ],
      }),
      isActive: new FormControl(true),
      isDefault: new FormControl(false),
      userViewId: new FormControl(0)
    });
    if (this.view) {
      this.viewForm.patchValue(this.view);
    }
  }

  getControl(control: string): AbstractControl {
    return this.viewForm.controls[control];
  }

  save(): void {
    if (this.viewForm.invalid) {
      FormHelper.MarkFormGroupDirty(this.viewForm);
      return;
    }
    if (this.moduleName == UserViews.TrackingBoard) {
      this.sessionTrackingBoardStorageValue = sessionStorage.getItem('ag-grid-tracking-board');
      this.exceptionFilters = sessionStorage.getItem('ag-grid-tracking-board-exceptions');
      let value: any
      if (this.sessionTrackingBoardStorageValue) {
        value = <GridParams>JSON.parse(this.sessionTrackingBoardStorageValue);
        this.gridParam.columnState = value.columnState;
        this.gridParam.filterModel = value.filterModel;
      }
      const viewCriteria = {
        'ag-grid-tracking-board': this.sessionTrackingBoardStorageValue,
        'ag-grid-tracking-board-exceptions': this.exceptionFilters
      };
      if (this.view) {
        const viewRequest: UserViewModel = {
          ...this.viewForm.value,
          modifiedBy: LoginHelper.GetUserDetail().userId,
          viewCriteria: JSON.stringify(viewCriteria),
          module: UserViews.TrackingBoard,
          userViewId: this.view.userViewId,
          createdBy: this.view.createdBy,
          createdDate: this.view.createdDate
        };
        this.service
          .updateView(viewRequest, CallerScreen.TrackingBoard)
          .subscribe((response) => {
            if (response.data) {
              this.setViewAfterCreateUpdateTrackingBoard(viewCriteria, viewRequest, response);
            }
          });
      } else {
        const viewRequest: UserViewModel = {
          ...this.viewForm.value,
          createdBy: LoginHelper.GetUserDetail().userId,
          modifiedBy: LoginHelper.GetUserDetail().userId,
          viewCriteria: JSON.stringify(viewCriteria),
          module: UserViews.TrackingBoard
        };
        this.service
          .createView(viewRequest, CallerScreen.TrackingBoard)
          .subscribe((response) => {
            if (response.data) {
              this.setViewAfterCreateUpdateTrackingBoard(viewCriteria, viewRequest, response);
            }
          });
      }
    }
    else {
      if (this.moduleName == UserViews.LoadBoard) {
        this.sessionStorageValue = sessionStorage.getItem('ag-grid-loadboard');
        this.myLoadFilter = sessionStorage.getItem('loadboard-myLoad-filter');
        this.loadStatusFilters = sessionStorage.getItem('loadboard-status-filter');
        this.regionFilter = sessionStorage.getItem('loadboard-region-filter');
        this.advancedFilters = sessionStorage.getItem('ag-grid-loadboard-advance-filters');
      }
      let value: any
      if (this.sessionStorageValue) {
        value = <GridParams>JSON.parse(this.sessionStorageValue);
        this.gridParam.columnState = value.columnState;
        this.gridParam.filterModel = value.filterModel;

      }
      if (this.myLoadFilter) {
        this.gridParam.myLoadFilter = JSON.parse(this.myLoadFilter);
      }
      const viewCriteria = {
        'ag-grid-loadboard': this.sessionStorageValue,
        'loadboard-myLoad-filter': this.myLoadFilter,
        'loadboard-status-filter': this.loadStatusFilters,
        'loadboard-region-filter': this.regionFilter,
        'ag-grid-loadboard-advance-filters': this.advancedFilters,
      };

      if (this.moduleName == UserViews.LoadBoard) {
        if (this.view) {
          const viewRequest: UserViewModel = {
            ...this.viewForm.value,
            modifiedBy: LoginHelper.GetUserDetail().userId,
            viewCriteria: JSON.stringify(viewCriteria),
            module: UserViews.LoadBoard,
            userViewId: this.view.userViewId,
            createdBy: this.view.createdBy,
            createdDate: this.view.createdDate
          };
          this.service
            .updateView(viewRequest, CallerScreen.LoadBoard)
            .subscribe((response) => {
              if (response.data) {
                this.setViewAfterCreateUpdateLoadBoard(viewCriteria, viewRequest, response);
              }
            });
        } else {
          const viewRequest: UserViewModel = {
            ...this.viewForm.value,
            createdBy: LoginHelper.GetUserDetail().userId,
            modifiedBy: LoginHelper.GetUserDetail().userId,
            viewCriteria: JSON.stringify(viewCriteria),
            module: UserViews.LoadBoard
          };
          this.service
            .createView(viewRequest, CallerScreen.LoadBoard)
            .subscribe((response) => {
              if (response.data) {
                this.setViewAfterCreateUpdateLoadBoard(viewCriteria, viewRequest, response);
              }
            });
        }
      }
    }
  }

  reset(): void {
    this.ref.close();
  }

  onUserViewNameBlur(value: string, module: string): void {
    if (value?.length >= 3 && value?.length <= 100) {
      this.service.checkDuplicateViewName(value, this.view ? this.view.userViewId : 0, module).subscribe((response) => {
        if (response.data) {
          this.viewForm.controls['viewName'].setErrors({ duplicatename: true });
        } else {
          this.viewForm.controls['viewName'].setErrors(null);
        }
      })
    }
  }

  setViewAfterCreateUpdateLoadBoard(viewCriteria: any, viewRequest: UserViewModel, response: any): void {
    sessionStorage.setItem(Constants.loadboardSessionStorageRegionFilterKey, viewCriteria['loadboard-region-filter']);
    sessionStorage.setItem(Constants.loadBoardCurrentView, viewRequest.viewName);
    sessionStorage.setItem(Constants.loadBoardSessionStorageKey, viewCriteria['ag-grid-loadboard'] || []);
    sessionStorage.setItem(Constants.loadboardSessionStorageMyLoadFilterKey, viewCriteria['loadboard-myLoad-filter'] || []);
    sessionStorage.setItem(Constants.loadboardSessionStorageStatusFilterKey, viewCriteria['loadboard-status-filter'] || []);
    sessionStorage.setItem(Constants.loadBoardAdvanceFilterSessionStorageKey, viewCriteria['ag-grid-loadboard-advance-filters'] || []);
    this.toastService.showSuccessMessage(response.userMessage);
    this.ref.close(response);
    this.loadService.triggerViewEvent.emit();
  }

  setViewAfterCreateUpdateTrackingBoard(viewCriteria: any, viewRequest: UserViewModel, response: any): void {
    sessionStorage.setItem(Constants.trackingBoardCurrentView, viewRequest.viewName);
    sessionStorage.setItem(Constants.trackingBoardSessionStorageKey, viewCriteria['ag-grid-tracking-board'] || []);
    sessionStorage.setItem(Constants.trackingBoardExceptionsKey, viewCriteria['ag-grid-tracking-board-exceptions'] || []);
    this.toastService.showSuccessMessage(response.userMessage);
    this.ref.close(response);
    this.loadService.triggerTrackingBoardViewEvent.emit();
  }
}
