import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskHide'
})
export class MaskHidePipe implements PipeTransform {

  transform(value: string): string {
    if (value != ('0' || '00' || '')) {
      const arr = value.split('');
      const tempArr: any = [];
      arr.forEach(element => {
        tempArr.push('X')
      });

      return `${tempArr.join('')}`;
    }
    return "";

  }

}
