import { Component, OnInit } from '@angular/core';
import { ContentType, Viewer } from '@app/core/constants/enums';
import { viewerType } from 'ngx-doc-viewer';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-doc-viewer-popup',
  templateUrl: './doc-viewer-popup.component.html',
  styles: [
  ],
})
export class DocViewerPopupComponent implements OnInit {
  viewer: viewerType = 'url'
  documentPath = this.filePath?.toString()
  constructor(private dynamicDialogService: DynamicDialogConfig,
  ) {

  }
  ngOnInit(): void {
    switch (this.documentType?.toString()) {
      case ContentType.Word:
      case ContentType.Excel:
      case ContentType.PowerPoint:
        this.viewer = Viewer.Office;
        break;
      case ContentType.PDF:
        this.viewer = Viewer.Url;
        break;
      case ContentType.ImagePNG:
        this.viewer = Viewer.Url;
        break;
      default:
        this.viewer = Viewer.Url;
        break;
    }
  }

  get filePath(): string {
    return this.dynamicDialogService.data?.filePath;
  }

  get documentType(): string {
    return this.dynamicDialogService.data?.type;
  }

  get rateConfirmationId(): number {
    return this.dynamicDialogService.data?.rateConfirmationId;
  }

  get isRateConPdf(): number {
    return this.dynamicDialogService.data?.isRateConPdf;
  }
}

