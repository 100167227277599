import { ZipCodeResponseModel } from "@app/core/models/zipcode.model";
import { CustomerContactModel, CustomerRequirementsModel } from "../customer/customer.model";
import { LoadImplicitExplicitPermissionModel } from "./load-edit/load-edit-permission.model";
import { FacilityDetailModel } from "../facility/facility.model";
import { CallerScreen } from "@app/core/constants/enums";

export interface LoadRateConfirmation {
    loadRateConfirmationId: number;
    loadDetailId: number;
    loadCarrierId: number;
    dispatcherId: number;
    loadCarrierName: string;
    dispatcherName: string;
    email: string;
    status: string;
    documentId: number;
    createdBy: number;
    createdByUser: string;
    modifyByUser: string;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: number;
    loadRateConfirmationGUID: string;
    initial: string;
    truckNumber: string;
    trailerNumber: string;
    driver1Name: string;
    driver1Phone: string;
    driver2Name: string;
    driver2Phone: string;
    iPAddress: string;
    userOfficePhone: string;
    userEmail: string;
    carrierOpsRepEmail: number;
    carrierOpsRepId: number;
    extension: string;
    carrierRepId: string;
    documentPath: string;
    isActiveCarrier: boolean;
}

export interface LoadRateCarrier {
    carrierId: number;
    loadCarrierId: number;
    name: string;
    phone: string;
    isActive: boolean;
}

export interface Dispatcher {
    dispatcherId: number | null;
    dispatcherName: string;
    email: string;
    cellPhone: string;
    officePhone: string;
    isActive?: boolean;
}

export interface LoadDetailViewModel {
    loadDetailId: number;
    loadStatusId: number;
    loadStatusSortOrder: number;
    pONumber: string;
    bOLNumber: string;
    proNumber: string;
    clientReferenceNumber: string;
    targetRate: number;
    maxBuy: number;
    totalCustomerRate: number;
    totalCarrierRate: number;
    isLocked: boolean;
    lockedBy: number;
    lockedDate: string;
    loadStatusReasonId: number;
    loadStatusComment: string;
    mileage: number | null;
    customsBroker: boolean;
    brokerName: string;
    email: string;
    phone1: string;
    phone2: string;
    createdBy: number;
    createdDate: string | null;
    modifiedBy: number;
    modifiedDate: string | null;
    loadCustomer: LoadCustomerViewModel | null;
    loadStakeHolder: LoadStakeHolderViewModel | null;
    loadReferenceNumbers: LoadReferenceNumberViewModel[] | null;
    loadStops: LoadStopViewModel[];
    loadBOLs: LoadBoLViewModel[];
    loadRequirement: LoadRequirementViewModel;
    loadStatusHistories: LoadStatusHistoryViewModel[] | null;
    loadCarriers: LoadCarrierViewModel[];
    loadNote: LoadNoteViewModel | null;
    loadRateConfirmations: LoadRateConfirmation[];
    loadStatus: string;
    poNumber: string;
    bolNumber: string;
    lockedByUser: string;
    createdByUSer: string;
    modifiedByUser: string;
    expireRate: boolean;
    [key: string]: any;
    macroPointOrderId: string | null;
    isParadePosted: boolean | null;
    showOnParadeLoadBoard: boolean | null;
    paradePostedDate: string | null;
    paradeCapacityLastSyncedDate: string | null;
    isDatPosted: boolean | null;
    datEquipmentId: number | null;
    datPostedBy: number | null;
    datUsername: string | null;
    datUniqueId: string | null;
    datPostedDate: string | null;
    datCapacityLastSyncedDate: string | null;
    datShipperCity: string | null;
    datShipperState: string | null;
    datShipperZip: string | null;
    datDeliveryCity: string | null;
    datDeliveryState: string | null;
    datDeliveryZip: string | null;
    ediLoadTenderId: number | null;
    isEDIAccepted: boolean | null;
    isEDIAppointmentUpdateSent: boolean | null;
    loopQId: string | null;
    loadImplicitExplicitPermission: LoadImplicitExplicitPermissionModel;
    datLastSyncDateTime: string;
    datLastSyncStatus: string;
    datSyncRetryCount: number;
    paradeLastSyncDateTime: string;
    paradeLastSyncStatus: string;
    paradeSyncRetryCount: number;
    releaseDate: string | null;
    loopLastSyncDateTime: string;
    loopLastSyncStatus: string;
    loopSyncRetryCount: number;
    elasticSearchLastSyncDateTime: string;
    elasticSearchLastSyncStatus: string;
    elasticSearchSyncRetryCount: number;
    breakthroughLastSyncDateTime: string;
    breakthroughLastSyncStatus: string;
    breakthroughSyncRetryCount: number;
    facilityDetailViewModels: FacilityDetailModel[] | null;
    facilityAppointmentValidationThreshold: number | null;
    accountingStatusViewModels: AccountingStatusViewModel[] | null;
    eiaRate: boolean;
    macropointCapacityLastSyncedDate: string;
}

export interface LoadCustomerViewModel {
    loadCustomerId: number;
    loadDetailId: number;
    customerId: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    billToCustomerName: string;
    billToAddress: string;
    billToCity: string;
    billToState: string;
    billToZip: string;
    billToCountry: string;
    paymentTermId: number;
    customerContactId?: number;
    commodityType: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    customer: string;
    paymentTerm: string;
    customerContact: string;
    createdByUser: string;
    modifiedByUser: string;
    customerContactDepartmentId?: number;
    netSuiteId?: string;
    loopQId?: string;
}

export interface LoadStakeHolderViewModel {
    loadStakeholderId: number;
    loadDetailId: number;
    deskOwnerId: number;
    deskOwner?: string;
    executiveSponsorId: number;
    managerId: number;
    accountOwnerId: number;
    accountManager?: string;
    accountManagerId: number;
    customerOperationsRepresentativeId: number;
    customerOperationsRepresentative?: string;
    carrierRepresentativeId: number;
    carrierRepresentative?: string;
    carrierOperationsRepresentativeId: number;
    carrierOperationsRepresentative?: string;
    additionalManagerId: number;
    additionalManager?: string;
    accountingSpecialistId: number;
    accountingSpecialist: string;
    additionalCustomerOperationsRepresentativeId: number;
    additionalCustomerOperationsRepresentative: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    executiveSponsor: any;
    manager: any;
    accountOwner: any;
    createdByUser: string;
    modifiedByUser: string;
    internalCustomerNote?: string;
    externalCustomerNote?: string;
}

export interface LoadReferenceNumberViewModel {
    loadReferenceNumberId: number;
    loadDetailId: number;
    referenceNumberTypeId: number | string;
    referenceNumber: string;
    isDeleted: boolean;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    referenceNumberType: any;
    createdByUSer: string;
    modifiedByUser: string;
}

export interface LoadStopViewModel {
    loadStopId: number;
    loadDetailId: number;
    stopNumber: string;
    isPickup: boolean;
    facilityCode: string;
    facilityName: string;
    firstComeFirstServe: boolean;
    live: boolean;
    dropTrailer: boolean;
    address: string;
    city: string;
    state: string;
    zip: string;
    country?: string;
    appointmentEarliestDate: string | null;
    appointmentEarliestTime: string;
    appointmentLatestDate: string | null;
    appointmentLatestTime: string;
    appointmentEarliestDateCT: string;
    appointmentEarliestTimeCT: string;
    appointmentLatestDateCT: string;
    appointmentLatestTimeCT: string;
    dropStartDate: string | null;
    dropStartTime: string;
    dropEndDate: string | null;
    dropEndTime: string;
    dropStartDateCT: string;
    dropStartTimeCT: string;
    dropEndDateCT: string;
    dropEndTimeCT: string;
    appointmentNote: string;
    stopSequence: number;
    stopSequenceUI: number;
    isDeleted: boolean;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    arrivalDate: any;
    arrivalTime: any;
    arrivalDateCT: any;
    arrivalTimeCT: any;
    completedPickupDate: any;
    completedPickupTime: any;
    completedPickupDateCT: any;
    completedPickupTimeCT: any;
    estimatedArrivalDate: any;
    estimatedArrivalTime: any;
    estimatedArrivalDateCT: any;
    estimatedArrivalTimeCT: any;
    estimatedNextArrivalDateCT: any;
    estimatedNextArrivalTimeCT: any;
    createdByUser: string;
    modifiedByUser: string;
    loadStopUtcOffset: string | null;
    appointment: boolean;
    facilityInternalNote: string | null;
    facilityRateConfirmationNote: string | null;
}

export interface LoadBoLViewModel {
    loadBOLId: number;
    loadDetailId: number;
    pieceTypeId: number;
    pieceCount: number;
    quantityTypeId: number;
    quantity: number;
    eDIReferenceNumber: number;
    weight: number;
    class: string;
    length: number;
    width: number;
    height: number;
    description: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    ediReferenceNumber: number;
    piecesTypeName: string;
    quantityTypeName: string;
}

export interface LoadRequirementViewModel {
    loadRequirementId: number;
    loadDetailId: number;
    loadModeId: number;
    loadMode: string;
    loadTypeId: number;
    loadType: string;
    equipmentId: number;
    etracking: boolean;
    pODRequirement: boolean | null;
    hazmat: boolean | null;
    teamService: boolean;
    liftGate: boolean | null;
    targetedCommodity: boolean | null;
    tankerEndorse: boolean | null;
    loadBar: boolean | null;
    straps: boolean | null;
    oversize: boolean | null;
    overweight: boolean | null;
    tWICCard: boolean | null;
    foodGrade: boolean | null;
    lumper: boolean | null;
    chains: boolean | null;
    highVisibility: boolean | null;
    temperatureRequirement: boolean | null;
    minTemperature: number | null;
    maxTemperature: number | null;
    tarps: boolean | null;
    tarpSizeId: number | null;
    bulkHead: boolean | null;
    temperature1: number | null;
    temperature2: number | null;
    highValue: boolean | null;
    amount: number | null;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    equipment: any;
    podRequirement: boolean;
    twicCard: boolean;
    tarpSize?: string | null;
    createdByUser: string;
    modifiedByUser: string;
}

export interface LoadStatusHistoryViewModel {
    loadStatusHistoryId: number;
    loadDetailId: number;
    loadStatusId: number;
    createdBy: number;
    createdDate: string;
}

export interface LoadAccessorialViewModel {
    loadAccessorialId: number;
    loadCarrierId: number;
    accessorialId?: number;
    carrierRate: number;
    customerRate: number;
    considerMaxBuy: boolean;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    accessorial: string;
    createdByUser: string;
    modifiedByUser: string;
    isDeleted: boolean;
    accessorialObj?: any;
    negativeAccessorial: boolean;
}

export interface LoadCarrierViewModel {
    loadCarrierId: number;
    loadDetailId: number;
    carrierId: number;
    lineHaulChargesCarrierRate: number;
    fuelSurchargesCarrierRate: number;
    lineHaulChargesCustomerRate: number;
    fuelSurchargesCustomerRate: number;
    mCNumber: string;
    trackingMethodId: number;
    tierId: number;
    dispatcherId: number;
    email: string;
    phone: string;
    truckLocationCity: string;
    truckLocationState: string;
    availableDate: any;
    availableTime: any;
    truckNumber: string;
    trailerNumber: string;
    firstDriverName: string;
    firstDriverPhoneNo: string;
    secondDriverName: string;
    secondDriverPhoneNo: string;
    coveredDate: string | null;
    uncoveredDate: string | null;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    loadAccessorials: LoadAccessorialViewModel[];
    carrier: any;
    mcNumber: any;
    trackingMethod: any;
    dispatcher: any;
    loadStatusReasonId: any;
    loadStatusReason: any;
    loadStatusComment: any;
    isActive: boolean;
    createdByUser: string;
    modifiedByUser: string;
    totalCustomerRate: number;
    totalCarrierRate: number;
    netSuiteId?: string;
    loopQId?: string;
    milesToPick?: number | null;
    rpmAllInCustomerRate: number;
    allInCustomerRate: number;
    rpmAllInCarrierRate: number;
    allInCarrierRate: number;
    rpmLineHaulChargesCarrierRate?: number;
    rpmFuelSurchargesCarrierRate?: number;
    rpmLineHaulChargesCustomerRate?: number;
    rpmFuelSurchargesCustomerRate?: number;
}

export interface CustomerResponseModel {
    id?: number;
    name?: string;
    status?: string;
}

export interface CustomerResponseParent {
    label?: string;
    items?: CustomerResponseModel[];
}

export interface CustomerDetailResponseModel {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    commodity?: string;
    customerNote?: CustomerNote;
    customerStakeholder?: CustomerStakeholderInformation;
    customerContact?: CustomerContactModel[];
    billingInformationEntity?: CustomerBillingInformation;
    shipsTargetedCommodity: boolean;
    customerRequirements: CustomerRequirementsModel;
    netSuiteId?: string;
    loopQId?: string;
    manageFuelSurcharge: boolean;
}

export interface CustomerNote {
    customerNoteId?: number,
    internalNote?: string,
    externalNote?: string
}

export interface CustomerStakeholderInformation {
    deskOwner?: number;
    customerOpsRep?: number;
    accountManager?: number;
    executiveSponsor?: number;
    manager?: number;
    accountOwner?: number;
    additionalManager?: number;
    additionalCustomerOpsRep?: number;
    accountingSpecialist?: number;
}

export interface CustomerContact {
    customerContactId: number;
    name: string;

}

export interface CustomerBillingInformation {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string
    paymentTerm: PaymentTermInformation;
    name?: string;
    paymentTermId?: number;
}

export interface PaymentTermInformation {
    paymentTermId?: number;
    name?: string;
}

export interface FacilityResponseModel {
    code?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    facilityDetailId?: number;
    region?: string;
}

export interface FacilityResponseParent {
    label?: string;
    items?: FacilityResponseModel[];
}

export interface LoadNoteViewModel {
    loadNoteId: number;
    loadDetailId: number;
    externalPickupFacilityNote?: string;
    externalCustomerNote?: string;
    externalDestinationFacilityNote?: string;
    internalPickupFacilityNote?: string;
    internalCustomerNote?: string;
    internalDestinationFacilityNote?: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    createdByUser: string;
    modifiedByUser: string;
}

export interface LoadBoardViewModel {
    loadStatus: string
    loadStatusSortOrder: number;
    loadStatusId: number;
    id: number;
    customerID: number;
    customer: string;
    originCity: string;
    originState: string;
    destinationCity: string;
    destinationState: string;
    puDate: string;
    puTime: string;
    puDateLatest: string;
    puTimeLatest: string;
    delDate: string;
    delTime: string;
    delDateLatest: string;
    delTimeLatest: string;
    carrierID: number;
    carrier: string;
    accountManagerID: number;
    accountManager: string;
    deskOwnerID: number;
    deskOwnerName: string;
    customerOpsID: number;
    customerOpsName: string;
    carrierRepID: number;
    carrierRepName: string;
    mode: string;
    miles: number;
    equipment: string;
    customerRate: number;
    maxBuyRate: number;
    targetRate: number;
    carrierRate: number;
    customerRefNumber: string;
    pickupNumber: number;
    opsRepID: number;
    opsRepName: string;
    loadType: string;
    customerContactPersonID: number;
    customerContactPersonName: string;
    pieces: number;
    weight: number;
    pallets: number;
    mcNumber: string;
    truckNumber: string;
    driverName: string;
    trailerNumber: string;
    cases: string;
    hazmat: boolean;
    multiStop: boolean;
    tanker: boolean;
    teamsService: boolean;
    temperatureRequired: boolean;
    dropTrailer: boolean;
    customerCode: string;
    offerCount?: number;
    originZip: string;
    destinationZip: string;
    openIncidents: number;
    poNumber: string | null;
    bolNumber: string | null;
    proNumber: string | null;
    firstDriverPhoneNumber: string | null;
    secondDriverPhoneNumber: string | null;
    containerReferenceNumber: string | null;
    rateConfirmationStatus: string | null,
    isRateConfirmationSigned: boolean | null;
    unresolvedIncidentCount: number | null;
    pudtStartDate: string | null;
    pudtStartTime: string | null;
    pudtEndDate: string | null;
    pudtEndTime: string | null;
}
export interface LoadActivityModel {
    loadActivityId: number
    loadDetailId: number
    activityTypeId: number
    activityType?: string
    activity: string
    createdBy: number
    createdByUser: string
    createdDate: string
}

export interface SearchLoadNumber {
    data: boolean
}

export interface LoadLockDetailModel {
    loadDetailId: number;
    lockedBy: number;
    userName: string;
    lockStartDateTime: string;
    lockExpireDateTime: string;
}

export interface SelectListLoadStatusItem {
    text: string
    value: string
    isActive: boolean
    sortOrder: number
}

export interface LoadBounceRequest {
    loadCarrierId: number;
    loadStatusReasonId: number;
    loadStatusComment: string;
}

export interface LoadCheckCallViewModel {
    loadCheckCallId: number;
    loadDetailId: number;
    loadStopId: number | null;
    loadCheckCallStatusId: number;
    note: string | null;
    verbal: string | null;
    checkCallDate: string | null;
    checkCallTime: string;
    checkCallDateCT: string | null;
    checkCallTimeCT: string | null;
    city: string | null;
    state: string | null;
    isLoadStatusUpdated: boolean | null;
    nextStopETADate: string | null;
    nextStopETATime: string | null;
    nextStopETADateCT: string | null;
    nextStopETATimeCT: string | null;
    lastStopETADate: string | null;
    lastStopETATime: string | null;
    lastStopETADateCT: string | null;
    lastStopETATimeCT: string | null;
    milesToNextStop: number | null;
    milesToLastStop: number | null;
    latitude: string | null;
    longitude: string | null;
    createdBy: number;
    createdByUser: string | null;
    createdDate: string | null;
    ediShipmentStatusReasonId: number | null;
    ediShipmentStatusReasonCode: string | null;
    ediIntegrationLogId: number | null;
}


export interface LoadCheckCallForm {
    checkCallDate: string | null;
    checkCallTime: string | null;
    city: string | null;
    state: string | null;
}

export interface GridParams {
    filterModel: '',
    columnState: '',
    regionFilter?: '',
    myLoadFilter?: '',
    loadStatusFilters?: '',
    advancedFilters?: '',

}

export interface LoadBounceRecoverResponse {
    loadCarrierId: number;
    loadDetailId: number;
    carrierId: number;
    lineHaulChargesCarrierRate: number;
    fuelSurchargesCarrierRate: number;
    lineHaulChargesCustomerRate: number;
    fuelSurchargesCustomerRate: number;
    mcNumber: string;
    trackingMethodId: number;
    dispatcherId: number;
    email: string;
    phone: string;
    truckLocationCity: string;
    truckLocationState: string;
    availableDate: string;
    availableTime: string;
    truckNumber: number;
    trailerNumber: number;
    firstDriverName: string;
    firstDriverPhoneNo: string;
    secondDriverName: string;
    secondDriverPhoneNo: string;
    coveredDate: string;
    uncoveredDate: string;
    isActive: boolean;
    tierId: number;
    loadStatusReasonId: number;
    loadStatusComment: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    loadDetail: LoadDetailViewModel;
    trackingMethod: string;
    loadAccessorials: LoadAccessorialViewModel[];
    loadStatusReason: string;
}
export interface LoadRateConfirmationESignViewModel {
    loadDetailViewModel: LoadDetailViewModel;
    loadRateConfirmation: LoadRateConfirmation;
    dispatcherList: Dispatcher[];
    carrierName: string;
    advanceCarrierRate: number;
    loadReferenceNumber: string[];
}

export interface LoadRateConfirmationESignRequestViewModel {
    loadDetailViewModel: LoadDetailViewModel;
    loadRateConfirmation: LoadRateConfirmation;
    loadCarrier: LoadCarrierViewModel | undefined;
    loadAccessorials: LoadAccessorialViewModel[] | undefined;
    carrier: string;
    attn: string | undefined
    officePhone: string | undefined;
    email: string | undefined;
    loadRateConfirmationGuid: string;
    loadReferenceNumber: string[];
    advanceCarrierRate: number;
    isActiveCarrier: boolean;
    resendOrForwardRateConfirmationPdf: boolean;
}
export interface LoadBounceRequest {
    loadCarrierId: number;
    loadDetailId: number;
    loadStatusReasonId: number;
    loadStatusComment: string;
}
export interface MilesRequestModel {
    origin: GeoCodeRequestModel;
    destination: GeoCodeRequestModel;
}
export interface GeoCodeRequestModel {
    city: string | null;
    state: string | null;
    zip?: string | null;
}


export interface AdvanceLoadBoardFilterModel {
    customerIdObj: {
        id: number;
        name: string;
        status: string;
        address: string;
        city: string;
        state: string;
        zip: string;
    }[];
    carrierIdObj: {
        id: number;
        name: string;
        motorCarrierNumber: string;
        departmentOfTransport: string;
        status: string;
        tier: string;
        trackBy: string;
    }[];
    deskOwnerIdObj: any;
    accountManagerIdObj: any;
    carrierRepIdObj: any;
    opsRepIdObj: any;
    puDateFrom: string;
    puDateTo: string;
    delDateFrom: string;
    delDateTo: string;
    pickupFacilityNameObj: FacilityResponseModel[];
    originCityObj: ZipCodeResponseModel[];
    originStateObj: ZipCodeResponseModel[];
    originZipObj: ZipCodeResponseModel[];
    consigneeFacilityNameObj: FacilityResponseModel[];
    destinationCityObj: ZipCodeResponseModel[];
    destinationStateObj: ZipCodeResponseModel[];
    destinationZipObj: ZipCodeResponseModel[];
    includeClosed: string[];
    includeCanceled: string[];
}

export interface EdiLoadTenderModel {
    ediLoadTenderId: number;
    loadDetailId: string | undefined;
    ediShipmentId: string;
    ediSenderId: string;
    ediLoadTenderStatus: string;
    acceptedAutomatically: boolean;
    acknowledgementSentOn: boolean;
    referenceNumber: string;
    customerName: string;
    customerId: number;
    originCity: string;
    originState: string;
    destinationCity: string;
    destinationState: string;
    pickupApptDateTime: string;
    deliveryApptDateTime: string;
    equipment: string;
    accountManager: string;
    customerRate: string;
    receivedDate: string;
    processedDate: string;
    respondBy: string;
    processedBy: number | undefined;
    processedByUser: string;
    ediLoadTenderUpdated: boolean;
    isRetendered: boolean;
    lastTenderStatus: string;
}

export interface TrackingBoardViewModel {
    loadStatus: string;
    loadDetailId: string;
    customerId: string;
    customerName: string;
    originCity: string;
    originState: string;
    destinationCity: string;
    destinationState: string;
    puApptEarlierDate: string;
    puApptLatestDate: string;
    delApptEarlierDate: string;
    delApptLatestDate: string;
    carrierName: string;
    accountManager: string;
    deskOwnerName: string;
    carrierRepName: string;
    accountManagerId: number;
    deskOwnerNameId: number;
    carrierRepNameId: number;
    customerRate: number;
    carrierRate: number;
    mcNumber: string;
    truckNumber: string;
    trailerNumber: string;
    firstDriverName: string;
    secondDriverName: string;
    incident: number;
    highVisibility: number;
    late: number;
    noCheckcall: number;
    latePickup: number;
    detention: number;
    noPOD: number;
    snooze: number;
    dropTrailer: number;
    firstDriverPhoneNo: number,
    secondDriverPhoneNo: number,
    lastCheckCallDateTime: string,
    lastCheckCallCity: string,
    lastCheckCallState: string,
    lastCheckCallNote: string,
    eTracking: number
    trackingMethod: string
}

export interface TrackingBoardExceptionSummaryViewModel {
    incident: number;
    highVisibility: number;
    late: number;
    noCheckcall: number;
    latePickup: number;
    detention: number;
    noPOD: number;
    snooze: number;
    dropTrailer: number;
}

export interface RejectEdiLoadTenderModel {
    loadDetailId: number;
    reasonId: number;
    additionalComment: string;
    modifiedBy: number;
    modifiedByUser: string;
}

export interface EdiExchangeHistory {
    ediLoadTenderLogId: number;
    event: string;
    api: string;
    requestDirection: string;
    requestBody: string;
    responseBody: string;
    statusCodeDescription: string | undefined;
    status: string;
    statusDescription: string;
    logDateTime: string;
    ediLoadTenderId: number;
    loadDetailId: string | undefined;
    customerName: string;
    customerId: string;
    referenceNumber: string;
    syncByUser: string;
    ediLoadTenderStatus: string;
    isCreditLimitExceeded: boolean;
}

export interface AssignLoadTenderModel {
    ediLoadTenderId: number;
    loadDetailId: number;
    modifiedBy: number;
    modifiedByUser: string;
}

export interface PurchaseJournal {
    purchaseJournalId: number;
    loadDetailId: number;
    paymentTo: string;
    carrierId: number;
    vendorId: string;
    accessorialId: number;
    accessorialCode: string;
    carrierAmount: number;
    customerAmount: number
    carrierName: string;
}
export interface LoadSnoozeModel {
    loadSnoozeId: number;
    loadDetailId: number;
    snoozePeriod: number;
    snoozeReason: string;
    snoozedBy: number;
    snoozedByUser: string;
    snoozedDateTimeCT: string;
    snoozeExpiryDateTimeCT: string;
}
export interface LoadTimelineViewModel {
    loadStatuses: LoadStatusViewModel[];
    currentLoadStatus: string;
    nextStopForETA: number;
    lastStop: LoadStopViewModel;
    loadCarrier: LoadCarrierViewModel;
    loadStops: LoadStopViewModel[];
    firstDriverName: string;
    secondDriverName: string;
    secondDriverPhoneNumber: string;
    firstDriverPhoneNumber: string;
    truckNumber: string;
    trailerNumber: string;
    loadCheckCalls: LoadCheckCallViewModel[];
    dispatchedMaxDate: string;

}
export const triggerViewEventVal: GridParams = {
    filterModel: '',
    columnState: ''
};


export interface LoadStatusViewModel {
    loadStatusId: number;
    loadDetailId: number;
    name: string;
    createdDate: string;
}

export interface LoadStatusSummaryModel {
    loadStatusId: number;
    loadStatus: string;
    count: number;
    sortOrder: number;
    isActive: boolean;
}

export interface EdiUpdateModel {
    field: string;
    oldValue: string;
    newValue: string;
    section: string;
    activityType: string;
}

export interface DatPostModel {
    loadDetailId: number;
    equipmentId: number;
    shipperCity: string;
    shipperState: string;
    shipperZipCode: string;
    deliveryCity: string;
    deliveryState: string;
    deliveryZipCode: string;
}

export interface LoadDetailStakeholderModel {
    loadDetailId: number;
    loadStatus: string;
    carrierRepId?: string;
    carrierRepName?: string;
}

export interface ValidFacilityResponseModel {
    facilityCode: string;
    isValid: boolean;
}

export interface LoadCloneModel {
    loadDetailId: number;
    cloneRequirement: boolean;
    cloneCustomerRate: boolean;
    cloneStakeHolder: boolean;
    cloneNote: boolean;
    cloneLoadStopViewModels: CloneLoadStopViewModel[];
}

export interface CloneLoadStopViewModel {
    loadStopId: number;
    facilityCode: string;
    facilityName: string;
    appointmentEarliestDate: string;
    isPickup: boolean
}

export interface FuelSurchargeRateDetailViewModel {
    fuelSurchargeRate: number;
    isFscDocumentAvailable: boolean;
}

export interface EDICustomerModel {
    customerRequirementsId: number;
    trackingMethodId?: number;
    trackingMethodName?: string;
    ediSenderId?: string;
    isEtracking?: boolean;
    isPOD: boolean;
    isEDI?: boolean;
    isEDIStatusUpdate?: boolean;
    isEDIInvoice?: boolean;
    isEDIAutoAccept?: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    sendMacroPointUpdate?: boolean;
    macroPointId?: string;
    sendLocationUpdate?: boolean;
    sendEventUpdate?: boolean;
    isBreakthrough?: boolean;
    breakthroughId?: string;
    referenceNumberTypeId?: number;
}

export interface LoadQuickViewSubjectModel {
    visible: boolean;
    loadDetailId: number;
    callerScreen: CallerScreen;
}

export interface AccountingStatusViewModel {
    id: number | null | undefined;
    name: string | null | undefined;
    type: string | null | undefined;
    accountingStatus: string | null | undefined;
}

export interface LoadCheckCallEventUpdatesModel {
    checkCallDate: string;
    loadCheckCalls: LoadCheckCallViewModel[];
}