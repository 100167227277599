import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ApiClientService {
  constructor(private readonly httpClient: HttpClient) { }

  get<T>(apiUrl: string, isAnonymous = true): Observable<T> {
    return this.httpClient.get<T>(apiUrl, { withCredentials: isAnonymous })
  }

  post<T1, T2>(apiUrl: string, payload?: T1, isAnonymous = true): Observable<T2> {
    return this.httpClient.post<T2>(apiUrl, payload, { withCredentials: isAnonymous })
  }

  put<T1, T2>(apiUrl: string, payload?: T1, isAnonymous = true): Observable<T2> {
    return this.httpClient.put<T2>(apiUrl, payload, { withCredentials: isAnonymous })
  }

  delete<T>(apiUrl: string, isAnonymous = true): Observable<T> {
    return this.httpClient.delete<T>(apiUrl, { withCredentials: isAnonymous })
  }
}
