import { Injectable } from '@angular/core';
import { Endpoint } from '@app/core/constants/enums';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { RoleView, SelectListView, UserViewModel } from './user-view.model';
import { Observable } from 'rxjs';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { String } from 'typescript-string-operations';

@Injectable({
  providedIn: 'root'
})
export class UserViewService {
  identityEndpoint!: string;

  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.identityEndpoint = this.appConfigService.getEndpoint(Endpoint.Identity);
  }
  getViewSelectList(module: string, callerScreen = ''): Observable<ResponseModel<SelectListView[]>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.userViews.getViewSelectList}`;
    url = String.format(url, module, callerScreen);
    return this.apiClient.get<ResponseModel<SelectListView[]>>(url);
  }

  createView(payload: UserViewModel, callerScreen = ''): Observable<ResponseModel<any>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.userViews.createView}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<UserViewModel, any>(url, payload);
  }
  updateView(payload: UserViewModel, callerScreen = ''): Observable<ResponseModel<any>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.userViews.updateView}`;
    url = String.format(url, callerScreen);
    return this.apiClient.put<UserViewModel, any>(url, payload);
  }
  deleteView(userViewId: number, callerScreen = ''): Observable<ResponseModel<SelectListView[]>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.userViews.deleteView}`;
    url = String.format(url, userViewId, callerScreen);
    return this.apiClient.get<ResponseModel<SelectListView[]>>(url);
  }
  checkDuplicateViewName(viewName: string, viewId: number, module: string): Observable<ResponseModel<any>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.userViews.checkDuplicateViewName}`;
    url = String.format(url, encodeURIComponent(viewName), viewId, module);
    return this.apiClient.get<ResponseModel<any>>(url);
  }
  getUserViews(roleId: number, callerScreen = ''): Observable<ResponseModel<any>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.userViews.getUserViews}`;
    url = String.format(url, roleId, callerScreen);
    return this.apiClient.get<ResponseModel<any>>(url);
  }
  assignUserViews(payload: any, roleId: number): Observable<ResponseModel<any>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.userViews.assignUserViews}`;
    url = String.format(url, roleId);
    return this.apiClient.post<RoleView[], ResponseModel<any>>(url, payload);
  }
}


