<div [formGroup]="formName">
    <label for="{{ elementId }}" [ngClass]="{'disable':control.disabled}" [hidden]="hideLabel">{{ label }}<em
            *ngIf="isRequired" class="asterisk"></em>
        <em *ngIf="iTagData !== ''" class="ml-2 pi pi-info-circle" tooltipEvent="hover|focus" tabindex="0"
            [attr.aria-label]="iTagData">
        </em>
    </label>
    <span class="p-input-icon-right d-block">
        <p-inputMask [mask]="mask" [unmask]="true" [autoClear]="false" class="{{ className }}"
            [attr.autofocus]="isAutoFocus" [type]="inputType" [inputId]="elementId" name="{{ controlName }}"
            autocomplete="new-password" data-placement="bottom" data-trigger="manual" [placeholder]="placeholder"
            [formControlName]="controlName" [attr.disabled]="disabled ? '' : null" (onBlur)="onBlur()">
        </p-inputMask>
    </span>

    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</div>