import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Constants } from '@app/core/constants/constants';
import { FactoringCompanyModel } from '@app/modules/accounting/factoring-company/factoring-company-model';
import { CarrierEntityModel } from '@app/modules/carrier/carrier.model';
import { CustomerModel } from '@app/modules/customer/customer.model';
import { LoadDetailViewModel } from '@app/modules/load/load.model';

@Component({
  selector: 'app-integration-sync-detail',
  templateUrl: './integration-sync-detail.component.html',
  styles: [
  ]
})
export class IntegrationSyncDetailComponent {
  @Input() loadDetail!: LoadDetailViewModel;
  @Input() carrierDetail!: CarrierEntityModel;
  @Input() customerDetail!: CustomerModel;
  @Input() factoringCompanyDetail!: FactoringCompanyModel;


  constructor(public datePipe: DatePipe) { }

  dateTimeFormat(timestamp: any): string {
    return this.datePipe?.transform(timestamp, Constants.dateTimeFormat) ?? '';
  }
}
