import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LoginHelper } from '@app/core/helpers/login.helper';
@Directive({
  selector: '[appPermission]'
})

export class PermissionDirective implements OnInit {
  @Input('appPermission') requiredPermission!: string;
  @Input() module!: string;
  constructor(
    private element: ElementRef) { }
  ngOnInit() {
    const permission = LoginHelper.IsModuleAccessible(this.module, this.requiredPermission);
    if (!permission) {
      this.removeElement();
    }
  }
  removeElement(): void {
    this.element.nativeElement.remove();
  }
}