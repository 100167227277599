import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadService } from '@app/modules/load/load.service';
import { LoadDocumentModel } from '../load-document.model';
import { ConfirmationService } from 'primeng/api';
import { Constants } from '@app/core/constants/constants';
import { distinctUntilChanged } from 'rxjs';
import { SelectListModel } from '@app/core/models/select.list.model';
import { CallerScreen } from '@app/core/constants/enums';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ToasterService } from '@app/core/services/toaster.service';
@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styles: [
  ]
})
export class EditDocumentComponent implements OnInit {

  documentForm!: FormGroup;
  @Input() document!: any;
  @Input() documentTypesSelectList: SelectListModel[] = [];
  @Input() editPanel!: any;
  @Output() editDocument = new EventEmitter<ResponseModel<any>>();
  @Input() loadDetailId!: number;
  updateRequestModel!: LoadDocumentModel;

  constructor(
    public readonly loadService: LoadService,
    private readonly confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private readonly toastService: ToasterService
  ) { }

  ngOnInit(): void {
    this.documentFormInit();
  }

  get frm() {
    return this.documentForm?.controls;
  }

  documentFormInit(): void {
    this.documentForm = this.fb.group({
      documentTypeId: new FormControl(null, {
        validators: [
          Validators.required
        ],
      }),
      documentType: new FormControl(null)
    });

    this.documentForm.controls['documentTypeId'].setValue(this.document.documentTypeId.toString());
    this.documentForm.controls['documentTypeId'].valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      const documentType = value ? this.documentTypesSelectList.find((x: SelectListModel) => x.value === value)?.text || '' : '';
      this.documentForm.controls['documentType'].setValue(documentType);
    });
  }

  updateDocument() {
    this.updateRequestModel = { 
      ...this.document, 
      loadDetailId: this.loadDetailId,
      documentTypeId: this.documentForm.controls['documentTypeId'].getRawValue(), 
      documentType: this.documentForm.value?.documentType 
    };
    this.loadService.updateDocument(this.updateRequestModel, CallerScreen.LoadProfile).subscribe((response) => {
      if (response.statusMessage === Constants.success) {
        this.toastService.showSuccessMessage(response.userMessage);
        this.editDocument.emit(response);
      }
    });
  }

  cancel() {
    this.confirmationService.confirm({
      message: Constants.cancelEditDocumentConfirmation,
      header: Constants.confirmationPopupHeader,
      icon: Constants.updateStatusDialogIcon,
      accept: () => {
        this.editPanel?.hide();
      }
    });
  }
}
