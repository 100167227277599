import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { API_ROUTES } from './core/constants/api-routes';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
const urlSuffixes = [
  `${API_ROUTES.load.trackingBoard.getTrackingBoardList}`,
  `${API_ROUTES.load.trackingBoard.getTrackingBoardSelectList}`,
  `${API_ROUTES.load.loadIncident.getIncidentStatusTypeSelectList}`,
  `${API_ROUTES.load.loadIncident.getIncidentTypeByIncidentStatusType.split('?')[0]}`,
  `${API_ROUTES.load.loadIncident.getResolutionReasonSelectList.split('?')[0]}`,
  `${API_ROUTES.identity.userViews.checkDuplicateViewName.split('?')[0]}`,
  `${API_ROUTES.identity.userViews.createView}`,
  `${API_ROUTES.customer.getCustomerSelectList.split('?')[0]}`,
  `${API_ROUTES.administration.zipCode.getZipCodeSelectList.split('?')[0]}`,
  `${API_ROUTES.identity.userViews.updateView}`,
  `${API_ROUTES.customer.getCustomerDetail.split('?')[0]}`,
  `${API_ROUTES.load.facility.getFacilitySelectList.split('?')[0]}`,
  `${API_ROUTES.load.hereMap.getLoadStopMileage.split('?')[0]}`,
  `${API_ROUTES.customer.getAccountingCustomerById.split('?')[0]}`,
  `${API_ROUTES.identity.user.getUserByDepartmentCodeTypeAhead.split('?')[0]}`,
  `${API_ROUTES.identity.userViews.getViewSelectList.split('?')[0]}`,
  `${API_ROUTES.administration.region.getRegionSelectList.split('?')[0]}`,
  `${API_ROUTES.load.loadDetail.getLoadStatusSelectList.split('?')[0]}`,
  `${API_ROUTES.load.loadDetail.setLoadBoardAuditLog.split('?')[0]}`,
  `${API_ROUTES.load.loadDetail.getLoadStatusSummary.split('?')[0]}`,
  `${API_ROUTES.load.loadDetail.getLoadDetailList.split('?')[0]}`,
  `${API_ROUTES.load.carrierLoadBoard.getCarrierLoadBoard}`,
  `${API_ROUTES.administration.accessorial.getAccessorialFilterSelectList.split('?')[0]}`,
  `${API_ROUTES.identity.userNotification.updateUserNotification.split('?')[0]}`,
  `${API_ROUTES.identity.userNotification.getUserNotificationList.split('?')[0]}`,
  `${API_ROUTES.identity.userNotification.getUnreadUserNotificationCount.split('?')[0]}`,
]


// Construct regular expressions for each URL suffix, including the escaped base URL
const excludeRegexpArray = urlSuffixes.map(suffix => new RegExp(`${escapedRegExp(suffix)}`));
const excludeStringsArray = excludeRegexpArray.map(regex => regex.source);
// Function to escape special characters in a string to be used in a regular expression
function escapedRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#117e82",
  bgsOpacity: 0.5,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.circle,
  blur: 15,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#11797d",
  fgsPosition: POSITION.centerCenter,
  fgsSize: 60,
  fgsType: SPINNER.circle,
  gap: 24,
  logoPosition: POSITION.centerCenter,
  logoSize: 120,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "#11797d",
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 3,
  hasProgressBar: false,
  text: "",
  textColor: "#FFFFFF",
  textPosition: POSITION.centerCenter,
  maxTime: -1,
  minTime: 300,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      excludeRegexp: excludeStringsArray
    })
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }