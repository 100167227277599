import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/core/constants/constants';
import { IncidentSliderTitle, IncidentStatus } from '@app/core/constants/enums';
import { FormHelper, NumberLengthValidator } from '@app/core/helpers/form.helper';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { SelectListModel } from '@app/core/models/select.list.model';
import { LoadService } from '@app/modules/load/load.service';
import { ConfirmationService } from 'primeng/api';
import { LoadIncidentModel } from '../load-incident.model';
import { ResponseModel } from '@app/core/models/api.response.model';
import { emptyString } from 'typescript-string-operations';

@Component({
  selector: 'app-resolve-incident',
  templateUrl: './resolve-incident.component.html',
  styles: [
  ]
})


export class ResolveIncidentComponent implements OnInit {

  resolveIncidentForm!: FormGroup;
  loadIncidentResolutionReasons: SelectListModel[] = [];
  isResolved!: boolean;
  incidentStatus = IncidentStatus;
  incidentSliderTitle = IncidentSliderTitle.ResolveIncident;

  defaultValue = {
    text: Constants.pleaseSelectDefaultText,
    value: null
  };
  @Input() loadIncident!: any;
  @Input() overlayPanel!: any;
  @Input() callerScreen!: string;
  @Output() resolveIncident = new EventEmitter<ResponseModel<LoadIncidentModel>>();

  constructor(
    public readonly loadService: LoadService,
    private readonly confirmationService: ConfirmationService,
    private fb: FormBuilder,
  ) {
  }
  ngOnInit(): void {
    this.getRelatedData();
    this.initForm();
  }

  get frm() {
    return this.resolveIncidentForm?.controls;
  }

  getControl(control: string): AbstractControl {
    return this.resolveIncidentForm.controls[control];
  }

  initForm(): void {
    this.resolveIncidentForm = this.fb.group({
      loadIncidentResolutionReasonId: new FormControl(null, {
        validators: [
          Validators.required
        ]
      }),
      resolutionAmount: new FormControl(null, {
        validators: [
          NumberLengthValidator(7),
          Validators.min(0)
        ]
      }),
      resolutionNote: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(1000)
        ]
      }),
    });

    if (this.loadIncident?.status === IncidentStatus.Resolved) {
      this.resolveIncidentForm.patchValue(this.loadIncident);
      this.getControl('loadIncidentResolutionReasonId').patchValue(this.loadIncident?.loadIncidentResolutionReasonId?.toString());
      this.isResolved = true;
      this.resolveIncidentForm.disable();
      this.getControl('resolutionNote').setValidators(null);
      this.getControl('resolutionNote').updateValueAndValidity();
      this.incidentSliderTitle = IncidentSliderTitle.AdditionalInformation;
    }
    else {
      this.incidentSliderTitle = IncidentSliderTitle.ResolveIncident;
    }
  }

  getReason(reasonId: number): string {
    return this.loadIncidentResolutionReasons?.find(x => x.value === reasonId)?.text || emptyString;
  }

  getRelatedData(): void {
    this.loadService.getResolutionReasonSelectList().subscribe((response) => {
      this.loadIncidentResolutionReasons = response.data;
      this.loadIncidentResolutionReasons.unshift(this.defaultValue);
    });
  }

  resolve() {
    if (this.resolveIncidentForm.invalid) {
      FormHelper.MarkFormGroupDirty(this.resolveIncidentForm);
      return;
    }
    const resolveRequest: LoadIncidentModel = {
      ...this.loadIncident,
      ...this.resolveIncidentForm.value,
      status: IncidentStatus.Resolved,
      processedBy: LoginHelper.GetUserDetail().userId,
      processedByUser: LoginHelper.GetUserDetail().userName,
    };
    this.loadService.resolveLoadIncident(resolveRequest, this.callerScreen).subscribe((response) => {
      if (response.statusMessage === Constants.success) {
        this.resolveIncident.emit(response);
      }
    });
  }

  cancel() {
    this.confirmationService.confirm({
      message: Constants.cancelResolveIncidentConfirmation,
      header: Constants.confirmationPopupHeader,
      icon: Constants.updateStatusDialogIcon,
      accept: () => {
        this.overlayPanel?.hide();
      }
    });
  }
}
