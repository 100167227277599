import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { ResponseModel } from '@app/core/models/api.response.model';
import { SelectListModel, StakeholderSelectListModel } from '@app/core/models/select.list.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations';
import { UserModel, UserScoreCardModel } from './user.model';
import { Endpoint } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { UpdateUserNotificationRequestModel, UserNotificationModel } from '@app/core/models/user-notification.model';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { LazyList } from '@app/core/models/lazy-list.response.model';

@Injectable()
export class UserService {
    identityEndpoint: string;
    constructor(
        private apiClient: ApiClientService,
        private readonly appConfigService: AppConfigService
    ) {
        this.identityEndpoint = this.appConfigService.getEndpoint(Endpoint.Identity);
    }

    getUsers(): Observable<ResponseModel<UserModel[]>> {
        const url = `${this.identityEndpoint}${API_ROUTES.identity.user.getUserList}`;
        return this.apiClient.get<ResponseModel<UserModel[]>>(url);
    }

    createUser(payload: UserModel): Observable<ResponseModel<UserModel>> {
        const url = `${this.identityEndpoint}${API_ROUTES.identity.user.createUser}`;
        return this.apiClient.post<UserModel, ResponseModel<UserModel>>(url, payload);
    }

    updateUser(payload: UserModel): Observable<ResponseModel<UserModel>> {
        const url = `${this.identityEndpoint}${API_ROUTES.identity.user.updateUser}`;
        return this.apiClient.put<UserModel, ResponseModel<UserModel>>(url, payload);
    }

    updateUserStatus(isActive: boolean, userId: number): Observable<ResponseModel<UserModel>> {
        let url = `${this.identityEndpoint}${API_ROUTES.identity.user.updateUserStatus}`;
        url = String.format(url, isActive, userId, LoginHelper.GetUserDetail().userId);
        return this.apiClient.put<undefined, ResponseModel<UserModel>>(url);
    }

    getRegionSelectList(regionId?: string): Observable<ResponseModel<SelectListModel[]>> {
        let url = `${this.appConfigService.getEndpoint(Endpoint.Administration)}${API_ROUTES.administration.region.getRegionSelectList}`;
        url = url + (regionId ? `?regionId=${regionId}` : ``);
        return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
    }
    getUserByDepartmentCodeTypeAhead(departmentCode: string, userName: string): Observable<ResponseModel<SelectListModel[]>> {
        let url = `${this.identityEndpoint}${API_ROUTES.identity.user.getUserByDepartmentCodeTypeAhead}`;
        url = String.format(url, departmentCode, userName);
        return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
    }

    getSalesScoreCard(userId = -1): Observable<ResponseModel<UserScoreCardModel>> {
        let url = `${this.identityEndpoint}${API_ROUTES.identity.userScoreCard.getSalesScoreCard}`;
        url = String.format(url, userId);
        return this.apiClient.get<ResponseModel<UserScoreCardModel>>(url);
    }

    getUnreadUserNotificationCount(): Observable<ResponseModel<number>> {
        let url = `${this.identityEndpoint}${API_ROUTES.identity.userNotification.getUnreadUserNotificationCount}`;
        url = String.format(url, LoginHelper.GetUserDetail().userId);
        return this.apiClient.get<ResponseModel<number>>(url);
    }

    getUserNotificationList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<UserNotificationModel[]>>> {
        const url = `${this.identityEndpoint}${API_ROUTES.identity.userNotification.getUserNotificationList}`;
        return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<UserNotificationModel[]>>>(url, payload);
    }

    updateUserNotification(payload: UpdateUserNotificationRequestModel): Observable<ResponseModel<UserNotificationModel>> {
        const url = `${this.identityEndpoint}${API_ROUTES.identity.userNotification.updateUserNotification}`;
        return this.apiClient.post<UpdateUserNotificationRequestModel, ResponseModel<UserNotificationModel>>(url, payload);
    }

    getUserByDepartmentCode(departmentCode: string, userName: string): Observable<ResponseModel<StakeholderSelectListModel[]>> {
        let url = `${this.identityEndpoint}${API_ROUTES.identity.user.getUserByDepartmentCode}`;
        url = String.format(url, departmentCode, userName);
        return this.apiClient.get<ResponseModel<StakeholderSelectListModel[]>>(url);
    }
}
