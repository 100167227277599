import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { Constants } from '@app/core/constants/constants';
import { CallerScreen, Endpoint } from '@app/core/constants/enums';
import { ResponseModel } from '@app/core/models/api.response.model';
import { LazyList } from '@app/core/models/lazy-list.response.model';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { SelectListModel } from '@app/core/models/select.list.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable, map } from 'rxjs';
import { String } from 'typescript-string-operations';
import { PotentialCapacityInAppNotificationModel } from '../capacity/capacity.model';
import { AwardCapacityModel, AwardDetailModel, AwardLockDetailModel, AwardPotentialCapacityModel, AwardStatusUpdateModel, AwardTruckMatchModel, AwardYieldViewModel, BidDocumentModel, LoadHistoryModel } from './award.model';
import { AuditLogListModel, LazyListAuditLogsListModel } from '@app/shared/components/audit-log/audit-log.model';

@Injectable({
  providedIn: 'root'
})
export class AwardService {

  awardEndpoint!: string;

  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.awardEndpoint = this.appConfigService.getEndpoint(Endpoint.Award);
  }

  getAwardTypeSelectList(awardTypeId = ''): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardType.awardTypeSelectList}`;
    url = String.format(url, awardTypeId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data || [];
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getAwardLaneTypeSelectList(awardLaneTypeId = ''): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardLaneType.awardLaneTypeSelectList}`;
    url = String.format(url, awardLaneTypeId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data || [];
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getAwardManagementList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<AwardDetailModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.getAwardList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<AwardDetailModel[]>>>(url, payload);
  }

  createAward(payload: AwardDetailModel): Observable<ResponseModel<AwardDetailModel>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardCreate}`;
    return this.apiClient.post<AwardDetailModel, ResponseModel<AwardDetailModel>>(url, payload);
  }

  addViewListActivityLog(): Observable<ResponseModel<any>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.addViewActivityLog}`;
    return this.apiClient.post<any, any>(url);
  }

  updateAward(payload: AwardDetailModel): Observable<ResponseModel<AwardDetailModel>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardUpdate}`;
    return this.apiClient.post<AwardDetailModel, ResponseModel<AwardDetailModel>>(url, payload);
  }

  getAwardDetail(awardDetailId: number): Observable<ResponseModel<AwardDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.getAwardDetail}`
    url = String.format(url, awardDetailId);
    return this.apiClient.get<ResponseModel<AwardDetailModel>>(url);
  }

  getAwardLockDetail(facilityDetailId: number): Observable<ResponseModel<AwardLockDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardLockDetail}`;
    url = String.format(url, facilityDetailId);
    return this.apiClient.get<ResponseModel<AwardLockDetailModel>>(url);
  }

  lockAward(awardDetailId: number, userId: number): Observable<ResponseModel<AwardLockDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardLock}`;
    url = String.format(url, awardDetailId, userId);
    return this.apiClient.post<undefined, ResponseModel<AwardLockDetailModel>>(url);
  }

  unlockAward(awardDetailId: number, userId: number): Observable<ResponseModel<string>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardUnlock}`;
    url = String.format(url, awardDetailId, userId);
    return this.apiClient.post<undefined, ResponseModel<string>>(url);
  }

  auditLogAwardProfile(awardDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardProfileAuditLog}`;
    url = String.format(url, awardDetailId);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  getAwardStatusReasons(reasonType = 'AwardProfile'): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardStatusReasonSelectList}`;
    url = String.format(url, reasonType);
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data || [];
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  updateAwardStatus(payload: AwardStatusUpdateModel, callerScreen = CallerScreen.AwardListing): Observable<ResponseModel<number>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardUpdateStatus}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<AwardStatusUpdateModel, ResponseModel<number>>(url, payload);
  }

  auditLogViewStakeholdersAwardProfile(awardDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.auditLogViewStakeholdersAwardProfile}`;
    url = String.format(url, awardDetailId);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  getApprovedCapacity(awardDetailId: number): Observable<ResponseModel<LazyList<AwardCapacityModel[]>>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.getApprovedWaterfallCapacity}`;
    url = String.format(url, awardDetailId);
    return this.apiClient.get<ResponseModel<LazyList<AwardCapacityModel[]>>>(url);
  }

  getSubmittedCapacityList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<AwardCapacityModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.getSubmittedWaterfallCapacity}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<AwardCapacityModel[]>>>(url, payload);
  }

  getAwardTruckMatchList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<AwardTruckMatchModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardTruckMatchList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<AwardTruckMatchModel[]>>>(url, payload);
  }

  addWaterfallViewActivityLog(awardDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.addViewWaterfallCapacityActivityLog}`;
    url = String.format(url, awardDetailId);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  getAwardLaneHistoryList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadHistoryModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.loadHistory.awardLaneHistoryList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadHistoryModel[]>>>(url, payload);
  }

  getAwardMarketHistoryList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadHistoryModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.loadHistory.awardMarketHistoryList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadHistoryModel[]>>>(url, payload);
  }

  getPotentialCapacityList(payload: GridFilterRequestModel, isIncludeEquipment = true): Observable<ResponseModel<LazyList<AwardPotentialCapacityModel[]>>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.awardPotentialCapacityList}`;
    url = String.format(url, isIncludeEquipment);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<AwardPotentialCapacityModel[]>>>(url, payload);
  }

  getCapacityMarketList(payload: GridFilterRequestModel, capacityDetailId: number): Observable<ResponseModel<LazyList<LoadHistoryModel[]>>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.loadHistory.getCapacityMarketList}`;
    url = String.format(url, capacityDetailId);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadHistoryModel[]>>>(url, payload);
  }

  addMarketHistoryListAuditLog(capacityDetailId: number, callerScreen = ''): Observable<ResponseModel<any>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.loadHistory.addMarketHistoryListAuditLog}`;
    url = String.format(url, capacityDetailId, callerScreen);
    return this.apiClient.post<any, any>(url);
  }

  updateSubmittedCapacityStatus(payload: AwardStatusUpdateModel): Observable<ResponseModel<number>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.updateSubmittedCapacityStatus}`;
    return this.apiClient.post<AwardStatusUpdateModel, ResponseModel<number>>(url, payload);
  }

  notifyRep(payload: PotentialCapacityInAppNotificationModel): Observable<ResponseModel<number>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardDetail.notifyRep}`;
    return this.apiClient.post<PotentialCapacityInAppNotificationModel, ResponseModel<number>>(url, payload);
  }

  getAwardAuditLogs(payload: GridFilterRequestModel, callerScreen = CallerScreen.AwardProfile): Observable<ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.auditLog.getAuditLogList}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>>(url, payload);
  }

  downloadBidDocument(bidDocumentId: number, downloadProcessedFile: boolean): Observable<ResponseModel<string>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.bidDocument.downloadBidDocument}`;
    url = String.format(url, bidDocumentId, downloadProcessedFile);
    return this.apiClient.get<ResponseModel<string>>(url);
  }

  getBidDocumentList(): Observable<ResponseModel<BidDocumentModel[]>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.bidDocument.getBidDocumentList}`;
    return this.apiClient.get<ResponseModel<BidDocumentModel[]>>(url);
  }

  uploadBidDocument(payload: FormData): Observable<ResponseModel<any>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.bidDocument.uploadBidDocument}`;
    return this.apiClient.post<FormData, ResponseModel<any>>(url, payload);
  }
  addViewActivityLog(subModule: string, sectionName: string, awardDetailId: number, callerScreen = CallerScreen.AwardProfile): Observable<ResponseModel<any>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.auditLog.addViewActivityLog}`;
    url = String.format(url, encodeURIComponent(subModule), encodeURIComponent(sectionName), awardDetailId, callerScreen);
    return this.apiClient.post<any, any>(url);
  }

  addLaneHistoryListAuditLog(awardDetailId: number, callerScreen = CallerScreen.AwardProfile): Observable<ResponseModel<boolean>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.loadHistory.addLaneHistoryListAuditLog}`;
    url = String.format(url, awardDetailId, callerScreen);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  getAwardYieldList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<AwardYieldViewModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.awardYield.getAwardYieldList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<AwardYieldViewModel[]>>>(url, payload);
  }
}

