<div *ngIf="customerScorecard" class="d-flex align-items-center justify-content-between mb-4">
    <h3 class="mb-0">KPI</h3>
    <span class="nano">Last Synced On: {{customerScorecard.lastSyncedOn |
        date:constants.dateTimeFormat}}</span>
</div>
<div *ngIf="!customerScorecard" class="d-flex align-items-center justify-content-between mb-4">
    <h3 class="mb-0">KPI</h3>
    <span class="nano">Last Synced On: - </span>
</div>
<div class="d-flex gap-3">
    <div class="row" *ngIf="customerScorecard">
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">Tender Acceptance</div>
                </div>
                <h2 *ngIf="customerScorecard.tenderAcceptancePct!==null" class="mb-0 fw-bold">
                    {{customerScorecard.tenderAcceptancePct}} <span class="tiny fw-normal">%</span>
                </h2>
                <h2 *ngIf="customerScorecard.tenderAcceptancePct===null" class="mb-0 fw-bold">-</h2>
                <p-progressBar *ngIf="customerScorecard.tenderAcceptancePct!==null" [value]="tenderAcceptancePct"
                    styleClass="credit-bar credit-class-red"></p-progressBar>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        delete_history
                    </span>
                    <div class="tiny text-black fw-medium"> # of Tenders Expired</div>
                </div>
                <h2 *ngIf="customerScorecard.tendersExpired!==null" class="mb-0 fw-bold">
                    {{customerScorecard.tendersExpired}}</h2>
                <h2 *ngIf="customerScorecard.tendersExpired===null" class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">Appt. OTP<br>&nbsp;</div>
                </div>
                <h2 *ngIf="customerScorecard.apptOnTimePickPct!==null" class="mb-0 fw-bold">
                    {{customerScorecard.apptOnTimePickPct}} <span class="tiny fw-normal">%</span>
                </h2>
                <h2 *ngIf="customerScorecard.apptOnTimePickPct===null" class="mb-0 fw-bold">-</h2>
                <div *ngIf="customerScorecard.apptOnTimePickPct!==null" class="progress-bar">
                    <p-progressBar *ngIf="customerScorecard.apptOnTimePickPct!==null" [value]="apptOnTimePickPct"
                        styleClass="credit-bar credit-class-red"></p-progressBar>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">RAD OTP</div>
                </div>
                <h2 *ngIf="customerScorecard.radOnTimePickPct!==null" class="mb-0 fw-bold">
                    {{customerScorecard.radOnTimePickPct}} <span class="tiny fw-normal">%</span>
                </h2>
                <h2 *ngIf="customerScorecard.radOnTimePickPct===null" class="mb-0 fw-bold">-
                </h2>
                <div *ngIf="customerScorecard.radOnTimePickPct!==null" class="progress-bar">
                    <p-progressBar [value]="radOnTimePickPct" styleClass="credit-bar credit-class-red"></p-progressBar>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">Appt. OTD</div>
                </div>
                <h2 *ngIf="customerScorecard.apptOnTimeDeliveryPct!==null" class="mb-0 fw-bold">
                    {{customerScorecard.apptOnTimeDeliveryPct}} <span class="tiny fw-normal">%</span></h2>
                <h2 *ngIf="customerScorecard.apptOnTimeDeliveryPct===null" class="mb-0 fw-bold">-</h2>
                <div *ngIf="customerScorecard.apptOnTimeDeliveryPct!==null" class="progress-bar">
                    <p-progressBar [value]="apptOnTimeDeliveryPct"
                        styleClass="credit-bar credit-class-red"></p-progressBar>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">RDD OTD</div>
                </div>
                <h2 *ngIf="customerScorecard.rddOnTimeDeliveryPct!==null" class="mb-0 fw-bold">
                    {{customerScorecard.rddOnTimeDeliveryPct}} <span class="tiny fw-normal">%</span></h2>
                <h2 *ngIf="customerScorecard.rddOnTimeDeliveryPct===null" class="mb-0 fw-bold">-</h2>
                <div *ngIf="customerScorecard.rddOnTimeDeliveryPct!==null" class="progress-bar">
                    <p-progressBar [value]="rddOnTimeDeliveryPct"
                        styleClass="credit-bar credit-class-red"></p-progressBar>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        schedule
                    </span>
                    <div class="tiny text-black fw-medium">Checkcall Timeliness</div>
                </div>
                <h2 *ngIf="customerScorecard.avgCheckCallTimeliness!==null" class="mb-0 fw-bold">
                    {{customerScorecard.avgCheckCallTimeliness}} </h2>
                <h2 *ngIf="customerScorecard.avgCheckCallTimeliness===null" class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">E-Track</div>
                </div>
                <h2 *ngIf="customerScorecard.etrackPct!==null" class="mb-0 fw-bold">
                    {{customerScorecard.etrackPct}} <span class="tiny fw-normal">%</span></h2>
                <h2 *ngIf="customerScorecard.etrackPct===null" class="mb-0 fw-bold">-</h2>
                <div *ngIf="customerScorecard.etrackPct!==null" class="progress-bar">
                    <p-progressBar [value]="etrackPct" styleClass="credit-bar credit-class-red"></p-progressBar>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!customerScorecard">
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">Tender Acceptance</div>
                </div>
                <h2 class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        delete_history
                    </span>
                    <div class="tiny text-black fw-medium"> # of Tenders Expired</div>
                </div>
                <h2 class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">Appt. OTP</div>
                </div>
                <h2 class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">RAD OTP</div>
                </div>
                <h2 class="mb-0 fw-bold">-
                </h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">Appt. OTD</div>
                </div>
                <h2 class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">RDD OTD</div>
                </div>
                <h2 class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        schedule
                    </span>
                    <div class="tiny text-black fw-medium">Checkcall Timeliness</div>
                </div>
                <h2 class="mb-0 fw-bold">-</h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-1 rounded-3 bg-grid p-3 bg-grid mb-3 border-lightestgray tiles-kpi">
                <div class="d-flex gap-2 align-items-baseline mb-3">
                    <span class="material-symbols-outlined tiny text-black">
                        percent
                    </span>
                    <div class="tiny text-black fw-medium">E-Track</div>
                </div>
                <h2 class="mb-0 fw-bold">-</h2>
            </div>
        </div>
    </div>
</div>