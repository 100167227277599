import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-number-control',
  templateUrl: './input-number-control.component.html',
  styles: [
  ]
})
export class InputNumberControlComponent {
  @Input() formName!: FormGroup;
  @Input() control!: AbstractControl;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() hideLabel!: boolean;
  @Input() max!: number;
  @Input() min = 0;
  @Input() elementId!: string;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() tabIndex!: number;
  @Input() isAutoFocus = false;
  @Input() iTagData = '';
  @Input() mode = 'decimal';
  @Input() currency = 'USD';
  @Output() blurEvent = new EventEmitter<string>();
  @Input() isCurrencyMode!: boolean;
  @Input() readOnly = false;
  @Input() locale = 'en-US';
  @Input() minFractionDigits!: number;
  @Input() className?: string = '';
  @Input() labelClass = '';
  @Input() isNegative = false; 
  @Output() inputEvent = new EventEmitter<any>();

  get isRequired(): boolean {
    if (!this.control) return false;

    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : null;
    if (validator && validator['required']) {
      return true;
    } else {
      return false;
    }
  }

  get isValid(): boolean | null {
    if (!this.control) return false;

    return this.control.status.toLowerCase() === 'invalid' ? true : null;
  }

  onBlur() {
    const value = this.control.value;
    this.control.setValue(value);
    this.blurEvent.emit(value);
  }

  onInput() {
    const value = this.control.value;
    this.control.setValue(value);
    this.inputEvent.emit(value);
  }
}
