import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskDatPhoneNumber'
})
export class MaskDatPhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      if (value.indexOf("+1") >= 0) {
        let numberValue = '';
        let extValue = '';
        if (value.indexOf("ext") >= 0) {
          numberValue = value.substring((value.indexOf("+1") + 2), value.indexOf("+1") +12);
          extValue = value.substring(value.indexOf("ext") + 3).replace('.', '').trim();
        }
        else {
          numberValue = value.substring(value.indexOf("+1") + 2);
        }
        const areaCode = numberValue.substring(0, 3);
        const firstPart = numberValue.substring(3, 6);
        const secondPart = numberValue.substring(6, 10);
        if (extValue && extValue.length > 0)
          return `+1 (${areaCode}) ${firstPart} ${secondPart} ext${extValue}`;
        else
          return `+1 (${areaCode}) ${firstPart} ${secondPart}`;
      }
      else {
        return value;
      }
    }
    else {
      return value;
    }
  }

}
