<form class="form-horizontal" focusInvalidInput novalidate autocomplete="off" [formGroup]="documentForm">
    <h3 class="fw-normal border-bottom border-light p-3 mb-0">Update Document Type</h3>
    <div class="p-3">
        <app-select-control [formName]="documentForm" elementId="documentTypeId" [options]="documentTypesSelectList"
            [multipleSelection]="false" [filter]="false" [showClear]="false" [isPrimeNgDropdown]="true"
            [isServerSideFilter]="false" styleClass="w-100" [control]="frm['documentTypeId']" controlName="documentTypeId"
            [isShowLabel]="true" label="Document Type">
        </app-select-control>
        <div class="d-flex mt-3 flex-grow-1 justify-content-end gap-2">
            <button class="btn btn-primary" (click)="updateDocument()">Save</button>
            <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
        </div>
    </div>
</form>