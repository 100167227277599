import { ColDef, ColumnApi, GridApi, GridReadyEvent, IDatasource, IGetRowsParams, RowModelType } from '@ag-grid-community/core';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Constants } from '@app/core/constants/constants';
import { AgGridHelpers } from '@app/core/helpers/ag-grid.helper';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { UpdateUserNotificationRequestModel, UserNotificationModel } from '@app/core/models/user-notification.model';
import { UserService } from '@app/modules/administration/user/user.service';
import { UserNotificationComponent } from './user-notification/user-notification.component';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-notification-list',
  templateUrl: './user-notification-list.component.html',
  styles: [
  ]
})
export class UserNotificationListComponent implements OnChanges {
  columnDefs: ColDef[] = [
    {
      field: "title",
      cellRenderer: UserNotificationComponent,
      width: 380,
      cellClass: 'cursor-pointer',
      onCellClicked: (event) => {
        if (event.data.redirectionUrl) {
          this.router.navigate([]).then(() => { window.open(`${event.data.redirectionUrl}`, '_blank'); });
        }
        if (event.data.userNotificationId) {
          const updateNotificationRequest: UpdateUserNotificationRequestModel = {
            isRead: true,
            userId: LoginHelper.GetUserDetail().userId,
            userNotificationId: event.data.notificationId
          };
          this.userService.updateUserNotification(updateNotificationRequest).subscribe((response) => {
            if (response.data) {
              this.setDataSource();
            }
          });
        }
      },
    }
  ];
  rowModelType: RowModelType = Constants.infiniteRowModelType;
  rowData!: UserNotificationModel[];
  params!: IGetRowsParams;
  filterRequest!: GridFilterRequestModel;
  pageSize = Constants.pageSize;
  noRecordFound = false;
  gridHeight = '795px';
  noRecordFoundMsg = Constants.noRecordFound;
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  defaultColDef = AgGridHelpers.getColumnDefinition();
  cacheBlockSize = Constants.pageSize;
  rowBuffer = 0;
  rowSelection: 'single' | 'multiple' = 'multiple';
  cacheOverflowSize = 1;
  maxConcurrentDataSourceRequests = 1;
  infiniteInitialRowCount = 300;
  maxBlocksInCache = Constants.pageSize;
  @Input() updateUserNotification!: number;
  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['updateUserNotification'].currentValue) {
      this.setDataSource();
    }
  }

  onGridReady(params: GridReadyEvent<UserNotificationModel[]>): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.setDataSource();
  }

  setDataSource(): void {
    const dataSource: IDatasource = {
      getRows: (param: IGetRowsParams) => {
        this.params = param;
        this.getUserNotificationList(param);
        this.gridApi.hideOverlay();
      }
    };
    this.gridApi && this.gridApi.setDatasource(dataSource);
  }

  getUserNotificationList(param: IGetRowsParams): void {
    this.filterRequest = {
      pageNumber: param.endRow / this.pageSize,
      pageSize: this.pageSize,
      sortOrders: [],
      filters: [],
      id: LoginHelper.GetUserDetail().userId
    }
    this.userService.getUserNotificationList(this.filterRequest).subscribe((response) => {
      if (!response.data || response.data?.totalRecords <= 0) {
        this.noRecordFound = true;
        this.params.successCallback([], 0);
      }
      if (response.data?.totalRecords > 0) {
        this.rowData = response.data.records;
        this.noRecordFound = false;
        this.params.successCallback(this.rowData, response.data?.totalRecords);
      }
    });
  }
}
