import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitial'
})
export class NameInitialPipe implements PipeTransform {

  transform(fullName: string): string {
    if (!fullName || typeof fullName !== 'string') {
      return '';
    }

    const names = fullName.split(' ');
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    }
    return names.reduce((acc, name) => acc + name.charAt(0).toUpperCase(), '');
  }

}
