<form class="form-horizontal" focusInvalidInput novalidate autocomplete="off" [formGroup]="formGroup">
    <label for="{{elementId}}" [ngClass]="{'disable':control.disabled}">
        {{ label }}
        <em *ngIf="isRequired" class="asterisk"></em>
    </label>
    <p-autoComplete [inputId]="elementId" formControlName="{{controlName}}" [suggestions]="suggestions" [group]="true"
        (completeMethod)="searchItem($event)" (onSelect)="selectItem($event)" [field]="valueField"
        [minLength]="minLength" [delay]="0" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
        (onBlur)="formHelper.PatchAutoCompleteTextBox(formGroup,controlName)" [multiple]="multiple"
        [placeholder]="placeholder">
        <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
                <span>{{ group.label }}</span>
            </div>
        </ng-template>
        <ng-template let-item pTemplate="item" *ngIf="optionFields.length === 0">
            <div>{{ item[''+optionField+''] }}</div>
        </ng-template>
        <ng-template let-item pTemplate="item" *ngIf="optionFields.length > 0">
            <div>
                <span *ngFor="let optionField of optionFields;let i=index">
                    {{ item[''+optionField+''] }}
                    <span *ngIf="i !== (optionFields.length - 1)">,</span>
                </span>
            </div>
        </ng-template>
    </p-autoComplete>
    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</form>