import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class ErrorLoggerService extends ErrorHandler {
    constructor() {
        super()
    }
    override handleError(error: string): void {
        super.handleError(error);
    }
}