<div *ngIf="isAwardCapacity()" class="flex flex-wrap gap-4 mb-3">
    <div class="flex align-items-center">
        <p-radioButton name="paymentTo" [value]="true" [(ngModel)]="isExistingCapacity" inputId="existingCapacity"
            (ngModelChange)="setCapacityFormStatus()"></p-radioButton>
        <label for="existingCapacity" class="ml-2 mt-1">Submit Existing Capacity</label>
    </div>
    <div class="flex align-items-center">
        <p-radioButton name="paymentTo" [value]="false" [(ngModel)]="isExistingCapacity" inputId="newCapacity"
            (ngModelChange)="setCapacityFormStatus()"></p-radioButton>
        <label for="newCapacity" class="ml-2 mt-1">Submit New Capacity</label>
    </div>
</div>
<app-capacity-general-detail *ngIf="capacityForm" [capacityForm]="capacityForm"
    [equipmentSelectList]="equipmentSelectList" (formSubmit)="save($event)" [isAwardCapacity]="isAwardCapacity()"
    [isExistingCapacity]="isExistingCapacity" [isCloneCapacity]="isCloneCapacity()" [awardDetailId]="awardDetailId()"></app-capacity-general-detail>