import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router
} from '@angular/router';
import { UserSession } from '@app/core/constants/enums';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly cookieService: CookieService
  ) { }

  canActivate(): boolean {
    const loginSuccess = this.cookieService.get(
      UserSession.LoginSuccessCookie
    );

    if (loginSuccess) {
      return true;
    } else {
      location.href = '/login';
      return false;
    }
  }
}
