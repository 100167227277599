<div class="info-msg text-info audit-log-info-msg">
    {{auditLogInfoMessage}}
</div>
<p-card styleClass="card-no-padding">
    <div class="position-relative">
        <ag-grid-angular style="width: auto;" [style.height]="gridHeight" class="ag-theme-material has-expanded-row"
            [headerHeight]="40" [rowHeight]="40" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
            [rowSelection]="rowSelection" [suppressCellFocus]="true" [animateRows]="true" [isRowMaster]="isRowMaster"
            [cacheBlockSize]="cacheBlockSize" [rowBuffer]="rowBuffer" [rowModelType]="rowModelType"
            [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams" [suppressContextMenu]="true"
            [suppressMenuHide]="true" [maxBlocksInCache]="maxBlocksInCache"
            [maxConcurrentDatasourceRequests]="maxConcurrentDataSourceRequests" [cacheOverflowSize]="cacheOverflowSize"
            (gridReady)="onGridReady($event)"></ag-grid-angular>
        <div class="no-records-message" style="position: absolute; top: 70%;left: 50%;transform: translate(-50%, -50%);"
            *ngIf="noRecordFound">
            {{ noRecordFoundMsg }}
        </div>
    </div>
</p-card>