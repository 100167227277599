//#region Imports
import { Component, Input, OnInit } from '@angular/core';
import { ExceptionType, FilterFieldType, FilterOperationType, LoopExceptionEventType, LoopExceptionTabs } from '@app/core/constants/enums';
import { Filter, GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { LoopExceptionViewModel } from '@app/modules/accounting/account-payable/account-payable-model';
import { AccountPayableService } from '@app/modules/accounting/account-payable/account-payable.service';
import { LoadService } from '@app/modules/load/load.service';
//#endregion

@Component({
  selector: 'app-load-exception',
  templateUrl: './load-exception.component.html',
  styles: [
  ]
})

export class LoadExceptionComponent implements OnInit {
  //#region Variables
  loopExceptionList!: LoopExceptionViewModel[];
  filterModel!: GridFilterRequestModel;
  statusFilters: Filter[] = [];
  totalRecords = 0;
  filterStatus: string | undefined = LoopExceptionTabs.All;
  @Input() loadDetailId = 0;
  @Input() carrierId = 0;
  loadExceptionList!: LoopExceptionViewModel[];
  billExceptionList!: LoopExceptionViewModel[];
  //#endregion

  //#region Constructor
  /**
   * Constructor for Loop Eception Component.
   * @param accountPaybaleService Account Payable Service
   * @param loadService Load Service
   */
  constructor(
    private readonly accountPaybaleService: AccountPayableService,
    private readonly loadService: LoadService
  ) { }

  //#endregion

  //#region NgMethods
  ngOnInit() {
    this.setFilterStatus(LoopExceptionTabs.All);
    this.refreshLoopException();
  }
  //#endregion

  //#region Methods
  get FilterStatusEnum() {
    return LoopExceptionTabs;
  }

  /**
   * Get LoopException Data
   */
  getLoopExceptionData() {
    this.setDefaultFilters();
    //API call for loop Exception
    this.accountPaybaleService.getLoopExceptionList(this.filterModel).subscribe((response) => {
      if (response.data) {
        this.loopExceptionList = response.data.records || [];
        
        this.loadExceptionList = this.loopExceptionList.filter(
          (record) =>
            record.targetEntityType === ExceptionType.SHIPMENT &&
            record.status !== 'Resolved' &&
            record.status !== 'Deleted'
        );
    
        this.billExceptionList = this.loopExceptionList.filter(
          (record) =>
            record.targetEntityType === ExceptionType.PAYABLE_INVOICE &&
            record.status !== 'Resolved' &&
            record.status !== 'Deleted' &&
            (this.carrierId > 0 ? record.carrierId === this.carrierId : true)
        );
    
        this.totalRecords = (this.loadExceptionList.length || 0) + (this.billExceptionList.length || 0);
      } else {
        this.loopExceptionList = [];
        this.loadExceptionList = [];
        this.billExceptionList = [];
        this.totalRecords = 0; // Ensure totalRecords is reset if no data is received
      }
    });    
  }

  /**
   * Set Filter Status Load/Bill
   * @param status Filter name for Loop Exception
   */
  setFilterStatus(status: string) {
    this.filterStatus = status;
    this.statusFilters = [];
    this.getLoopExceptionData();
  }

  /**
   * Method used to set default filter values
   */
  setDefaultFilters() {
    this.filterModel = {
      pageNumber: 1,
      pageSize: 20,
      sortOrders: [],
      filters: [],
      filtersWithOrCondition: []
    }

    if (this.statusFilters?.length) {
      this.statusFilters.forEach(sf => {
        this.filterModel.filters?.push(sf);
      });
    }

    if (this.loadDetailId > 0) {
      this.filterModel.filters?.push({
        fieldName: 'loadDetailId',
        fieldType: FilterFieldType.Number,
        operation: FilterOperationType.Equals,
        valueOne: this.loadDetailId.toString(),
        valueTwo: ''
      });
    }
  }

  /**
   * Refresh Loop Exception Data
   */
  refreshLoopException() {
    this.loadService.loopException$.subscribe(() => {
      this.getLoopExceptionData();
    });
  }
  //#endregion
}