<h4 class="border-bottom p-3 mb-0">Integration Sync Details</h4>
<div *ngIf="loadDetail">
    <div class="p-3 border-bottom">
        DAT - <span *ngIf="loadDetail.datLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="loadDetail.datLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="loadDetail.datLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(loadDetail.datLastSyncDateTime) ?
            dateTimeFormat(loadDetail.datLastSyncDateTime) : 'N/A'}}</p>
    </div>
    <div class="p-3 border-bottom">
        Parade - <span *ngIf="loadDetail.paradeLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="loadDetail.paradeLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="loadDetail.paradeLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(loadDetail.paradeLastSyncDateTime) ?
            dateTimeFormat(loadDetail.paradeLastSyncDateTime) : 'N/A'}}</p>
    </div>
    <div class="p-3 border-bottom">
        Loop - <span *ngIf="loadDetail.loopLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="loadDetail.loopLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="loadDetail.loopLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(loadDetail.loopLastSyncDateTime) ?
            dateTimeFormat(loadDetail.loopLastSyncDateTime) : 'N/A'}}</p>
    </div>
    <div class="p-3 border-bottom" style="display: none;">
        Elastic Search - <span *ngIf="loadDetail.elasticSearchLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="loadDetail.elasticSearchLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="loadDetail.elasticSearchLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(loadDetail.elasticSearchLastSyncDateTime) ?
            dateTimeFormat(loadDetail.elasticSearchLastSyncDateTime) : 'N/A'}}</p>
    </div>
    <div class="p-3 border-bottom">
        Breakthrough Fuel - <span *ngIf="loadDetail.breakthroughLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="loadDetail.breakthroughLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="loadDetail.breakthroughLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(loadDetail.breakthroughLastSyncDateTime) ?
            dateTimeFormat(loadDetail.breakthroughLastSyncDateTime) : 'N/A'}}</p>
    </div>
</div>
<div *ngIf="customerDetail">
    <div class="p-3 border-bottom">
        Loop -
        <span *ngIf="customerDetail.loopLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="customerDetail.loopLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="customerDetail.loopLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">
            Last synced on {{ dateTimeFormat(customerDetail.loopLastSyncDateTime) ?
            dateTimeFormat(customerDetail.loopLastSyncDateTime) : 'N/A' }}
        </p>
    </div>
    <div class="p-3 border-bottom">
        NetSuite - <span *ngIf="customerDetail.netSuiteLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="customerDetail.netSuiteLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="customerDetail.netSuiteLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">
            Last synced on {{ dateTimeFormat(customerDetail.netSuiteLastSyncDateTime) ?
            dateTimeFormat(customerDetail.netSuiteLastSyncDateTime) : 'N/A'
            }}
        </p>
    </div>
</div>
<div *ngIf="carrierDetail">
    <div class="p-3 border-bottom">
        Loop - <span *ngIf="carrierDetail.loopLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="carrierDetail.loopLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="carrierDetail.loopLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{ dateTimeFormat(carrierDetail.loopLastSyncDateTime) ?
            dateTimeFormat(carrierDetail.loopLastSyncDateTime) : 'N/A'}}</p>
    </div>
    <div class="p-3 border-bottom">
        NetSuite - <span *ngIf="carrierDetail.netSuiteLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="carrierDetail.netSuiteLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="carrierDetail.netSuiteLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(carrierDetail.netSuiteLastSyncDateTime) ?
            dateTimeFormat(carrierDetail.netSuiteLastSyncDateTime) : 'N/A'}}</p>
    </div>
    <div class="p-3 border-bottom">
        Macropoint - <span *ngIf="carrierDetail.macroPointLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="carrierDetail.macroPointLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="carrierDetail.macroPointLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(carrierDetail.macroPointLastSyncDateTime) ?
            dateTimeFormat(carrierDetail.macroPointLastSyncDateTime) : 'N/A'}}</p>
    </div>
</div>
<div *ngIf="factoringCompanyDetail">
    <div class="p-3 border-bottom">
        Loop - <span *ngIf="factoringCompanyDetail.loopLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="factoringCompanyDetail.loopLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="factoringCompanyDetail.loopLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{ dateTimeFormat(factoringCompanyDetail.loopLastSyncDateTime) ?
            dateTimeFormat(factoringCompanyDetail.loopLastSyncDateTime) : 'N/A'}}</p>
    </div>
    <div class="p-3 border-bottom">
        NetSuite - <span *ngIf="factoringCompanyDetail.netSuiteLastSyncStatus === 'true'"
            class="badge bg-success rounded-5 fw-normal lh-base">Success</span>
        <span *ngIf="factoringCompanyDetail.netSuiteLastSyncStatus === 'false'"
            class="badge bg-danger rounded-5 fw-normal lh-base">Failure</span>
        <span *ngIf="factoringCompanyDetail.netSuiteLastSyncStatus === null">
            N/A
        </span>
        <p class="mb-0">Last synced on {{dateTimeFormat(factoringCompanyDetail.netSuiteLastSyncDateTime) ?
            dateTimeFormat(factoringCompanyDetail.netSuiteLastSyncDateTime) : 'N/A'}}</p>
    </div>
</div>