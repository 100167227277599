import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMatchStatus]'
})
export class MatchStatusDirective implements OnInit {

  @Input('appMatchStatus') matchString!: string;
  @Input() statusesToCheck!: string[];

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    if (this.matchString && this.statusesToCheck && this.statusesToCheck.includes(this.matchString)) {
      this.renderer.removeChild(this.el.nativeElement, this.el.nativeElement);
    }
  }

}
