import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-fsc-display',
  templateUrl: './fsc-display.component.html',
  styles: [
  ]
})
export class FSCDisplayComponent {
  params!: ICellRendererParams | any;
  includesFSC = false;
  agInit(params: ICellRendererParams) {
    this.params = params;
    const includesFSC = this.params?.node?.data?.includesFSC;
    const includeFSC = this.params?.node?.data?.includeFSC;
    if (includesFSC === undefined && includeFSC === undefined) {
      this.includesFSC = false;
    } else if (includesFSC === false || includeFSC === false) {
      this.includesFSC = false;
    } else if (includesFSC === true || includeFSC === true) {
      console.log(includesFSC, this.params?.node?.data);
      this.includesFSC = true;
    }
  }
}
