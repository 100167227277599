import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { Constants } from '@app/core/constants/constants';
import { Endpoint } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable, map } from 'rxjs';
import { String } from 'typescript-string-operations';
import { EquipmentModel } from './equipment.model';

@Injectable()
export class EquipmentService {
  administrationEndpoint!: string;

  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.administrationEndpoint = `${this.appConfigService.getEndpoint(Endpoint.Administration)}`;
  }

  getEquipments(): Observable<ResponseModel<EquipmentModel[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getEquipmentList}`;
    return this.apiClient.get<ResponseModel<EquipmentModel[]>>(url);
  }

  createEquipment(payload: EquipmentModel): Observable<ResponseModel<EquipmentModel>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.createEquipment}`;
    return this.apiClient.post<EquipmentModel, ResponseModel<EquipmentModel>>(url, payload);
  }

  updateEquipment(payload: EquipmentModel): Observable<ResponseModel<EquipmentModel>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.updateEquipment}`;
    return this.apiClient.put<EquipmentModel, ResponseModel<EquipmentModel>>(url, payload);
  }

  updateEquipmentStatus(isActive: boolean, equipmentId: number): Observable<ResponseModel<EquipmentModel>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.updateEquipmentStatus}`;
    url = String.format(url, isActive, equipmentId, LoginHelper.GetUserDetail().userId);
    return this.apiClient.put<undefined, ResponseModel<EquipmentModel>>(url);
  }

  getEquipmentSelectList(equipmentId?: number): Observable<ResponseModel<any[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getEquipmentSelectList}`;
    url = url + (equipmentId ? `?equipmentId=${equipmentId}` : ``);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, equipmentId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getDatEquipmentSelectList(datEquipmentId?: number): Observable<ResponseModel<any[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getDatEquipmentSelectList}`;
    url = String.format(url, datEquipmentId ?? 0);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ code: Constants.pleaseSelectDefaultText, datEquipmentId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getDatEquipmentCategorySelectList(datEquipmentCategoryId?: number): Observable<ResponseModel<any[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getDatEquipmentCategorySelectList}`;
    url = String.format(url, datEquipmentCategoryId ?? 0);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, datEquipmentCategoryId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getParadeEquipmentSelectList(paradeEquipmentId?: number): Observable<ResponseModel<any[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getParadeEquipmentSelectList}`;
    url = String.format(url, paradeEquipmentId ?? 0);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, paradeEquipmentId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getMacroPointEquipmentSelectList(macroPointEquipmentId?: number): Observable<ResponseModel<any[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getMcroPointEquipmentSelectList}`;
    url = String.format(url, macroPointEquipmentId ?? 0);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, macroPointEquipmentId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  /**
   * Get Equipment by id.
   * @param equipmentId equipment id of the equipment.
   * @returns Equipment view Model from database.
   */
  getEquipmentById(equipmentId?: number): Observable<ResponseModel<EquipmentModel>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getEquipmentById}`;
    url = String.format(url, equipmentId ?? 0);
    return this.apiClient.get<ResponseModel<EquipmentModel>>(url);
  }
}
