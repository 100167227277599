
export enum UserSession {
  SessionCookie = 'SessionCookie',
  LoginSuccessCookie = 'LoginSuccess',
  LoginFailureCookie = 'LoginFailure',
  User = 'User'
}

export enum Module {
  Accessorial = 'Accessorial',
  Department = 'Department',
  Equipment = 'Equipment',
  Location = 'Location',
  Customer = 'Customer',
  Carrier = 'Carrier',
  Role = 'Role',
  Configuration = 'Configuration',
  User = 'User',
  CustomerContact = 'Customer Contact',
  CarrierContact = 'Carrier Contact',
  Title = 'Title',
  ContactTier = 'Contact Tier',
  Stakeholders = 'Stakeholders',
  Stop = 'Stop',
  CreditRequest = 'Credit Request',
  ReferenceNumber = 'Reference Number',
  Load = 'Load',
  Contact = 'Contact',
  CarrierBid = 'Carrier Bid',
  FactoringCompany = 'Factoring Company',
  PurchaseJournal = 'Purchase Journal',
  CarrierCapacity = 'Carrier Capacity',
  Document = 'Document',
  Capacity = 'Capacity',
  TruckMatch = ' Truck Match',
  View = 'view',
  Notification = 'Notification',
  Invoice = 'Invoice',
  Bill = 'Bill',
  Facility = 'Facility',
  PreferredLanes = 'Preferred Lane',
  Award = 'Award',
  PreferredLaneCapacity = 'Preferred Lane Capacity',
  CustomerAccessorial = 'CustomerAccessorial',
  Bids = 'Bids'
}

export enum APIVersion {
  V1 = 'v1'
}

export enum ButtonText {
  Add = 'Add',
  Update = 'Update',
  Save = 'Save',
  Cancel = 'Cancel',
  Clear = 'Clear'
}

export enum PermissionCode {
  ActivateRole = 'ActivateRole',
  ActivateUser = 'ActivateUser',
  AddCarrier = 'AddCarrier',
  AddCustomer = 'AddCustomer',
  AddCustomerContact = 'AddCustomerContact',
  CloneRole = 'CloneRole',
  CreateRoles = 'CreateRoles',
  CreateUser = 'CreateUser',
  EditCustomerContactDetails = 'EditCustomerContactDetails',
  EditRoles = 'EditRoles',
  EditUser = 'EditUser',
  InactiveActivecontact = 'InactiveActivecontact',
  ManageStakeholderAssignment = 'ManageStakeholderAssignment',
  ManageAccessorials = 'ManageAccessorials',
  ManageConfiguration = 'ManageConfiguration',
  ManageCustomerAssignment = 'ManageCustomerAssignment',
  ManageDepartments = 'ManageDepartments',
  ManageEquipments = 'ManageEquipments',
  ManageGeneralDetails = 'ManageAwardGeneralDetails',
  ManageLocations = 'ManageLocations',
  ManageNotifications = 'ManageNotifications',
  ManageShipsTargetedCommodity = 'ManageShipsTargetedCommodity',
  ViewAccessorials = 'ViewAccessorials',
  ViewAllCarriers = 'ViewCarrier',
  ViewAllCustomers = 'ViewCustomers',
  ViewConfiguration = 'ViewConfiguration',
  ViewCustomerContactDetails = 'ViewCustomerContactDetails',
  ViewCustomerProfile = 'ViewCustomerProfile',
  ViewDepartments = 'ViewDepartments',
  ViewEquipments = 'ViewEquipments',
  ViewLocations = 'ViewLocations',
  ViewNotifications = 'ViewNotifications',
  ViewRoles = 'ViewRoles',
  ViewUsers = 'ViewUsers',
  ViewCarrierProfile = 'ViewCarrierProfile',
  CarrierManageGeneralDetails = 'ManageGeneralDetails',
  ManageStakeholderAssignation = 'ManageStakeholderAssignation',
  MarkCustomerasDNUActive = 'MarkCustomerasDNUActive',
  ViewAccountingDetails = 'ViewAccountingDetails',
  RaiseaCreditRequest = 'RaiseaCreditRequest',
  InviteCarrier = 'InviteCarrier',
  RejectCarrier = 'RejectCarrier',
  ApproveCarrier = 'ApproveCarrier',
  ManageCreditDetails = 'ManageCreditDetails',
  ManageBillingDetails = 'ManageBillingDetails',
  ManageAccountingCompliance = 'ManageAccountingCompliance',
  SyncCarrierDetails = 'SyncCarrierDetails',
  ManageCarrierContacts = 'ManageCarrierContacts',
  ManageNotes = 'ManageNotes',
  MarkCarrierasDNUActive = 'MarkCarrierasDNUActive',
  CarrierViewAccountingDetails = 'ViewAccountingDetails',
  ManageAccountingDetails = 'ManageAccountingDetails',
  ViewCreditRequests = 'ViewCreditRequests',
  ApproveRejectCreditRequest = 'ApproveRejectCreditRequest',
  UnlockCustomer = 'UnlockCustomer',
  UnlockCarrier = 'UnlockCarrier',
  ViewRequirements = 'ViewRequirements',
  ManageLoadRequirements = 'ManageLoadRequirements',
  ManageEDIRequirements = 'ManageEDIRequirements',
  ViewCustomerDocuments = 'ViewCustomerDocuments',
  DownloadCustomerDocument = 'DownloadCustomerDocument',
  DeleteCustomerDocument = 'DeleteCustomerDocument',
  // ActivateFactoringCompany = 'ActivateFactoringCompany',
  EditFactoringCompany = 'EditFactoringCompany',
  ViewCustomerDocument = 'ViewCustomerDocumentDetails',
  ManageCarrierCapacity = 'ManageCapacity',
  UploadCustomerDocument = 'UploadCustomerDocument',
  ViewAllLoads = 'ViewAllLoads',
  ManageDynamicColumns = 'ManageDynamicColumns',
  ViewCarrierDocuments = 'ViewCarrierDocuments',
  ManageActivity = 'ManageActivity',
  ManageActivityonload = 'ManageActivityonload',
  ViewEDILoadTenders = 'ViewEDILoadTenders',
  AcceptLoadTender = 'AcceptLoadTender',
  DeclineLoadTender = 'DeclineLoadTender',
  AssignLoadTender = 'AssignLoadTender',
  ViewEDIExchangeHistory = 'ViewEDIExchangeHistory',
  UploadCarrierDocument = 'UploadCarrierDocument',
  ViewCarrierDocument = 'ViewCarrierDocumentDetails',
  DeleteCarrierDocument = 'DeleteCarrierDocument',
  DownloadCarrierDocument = 'DownloadCarrierDocument',
  CreateNewLoad = 'CreateNewLoad',
  ViewTrackingBoard = 'ViewTrackingBoard',
  ViewAuditLogs = 'ViewAuditLogs',
  ViewComplianceDetails = 'ViewComplianceDetails',
  ViewSystemLogs = 'ViewSystemLogs',
  ViewLanePricing = 'ViewLanePricing',
  ViewLoadHistory = 'ViewLoadHistory',
  EditLoadFromPREAP = 'EditLoadfromPREAP',
  EditLoadFromPREAR = 'EditLoadfromPREAR',
  ViewAllAccountsReceivable = 'ViewAllAccountsReceivable',
  ViewAllAccountsPayable = 'ViewAllAccountsPayable',
  ViewCarrierLoadboard = 'ViewCarrierLoadboard',
  SnoozeLoad = 'SnoozeLoad',
  MarkLoadasHighVisibility = 'MarkLoadasHighVisibility',
  ManageCheckcalls = 'ManageCheckcalls',
  ManageIncidents = 'ManageIncidents',
  UnlockLoad = 'UnlockLoad',
  ViewAllViews = 'ViewAllViews',
  CreateNewView = 'CreateNewView',
  DeleteView = 'DeleteView',
  ModifyView = 'ModifyView',
  AssignView = 'AssignView',
  ViewDocumentdetailsonLoad = 'ViewDocumentdetailsonLoad',
  ViewDocumentonLoad = 'ViewDocumentonLoad',
  UploadDocumentonLoad = 'UploadDocumentonLoad',
  DeleteDocumentonLoad = 'DeleteDocumentonLoad',
  DownloadDocumentonLoad = 'DownloadDocumentonLoad',
  ViewAllFactoringCompany = 'ViewAllFactoringCompany',
  AddNewFactoringCompany = 'AddNewFactoringCompany',
  ActivateInactivateFactoringCompany = 'ActivateInactivateFactoringCompany',
  ViewDocuments = 'ViewDocuments',
  DownloadDocuments = 'DownloadDocuments',
  UploadDocuments = 'UploadDocuments',
  ViewAllAgingAP = 'ViewAllAgingAP',
  AccessAccountsReceivable = 'AccessAccountsReceivable',
  ManageDocuments = 'ManageDocuments',
  ViewARInvoice = 'ViewARInvoice',
  VoidARinvoice = 'VoidARinvoice',
  CreateAdditionalInvoice = 'CreateAdditionalInvoice',
  DownloadInvoice = 'DownloadInvoice',
  SyncARPaymentstatus = 'SyncARPaymentstatus',
  RefreshInvoice = 'RefreshInvoice',
  ViewAllClosedAR = 'ViewAllClosedAR',
  ViewPurchaseJournal = 'ViewPurchaseJournal',
  CreatePurchaseJournal = 'CreatePurchaseJournal',
  VoidPurchaseJournal = 'VoidPurchaseJournal',
  ViewAllAuditReview = 'ViewAllAuditReview',
  ReleaseARentry = 'ReleaseARentry',
  ReleaseAPentry = 'ReleaseAPentry',
  EditLoadfromAuditReview = 'EditLoadfromAuditReview',
  AccessAccountsPayable = 'AccessAccountsPayable',
  EditLoadfromPREAP = 'EditLoadfromPREAP',
  RevertforReviewPREAP = 'RevertforReviewPREAP',
  CreateBillManually = 'CreateBillManually',
  ViewAPBill = 'ViewAPBill',
  VoidBill = 'VoidBill',
  RefreshBill = 'RefreshBill',
  CreateAdditionalBill = 'CreateAdditionalBill',
  SyncAPPaymentstatus = 'SyncAPPaymentstatus',
  ViewallClosedAP = 'ViewallClosedAP',
  EditLoadfromPREAR = 'EditLoadfromPREAR',
  RevertforReviewPREAR = 'RevertforReviewPREAR',
  CreateInvoice = 'CreateInvoice',
  CreateBulkInvoice = 'CreateBulkInvoice',
  ViewAllAgingAR = 'ViewAllAgingAR',
  ManageCarrierEquipOrServices = 'ManageCarrierEquipOrServices',
  ViewLoadDetailsCarrierLoadboard = 'ViewLoadDetailsCarrierLoadboard',
  ManualOnboardCarrier = 'ManualOnboardCarrier',
  ManageCarrierNotes = 'ManageCarrierNotes',
  ViewCarrierNotes = 'ViewCarrierNotes',
  EditDeleteActivityNotes = 'EditDeleteActivityNotes',
  EditAndDeleteActivityNotes = 'EditAndDeleteActivityNotes',
  ViewFacilityListing = 'ViewFacilityListing',
  AddFacility = 'AddFacility',
  AccessFacilityProfile = 'AccessFacilityProfile',
  EditFacilityProfile = 'EditFacilityProfile',
  ManageFacilityGeneralDetails = 'ManageFacilityGeneralDetails',
  ViewFacilityCustomerDetails = 'ViewFacilityCustomerDetails',
  AddFacilityCustomer = 'AddFacilityCustomer',
  EditFacilityCustomer = 'EditFacilityCustomer',
  InactiveActiveFacilityCustomer = 'InactiveActiveFacilityCustomer',
  ViewFacilityContactDetails = 'ViewFacilityContactDetails',
  AddFacilityContact = 'AddFacilityContact',
  EditFacilityContact = 'EditFacilityContact',
  InactiveActiveFacilityContact = 'InactiveActiveFacilityContact',
  ViewFacilityNotes = 'ViewFacilityNotes',
  EditFacilityNotes = 'EditFacilityNotes',
  ManageFacilityCode = 'ManageFacilityCode',
  InactiveActiveFacility = 'InactiveActiveFacility',
  ManagePreferredLanes = 'ManagePreferredLanes',
  ViewAwardListing = 'ViewAwardListing',
  AddNewAward = 'AddNewAward',
  AccessAwardProfile = 'AccessAwardProfile',
  EditAwardProfile = 'EditAwardProfile',
  ManageAwardGeneralDetails = 'ManageAwardGeneralDetails',
  ManagePLNumber = 'ManagePLNumber',
  ViewCapacityListing = 'ViewCapacityListing',
  AccessCapacityProfile = 'AccessCapacityProfile',
  EditCapacityProfile = 'EditCapacityProfile',
  ManageCapacityGeneralDetails = 'ManageCapacityGeneralDetails',
  ManageCapacityID = 'ManageCapacityID',
  AddCapacity = 'AddCapacity',
  ViewSalesTouchPoints = 'ViewSalesTouchPoints',
  ManageAwardStakeholders = 'ManageAwardStakeholders',
  ApproveRejectAward = 'ApproveRejectAward',
  ResubmitAward = 'ResubmitAward',
  AddFacilityFromLoad = 'AddFacilityFromLoad',
  ViewSubmittedCapacity = 'ViewSubmittedCapacity',
  ViewLaneHistoryOnCapacityProfile = 'ViewLaneHistoryOnCapacityProfile',
  ViewMarketHistoryOnCapacityProfile = 'ViewMarketHistoryOnCapacityProfile',
  ViewPotentialAwards = 'ViewPotentialAwards',
  ViewAccessorialMapping = 'ViewAccessorialMapping',
  AddAccessorialMapping = 'AddAccessorialMapping',
  EditAccessorialMapping = 'EditAccessorialMapping',
  ActiveInactiveAccessorialMapping = 'ActiveInactiveAccessorialMapping',
  ActiveInactiveAward = 'ActiveInactiveAward',
  BulkRelease = 'BulkRelease',
  SubmitCapacityOnAward = 'SubmitCapacityOnAward',
  ApproveRejectBids = 'ApproveRejectBids',
  ManageFSCRates = 'ManageFSCRates',
  ManageMacropointRequirement = 'ManageMacropointRequirement',
  ManageFSC = 'ManageFSC',
  CloneLoad = 'CloneLoad',
  UploadAwardBids = 'UploadAwardBids',
  ActiveInactiveCapacity = 'ActiveInactiveCapacity',
  CloneCapacity = 'CloneCapacity',
  ReleaseReport = "ReleaseReport",
  QuickView = 'QuickView',
  ViewArAging = 'ViewArAging',
  ResendInvoiceToCustomer = 'ResendInvoiceToCustomer',
  ResendInvoiceToEDI = 'ResendInvoiceToEDI',
  ViewAllTrackingBoardViews = 'ViewAllTrackingBoardViews',
  AssignTrackingBoardView = 'AssignTrackingBoardView',
  DeleteTrackingBoardView = 'DeleteTrackingBoardView',
  ModifyTrackingBoardView = 'ModifyTrackingBoardView',
  ExportLoads = 'ExportLoads',
  ExportCustomers = 'ExportCustomers',
  ExportCarriers = 'ExportCarriers',
  ExportAccountReceivable = 'ExportAccountReceivable',
  ExportAccountPayable = 'ExportAccountPayable',
  ViewAwards = 'ViewAwards',
  ViewLoadboardUpdates = 'ViewLoadboardUpdates',
  ViewCapacityListingOnCarrierProfile = 'ViewCapacityListingOnCarrierProfile',
  CreateNewTrackingBoardView = 'CreateNewTrackingBoardView',
  EditDocuments = 'EditDocuments',
  BulkAccept = 'BulkAccept',
  ActiveInactiveCapacityOnCarrierProfile = 'ActiveInactiveCapacityOnCarrierProfile',
  RevertLoadFromPreAP = 'RevertLoadFromPreAP',
  RevertLoadFromPreAR = 'RevertLoadFromPreAR',
  ViewYieldManagement = 'ViewYieldManagement',
  ViewReviewCarrierListing = 'ViewReviewCarrierListing',
  ApproveRejectCarrierOnLoad = 'ApproveRejectCarrierOnLoad',
  ResubmitAwardOnCustomerProfile = 'ResubmitAwardOnCustomerProfile',
  MarkLoadAsPriority = 'MarkLoadAsPriority',
  UnlockLoadFromTrackingBoard = 'UnlockLoadFromTrackingBoard',
  LockLoadFromTrackingBoard = 'LockLoadFromTrackingBoard',
  EditInvoice = 'EditInvoice'
}

export enum PermissionModuleCode {
  Administration = 'Administration',
  MasterManagement = 'MasterManagement',
  RoleManagement = 'RoleManagement',
  UserManagement = 'UserManagement',
  SystemConfiguration = 'SystemConfiguration',
  CustomerManagement = 'CustomerManagement',
  CustomerListing = 'CustomerListing',
  CustomerProfile = 'CustomerProfile',
  Carrier = 'Carrier',
  CarrierListing = 'CarrierListing',
  CarrierProfile = 'CarrierProfile',
  Load = 'Load',
  CarrierAccounting = 'CarrierAccounting',
  CreditApproval = 'CreditApproval',
  CustomerDocuments = 'CustomerDocuments',
  CarrierDocuments = 'CarrierDocuments',
  Requirements = 'Requirements',
  FactoringCompany = 'FactoringCompany',
  CarrierCapacity = 'CarrierCapacity',
  Loadboard = 'Loadboard',
  LoadProfile = 'LoadProfile',
  CheckCall = 'CheckCall',
  AccountsPayable = 'AccountsPayable',
  CarrierNotes = 'CarrierNotes',
  PreferredLanes = 'PreferredLanes',

  PreAR = 'PreAR',
  ARAging = 'ARAging',
  ARClose = 'ARClosed',
  PreAP = 'PreAP',
  APAging = 'APAging',
  APClose = 'APClosed',
  PurchaseJournal = 'AdditionalPurchaseJournal',

  ManageTruckMatch = 'ManageTruckMatch',
  EDIManagement = 'EDIManagement',
  SystemLogs = 'SystemLogs',
  TrackingBoard = 'TrackingBoard',
  AuditReview = 'AuditReview',
  LoadHistory = 'LoadHistory',
  NotificationManagement = 'NotificationManagement',
  CarrierLoadboard = 'CarrierLoadboard',
  LoadLock = 'LoadLock',
  ViewManagement = 'ViewManagement',
  DocumentManagement = 'DocumentManagement',
  CreateBulkInvoice = 'CreateBulkInvoice',
  FacilityListing = 'FacilityListing',
  FacilityProfile = 'FacilityProfile',
  AwardListing = 'AwardListing',
  AwardProfile = 'AwardProfile',
  CapacityProfile = 'CapacityProfile',
  CapacityListing = 'CapacityListing',
  Award = 'Award',
  CustomerAccessorial = 'CustomerAccessorial',
  AwardAcceptedCapacity = "AwardAcceptedCapacity",
  AwardSubmittedCapacity = "AwardSubmittedCapacity",
  ManageFSCRates = "ManageFSCRates",
  CloneLoad = "CloneLoad",
  YieldManagement = 'YieldManagement',
  ReviewCarrier = 'ReviewCarrier'
}


export enum Endpoint {
  Identity = 'identity',
  Administration = 'administration',
  Customer = 'customer',
  Carrier = 'carrier',
  Load = 'load',
  Webapp = 'webapp',
  Accounting = 'accounting',
  RealTimeMessaging = 'realtimemessaging',
  Facility = 'facility',
  Award = 'award'
}

export enum FieldType {
  Password = 'password',
  Text = 'text',
  Button = 'button'
}

export enum CarrierStatus {
  New = 'new',
  Invited = 'invited',
  InReview = 'in review',
  Active = 'active',
  DoNotUse = 'do not use',
  NonCompliant = 'non-compliant',
  ActiveLimited = 'active-limited'
}

export enum CarrierStakeholderType {
  CarrierRep = 'Carrier Rep',
  OpsRep = 'Ops Rep',
  APSpecialist = 'AP Specialist'
}

export enum CarrierSearch {
  DOTNumber = 'DOT Number',
  MCNumber = 'MC Number'
}

export enum MessageSeverity {
  Error = 'Error',
  Information = 'Information',
  Warning = 'Warning'
}

export enum InviteVerificationType {
  Verified = 'Verified',
  Unverified = 'Unverified',
  FMCSA = 'FMCSA'
}

export enum EmailTypValues {
  Verified = 1,
  Unverified = 2,
  FMCSA = 3
}

export enum PaymentToType {
  Carrier = 'Carrier',
  Vendor = 'Vendor'
}

export enum ToastMessageType {
  Information = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warn'
}

export enum ToastMessageTitle {
  Information = 'Information',
  Success = 'Success',
  Error = 'Error',
  Warning = 'Warning',
  Validation = 'Validation'
}

export enum CustomerStatus {
  Active = 'active',
  Dormant = 'dormant',
  NewDraft = 'new/draft',
  CreditHold = 'credit hold',
  CreditRequested = 'credit requested',
  DoNotUse = 'do not use',
}

export enum LoadMode {
  Drayage = 'Drayage',
  Intermodal = 'Intermodal',
  LTL = 'LTL',
  Partial = 'Partial',
  TL = 'TL'
}

export enum LoadStatus {
  Draft = 'Draft',
  Open = 'Open',
  Waterfall = 'Waterfall',
  Assigned = 'Assigned',
  Covered = 'Covered',
  Dispatched = 'Dispatched',
  AtPickup = 'At Pickup',
  Loaded = 'Loaded',
  InTransit = 'In-Transit',
  AtDestination = 'At Destination',
  Delivered = 'Delivered',
  Billing = 'Billing',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
  Review = 'Review',
  ArAging = 'ArAging'
}

export enum FilterOperationType {
  Contains = 'Contains',
  NotContains = 'NotContains',
  Equals = 'Equals',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  InRange = 'InRange',
  In = 'In',
  StartsWith = 'StartWith',
}

export enum DepartmentCode {
  Sales = 'Sales',
  Procurement = 'Procurement',
  Accounting = 'Accounting'
}
export enum FilterFieldType {
  Number = 'Number',
  Text = 'Text',
  Date = 'Date',
  Set = 'Set',
  Status = 'Status',
  Decimal = 'Decimal',
  Boolean = 'Boolean'
}
export enum CustomerProfileTabs {
  Contacts = 'Contacts',
  Notes = 'Notes',
  Requirements = 'Requirements',
  Accounting = 'Accounting',
  Documents = 'Documents',
  LoadHistory = 'Load History',
  AccountRecievableAging = 'AR AGING',
  ManageFSC = 'Manage FSC',
  Awards = 'Awards',
  AuditLog = 'Audit Log',
}

export enum LoadProfileTab {
  CustomerDetails = 0,
  Stakeholders = 1,
  TruckMatch = 2,
  BOL = 3,
  LanePricing = 4,
  CarrierDetail = 6,
  BidsAndOffer = 5,
  RateConfirmation = 7,
  CheckCall = 8,
  Incidents = 9,
  Documents = 10,
  Notes = 11,
  AuditLog = 12
}

export enum CarrierTabs {
  Contacts = 0,
  Compliance = 1,
  ManageCapacity = 2,
  PreferredLanes = 3,
  LoadHistory = 4,
  Accounting = 5,
  Documents = 6,
  Notes = 7,
  ManageFSC = 8,
  Capacities = 9,
  AuditLog = 10,
  CarrierSalesTouchPoint = 11,
}

export enum CarrierCarrierCertificateTabs {
  Identity = 0,
  Authority = 1,
  Insurance = 2,
  Certificate = 3,
  Safety = 4,
  Crash = 5
}
export enum LoadStatusReasonType {
  BounceLoad = 'BounceLoad',
  CancelLoad = 'CancelLoad'
}

export enum LoadCheckCallStatus {
  Dispatched = 'Dispatched',
  AtPickup = 'At Pickup',
  Loaded = 'Loaded',
  InTransit = 'In-Transit',
  AtDestination = 'At Destination',
  Delivered = 'Delivered',
  Update = 'Update',
  Covered = 'Covered',
  Bounced = 'Bounced'
}

export enum CreditRequestStatus {
  All = 'All',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum SignalRGroup {
  LoadLock = 'LoadLock',
  LoadUnLock = 'LoadUnLock',
  CreateLoad = 'CreateLoad',
  CheckCall = 'CheckCall',
  BounceLoad = 'BounceLoad',
  RecoverLoad = 'RecoverLoad',
  CarrierLock = 'CarrierLock',
  CarrierUnLock = 'CarrierUnLock',
  CustomerLock = 'CustomerLock',
  CustomerUnLock = 'CustomerUnLock',
  CreateLoadBid = 'CreateLoadBid',
  ReleaseForBilling = 'ReleaseForBilling',
  EDITenderReject = 'EDITenderReject',
  EDITenderAccept = 'EDITenderAccept',
  ARRevert = 'ARRevert',
  APRevert = 'APRevert',
  Parade = 'Parade',
  UserDisable = 'UserDisable',
  InAppNotification = 'InAppNotification',
  UpsertLoad = 'UpsertLoad',
  LoadBOL = 'LoadBOL',
  CustomerDNU = 'CustomerDNU',
  CustomerActive = 'CustomerActive',
  CarrierDNU = 'CarrierDNU',
  CarrierActive = 'CarrierActive',
  ARAdditionalInvoice = 'ARAdditionalInvoice',
  APAdditionalBill = 'APAdditionalBill',
  Incidents = 'Incidents',
  RefreshARAPList = 'RefreshARAPList',
  UpsertCarrierLoadboard = 'UpsertCarrierLoadboard',
  CarrierManualOnboard = 'CarrierManualOnboard',
  CarrierManualOffboard = 'CarrierManualOffboard',
  FacilityLock = 'FacilityLock',
  FacilityUnLock = 'FacilityUnLock',
  AwardLock = 'AwardLock',
  AwardUnLock = 'AwardUnLock',
  CapacityLock = 'CapacityLock',
  CapacityUnLock = 'CapacityUnLock',
  UpsertFacility = 'UpsertFacility',
  UpsertAward = 'UpsertAward',
  UpsertCapacity = 'UpsertCapacity',
  UnreadNotification = 'UnreadNotification',
  RefreshDocumentList = 'RefreshDocumentList',
  LoadQueue = "LoadQueue",
  UpsertLoadDetailQueue = "UpsertLoadDetailQueue",
  LoadReviewCarrier = 'LoadReviewCarrier',
  LoadWaterfallPriorNotification = 'LoadWaterfallPriorNotification',
  TrackingBoardLock = 'TrackingBoardLock',
  TrackingBoardUnLock = 'TrackingBoardUnLock',
  LoopException = 'LoopException'
}
export enum SignalRMethod {
  AddToGroup = 'AddToGroup',
  RemoveFromGroup = 'RemoveFromGroup',
  SendMessageToGroup = 'SendMessageToGroup',
  ReceiveMessage = 'ReceiveMessage'
}

export enum LoadCarrierBidStatus {
  Open = 'Open',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Live = 'Live',
  Dead = 'Dead',
}

export enum PaymentModes {
  ACH = 1,
  Checks = 2,
}

export enum CarrierProfileTabs {
  Contacts = 'Contacts',
  Compliance = 'Compliance',
  Trucks = 'Trucks',
  PreferredLanes = 'Preferred Lane',
  Accounting = 'Accounting',
  Documents = 'Documents',
  AuditLog = 'Audit Log',
  LoadHistory = 'Load History',
  Notes = 'Notes',
  CarrierSalesTouchPoint = 'Sales Touch Point',
  ManageFSC = 'Manage FSC',
  Capacities = 'Capacities',
}

export enum RateConfirmationStatus {
  Pending = 'Pending',
  Signed = 'Signed',
  Expired = 'Expired',
  Forwarded = 'Forwarded',
  Resent = 'Resent',
}

export enum Viewer {
  Office = 'office',
  Pdf = 'pdf',
  Url = 'url'
}

export enum ActiveInactiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Inactivate = 'Inactivate',
  Activate = 'Activate',
  Expired = 'Expired'
}

export enum AvailUnavailStatus {
  Avail = 'Avail',
  Unavail = 'Unavail',
}

export enum BooleanValues {
  True = 'true',
  False = 'false'
}

export enum YesNoStatus {
  Yes = 'Yes',
  No = 'No'
}

export enum FactoringCompanyPaymentModes {
  ACH = 'ACH',
  Checks = 'Checks'
}

export enum ContentType {
  Word = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PowerPoint = 'application/vnd.ms-powerpoint',
  PDF = 'application/pdf',
  ImagePNG = 'image/png',
  Json = 'application/json'
}

export enum FileExtension {
  PDF = '.pdf',
  Excel = '.xlsx',
  CSV = '.csv'
}

export enum LoadTruckMatchStatus {
  Active = 'Active',
  DoNotUse = 'Do Not Use',
  New = 'New',
  Invited = 'Invited',
  InReview = 'In Review',
  NonCompliant = 'Non-Compliant'
}

export enum TruckMatch {
  DAT = 'DAT',
  Parade = 'Parade',
  LoadHistory = 'Load History',
  TmsTruck = 'T1',
  PreferredLane = 'Preferred Lanes',
  MacroPoint = 'MacroPoint'
}

export enum IncidentStatus {
  Open = 'Open',
  Resolved = 'Resolved',
  InProgress = 'In Progress'
}
export enum LoadIncidentStatusType {
  AtPickup = 'At Pickup',
  InTransit = 'In-Transit',
  AtDelivery = 'At Delivery'
}

export enum PurchaseJournalStatus {
  Paid = 'Paid',
  Active = 'Active',
  PartiallyPaid = 'Partially Paid',
  Inactive = 'Inactive'
}

export enum EdiLoadTenderListStatus {
  All = 'All',
  Pending = 'Pending',
  Assigned = 'Assigned',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Queued = 'Queued',
}

export enum AccountingStatus {
  New = "New",
  Aging = "Aging",
  Paid = "Paid",
  Closed = 'Closed'
}

export enum AccountDocumentType {
  All = 'All',
  DocumentMissing = 'Document Missing',
  InvoiceReady = 'Invoice Ready'
}

export enum AccountInvoiceStatus {
  Paid = 'Paid',
  NotPaid = 'Not-Paid',
  PartialPaid = 'Partial Paid'
}

export enum AccountAPAgingFilterOptions {
  All = 'All',
  QuickPay = 'Quick Pay',
  DueToday = 'Due Today',
  Overdue = 'Overdue',
  PartialPaid = 'Partial Paid',
  AdditionalBills = 'Additional Bills',
}

export enum AccountARAgingFilterOptions {
  All = 'All',
  DueToday = 'Due Today',
  Overdue = 'Overdue',
  PartialPaid = 'Partial Paid',
  AdditionalBills = 'Additional Invoices',
}

export enum AccountARCloseFilterOptions {
  All = 'All',
  AdditionalInvoices = 'Additional Invoices',
}

export enum InvoicePaymentStatus {
  Active = 'Active',
  NotPaid = 'Not-Paid',
  PartialPaid = 'Partial Paid',
  Paid = 'Paid',
}

export enum AccountAPCloseFilterOptions {
  All = 'All',
  QuickPay = 'Quick Pay',
  AdditionalBills = 'Additional Bills',
}


export enum UserViews {
  LoadBoard = 'LoadBoard',
  TrackingBoard = 'TrackingBoard'
}

export enum TrackingMethod {
  Manual = 'Manual',
  MacroPoint = 'MacroPoint'
}

export enum PaymentTo {
  Vendor = "vendor",
  Carrier = "carrier"
}

export enum TimeZone {
  CT = 'America/Chicago',
}

export enum TrackingBoardExceptions {
  Incident = 'Incident',
  HighVisibility = 'HighVisibility',
  IsLateToFirstPickup = 'IsLateToFirstPickup',
  Detention = 'Detention',
  NoPOD = 'NoPOD',
  Snooze = 'Snooze',
  DropTrailer = 'DropTrailer',
  OnTime = 'OnTime',
  IsPriorityLoad = 'IsPriorityLoad',
  MissingMacropointConfirmation = 'MissingMacropointConfirmation',
  ArrivingAtPickup = 'ArrivingAtPickup',
  ArrivingAtDrop = 'ArrivingAtDrop',
  TrendingLateToPickup = 'TrendingLateToPickup',
  TrendingLateToDrop = 'TrendingLateToDrop',
  MissingCheckCall = 'MissingCheckCall'
}


export enum IncidentSliderTitle {
  ResolveIncident = 'Resolve Incident',
  AdditionalInformation = 'Additional Information'
}

export enum APFilterOptions {
  All = 'All',
  QuickPay = 'QuickPay'
}
export enum LoadTimeline {
  Draft = 'Draft',
  Open = 'Open',
  Covered = 'Covered',
  Dispatched = 'Dispatched',
  AtPickUp = 'At Pickup',
  Loaded = 'Loaded',
  InTransit = 'In-Transit',
  AtDestination = 'At Destination',
  Delivered = 'Delivered',
}
export enum LoadCheckCallTimeLine {
  Dispatched = 1,
  AtPickup = 2,
  Loaded = 3,
  InTransit = 4,
  AtDestination = 5,
  Delivered = 6
}
export enum LoadHistoryButtonText {
  All = 'All',
  Active = 'Active',
  Past = 'Past'
}

export enum AccountType {
  AP = 'AP',
  AR = 'AR'
}

export enum RoleLandingPages {
  Loadboard = 'Loadboard',
  TrackingBoard = 'Tracking Board',
  UserListing = 'User Listing',
  ARListing = 'AR Listing',
  APListing = 'AP Listing',
}

export enum RoleLandingPagePermissions {
  TrackingBoard = 'View Tracking Board',
  ViewUser = 'View User',
}

export enum LoadProfileTabName {
  CustomerDetails = 'Customer Details',
  Stakeholders = 'Stakeholders',
  TruckMatch = 'Truck Match',
  BOL = 'BOL',
  LanePricing = 'Lane Pricing',
  CarrierDetail = 'Carrier Details',
  BidsAndOffer = 'Offers',
  RateConfirmation = 'Rate Confirmation',
  CheckCall = 'Check Call',
  Incidents = 'Incidents',
  Documents = 'Documents',
  Notes = 'Notes',
  AuditLog = 'Audit Log'
}

export enum ConfigurationButtonKey {
  PaymentTerms = 'Payment Terms',
  LineItems = 'Line Items'
}

export enum PageTitle {
  Login = 'Login',
  Loadboard = 'Loadboard',
  TrackingBoard = 'Tracking Board',
  AuditReview = 'Audit Review',
  CustomerDirectory = 'Customer Directory',
  CarrierDirectory = 'Carrier Directory',
  PRE_AP = 'PRE-AP',
  AP_Aging = 'AP-Aging',
  AP_Closed = 'AP-Closed',
  PRE_AR = 'PRE-AR',
  AR_Aging = 'AR-Aging',
  AR_Closed = 'AR-Closed',
  CreditApproval = 'Credit Approval',
  AdditionalPurchaseJournal = 'Additional Purchase Journal',
  FactoringCompany = 'Factoring Company',
  UserManagement = 'User Management',
  RolesManagement = 'Roles Management',
  MastersManagement = 'Masters Management',
  AccessorialMaster = 'Accessorial Master',
  DepartmentMaster = 'Department Master',
  EquipmentMaster = 'Equipment Master',
  LocationMaster = 'Location Master',
  SystemConfiguration = 'System Configuration',
  SystemLogs = 'System Logs',
  EDILoadTenders = 'EDI Load Tenders',
  NotificationMaster = 'Notification Master',
  CarrierLoadboard = 'Carrier Loadboard',
  ReportMasterData = 'Report Master Data',
  Facilities = 'Facility Listing',
  FacilityManagement = 'Facility Management',
  Awards = 'Award Listing',
  AwardManagement = 'Award Management',
  Capacities = 'Capacity Listing',
  CapacityManagement = 'Capacity Management',
  ManageFSC = 'Manage FSC',
  YieldManagement = 'Yield Management',
  ReviewCarrier = 'Review Carrier'
}

export enum LoadSubModule {
  LoadDocument = 'LoadDocument',
  LoadIncident = 'LoadIncident',
  LoadCheckCall = 'LoadCheckCall',
  LoadRateConfirmation = 'LoadRateConfirmation',
  LoadCarrierBid = 'LoadCarrierBid',
  LanePricing = 'LanePricing',
  LoadBOL = 'LoadBOL',
  LoadTMSTruck = 'LoadTMSTruck',
  LoadLoadTMSTruckCapacity = 'LoadLoadTMSTruckCapacity',
  CustomerDetail = 'CustomerDetail',
  Stakeholders = 'Stakeholders',
  CarrierDetails = 'CarrierDetails',
  Notes = 'Notes',
  AuditLog = 'AuditLog',
  FacilityDetails = 'FacilityDetails'
}

export enum LoadSectionName {
  ViewLoadDocument = 'ViewLoadDocument',
  ViewLoadIncident = 'ViewLoadIncident',
  ViewCheckCall = 'ViewCheckCall',
  ViewLoadRateConfirmation = 'ViewLoadRateConfirmation',
  ViewLoadCarrierBid = 'ViewLoadCarrierBid',
  ViewLanepricing = 'ViewLanepricing',
  ViewLoadBOL = 'ViewLoadBOL',
  ViewTruckHistory = 'ViewTruckHistory',
  ViewTMSTruckCapacity = 'ViewTMSTruckCapacity',
  ViewCustomerDetail = 'ViewCustomerDetail',
  ViewStakeholders = 'ViewStakeholders',
  ViewCarrierDetails = 'ViewCarrierDetails',
  ViewNotes = 'ViewNotes',
  ViewAuditLog = 'ViewAuditLog',
  ViewFacilityDetails = 'ViewFacilityDetails'
}

export enum ARSectionName {
  ViewAccountReceivableList = 'ViewAccountReceivableList',
  ViewAR_AGINGList = 'ViewAR_AGINGList',
  ViewARCLOSEDList = 'ViewARCLOSEDList',
  ViewARAgingDocumentList = 'ViewARAgingDocumentList',
  ViewARCLOSEDDocumentList = 'ViewARCLOSEDDocumentList',
  ViewDocumentsListInAccountReceivable = 'ViewDocumentsListInAccountReceivable',
  ViewAuditReviewList = 'ViewAuditReviewList',
  ViewPurchaseJournalList = 'ViewPurchaseJournalList',

}

export enum APSectionName {
  ViewPreAccountPaybleList = 'ViewPreAccountPaybleList',
  ViewPreAccountPaybleAgingList = 'ViewPreAccountPaybleAgingList',
  ViewPreAccountPaybleClosingList = 'ViewPreAccountPaybleClosingList',
}

export enum TrackByOptions {
  MacroPoint = 'MacroPoint',
  Manual = 'Manual'
}

export enum ConfigurationGroup {
  TrackingBoard = 'Tracking Board'
}

export enum ConfigurationDetail {
  TrackingBoardAutoRefreshTime = 'TrackingBoardAutoRefreshTime',
  UnlockTimer = 'UnlockTimer',
  DefaultPaymentTermId = 'DefaultPaymentTermId',
  AuditLogViewEventFlag = 'AuditLogViewEventFlag',
  LoadStopValidationThreshold = 'LoadStopValidationThreshold',
  FacilityAppointmentValidationThreshold = 'FacilityAppointmentValidationThreshold',
  EIARate = 'EIARate',
  HighwayIntegrationEnabled = 'HighwayIntegrationEnabled',
  LoadBlobStorageURL = 'LoadBlobStorageURL',
  FSCRateAndWaterfallThreshold = 'FSCRateAndWaterfallThreshold'
}

export enum InvoiceStatus {
  New = 'New',
  Pending = 'Pending',
  Active = 'Active',
  Fail = 'Fail',
  Void = 'Void'
}

export enum CallerScreen {
  LoadBoard = 'LoadBoard',
  LoadProfile = 'LoadProfile',
  TrackingBoard = 'TrackingBoard',
  CarrierLoadBoard = 'CarrierLoadBoard',
  EDI = 'EDI',
  BOLList = 'BOLList',
  LoadStatusPopup = 'LoadStatusPopup',
  AccountReceivable = 'AccountReceivable',
  AccountPayable = 'AccountPayable',
  LoadProfileCancel = 'LoadProfileCancel',
  PreAP = 'PreAP',
  PreAR = 'PreAR',
  AuditReview = 'AuditReview',
  PurchaseJournal = 'PurchaseJournal',
  FacilityProfile = 'FacilityProfile',
  FacilityList = 'FacilityList',
  AwardProfile = 'AwardProfile',
  AwardListing = 'AwardListing',
  CapacityListing = 'CapacityListing',
  CapacityProfile = 'CapacityProfile',
  CustomerProfile = 'CustomerProfile',
  CarrierProfile = 'CarrierProfile',
  PreferredLane = 'PreferredLane',
  Trucks = 'Trucks',
  LoadReviewCarrierList = 'LoadReviewCarrierList',
  LoadWaterfallProfile = 'LoadWaterfallProfile'
}

export enum AuditLogActivityType {
  Add = 'Add',
  Delete = 'Delete',
  Edit = 'Edit',
  Update = 'Update'
}

export enum SystemUser {
  TMS = 'TMS',
  EDI = 'EDI',
  MacroPoint = 'MacroPoint',
  RateConfirmation = 'RateConfirmation',
  Administrator = 'Administrator',
  FourKites = 'FourKites',
  P44 = 'P44',
  Loop = 'Loop'
}

export enum QuickPayEnum {
  Yes = 'Yes',
  No = 'No'
}

export enum CarrierLoadboardRequirementTab {
  Requirements = 0,
  BOL = 1,
  ReferenceNumbers = 2,
  Stakeholders = 3
}

export enum CarrierLoadboardTruckMatchTab {
  TruckMatch = 0,
  LanePricing = 1,
  Offers = 2
}

export enum CarrierLoadboardCarrierTab {
  CarrierDetails = 0,
  RateConfirmation = 1
}

export enum BillStatus {
  Active = 'Active',
  Fail = 'Fail',
  Void = 'Void'
}

export enum ActivityType {
  General = 'General'
}

export enum AuditLogFieldName {
  ActivityType = 'activityType',
  Activity = 'activity'
}

export enum AuditLogEventType {
  View = 'view'
}

export enum FacilityStatus {
  All = '',
  Pending = 'Pending',
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum FacilityOperationType {
  Shipping = 'Shipping',
  Receiving = 'Receiving',
  TrailerYard = 'Trailer-Yard'
}

export enum FacilityOperationWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export enum FacilityTabs {
  Customer = 'Customer',
  Contacts = 'Contacts',
  Notes = 'Notes',
  AuditLog = 'Audit Log'
}

export enum FacilityTabIndex {
  Customer = 0,
  Contacts = 1,
  Notes = 2,
  AuditLog = 3,
}

export enum ListActionType {
  Add = 'Add',
  Delete = 'Delete',
  Edit = 'Edit',
  Update = 'Update'
}

export enum PreferredLaneCheckboxFilter {
  Blank = '',
  Yes = 'Yes'
}

export enum PreferredLaneStatusFilter {
  Active = 'Active',
  InActive = 'Inactive',
  Expired = 'Expired'
}

export enum PreferredLaneStopType {
  Origin = 'Origin',
  Destination = 'Destination'
}

export enum AwardStatusList {
  All = 'All',
  Bids = 'Bids',
  Pending = 'Pending',
  Validated = 'Validated',
  Active = 'Active',
  Expired = 'Expired',
  Lost = 'Lost',
  Inactive = 'Inactive',
  Rejected = 'Rejected'
}

export enum CityStateZipSearchKey {
  City = 'city',
  State = 'statecode',
  Zip = 'zip',
}

export enum FacilitySearchKey {
  Name = 'name',
  Code = 'code',
}

export enum AwardLaneType {
  FiveZip = '5Zip',
  ThreeZip = '3Zip',
  City = 'City',
  Region = 'Region',
  State = 'State',
  Reg = 'Reg.',
}

export enum AwardStatus {
  Pending = 'Pending',
  Validated = 'Validated',
  Active = 'Active',
  InActive = 'Inactive',
  Rejected = 'Rejected',
  Expired = 'Expired',
  Lost = 'Lost',
  Bids = 'Bids'
}

export enum IncidentStatusValue {
  Open = 1,
  InProgress = 2
}

export enum CapacityStatus {
  All = '',
  Active = 'Active',
  Awarded = 'Awarded',
  Inactive = 'InActive'
}

export enum AwardTabs {
  Waterfall = 'Waterfall',
  LaneHistory = 'Lane History',
  MarketHistory = 'Market History',
  PotentialCapacity = 'Potential Capacity',
  TruckMatchT1 = 'Truck Match(T1)',
  Stakeholders = 'Stakeholders',
  AuditLog = 'Audit Log'
}

export enum AwardTabIndex {
  Waterfall = 0,
  LaneHistory = 1,
  MarketHistory = 2,
  PotentialCapacity = 3,
  TruckMatchT1 = 4,
  Stakeholders = 5,
  AuditLog = 6
}

export enum AwardStatusAction {
  Approve = 'Approve',
  Reject = 'Reject',
  Resubmit = 'Resubmit',
  Activate = 'Activate',
  Inactivate = 'Inactivate',
  Pending = 'Pending',
  Lost = 'Lost'
}

export enum FacilityAction {
  Activate = 'Activate',
  Inactivate = 'Inactivate',
}

export enum FacilityCustomerStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum FacilityDepartment {
  Primary = 'Primary'
}

export enum AwardTruckMatchStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum FacilityProfileTabName {
  Customer = 'Customer',
  Contacts = 'Contacts',
  Notes = 'Notes',
  AuditLog = 'Audit Log'
}

export enum FacilitySubModule {
  AuditLog = 'AuditLog',
  Customer = 'Customer',
  Contacts = 'Contacts',
  Notes = 'Notes'
}

export enum FacilitySectionName {
  ViewAuditLog = 'ViewAuditLog',
  ViewCustomer = 'ViewCustomer',
  ViewContacts = 'ViewContacts',
  ViewNotes = 'ViewNotes'
}
export enum CapacityTabs {
  SubmittedCapacity = 'Submitted Capacity',
  LaneHistory = 'Lane History',
  MarketHistory = 'Market History',
  PotentialAwards = 'Potential Awards',
  AuditLog = 'Audit Log'
}

export enum CapacityTabIndex {
  SubmittedCapacity = 0,
  LaneHistory = 1,
  MarketHistory = 2,
  PotentialAwards = 3,
  AuditLog = 4
}

export enum SubmittedCapacityStatus {
  Pending = 'Pending',
  Awarded = 'Awarded',
  Rejected = 'Rejected',
}

export enum TrueFalse {
  True = 'true',
  False = 'false',
}

export enum CustomerMappedAccessorialStatus {
  InActive = 'Inactive',
  Active = 'Active'
}

export enum AwardSubmittedCapacityStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum CapacityAction {
  Submit = 'Submit',
  Remove = 'Remove'
}

export enum CapacityStatusAction {
  Approve = 'Approve',
  Reject = 'Reject',
  Resubmit = 'Resubmit',
}

export enum PotentialCapacityAction {
  NotifyRep = 'Notify Rep',
}

export enum CapacityLaneHistoryStatus {
  All = '',
  Covered = 'Covered',
  Dispatched = 'Dispatched',
  AtPickup = 'At Pickup',
  Loaded = 'Loaded',
  InTransit = 'In-Transit',
  AtDestination = 'At Destination',
  Review = 'Review',
  Delivered = 'Delivered',
  Billing = 'Billing',
  Closed = 'Closed',
  Cancelled = 'Cancelled'
}

export enum GlobalSearchSuggestionTitle {
  Carrier = 'Carrier',
  Customer = 'Customer',
  Load = 'Load',
}

export enum LoadAwardStatus {
  Draft = 'Draft',
  Open = 'Open',
  Waterfall = 'Waterfall',
  Assigned = 'Assigned',
  Covered = 'Covered',
  Dispatched = 'Dispatched',
  AtPickup = 'At Pickup',
  Loaded = 'Loaded',
  InTransit = 'In-Transit',
  AtDestination = 'At Destination',
  Delivered = 'Delivered',
  Billing = 'Billing',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
  Review = 'Review',
}

export enum CapacityListStatusAction {
  Activate = 'Activate',
  Inactivate = 'Inactivate'
}

export enum AwardSubModule {
  AuditLog = 'AuditLog',
  Waterfall = 'Waterfall',
  LaneHistory = 'LaneHistory',
  MarketHistory = 'MarketHistory',
  PotentialCapacity = 'PotentialCapacity',
  Stakeholders = 'Stakeholders',
  TruckMatchT1 = 'TruckMatchT1'
}

export enum AwardSectionName {
  ViewWaterfall = 'ViewWaterfall',
  ViewAuditLog = 'ViewAuditLog',
  ViewAwardLaneHistory = 'ViewAwardLaneHistory',
  ViewAwardMarketHistory = 'ViewAwardMarketHistory',
  ViewPotentialCapacity = 'ViewPotentialCapacity',
  ViewAwardStakeholders = 'ViewAwardStakeholders',
  ViewTruckMatchT1 = 'ViewTruckMatchT1'
}

export enum CapacitySubModule {
  AuditLog = 'AuditLog',
  SubmittedCapacity = 'SubmittedCapacity',
  LaneHistory = 'LaneHistory',
  MarketHistory = 'MarketHistory',
  PotentialAwards = 'PotentialAwards',
}

export enum CapacitySectionName {
  ViewAuditLog = 'ViewAuditLog',
  ViewSubmittedCapacity = 'ViewSubmittedCapacity',
  ViewLaneHistory = 'ViewLaneHistory',
  ViewMarketHistory = 'ViewMarketHistory',
  ViewPotentialAwards = 'ViewPotentialAwards',
}

export enum ExportCSVEntity {
  LoadBoard = 'LoadBoard',
  PreAP = 'PreAP',
  PreAR = 'PreAR',
  APAging = 'APAging',
  ARAging = 'ARAging',
  APClosed = 'APClosed',
  ARClosed = 'ARClosed',
  Carrier = 'Carrier',
  Customer = 'Customer'
}

export enum LoadQuickViewTab {
  TruckMatch = 'Truck Match',
  LaneHistory = 'Lane History',
  Incidents = 'Incidents',
  RateConfirmation = 'Rate Confirmation',
  Offers = 'Offers',
  CheckCall = 'Check call',
  Documents = 'Documents',
  Notes = 'Notes',
  None = 'None',
}

export enum PiecesTypeName {
  Pieces = 'Pieces',
  Pallets = 'Pallets'
}

export enum ReferenceNumberType {
  ContainerNo = "Container #"
}

export enum AccountingType {
  AP = 'AP',
  AR = 'AR'
}

export enum AccountingScreenTabs {
  PreAp = 'PRE-AP',
  ApAging = 'AP-AGING',
  ApClosed = 'AP-CLOSED',
  PreAr = 'PRE-AR',
  ArAging = 'AR-AGING',
  ArClosed = 'AR-CLOSED',
  Review = 'REVIEW'
}

export enum LoadQueueUserStatus {
  Pending = 'Pending',
  Active = 'Active',
  Completed = 'Completed',
  Left = 'Left',
  Bursted = 'Bursted',
  Cancelled = 'Cancelled',
  Locked = 'Locked'
}

export enum CheckCallEvent {
  EventUpdate = 'EventUpdate',
  LocationUpdate = 'LocationUpdate'
}

export enum LoadEquipment {
  Reefer = 'Reefer',
  Flatbed = 'Flatbed'
}

export enum HighwayEnabledTabs {
  Identity = 0,
  Insurance = 1,
  Certificate = 2,
  Assesment = 3
}

export enum CallerEvent {
  GlobalSave = 'GlobalSave',
  OnCarrierSelect = 'OnCarrierSelect',
  RecoverLoad = 'RecoverLoad'
}

export enum WaterfallLoadStatus {
  Accept = 'Accept',
  Reject = 'Reject'
}

export enum LoadAwardCapacityQueueStatus {
  Pending = 'Pending',
  Active = 'Active',
  Completed = 'Completed',
  Skipped = 'Skipped'
}

export enum LoadAwardCapacityStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  AutoRejected = 'AutoRejected',
  Skipped = 'Skipped'
}

export enum CarrierOwnershipStatus {
  Owned = 'Owned',
  Open = 'Open',
  Lead = 'Lead',
  Opportunity = 'Opportunity'
}

export enum FleetType {
  Owned = 'Owned',
  Retention = 'Retention'
}

// Enum used for loop Exception Popup
export enum LoopExceptionTabs {
  All = 'All',
  Load = 'Load',
  Bill = 'Bill'
}

export enum ExceptionType {
  SHIPMENT = 'SHIPMENT',
  PAYABLE_INVOICE = 'PAYABLE_INVOICE'
}

export enum LoopExceptionEventType {
  Created = 'Created',
  Resolved = 'Resolved',
  Reopened = 'Reopened',
  Deleted = 'Deleted'
}