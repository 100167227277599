import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  show(): void {
    const pageLoader: HTMLHtmlElement = this.document.getElementsByClassName('page-loader')[0] as HTMLHtmlElement;
    pageLoader?.classList.remove('d-none');
  }

  hide(): void {
    const pageLoader: HTMLHtmlElement = this.document.getElementsByClassName('page-loader')[0] as HTMLHtmlElement;
    pageLoader?.classList.add('d-none');
  }
}
