<h4 class="border-bottom p-3 mb-0 d-flex align-items-center justify-content-between">
    <div>
        Exception ({{totalRecords}})
    </div>
    <div class="d-flex align-items-center">
        <button class="btn btn-outline-primary mr-2 px-1 py-0 small" (click)="setFilterStatus(FilterStatusEnum.All)"
            [ngClass]="{'active': filterStatus === FilterStatusEnum.All}">
            {{FilterStatusEnum.All}}
        </button>
        <button [disabled]="loadExceptionList.length === 0" class="btn btn-outline-primary mr-2 px-1 py-0 small" (click)="setFilterStatus(FilterStatusEnum.Load)"
            [ngClass]="{'active': filterStatus === FilterStatusEnum.Load}">
            {{FilterStatusEnum.Load}}
        </button>
        <button [disabled]="billExceptionList.length === 0" class="btn btn-outline-primary px-1 py-0 small" (click)="setFilterStatus(FilterStatusEnum.Bill)"
            [ngClass]="{'active': filterStatus === FilterStatusEnum.Bill}">
            {{FilterStatusEnum.Bill}}
        </button>
    </div>
</h4>

<div class="wrapper-ex" *ngIf="!(filterStatus === FilterStatusEnum.Bill) && loadExceptionList.length > 0">
    <label for="">Load</label>
    <div class="card-ex" *ngFor="let loopException of loadExceptionList">
        <span class="material-symbols-outlined">
            emergency_home
        </span>
        <div class="ex-ex">
            <h4>{{loopException.exceptionName}}</h4>
        </div>
    </div> 
</div>

<div class="wrapper-ex" *ngIf="!(filterStatus === FilterStatusEnum.Load) && billExceptionList.length > 0">
    <label for="">Bill</label>
    <div class="card-ex" *ngFor="let loopException of billExceptionList">
        <span class="material-symbols-outlined">
            emergency_home
        </span>
        <div class="ex-ex">
            <h4>{{loopException.exceptionName}}</h4>
            <div class="carrier-ex">
                <span class="material-symbols-outlined">fire_truck</span>
                <span>{{loopException.carrierName}}</span>
            </div>
        </div>
    </div>
</div>