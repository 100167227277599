import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padNumberWithZero'
})
export class PadNumberWithZeroPipe implements PipeTransform {
  transform(number: number): string {
    if (number > 0 && number < 10) {
      return '0' + number.toString();
    } else {
      return number?.toString();
    }
  }
}
