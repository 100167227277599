import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Endpoint } from '@app/core/constants/enums';
import { AccountPayable, AccountPayableDetailModel, AccountPayableListModel, AccountPayableSummary, LazyListAccountPayable } from './account-payable-model';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { AccountPayableReviewModel } from '../account-receivable/account-receivable-model';
import { String } from "typescript-string-operations";
import { BillDataModel, CarrierBillModel, LoadCarrierRatesModel } from '../shared/carrier-bills/carrier-bills-model';
import { Constants } from '@app/core/constants/constants';

@Injectable()
export class AccountPayableService {

  accountPayableSummaryDataCheck = new BehaviorSubject<boolean>(true);
  accountPayableSummaryDataCheck$ = this.accountPayableSummaryDataCheck.asObservable();
  billDataGet = new BehaviorSubject<BillDataModel>({ billCount: 0, carrierInvoiceId: "", billNumber: "" });
  billDataGet$ = this.billDataGet.asObservable();
  advanceBill = new BehaviorSubject<number>(0);
  advanceBill$ = this.advanceBill.asObservable();

  loadEndpoint!: string;
  accountingEndpoint!: string;
  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.accountingEndpoint = this.appConfigService.getEndpoint(Endpoint.Accounting);
    this.loadEndpoint = this.appConfigService.getEndpoint(Endpoint.Load);
  }

  getAccountPayableList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyListAccountPayable<AccountPayableListModel[]>>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.getAccountPayableList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyListAccountPayable<AccountPayableListModel[]>>>(url, payload);
  }

  getAccountPayableAgingList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyListAccountPayable<AccountPayableListModel[]>>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.getAccountPayableAgingList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyListAccountPayable<AccountPayableListModel[]>>>(url, payload);
  }

  getAccountPayableClosingList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyListAccountPayable<AccountPayableListModel[]>>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.getAccountPayableClosingList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyListAccountPayable<AccountPayableListModel[]>>>(url, payload);
  }

  getAccountPayablePreAPSummary(): Observable<ResponseModel<AccountPayableSummary[]>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.getAccountPayablePreAPSummary}`;
    return this.apiClient.get<ResponseModel<AccountPayableSummary[]>>(url);
  }

  getAccountPayableAPAgingSummary(): Observable<ResponseModel<AccountPayableSummary[]>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.getAccountPayableAPAgingSummary}`;
    return this.apiClient.get<ResponseModel<AccountPayableSummary[]>>(url);
  }

  createAccountPayable(payload: AccountPayable[]): Observable<ResponseModel<AccountPayable>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.createAccountPayable}`;
    return this.apiClient.post<AccountPayable[], ResponseModel<AccountPayable>>(url, payload);
  }

  revertAccountPayableRecord(payload: AccountPayableReviewModel): Observable<ResponseModel<AccountPayableReviewModel>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.revertRecord}`;
    return this.apiClient.put<AccountPayableReviewModel, ResponseModel<AccountPayableReviewModel>>(url, payload);
  }

  updateAccountPayableDetails(payload: AccountPayableDetailModel): Observable<ResponseModel<AccountPayableDetailModel>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.updateAccountPayableDetails}`;
    return this.apiClient.put<AccountPayableDetailModel, ResponseModel<AccountPayableDetailModel>>(url, payload);
  }

  getAccountPayableBillDetails(accountPayableId: number, isAPClosedScreen: boolean, loadDetailId: number, isAuditLog: boolean): Observable<ResponseModel<CarrierBillModel[]>> {
    let url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.getAccountPayableBillDetails}`;
    url = String.format(url, accountPayableId, isAPClosedScreen, loadDetailId, isAuditLog);
    return this.apiClient.get<ResponseModel<CarrierBillModel[]>>(url);
  }

  createAdditionalBill(payload: CarrierBillModel): Observable<ResponseModel<CarrierBillModel>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.addAdditionalBill}`;
    return this.apiClient.post<CarrierBillModel, ResponseModel<CarrierBillModel>>(url, payload);
  }

  updateLoadCarrierRates(payload: LoadCarrierRatesModel): Observable<ResponseModel<LoadCarrierRatesModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadAccounting.updateLoadCarrierRates}`;
    return this.apiClient.post<LoadCarrierRatesModel, ResponseModel<LoadCarrierRatesModel>>(url, payload);
  }

  voidAccountPayableBill(payload: any): Observable<ResponseModel<any>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.voidAccountPayableBill}`;
    return this.apiClient.put<any, ResponseModel<any>>(url, payload);
  }

  voidLoadCarrierRates(payload: LoadCarrierRatesModel): Observable<ResponseModel<LoadCarrierRatesModel>> {
    const url = `${this.loadEndpoint}${API_ROUTES.load.loadAccounting.voidLoadCarrierRates}`;
    return this.apiClient.post<LoadCarrierRatesModel, ResponseModel<LoadCarrierRatesModel>>(url, payload);
  }

  resendFailBill(payload: any): Observable<ResponseModel<any>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.resendFailBill}`;
    return this.apiClient.post<any, ResponseModel<any>>(url, payload);
  }

  getLoadDetailsForBilling(loadDetailId: number, carrierId: number): Observable<ResponseModel<any[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadDetailsForBilling}`;
    url = String.format(url, loadDetailId, carrierId);
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  accountPayableAllBillPaymentSync(payload: any): Observable<ResponseModel<any>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.accountPayableAllBillPaymentSync}`;
    return this.apiClient.post<any, ResponseModel<any>>(url, payload);
  }

  accountPayableManualBillPaymentSync(payload: any): Observable<ResponseModel<any>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.accountPayableManualBillPaymentSync}`;
    return this.apiClient.post<any, ResponseModel<any>>(url, payload);
  }

  addViewActivityLog(sectionName: string): Observable<ResponseModel<any>> {
    let url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.addViewActivityLog}`;
    url = String.format(url, sectionName);
    return this.apiClient.post<any, any>(url);
  }

  exportToCSV(payload: GridFilterRequestModel): Observable<ResponseModel<any>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.accountPayable.exportToCSV}`;
    payload = JSON.parse(JSON.stringify(payload));
    payload.pageSize = Constants.maxPageSizeForExportListing;
    payload.pageNumber = 1;
    payload.sortOrders = [
      {
        sortOrderField: 'loadStatusId',
        sortOrderType: 'asc'
      }];

    return this.apiClient.post<GridFilterRequestModel, ResponseModel<any>>(url, payload);
  }

  /**
   * Get Loop Exception List forom DB.
   * @param payload filterModel for loopException
   * @returns api client Post request for loopException.
   */
  getLoopExceptionList(payload: GridFilterRequestModel): Observable<ResponseModel<any>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.accounting.loopException.list}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<any>>(url, payload);
  }
}