<form class="form-horizontal" focusInvalidInput novalidate autocomplete="off" [formGroup]="viewForm"
    (ngSubmit)="save()">
    <div class="mb-3">
        <app-input-control [formName]="viewForm" elementId="viewName" [control]="form['viewName']"
            controlName="viewName" label=" View Name" placeholder="View Name" [isAutoFocus]="true">
        </app-input-control>
    </div>
    <div class="row">
        <div class="col-12 text-right pb-0">
            <button class="btn btn-primary mr-2">{{getButtonTitle}}</button>
            <button type="button" (click)="reset()" class="btn btn-outline-primary">
                Cancel
            </button>
        </div>
    </div>
</form>