<form class="form-horizontal" focusInvalidInput novalidate autocomplete="off" (ngSubmit)="submit()"
    [formGroup]="capacityForm">
    <h3>General</h3>
    <hr>
    <div class="row mb-3">
        <div *ngIf="isExistingAwardCapacity()" class="col-md-2">
            <app-autocomplete-control [suggestions]="capacityDocuments" [formGroup]="capacityForm"
                [control]="getControl('capacityUniqueIdObj')" controlName="capacityUniqueIdObj"
                elementId="capacityUniqueIdObj" [optionFields]="['capacityUniqueId','carrierName']"
                valueField="capacityUniqueId" label="Capacity ID" (search)="searchByCapacityUniqueId($event)"
                (selected)="onCapacityUniqueIdSelect($event)" placeholder="Capacity ID" [multiple]="false">
            </app-autocomplete-control>
        </div>
        <div class="{{(isEditMode || (isAwardCapacity && isExistingAwardCapacity())) ? 'col-md-2':'col-md-3'}}">
            <app-input-control *ngIf="isExistingAwardCapacity()" [formName]="capacityForm" elementId="mcdotNumber"
                styleClass="w-100" [control]="getControl('mcdotNumber')" controlName="mcdotNumber" label="MC/DOT Number"
                placeholder="MC/DOT Number">
            </app-input-control>
            <app-autocomplete-control *ngIf="!isExistingAwardCapacity()" [suggestions]="mcNumberDocument"
                [formGroup]="capacityForm" [control]="getControl('mcdotNumberObj')" controlName="mcdotNumberObj"
                elementId="mcdotNumberObj" optionField="name" valueField="motorCarrierNumber" label="MC/DOT Number"
                (search)="searchByCarrierNumber($event)" (selected)="onCarrierSelect($event)"
                placeholder="MC/DOT Number" [multiple]="false">
            </app-autocomplete-control>
        </div>
        <div class="{{(isEditMode || isAwardCapacity && isExistingAwardCapacity()) ? 'col-md-2':'col-md-3'}}">
            <app-autocomplete-control [suggestions]="mcNumberDocument" [formGroup]="capacityForm"
                [control]="getControl('carrierNameObj')" controlName="carrierNameObj" elementId="carrierNameObj"
                optionField="name" valueField="name" label="Carrier" (search)="searchByCarrierName($event)"
                (selected)="onCarrierSelect($event)" placeholder="Carrier" [multiple]="false">
            </app-autocomplete-control>
        </div>
        <div class="{{(isEditMode || isAwardCapacity && isExistingAwardCapacity()) ? 'col-md-2':'col-md-2'}}">
            <app-input-control [formName]="capacityForm" elementId="carrierRepresentativeName" styleClass="w-100"
                [control]="getControl('carrierRepresentativeName')" controlName="carrierRepresentativeName"
                label="Carrier Rep" placeholder="Carrier Rep">
            </app-input-control>
        </div>
        <div class="col-md-2" *ngIf="isEditMode">
            <div class="d-flex">
                <app-input-control [formName]="capacityForm" elementId="capacityUniqueId" class="flex-grow-1"
                    styleClass="w-100" [control]="getControl('capacityUniqueId')" controlName="capacityUniqueId"
                    label="Capacity Id" placeholder="Capacity Id">
                </app-input-control>
                <app-input-control [formName]="capacityForm" elementId="capacityUniqueIdSuffix" style="width: 50px;"
                    [control]="getControl('capacityUniqueIdSuffix')" controlName="capacityUniqueIdSuffix" label="&nbsp;"
                    placeholder="Capacity Id">
                </app-input-control>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row w-100">
                <div class="col-md-8">
                    <app-input-number-control [formName]="capacityForm" [control]="getControl('rates')"
                        inputType="number" controlName="rates" label="Rates" placeholder="Rates" [isCurrencyMode]="true"
                        [minFractionDigits]="2" mode="currency" currency="USD">
                    </app-input-number-control>
                </div>
                <div class="col-md-4 d-flex align-items-center">
                    <app-checkbox-control [formName]="capacityForm" [control]="getControl('includeFSC')"
                        controlName="includeFSC" elementId="includeFSC"
                        label="{{isExistingAwardCapacity()?'FSC Included':'Includes FSC'}}"></app-checkbox-control>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div *ngIf="isExistingAwardCapacity()" class="col-md-3">
            <app-input-control [formName]="capacityForm" elementId="dispatcherName" styleClass="w-100"
                [control]="getControl('dispatcherName')" controlName="dispatcherName" label="Dispatcher"
                placeholder="Dispatcher">
            </app-input-control>
        </div>
        <div *ngIf="!isExistingAwardCapacity()" class="col-md-3">
            <app-select-control
                *ngIf="carrierDispatchers && carrierDispatchers.length > 0 || getControl('carrierId').getRawValue() === null"
                [formName]="capacityForm" elementId="dispatcherId"
                [options]="carrierDispatchers.length > 0 ? carrierDispatchers : [defaultDispatcher]"
                [multipleSelection]="false" [filter]="false" [showClear]="false" [isPrimeNgDropdown]="true"
                [isServerSideFilter]="false" styleClass="w-100" [control]="getControl('dispatcherId')"
                controlName="dispatcherId" [isShowLabel]="true" label="Dispatcher" optionLabel="dispatcherName"
                optionValue="dispatcherId" (selectChangeEvent)="onDispatcherSelect($event)">
            </app-select-control>
        </div>
        <div class="col-md-3">
            <app-input-control [formName]="capacityForm" elementId="email" styleClass="w-100"
                [control]="getControl('email')" controlName="email" label="Email" placeholder="Email">
            </app-input-control>
        </div>
        <div class="col-md-3">
            <app-input-mask-control [formName]="capacityForm" elementId="phone" [control]="getControl('phone')"
                controlName="phone" label="Phone" placeholder="Phone" mask="(999) 999 9999"></app-input-mask-control>
        </div>
        <div class="col-md-3">
            <div class="row">
                <div *ngIf="isExistingAwardCapacity()" class="col-md-8">
                    <app-input-control [formName]="capacityForm" elementId="equipmentName" styleClass="w-100"
                        [control]="getControl('equipmentName')" controlName="equipmentName" label="Equipment"
                        placeholder="Equipment">
                    </app-input-control>
                </div>
                <div *ngIf="!isExistingAwardCapacity()" class="col-md-8">
                    <app-select-control styleClass="w-100" [formName]="capacityForm" elementId="equipmentId"
                        [options]="equipmentSelectList" [multipleSelection]="false" [filter]="false" [showClear]="false"
                        [isPrimeNgDropdown]="true" [isServerSideFilter]="false" optionLabel="name"
                        optionValue="equipmentId" [control]="getControl('equipmentId')" controlName="equipmentId"
                        [isShowLabel]="true" label="Equipment" (selectChangeEvent)="onEquipmentSelect($event)">
                    </app-select-control>
                </div>
                <div class="col-md-4 d-flex align-items-center gap-3">
                    <app-checkbox-control [formName]="capacityForm" [control]="getControl('dropTrailer')"
                        controlName="dropTrailer" elementId="dropTrailer" label="DT"></app-checkbox-control>
                    <app-checkbox-control [formName]="capacityForm" [control]="getControl('termService')"
                        controlName="termService" elementId="termService" label="Team"></app-checkbox-control>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-6">
            <div class="row">
                <div *ngIf="isExistingAwardCapacity()" class="col-md-4">
                    <app-input-control [formName]="capacityForm" elementId="originCity" styleClass="w-100"
                        [control]="getControl('originCity')" controlName="originCity" label="Origin City"
                        placeholder="Origin City">
                    </app-input-control>
                </div>
                <div *ngIf="!isExistingAwardCapacity()" class="col-md-4">
                    <label for="originCityObj">
                        Origin
                        <em class="asterisk" *ngIf="formHelper.isRequired(getControl('originCityObj'))"></em>
                    </label>
                    <p-autoComplete inputId="originCityObj" formControlName="originCityObj"
                        [suggestions]="cityDocuments" [group]="true"
                        (completeMethod)="searchByCityStateZip($event,searchType.City)"
                        (onSelect)="onOriginCitySelect($event)" field="city" [minLength]="3" [delay]="0"
                        placeholder="Origin City" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
                        (onBlur)="formHelper.PatchAutoCompleteTextBox(capacityForm,'originCityObj')">
                        <ng-template let-group pTemplate="group">
                            <div class="flex align-items-center">
                                <span>{{ group.label }}</span>
                            </div>
                        </ng-template>
                        <ng-template let-cityDocument pTemplate="item">
                            <div>{{ cityDocument.city }}, {{ cityDocument.stateCode }} {{ cityDocument.zip }}
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <app-error-message [control]="getControl('originCityObj')" label="City" elementId="originCityObj">
                    </app-error-message>
                </div>
                <div *ngIf="isExistingAwardCapacity()" class="col-md-4">
                    <app-input-control [formName]="capacityForm" elementId="originState" styleClass="w-100"
                        [control]="getControl('originState')" controlName="originState" label="Origin State"
                        placeholder="Origin State">
                    </app-input-control>
                </div>
                <div *ngIf="!isExistingAwardCapacity()" class="col-md-4">
                    <label for="originStateObj">
                        &nbsp;
                    </label>
                    <p-autoComplete inputId="originStateObj" formControlName="originStateObj"
                        [suggestions]="stateDocuments" [group]="true"
                        (completeMethod)="searchByCityStateZip($event,searchType.State,'originCityObj')"
                        (onSelect)="onOriginStateSelect($event)" field="stateCode" [minLength]="2" [delay]="0"
                        placeholder="Origin State" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
                        (onBlur)="formHelper.PatchAutoCompleteTextBox(capacityForm,'originStateObj')">
                        <ng-template let-group pTemplate="group">
                            <div class="flex align-items-center">
                                <span>{{ group.label }}</span>
                            </div>
                        </ng-template>
                        <ng-template let-stateDocument pTemplate="item">
                            <div>{{ stateDocument.stateCode }}</div>
                        </ng-template>
                    </p-autoComplete>
                    <app-error-message [control]="getControl('originStateObj')" label="State"
                        elementId="originStateObj"></app-error-message>
                </div>
                <div *ngIf="isExistingAwardCapacity()" class="col-md-4">
                    <app-input-control [formName]="capacityForm" elementId="originZip" styleClass="w-100"
                        [control]="getControl('originZip')" controlName="originZip" label="Origin Zip"
                        placeholder="Origin Zip">
                    </app-input-control>
                </div>
                <div *ngIf="!isExistingAwardCapacity()" class="col-md-4">
                    <label for="originZipObj">
                        &nbsp;
                    </label>
                    <p-autoComplete inputId="originZipObj" formControlName="originZipObj"
                        [suggestions]="zipCodeDocuments" [group]="true"
                        (completeMethod)="searchByCityStateZip($event,searchType.Zip)"
                        (onSelect)="onOriginZipCodeSelect($event)" field="zip" [minLength]="3" [delay]="0"
                        placeholder="Origin Zip" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
                        (onBlur)="formHelper.PatchAutoCompleteTextBox(capacityForm,'originZipObj')">
                        <ng-template let-group pTemplate="group">
                            <div class="flex align-items-center">
                                <span>{{ group.label }}</span>
                            </div>
                        </ng-template>
                        <ng-template let-zipCodeDocument pTemplate="item">
                            <div>{{ zipCodeDocument.city }}, {{ zipCodeDocument.stateCode }} {{
                                zipCodeDocument.zip }}
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <app-error-message [control]="getControl('originZipObj')" label="Zip"
                        elementId="originZipObj"></app-error-message>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div *ngIf="isExistingAwardCapacity()" class="col-md-4">
                    <app-input-control [formName]="capacityForm" elementId="destinationCity" styleClass="w-100"
                        [control]="getControl('destinationCity')" controlName="destinationCity" label="Destination City"
                        placeholder="Destination City">
                    </app-input-control>
                </div>
                <div *ngIf="!isExistingAwardCapacity()" class="col-md-4">
                    <label for="destinationCityObj">
                        Destination
                        <em class="asterisk" *ngIf="formHelper.isRequired(getControl('destinationCityObj'))"></em>
                    </label>
                    <p-autoComplete inputId="destinationCityObj" formControlName="destinationCityObj"
                        [suggestions]="cityDocuments" [group]="true"
                        (completeMethod)="searchByCityStateZip($event,searchType.City)"
                        (onSelect)="onDestinationCitySelect($event)" field="city" [minLength]="3" [delay]="0"
                        placeholder="Destination City" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
                        (onBlur)="formHelper.PatchAutoCompleteTextBox(capacityForm,'destinationCityObj')">
                        <ng-template let-group pTemplate="group">
                            <div class="flex align-items-center">
                                <span>{{ group.label }}</span>
                            </div>
                        </ng-template>
                        <ng-template let-cityDocument pTemplate="item">
                            <div>{{ cityDocument.city }}, {{ cityDocument.stateCode }} {{ cityDocument.zip }}
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <app-error-message [control]="getControl('destinationCityObj')" label="City"
                        elementId="destinationCityObj">
                    </app-error-message>
                </div>
                <div *ngIf="isExistingAwardCapacity()" class="col-md-4">
                    <app-input-control [formName]="capacityForm" elementId="destinationState" styleClass="w-100"
                        [control]="getControl('destinationState')" controlName="destinationState"
                        label="Destination State" placeholder="Destination State">
                    </app-input-control>
                </div>
                <div *ngIf="!isExistingAwardCapacity()" class="col-md-4">
                    <label for="destinationStateObj">
                        &nbsp;
                    </label>
                    <p-autoComplete inputId="destinationStateObj" formControlName="destinationStateObj"
                        [suggestions]="stateDocuments" [group]="true"
                        (completeMethod)="searchByCityStateZip($event,searchType.State,'destinationCityObj')"
                        (onSelect)="onDestinationStateSelect($event)" field="stateCode" [minLength]="2" [delay]="0"
                        placeholder="Destination State" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
                        (onBlur)="formHelper.PatchAutoCompleteTextBox(capacityForm,'destinationStateObj')">
                        <ng-template let-group pTemplate="group">
                            <div class="flex align-items-center">
                                <span>{{ group.label }}</span>
                            </div>
                        </ng-template>
                        <ng-template let-stateDocument pTemplate="item">
                            <div>{{ stateDocument.stateCode }}</div>
                        </ng-template>
                    </p-autoComplete>
                    <app-error-message [control]="getControl('destinationStateObj')" label="State"
                        elementId="destinationStateObj"></app-error-message>
                </div>
                <div *ngIf="isExistingAwardCapacity()" class="col-md-4">
                    <app-input-control [formName]="capacityForm" elementId="destinationZip" styleClass="w-100"
                        [control]="getControl('destinationZip')" controlName="destinationZip" label="Destination Zip"
                        placeholder="Destination Zip">
                    </app-input-control>
                </div>
                <div *ngIf="!isExistingAwardCapacity()" class="col-md-4">
                    <label for="destinationZipObj">
                        &nbsp;
                    </label>
                    <p-autoComplete inputId="destinationZipObj" formControlName="destinationZipObj"
                        [suggestions]="zipCodeDocuments" [group]="true"
                        (completeMethod)="searchByCityStateZip($event,searchType.Zip)"
                        (onSelect)="onDestinationZipCodeSelect($event)" field="zip" [minLength]="3" [delay]="0"
                        placeholder="Destination Zip" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
                        (onBlur)="formHelper.PatchAutoCompleteTextBox(capacityForm,'destinationZipObj')">
                        <ng-template let-group pTemplate="group">
                            <div class="flex align-items-center">
                                <span>{{ group.label }}</span>
                            </div>
                        </ng-template>
                        <ng-template let-zipCodeDocument pTemplate="item">
                            <div>{{ zipCodeDocument.city }}, {{ zipCodeDocument.stateCode }} {{
                                zipCodeDocument.zip }}
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <app-error-message [control]="getControl('destinationZipObj')" label="Zip"
                        elementId="destinationZipObj"></app-error-message>
                </div>
            </div>
        </div>
    </div>
    <h3>Capacity</h3>
    <hr>
    <div class="row mb-4">
        <div class="col-md-3">
            <app-input-number-control [formName]="capacityForm" [control]="getControl('totalWeeklyCapacity')"
                inputType="number" controlName="totalWeeklyCapacity" label="Total Weekly Capacity"
                placeholder="Total Weekly Capacity" [isCurrencyMode]="false" [minFractionDigits]="0">
            </app-input-number-control>
        </div>
        <div class="col-md-3">
            <label for="capacityStartDate" [ngClass]="{'disable':getControl('capacityStartDate').disabled}">
                Start Date
            </label>
            <div>
                <p-calendar formControlName="capacityStartDate" dataType="string" dateFormat="mm-dd-yy"
                    inputId="capacityStartDate" placeholder="Start Date" name="capacityStartDate" [baseZIndex]="9999999"
                    appendTo="body">
                </p-calendar>
                <app-error-message [control]="getControl('capacityStartDate')" label="Start Date"
                    elementId="capacityStartDate">
                </app-error-message>
            </div>
        </div>
        <div class="col-md-3">
            <label for="capacityEndDate" [ngClass]="{'disable':getControl('capacityEndDate').disabled}">
                End Date
            </label>
            <div>
                <p-calendar formControlName="capacityEndDate" dataType="string" dateFormat="mm-dd-yy"
                    inputId="capacityEndDate" placeholder="End Date" name="capacityEndDate" [baseZIndex]="9999999"
                    appendTo="body">
                </p-calendar>
                <app-error-message [control]="getControl('capacityEndDate')" label="End Date"
                    elementId="capacityEndDate">
                </app-error-message>
            </div>
        </div>
        <div class="col-md-3">
            <app-input-number-control [formName]="capacityForm" [control]="getControl('overallCommitment')"
                inputType="number" controlName="overallCommitment" label="Overall Commitment%"
                placeholder="Overall Commitment%" [isCurrencyMode]="false" [minFractionDigits]="2">
            </app-input-number-control>
        </div>
    </div>
    <div class="d-flex align-items-center gap-3 mb-4">
        <h5 class="mb-0">Days with Capacity:</h5>
        <div *ngFor="let capacitySchedule of getCapacityScheduleForms().controls; let i=index">
            <app-capacity-schedule-item [capacityScheduleForm]="capacitySchedule" [displayCapacity]="false">
            </app-capacity-schedule-item>
        </div>
    </div>
    <h5 class="mb-1">Capacity Per Day:</h5>
    <div class="d-flex gap-3 mb-3">
        <div *ngFor="let capacitySchedule of getCapacityScheduleForms().controls; let i=index">
            <app-capacity-schedule-item [capacityScheduleForm]="capacitySchedule">
            </app-capacity-schedule-item>
        </div>
    </div>
    <div *ngIf="isExistingAwardCapacity()" class="row mb-3">
        <div class="col-md-12">
            <app-text-area-control [formName]="capacityForm" elementId="notes" [control]="getControl('notes')"
                controlName="notes" label="Notes" placeholder="Notes">
            </app-text-area-control>
        </div>
    </div>
    <div *ngIf="!isEditMode" class="row">
        <div class="col-12 text-right pb-0">
            <button *ngIf="isCloneCapacity" class="btn btn-primary mr-2">Clone</button>
            <button *ngIf="!isCloneCapacity" class="btn btn-primary mr-2">{{isExistingAwardCapacity() ? 'Submit' :
                'Add'}}</button>
            <button *ngIf="!isExistingAwardCapacity() && !isCloneCapacity" type="button"
                class="btn btn-outline-primary mr-2" (click)="reset()">Reset</button>
            <button type="button" class="btn btn-outline-primary mr-2" (click)="cancel()">Cancel</button>
        </div>
    </div>
</form>