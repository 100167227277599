import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FieldType } from '@app/core/constants/enums';

@Component({
  selector: 'app-input-password-control',
  templateUrl: './input-password-control.component.html',
  styles: [
  ]
})
export class InputPasswordControlComponent implements OnChanges{
  @Input() formName!: FormGroup;
  @Input() control!: AbstractControl;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() hideLabel!: boolean;
  @Input() maxLength!: number;
  @Input() minLength!: number;
  @Input() elementId!: string;
  @Input() inputType = FieldType.Password;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() className?: string = '';
  @Input() tabIndex!: number;
  @Input() isAutoFocus = false;
  @Input() iTagData = '';
  @Input() showPassword!: boolean;
  @Input() resetPasswordVisibility = false;
  @Output() resetPasswordVisibilityChange = new EventEmitter<boolean>();

  @Output() blurEvent = new EventEmitter<string>();
  @Output() inputEvent = new EventEmitter<string>();
  @Output() keyupEvent = new EventEmitter<any>();

  get isRequired(): boolean {
    if (!this.control) return false;

    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : null;
    if (validator && validator['required']) {
      return true;
    } else {
      return false;
    }
  }

  get isValid(): boolean | null {
    if (!this.control) return false;

    return this.control.status.toLowerCase() === 'invalid' ? true : null;
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['resetPasswordVisibility']?.currentValue===true){
      this.togglePasswordVisibility(true);
    }
  }

  onBlur() {
    const value = this.control.value && this.control.value.trim();
    this.control.setValue(value);
    this.blurEvent.emit(value);
  }
  onInput() {
    const value = this.control.value && this.control.value.trim();
    this.inputEvent.emit(value);
  }
  
  onKeyup(event: KeyboardEvent) {
    this.keyupEvent.emit(event);
  }

  togglePasswordVisibility(showPassword = false) {
    this.showPassword = !showPassword;
    this.inputType = this.showPassword ? FieldType.Text : FieldType.Password;
    this.resetPasswordVisibilityChange.emit(true);
  }
}
