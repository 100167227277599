import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@app/core/constants/constants';
import { AgGridHelpers } from '@app/core/helpers/ag-grid.helper';
import { ColDef, GridApi, ICellRendererParams, IGetRowsParams, IsRowMaster, RowModelType } from '@ag-grid-community/core';
import { AuditLogEventType, AuditLogFieldName, ConfigurationDetail, FieldType, FilterFieldType, FilterOperationType, Module } from '@app/core/constants/enums';
import { DatePipe } from '@angular/common';
import { MaskPhoneNumberPipe } from '@app/shared/pipes/mask-phone-number.pipe';
import { ActivatedRoute } from '@angular/router';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { ConfigurationService } from '@app/modules/administration/configuration/configuration.service';
import { ResponseModel } from '@app/core/models/api.response.model';
import { FacilityService } from '@app/modules/facility/facility.service';
import { AwardService } from '@app/modules/award/award.service';
import { CapacityService } from '@app/modules/capacity/capacity.service';
import { LoadService } from '@app/modules/load/load.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styles: [
  ]
})
export class AuditLogComponent implements OnInit {
  gridApi!: GridApi;
  pageSize = Constants.masterGridPageSize;
  columnDefs!: ColDef[];
  rowModelType: RowModelType = Constants.serverSideRowModelType;
  params!: IGetRowsParams;
  cacheBlockSize = Constants.masterGridPageSize;
  rowBuffer = 0;
  rowSelection: 'single' | 'multiple' = 'multiple';
  cacheOverflowSize = 2;
  maxConcurrentDataSourceRequests = 1;
  maxBlocksInCache = 10;
  defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  noRecordFound = false;
  gridHeight = '470px';
  noRecordFoundMsg = Constants.noRecordFound;
  gridColumnApi: any;
  detailCellRendererParams!: any;
  filterRequest!: GridFilterRequestModel;
  isRowMaster: IsRowMaster = (dataItem: any) => {
    return dataItem ? dataItem.auditEntries.length > 0 : false;
  };
  auditLogInfoMessage = Constants.auditLogInfoMessage;
  auditLogViewEventFlag !: string;
  @Input() module!: string;
  detailId!: number;

  constructor(
    private readonly loadService: LoadService,
    private readonly facilityService: FacilityService,
    private readonly capacityService: CapacityService,
    private readonly awardService: AwardService,
    public readonly datePipe: DatePipe,
    private readonly activatedRoute: ActivatedRoute,
    private readonly maskPhoneNumber: MaskPhoneNumberPipe,
    private readonly configService: ConfigurationService
  ) { }

  ngOnInit(): void {
    this.columnDefs = [
      {
        headerName: 'Activity',
        field: 'activityType',
        tooltipField: 'activityType',
        filter: 'agTextColumnFilter',
        filterParams: AgGridHelpers.getTextFilterParams,
        cellRenderer: 'agGroupCellRenderer',
        menuTabs: ['filterMenuTab']
      },
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        filter: 'agTextColumnFilter',
        filterParams: AgGridHelpers.getTextFilterParams,
        menuTabs: ['filterMenuTab']
      },
      {
        headerName: 'Date & Time',
        field: 'timeStamp',
        filter: 'agDateColumnFilter',
        filterParams: AgGridHelpers.GetDateFilterParams,
        menuTabs: ['filterMenuTab'],
        valueFormatter: this.dateCellRenderer.bind(this),
        tooltipValueGetter: this.dateCellRenderer.bind(this)
      },
      {
        headerName: 'User',
        field: 'userName',
        tooltipField: 'userName',
        filter: 'agTextColumnFilter',
        filterParams: AgGridHelpers.getTextFilterParams,
        menuTabs: ['filterMenuTab']
      }
    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        rowHeight: 30,
        headerHeight: 30,
        columnDefs: [
          {
            headerName: 'Section',
            field: 'section',
            cellRenderer: this.sectionTypeCellRenderer,
            hide: true,
            rowGroup: true,
            width: 400,
            tooltipField: 'section',
            sortable: false,
            suppressCount: true,
            menuTabs: []
          },
          {
            headerName: 'Action',
            field: 'activityType',
            aggFunc: 'first',
            cellRenderer: this.activityTypeCellRenderer,
            width: 180,
            tooltipField: 'activityType',
            sortable: false,
            menuTabs: []
          },
          {
            field: 'field',
            headerName: 'Field Name',
            tooltipField: 'field',
            filter: false,
            menuTabs: []
          },
          {
            field: 'oldValue',
            headerName: 'Previous Value',
            filter: false,
            menuTabs: [],
            width: 200,
            tooltipValueGetter: this.formatPreviousFieldValue.bind(this),
            valueFormatter: this.formatPreviousFieldValue.bind(this)
          },
          {
            field: 'newValue',
            headerName: 'New Value',
            filter: false,
            menuTabs: [],
            width: 200,
            tooltipValueGetter: this.formatNewFieldValue.bind(this),
            valueFormatter: this.formatNewFieldValue.bind(this)
          }
        ],
        defaultColDef: {
          flex: 1,
        },
        groupRowRendererParams: {
          suppressCount: true
        },
        groupDisplayType: 'multipleColumns',
        groupDefaultExpanded: 2,
        suppressCount: true,
        suppressAggFuncInHeader: true,
        suppressContextMenu: true,
        suppressMenuHide: true,
        suppressCellFocus: true
      },
      getDetailRowData: (params: { successCallback: (arg0: any) => void; data: { auditEntries: any; }; }) => {
        params.successCallback(params.data.auditEntries);
      },
    };
  }

  activityTypeCellRenderer(params: ICellRendererParams) {
    if (params.node.group === true) { return AgGridHelpers.AuditLogTypeCellRenderer(params.value); }
    else { return ''; }
  }

  sectionTypeCellRenderer(params: ICellRendererParams) {
    if (params.node.group === true) {
      const spanEl = document.createElement('span');
      spanEl.textContent = params.value;
      spanEl.title = params.value;
      return spanEl;
    }
    else { return ''; }
  }

  dateCellRenderer(params: any): string {
    return this.datePipe?.transform(params.data?.timeStamp, Constants.longDateTimeFormat) ?? '';
  }
  formatPreviousFieldValue(params: any): any {
    return this.formatFieldsValue(params, false);
  }

  formatNewFieldValue(params: any): any {
    return this.formatFieldsValue(params, true);
  }

  formatFieldsValue(params: any, isNewValue: boolean): any {
    switch (params.data?.field) {
      case 'Phone':
      case 'FaxNumber':
      case 'CellPhone':
      case 'OfficePhone':
        const fieldValue = isNewValue ? (params.data?.newValue) : (params.data?.oldValue);
        return this.maskPhoneNumber.transform(fieldValue);
      case 'RequestedCredit':
      case 'CreditLimit':
      case 'CreditUsed':
      case 'CreditRemaining':
      case 'Unbilled':
      case 'AmountDue':
      case 'Overdue':
        const currencyValue = isNewValue ? (params.data?.newValue) : (params.data?.oldValue);
        return currencyValue ? AgGridHelpers.currencyFormat(currencyValue) : '';
      default:
        const defaultFieldValue = isNewValue ? (params.data?.newValue) : (params.data?.oldValue);
        return defaultFieldValue;
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setDataSource();
  }

  setDataSource(): void {
    const dataSource = {
      getRows: (param: any) => {
        this.params = param;
        this.getAuditLogs(param);
        this.gridApi.hideOverlay();
      }
    };
    this.gridApi && this.gridApi.setServerSideDatasource(dataSource);
  }
  getAuditLogs(param: any) {
    this.filterRequest = {
      pageNumber: ((param?.request?.endRow) / this.pageSize) ? ((param?.request?.endRow) / this.pageSize) : 1,
      pageSize: this.pageSize,
      sortOrders: param?.request?.sortModel.length ? [
        {
          sortOrderField: param?.request?.sortModel[0].colId,
          sortOrderType: param?.request?.sortModel[0].sort
        }] : [],
      filters: []
    }
    this.filterRequest.sortOrders?.push({
      sortOrderField: 'timeStamp',
      sortOrderType: 'desc'
    });
    this.configService.getConfigurationCache([ConfigurationDetail.AuditLogViewEventFlag]).subscribe((response: ResponseModel<any>) => {
      if (response.data) {
        this.auditLogViewEventFlag = response.data[0]?.value;
      }

      Object.keys(param?.request?.filterModel || {}).forEach((item) => {
        if (param?.request?.filterModel[item].filterType?.toLowerCase() == FilterFieldType.Set.toLowerCase()) {
          this.filterRequest.filters?.push({
            fieldName: item,
            fieldType: '',
            operation: FilterOperationType.In,
            valueOne: null,
            valueTwo: null,
            values: param?.request?.filterModel[item].values || null
          });
        }
        else {
          this.filterRequest.filters?.push({
            fieldName: item,
            fieldType: AgGridHelpers.GetFilterFieldType(param?.request?.filterModel[item].filterType),
            operation: AgGridHelpers.GetFilterOperationType(param?.request?.filterModel[item].type),
            valueOne: param?.request?.filterModel[item].filter?.toString() ||
              param?.request?.filterModel[item].filterFrom?.toString() ||
              param?.request?.filterModel[item].dateFrom?.toString().slice(0, 10) ||
              undefined,
            valueTwo: param?.request?.filterModel[item].filterTo?.toString()
              || param?.request?.filterModel[item].dateTo?.toString().slice(0, 10)
              || undefined,
            values: null
          });
        }
      });

      if (this.auditLogViewEventFlag.toLowerCase() === 'false') {
        this.filterRequest.filters?.push({
          fieldName: AuditLogFieldName.ActivityType,
          fieldType: FieldType.Text,
          operation: FilterOperationType.NotContains,
          valueOne: AuditLogEventType.View,
          valueTwo: null,
          values: null
        });
      }

      this.detailId = parseInt(this.activatedRoute.snapshot.paramMap.get((this.module.toLowerCase() + 'DetailId')) ?? '');
      if (!isNaN(this.detailId)) {
        this.filterRequest.id = this.detailId;
      }

      switch (this.module) {
        case Module.Load.toString():
          this.loadService.getLoadAuditLogs(this.filterRequest, this.detailId).subscribe((response) => {
            this.successCallBack(response);
          });
          break;
        case Module.Facility.toString():
          this.facilityService.getFacilityAuditLogs(this.filterRequest).subscribe((response) => {
            this.successCallBack(response);
          });
          break;
        case Module.Award.toString():
          this.awardService.getAwardAuditLogs(this.filterRequest).subscribe((response) => {
            this.successCallBack(response);
          });
          break;
        case Module.Capacity.toString():
          this.capacityService.getCapacityAuditLogs(this.filterRequest).subscribe((response) => {
            this.successCallBack(response);
          });
          break;
      }
    });
  }

  successCallBack(response: any): void {
    if (!response?.data || response?.data?.totalRecords <= 0) {
      this.noRecordFound = true;
      this.gridHeight = '150px';
      this.params.successCallback([], 0);
    }
    if (response?.data?.totalRecords > 0) {
      this.noRecordFound = false;
      this.gridHeight = '470px';
      this.params.successCallback(response?.data?.records, response?.data?.totalRecords);
    }
  }
}
