import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CapacityHelper } from '../shared/capacity.helper';
import { SelectListModel } from '@app/core/models/select.list.model';
import { EquipmentService } from '@app/modules/master/equipment/equipment.service';
import { forkJoin } from 'rxjs';
import { CapacityService } from '../capacity.service';
import { CapacityDetailModel } from '../capacity.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormHelper, NumberLengthValidator } from '@app/core/helpers/form.helper';
import { CallerScreen } from '@app/core/constants/enums';

@Component({
  selector: 'app-capacity-add',
  templateUrl: './capacity-add.component.html',
  styles: [
  ]
})
export class CapacityAddComponent {
  capacityForm!: FormGroup;
  equipmentSelectList: SelectListModel[] = [];
  isExistingCapacity = true;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly equipmentService: EquipmentService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly service: CapacityService,
    private dynamicDialogService: DynamicDialogConfig,
  ) {
    if (this.dynamicDialogService.data?.cloneCapacityDetail) {
      this.getCloneCapacityDetail();
    } else {
      this.initCapacityForm();
    }
  }

  initCapacityForm(): void {
    const equipmentObservable = this.equipmentService.getEquipmentSelectList();
    forkJoin([equipmentObservable]).subscribe(
      (responses) => {
        const equipmentResponse = responses[0];
        if (equipmentResponse.data) {
          this.equipmentSelectList = equipmentResponse.data;
          this.capacityForm = CapacityHelper.getCapacityForm(this.formBuilder);
          if (this.isAwardCapacity() && this.isExistingCapacity) {
            FormHelper.EnableDisableFormControls(this.capacityForm, false, ['capacityUniqueIdObj', 'notes']);
            FormHelper.SetControlValidators(this.capacityForm, 'capacityUniqueIdObj', [Validators.required]);
            FormHelper.SetControlValidators(this.capacityForm, 'rates', []);
            FormHelper.SetControlValidators(this.capacityForm, 'totalWeeklyCapacity', []);
            this.setCapacityScheduleAvailability();
          }
        }
      }
    );
  }

  getCloneCapacityDetail(): void {
    const equipmentObservable = this.equipmentService.getEquipmentSelectList();
    forkJoin([equipmentObservable]).subscribe(
      (responses) => {
        const equipmentResponse = responses[0];
        if (equipmentResponse.data) {
          this.equipmentSelectList = equipmentResponse.data;
          this.capacityForm = CapacityHelper.getCapacityForm(this.formBuilder, this.dynamicDialogService.data?.cloneCapacityDetail);
          FormHelper.EnableDisableFormControl(this.capacityForm, false, 'capacityUniqueIdSuffix');
          FormHelper.EnableDisableFormControl(this.capacityForm, false, 'carrierNameObj');
          FormHelper.EnableDisableFormControl(this.capacityForm, false, 'carrierRepresentativeName');
          FormHelper.EnableDisableFormControl(this.capacityForm, false, 'phone');
          FormHelper.EnableDisableFormControl(this.capacityForm, false, 'email');
          this.getCapacityScheduleForms().controls.forEach((facilityScheduleForm: FormGroup) => {
            CapacityHelper.EnableDisableCapacityScheduleForm(facilityScheduleForm);
          });
        }
      }
    );
  }

  save(capacityDetail: CapacityDetailModel): void {
    if (this.isAwardCapacity()) {
      capacityDetail.awardDetailId = this.dynamicDialogService.data?.awardDetailId;
      if (this.isExistingCapacity) {
        this.service.createAwardCapacity(capacityDetail, this.callerScreen()).subscribe((response) => {
          if (response.data) {
            this.dialogRef.close(response);
          }
        });
      } else {
        this.service.createCapacity(capacityDetail, this.callerScreen()).subscribe((response) => {
          if (response.data) {
            this.dialogRef.close(response);
          }
        });
      }
    } else if (this.isCloneCapacity()) {
      capacityDetail.capacitySchedules.forEach(capacitySchedule => {
        capacitySchedule.capacityScheduleId = 0;
      });
      this.service.cloneCapacity(capacityDetail, CallerScreen.CapacityProfile).subscribe((response) => {
        if (response.data) {
          this.dialogRef.close(response);
        }
      });
    } else {
      this.service.createCapacity(capacityDetail, CallerScreen.CapacityListing).subscribe((response) => {
        if (response.data) {
          this.dialogRef.close(response);
        }
      });
    }
  }

  isAwardCapacity(): boolean {
    return this.dynamicDialogService.data?.isAwardCapacity;
  }

  isCloneCapacity(): boolean {
    return this.dynamicDialogService.data?.isCloneCapacity;
  }
  callerScreen(): string {
    return this.dynamicDialogService.data?.callerScreen;
  }

  setCapacityFormStatus(): void {
    if (this.isExistingCapacity) {
      FormHelper.EnableDisableFormControls(this.capacityForm, false, ['capacityStartDate', 'capacityEndDate', 'capacityUniqueIdObj', 'notes']);
      this.capacityForm.controls['capacityStartDate'].disable();
      this.capacityForm.controls['capacityEndDate'].disable();
      FormHelper.SetControlValidators(this.capacityForm, 'capacityUniqueIdObj', [Validators.required]);
      FormHelper.SetControlValidators(this.capacityForm, 'rates', []);
      FormHelper.SetControlValidators(this.capacityForm, 'totalWeeklyCapacity', []);
      this.setCapacityScheduleAvailability();
    } else {
      this.capacityForm.reset();
      FormHelper.EnableDisableFormControls(this.capacityForm, true, ['capacityUniqueIdSuffix', 'carrierName', 'carrierRepresentativeName', 'phone', 'email']);
      FormHelper.SetControlValidators(this.capacityForm, 'capacityUniqueIdObj', []);
      FormHelper.SetControlValidators(this.capacityForm, 'capacityUniqueId', []);
      FormHelper.SetControlValidators(this.capacityForm, 'rates', [Validators.required, NumberLengthValidator(7)]);
      FormHelper.SetControlValidators(this.capacityForm, 'totalWeeklyCapacity', [Validators.required, NumberLengthValidator(7)]);
      this.getCapacityScheduleForms().controls.forEach((facilityScheduleForm: FormGroup) => {
        CapacityHelper.EnableDisableCapacityScheduleForm(facilityScheduleForm);
      });
    }
  }

  getCapacityScheduleForms() {
    return this.capacityForm.get('capacitySchedules') as any;
  }

  awardDetailId(): number {
    return this.dynamicDialogService.data?.awardDetailId;
  }

  setCapacityScheduleAvailability(): void {
    const capacityScheduleForms = this.capacityForm.controls['capacitySchedules'] as any;
    capacityScheduleForms.controls.forEach((form: FormGroup) => {
      form.controls['capacityAvailable'].patchValue(false);
    });
  }
}
