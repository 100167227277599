import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup
} from '@angular/forms';
import { SelectListModel } from '@app/core/models/select.list.model';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-select-control',
  templateUrl: './select-control.component.html',
  styles: [],
})
export class SelectControlComponent implements AfterViewInit {
  constructor(private el: ElementRef) { }

  @Input() formName!: FormGroup;
  @Input() control!: AbstractControl;
  @ViewChild('dropref') dropRef!: Dropdown;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() elementId!: string;
  @Input() options!: SelectListModel[];
  @Input() defaultText = 'Please Select';
  @Input() defaultValue = null;
  @Input() multipleSelection!: boolean;
  @Input() filter = true;
  @Input() tabIndex = undefined;
  @Input() showClear!: boolean;
  @Input() maxSelectedLabels!: number;
  @Input() baseZIndex!: number;
  @Output() multiSelectChangeEvent: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() showHeader!: boolean;
  @Output() selectChangeEvent: EventEmitter<any> =
    new EventEmitter<number>();
  @Input() multiSelectDefaultLabel = '';
  @Input() styleClass!: string;
  @Input() disabled = false;
  @Input() isGivenDefaultValue = true;
  @Input() inputLimit!: number;
  @Input() isViewShipmentEDIFlag = false;
  @Input() optionBackground = '#f3beba';
  @Input() isPrimeNgDropdown = false;
  @Input() resetFilterOnHide = true;
  @Input() virtualScroll = true;
  @Input() itemSize!: number;
  @Input() autofocus = false;
  @Input() iTagData = '';
  @Input() isShowLabel = true;
  @Input() isDropdownInPopup = false;
  @Input() optionLabel = 'text';
  @Input() optionValue = 'value';
  @Input() scrollHeight = '200';
  @Input() isServerSideFilter = false;
  @Input() selectionLimit = 0;
  @Input() className = '';
  @Output() inputFilterEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() hideEvent: EventEmitter<number> = new EventEmitter<number>();

  get isRequired(): boolean {
    if (!this.control) return false;

    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : null;
    if (validator && validator['required']) {
      return true;
    } else {
      return false;
    }
  }

  onMultiSelectChanged(event: any): void {
    if (event.value.length > 0 && event.value.length > this.inputLimit) {
      event.value.pop();
    }
    this.multiSelectChangeEvent.emit(event);
  }

  onSelectChanged(event: any): void {
    if (this.isServerSideFilter && !event.value) {
      setTimeout(() => {
        this.dropRef.resetFilter();
      }, 100);
    }
    this.selectChangeEvent.emit(event);
  }

  onHideEvent(event: any): void {
    this.hideEvent.emit(event);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const search = this.el.nativeElement.querySelector('.p-dropdown-trigger');
      search?.setAttribute('aria-label', 'Search');
      const input = this.el.nativeElement.querySelector('.p-hidden-accessible');
      input?.insertAdjacentHTML(
        'afterbegin',
        '<label class="sr-only">Search</label>'
      );
    }, 500);
  }
}
