import { ICellRendererParams } from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Constants } from '@app/core/constants/constants';
import { UserNotificationModel } from '@app/core/models/user-notification.model';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styles: [
  ]
})
export class UserNotificationComponent {
  params!: ICellRendererParams | any;
  userNotification!: UserNotificationModel;

  constructor(
    private readonly datePipe: DatePipe
  ) { }

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.userNotification = params.node.data;
    if (this.userNotification) {
      this.userNotification.createdDate = this.datePipe.transform(this.userNotification?.createdDate, Constants.dateTimeFormat) || '';
    }
  }
}
