import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Input, ViewChild } from '@angular/core';
import { CarrierStakeholderType } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { UserScoreCardModel } from '@app/modules/administration/user/user.model';
import { UserService } from '@app/modules/administration/user/user.service';
import { CarrierStakeholderModel } from '@app/modules/carrier/carrier.model';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-user-score-card',
  templateUrl: './user-score-card.component.html',
  styles: [
  ],
})
export class UserScoreCardComponent {
  params!: ICellRendererParams | any;
  userSalesData!: UserScoreCardModel;
  @ViewChild('op') overlayPanel!: OverlayPanel;

  @Input() displayOnGrid = true;
  @Input() userName!: string;
  @Input() userId!: number;

  constructor(
    private readonly userService: UserService
  ) { }

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  userClick(event: any): void {
    if (this.displayOnGrid) {
      if (this.params?.customerList) {
        this.userService.getSalesScoreCard(this.params?.data.customerStakeholder[this.params.userId]).subscribe((response) => {
          if (response.data) {
            this.userSalesData = response.data;
            this.overlayPanel.toggle(event);
          }
        })
      } else if (this.params?.carrierList) {
        this.userService.getSalesScoreCard(this.getCarrierRepId()).subscribe((response) => {
          if (response.data) {
            this.userSalesData = response.data;
            this.overlayPanel.toggle(event);
          }
        })
      } else {
        this.userService.getSalesScoreCard(this.params?.data[this.params.userId]).subscribe((response) => {
          if (response.data) {
            this.userSalesData = response.data;
            this.overlayPanel.toggle(event);
          }
        })
      }
    } else {
      this.userService.getSalesScoreCard(this.userId).subscribe((response) => {
        if (response.data) {
          this.userSalesData = response.data;
          this.overlayPanel.toggle(event);
        }
      })
    }
  }

  getCarrierRepId(): number {
    const carrierStakeholders: CarrierStakeholderModel[] = this.params?.data?.carrierStakeholder || [];
    const userId = carrierStakeholders.find(x => x.carrierStakeholderType.name === CarrierStakeholderType.CarrierRep)?.carrierStakeholderId;
    return userId ? userId : 0;
  }

  getCarrierRepName(): string {
    const carrierStakeholders: CarrierStakeholderModel[] = this.params?.data?.carrierStakeholder || [];
    const name = carrierStakeholders.find(x => x.carrierStakeholderType.name === CarrierStakeholderType.CarrierRep)?.carrierStakeholderName;
    return name ? name : '';
  }

  isSystemUser(): boolean {
    return this.displayOnGrid ? LoginHelper.IsSystemUser(this.params?.data && this.params?.data[this.params.userName]) : LoginHelper.IsSystemUser(this.userName);
  }
}

