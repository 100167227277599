import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ErrorLoggerService } from './interceptors/error.logger';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ApiClientService } from './services/api-client.service';
import { AppConfigService } from './services/app-config.service';
import { CoreService } from './services/core.service';
import { SpinnerService } from './services/spinner.service';
import { ToasterService } from './services/toaster.service';
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.getAppConfig();
  };
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    AppConfigService,
    ApiClientService,
    ToasterService,
    SpinnerService,
    CoreService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpErrorInterceptor,
    },
    { provide: ErrorHandler, useClass: ErrorLoggerService }
  ],
})
export class CoreModule { }
