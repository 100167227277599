import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormGroup
} from '@angular/forms';

@Component({
  selector: 'app-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styles: [],

})
export class CheckboxControlComponent {
  @Input() formName!: FormGroup;
  @Input() control!: AbstractControl;
  @Input() controlName!: string;
  @Input() name!: string;
  @Input() label!: string;
  @Input() value!: string;
  @Input() readonly!: boolean;
  @Input() elementId!: string;
  @Input() disabled!: boolean;
  @Input() iTagData = '';
  @Output() checkboxChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  onCheckboxChanged(event: any): void {
    this.checkboxChangeEvent.emit(event);
  }

  get isRequired(): boolean {
    if (!this.control) return false;

    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : null;
    if (validator && validator['required']) {
      return true;
    } else {
      return false;
    }
  }
}
