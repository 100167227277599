<div *ngIf="params?.module !== this.module.UserManagement &&
    params?.module !== this.module.RoleManagement &&
    params?.module !== this.module.CustomerManagement &&
    params?.module !== this.module.Carrier &&
    params?.module !== this.module.AuditReview &&
    params?.module !== this.module.PreAR &&
    params?.module !== this.module.AccountsPayable">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2"
        [appPermission]="params?.permission" *ngIf="params?.node?.data?.isActive" [module]="params?.module"
        (click)="edit()">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>

    <a href="javascript:void(0);" *ngIf="!params?.node?.data?.isDefault" [appPermission]="params?.permission"
        [module]="params?.module" tooltipPosition="left"
        pTooltip="{{params?.node?.data?.isActive || params?.node?.data?.active ? 'Inactivate':'Activate'}}"
        [ngClass]="{'text-danger':params?.node?.data?.isActive || params?.node?.data?.active,'text-success':!params?.node?.data?.isActive}"
        class="mr-2" (click)="updateStatus()">
        <span *ngIf="params?.node?.data?.isActive || params?.node?.data?.active; else activeNode"
            class="material-symbols-outlined">remove_circle_outline</span>
        <ng-template #activeNode>
            <span class="material-symbols-outlined">
                check_circle
            </span>
        </ng-template>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Set as Default" [appPermission]="params?.permission"
        [module]="params?.module" *ngIf="params?.action === 'set-default' && !params?.node?.data?.isDefault"
        class="text-primary mr-2" (click)="setDefault()">
        <span class="material-symbols-outlined">
            verified
        </span>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Stakeholder Assignation"
        *ngIf="params?.action === 'assign-sales-rep'" class="text-primary mr-2" (click)="assignRep()">
        <span class="material-symbols-outlined">
            person_add
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.UserManagement || params?.module === this.module.RoleManagement">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2"
        *ngIf="params?.node?.data?.isActive" (click)="edit()">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>

    <a href="javascript:void(0);" *ngIf="!params?.node?.data?.isDefault" [appPermission]="params?.activatePermission"
        [module]="params?.module" tooltipPosition="left"
        pTooltip="{{params?.node?.data?.isActive || params?.node?.data?.active ? 'Inactivate':'Activate'}}"
        [ngClass]="{'text-danger':params?.node?.data?.isActive || params?.node?.data?.active,'text-success':!params?.node?.data?.isActive}"
        class="mr-2" (click)="updateStatus()">
        <span *ngIf="params?.node?.data?.isActive || params?.node?.data?.active; else activeNode"
            class="material-symbols-outlined">remove_circle_outline</span>
        <ng-template #activeNode>
            <span class="material-symbols-outlined">
                check_circle
            </span>
        </ng-template>
    </a>
    <a *ngIf="params?.module === this.module.RoleManagement" href="javascript:void(0);" tooltipPosition="left"
        pTooltip="Assign Views" class="text-primary mr-2" (click)="assignViewsClick()"
        [appPermission]="params?.viewPermissionCode" [module]="params?.viewModule">
        <span class="material-symbols-outlined">
            settings_account_box
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.CustomerManagement">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Stakeholder Assignation"
        *ngIf="params?.action === 'assign-sales-rep'" [appPermission]="params?.manageStakeholderAssignment"
        [module]="params?.manageProfile" class="text-primary mr-2" (click)="assignRep()">
        <span class="material-symbols-outlined">
            person_add
        </span>
    </a>
    <a href="javascript:void(0);" *ngIf="params?.node?.data?.customerStatus?.name.toLowerCase() === customerStatusEnum.Active &&
        (params?.markCustomerasDNUActive || (params?.node?.data?.markDnuPermission))" tooltipPosition="left"
        pTooltip="Mark DNU" [ngClass]="{'text-danger':true}" class="mr-2" (click)="updateStatus()">
        <span class="material-symbols-outlined text-warning">block</span>
    </a>
    <a href="javascript:void(0);" *ngIf="params?.node?.data?.customerStatus?.name.toLowerCase() === customerStatusEnum.DoNotUse &&
        (params?.markCustomerasDNUActive || (params?.node?.data?.markDnuPermission))" tooltipPosition="left"
        pTooltip="Activate" [ngClass]="{'text-success':true}" class="mr-2" (click)="updateStatus()">
        <span class="material-symbols-outlined">
            check_circle
        </span>
    </a>

    <div *ngIf="params?.action === 'manage-customer-fsc'">
        <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Download"
            class="btn btn-action btn-link text-cover mr-1" (click)="downloadDocument()">
            <span class="material-symbols-outlined">
                download
            </span>
        </button>
    </div>
</div>

<div *ngIf="params?.module === this.module.PurchaseJournal &&  params.node?.data">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Sync Payment" class="text-warning mr-2"
        (click)="syncPaymentClick()">
        <span class="material-symbols-outlined">
            sync
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Void" class="text-primary mr-2" (click)="remove()"
        [module]="params?.module" [appPermission]="params?.voidPurchaseJournal">
        <span class="material-symbols-outlined text-danger"
            [ngClass]="{'text-draft': params.node?.data?.status !== purchaseJournalStatus.Active}">
            delete
        </span>
    </a>
</div>

<div *ngIf="params?.carrierModule === module.CarrierListing">
    <a href="javascript:void(0);" *ngIf="params?.canAssignStakeholder" tooltipPosition="left"
        pTooltip="Stakeholder Assignation" class="text-primary mr-2" (click)="assignRep()">
        <span class="material-symbols-outlined">
            person_add
        </span>
    </a>
    <a href="javascript:void(0);" *ngIf="params?.node?.data?.carrierStatus?.name.toLowerCase() !== carrierStatusEnum.DoNotUse &&
        (params?.canMarkCarrierDNUActive || (params?.node?.data?.markDnuPermission))" tooltipPosition="left"
        pTooltip="Mark DNU" [ngClass]="{'text-danger':true}" class="mr-2" (click)="markDNU()">
        <span class="material-symbols-outlined text-warning">block</span>
    </a>
    <a href="javascript:void(0);" *ngIf="params?.node?.data?.carrierStatus?.name.toLowerCase() === carrierStatusEnum.DoNotUse &&
        (params?.canMarkCarrierDNUActive || (params?.node?.data?.markDnuPermission))" tooltipPosition="left"
        pTooltip="Activate" [ngClass]="{'text-success':true}" class="mr-2" (click)="markActive()">
        <span class="material-symbols-outlined">
            check_circle
        </span>
    </a>
</div>

<div>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit"
        class="btn btn-action btn-link p-0 text-primary mr-2" (click)="edit()"
        *ngIf="params?.action === 'edit-bol' && params?.editPermission" [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined">
            edit
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Delete"
        class="btn btn-action btn-link p-0 text-danger mr-2" (click)="remove()"
        *ngIf="params?.action === 'edit-bol' && params?.deletePermission" [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined">
            delete
        </span>
    </button>
</div>

<div *ngIf="params?.module === this.module.Load">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Quick View" class="text-primary mr-2"
        (click)="loadQuickView()" [appPermission]="permission.QuickView" [module]="module.Loadboard">
        <span class="material-symbols-outlined">
            saved_search
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Documents" class="text-released mr-2"
        (click)="loadDocument()" *ngIf="isViewLoadDocumentsPermitted()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Activity" class="text-dark mr-2"
        (click)="loadActivitiesClick()" [appPermission]="permission.ManageActivityonload" [module]="module.LoadProfile">
        <span class="material-symbols-outlined">
            article
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Clone" class="text-loaded mr-2"
        (click)="cloneLoadClick()" [appPermission]="permission.CloneLoad" [module]="module.Loadboard">
        <span class="material-symbols-outlined">
            file_copy
        </span>
    </a>
</div>

<a href="javascript:void(0);" tooltipPosition="left" pTooltip="View" class="text-primary mr-2" (click)="viewPdf()"
    *ngIf="params?.action === 'rate-con-pdf' &&
    (params?.data?.status === rateConfirmationStatus.Signed || params?.data?.status === rateConfirmationStatus.Forwarded || params?.data?.status === rateConfirmationStatus.Resent)">
    <span class="material-symbols-outlined">
        saved_search
    </span>
</a>

<div *ngIf="params?.module === this.module.CreditApproval">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Approve" class="text-primary mr-2"
        (click)="approveRequest()" [appPermission]="params?.approveRejectPermission" [module]="params?.module"
        *ngIf="params?.action === 'approve-reject-credit-request' && params?.data?.creditRequestStatus.name === creditRequestStatusEnum.Pending">
        <span class="material-symbols-outlined text-success">
            check_circle
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Reject" class="text-danger mr-2"
        (click)="rejectRequest()" [appPermission]="params?.approveRejectPermission" [module]="params?.module"
        *ngIf="params?.action === 'approve-reject-credit-request' && params?.data?.creditRequestStatus.name === creditRequestStatusEnum.Pending">
        <span class="material-symbols-outlined text-danger">
            cancel
        </span>
    </a>
</div>

<div>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Approve"
        class="btn btn-action btn-link p-0 text-primary mr-2" (click)="approveBidRequest()"
        *ngIf="params?.action === 'carrier-bids' &&
        params?.approvePermitted &&
        (params?.data?.loadCarrierBidStatusName === loadBidStatus.Live || params?.data?.loadCarrierBidStatusName === loadBidStatus.Dead)" [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined text-success">
            check_circle
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Reject"
        class="btn btn-action btn-link p-0 text-danger mr-2" (click)="rejectBidRequest()"
        *ngIf="params?.action === 'carrier-bids' && params?.rejectPermitted && params?.data?.loadCarrierBidStatusName === loadBidStatus.Live"
        [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined text-danger">
            cancel
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Assign"
        class="btn btn-action btn-link p-0 text-released mr-3"
        *ngIf="params?.action === 'carrier-bids' && params?.assignPermitted" (click)="assignCapacity()"
        [disabled]="params?.isDisabled || params?.isOpenLoadInReview">
        <span class="material-symbols-outlined">
            fact_check
        </span>
    </button>
</div>

<div *ngIf="params?.module === module.CustomerDocuments || params?.module === module.CarrierDocuments">
    <a *ngIf="((params?.permissions?.canViewDocument && params?.displayViewDocumentIconSection)  || params?.permissions?.hasImplicitPermission)"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="View" class="text-dark mr-1"
        (click)="viewDocument()"
        [ngClass]="{'invisible': !(params?.data?.documentName?.toLowerCase()?.endsWith(fileExtensionEnum.PDF)) }">
        <span class="material-symbols-outlined">
            zoom_in
        </span>
    </a>
    <a *ngIf="params?.permissions?.canDownloadDocument || params?.permissions?.hasImplicitPermission"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Download" class="text-cover mr-1"
        (click)="downloadDocument()">
        <span class="material-symbols-outlined">
            system_update_alt
        </span>
    </a>
    <a *ngIf="params?.permissions?.canDeleteDocument || params?.permissions?.hasImplicitPermission"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Delete" class="text-danger mr-1"
        (click)="deleteDocument()">
        <span class="material-symbols-outlined">
            delete
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.FactoringCompany">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2"
        *ngIf="params?.node?.data?.isActive" (click)="edit()" [appPermission]="params?.editPermission"
        [module]="params?.module">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left"
        pTooltip="{{params?.node?.data?.isActive || params?.node?.data?.active ? 'Inactivate':'Activate'}}"
        [ngClass]="{'text-danger':params?.node?.data?.isActive,'text-success':!params?.node?.data?.isActive}"
        class="mr-2" (click)="updateStatus()" [appPermission]="params?.activatePermission" [module]="params?.module">
        <span *ngIf="params?.node?.data?.isActive; else activeNode"
            class="material-symbols-outlined">remove_circle_outline</span>
        <ng-template #activeNode>
            <span class="material-symbols-outlined">
                check_circle
            </span>
        </ng-template>
    </a>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Intergration Sync Detail"
        class="btn btn-secondary px-1 py-0 rounded-5 text-center" (click)="op.toggle($event)">
        <span class="material-symbols-outlined text-white cursor-pointer" style="font-size: 15px;">
            sync
        </span>
    </button>
    <p-overlayPanel #op styleClass="overlay-panel-sync">
        <ng-template pTemplate="content">
            <app-integration-sync-detail [factoringCompanyDetail]="params?.data"></app-integration-sync-detail>
        </ng-template>
    </p-overlayPanel>
</div>

<div>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit"
        class="btn btn-action btn-link p-0 text-primary mr-1" (click)="toggleEditPanel($event,editPanel)"
        *ngIf="params?.action === 'document' && params?.editPermitted" [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined">
            edit_square
        </span>
    </button>
    <p-overlayPanel #editPanel styleClass="grid-overlay" [showCloseIcon]="true" [dismissable]="false">
        <ng-template pTemplate="content">
            <app-edit-document [document]="params?.data" (editDocument)="editDocument($event);editPanel.toggle($event)"
                [editPanel]="editPanel" [documentTypesSelectList]="params?.documentTypesSelectList"
                [loadDetailId]="params?.loadDetailId">
            </app-edit-document>
        </ng-template>
    </p-overlayPanel>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="View"
        class="btn btn-action btn-link p-0 text-dark mr-1" (click)="onViewerClick()"
        *ngIf="params?.action === 'document' && params?.displayViewDocumentIconSection && params?.viewPermitted"
        [ngClass]="{'invisible': !(params?.data?.name?.toLowerCase()?.endsWith('.pdf')) }">
        <span class="material-symbols-outlined">
            zoom_in
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Download"
        class="btn btn-action btn-link p-0 text-cover mr-1" (click)="downloadDocument()"
        *ngIf="params?.action === 'document' && params?.downloadPermitted">
        <span class="material-symbols-outlined">
            system_update_alt
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Delete"
        class="btn btn-action btn-link p-0 text-danger mr-1" (click)="remove()"
        *ngIf="params?.action === 'document' && params?.deletePermitted" [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined">
            delete
        </span>
    </button>
</div>

<div *ngIf="params?.module === this.module.CarrierCapacity && params?.data?.isCreatedInTMS">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2" (click)="edit()"
        *ngIf="params?.data?.isActive">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Load Match" class="text-primary mr-2"
        (click)="onCapacityLoadClick()" *ngIf="params?.data?.isActive">
        <span class="material-symbols-outlined">
            all_match
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Delete" class="text-danger mr-2" (click)="remove()"
        *ngIf="params?.data?.isActive">
        <span class="material-symbols-outlined">
            delete
        </span>
    </a>
</div>
<div *ngIf="params?.module === this.module.ManageTruckMatch">
    <a href="javascript:void(0);" tooltipPosition="left"
        pTooltip="{{params?.node?.data?.status === availUnavailStatus.Avail ? 'Inactivate':'Activate'}}"
        [ngClass]="{'text-danger':params?.node?.data?.status === availUnavailStatus.Avail}" class="mr-2"
        (click)="updateStatus()" *ngIf="params?.action !== 'loadHistory' && params?.manageTruckMatch">
        <span class="material-symbols-outlined " *ngIf="params?.node?.data?.status === availUnavailStatus.Unavail">
            check_circle
        </span>
        <span class="material-symbols-outlined" *ngIf="params?.node?.data?.status === availUnavailStatus.Avail">
            remove_circle_outline
        </span>
    </a>
    <button href="javascript:void(0);" [pTooltip]="params?.data?.description"
        class="btn btn-action btn-link p-0 text-lightgray mr-2"
        *ngIf="params?.data?.description && params?.action !== 'loadHistory' && !params?.isLoadQuickView">
        <span class="material-symbols-outlined">
            info
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Assign"
        class="btn btn-action btn-link p-0 text-released mr-2" (click)="assignCapacity()"
        *ngIf="params?.assignPermitted"
        [disabled]="params?.node?.data?.status === availUnavailStatus.Unavail || params?.isDisabled || params?.isOpenLoadInReview">
        <span class="material-symbols-outlined">
            fact_check
        </span>
    </button>
</div>
<div>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2" (click)="edit()"
        *ngIf="params?.action === 'incidentAction' && params?.data?.status !== incidentStatusEnum.Resolved && params?.editPermitted">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Resolve"
        class="btn btn-action btn-link p-0 text-primary mr-2" (click)="toggleOverlayPanel($event,overlayPanel)"
        *ngIf="params?.action === 'incidentAction' && params?.data?.status !== incidentStatusEnum.Resolved && (params?.resolvePermitted || (params?.data?.assignedTo === getCurrentUser().userId))"
        [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined">
            settings
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Information"
        class="btn btn-action btn-link p-0 text-lightgray mr-2" (click)="toggleOverlayPanel($event,overlayPanel)"
        *ngIf="params?.action === 'incidentAction' && params?.data?.status === incidentStatusEnum.Resolved"
        [disabled]="params?.isDisabled">
        <span class="material-symbols-outlined">
            info
        </span>
    </button>
    <p-overlayPanel #overlayPanel styleClass="grid-overlay" [showCloseIcon]="true" [dismissable]="false">
        <ng-template pTemplate="content">
            <app-resolve-incident [loadIncident]="params?.data"
                (resolveIncident)="resolveIncident($event);overlayPanel.toggle($event)" [overlayPanel]="overlayPanel"
                [callerScreen]="params?.callerScreen"></app-resolve-incident>
        </ng-template>
    </p-overlayPanel>
</div>

<div *ngIf="params?.module === this.module.EDIManagement">
    <button tooltipPosition="left" pTooltip="Assign" (click)="assignEdiTender()" class="btn btn-link p-0 text-open"
        [appPermission]="params?.assignEdiTenderPermission" [module]="params?.module"
        [disabled]="!processEdi(params?.node?.data?.ediLoadTenderStatus)" *ngIf="params?.isFirstColumn === false">
        <span class="material-symbols-outlined">
            fact_check
        </span>
    </button>
    <button tooltipPosition="left" pTooltip="Process" class="btn btn-link p-0 text-dispatch"
        (click)="processEdiTender()" *ngIf="isEDIProcessVisible() && params?.isFirstColumn === false"
        [disabled]="!processEdi(params?.node?.data?.ediLoadTenderStatus)">
        <span class="material-symbols-outlined">
            sync
        </span>
    </button>
    <a href="javascript:void(0);" class="text-dispatch mr-2" [appPermission]="params?.processEdiTenderPermission"
        [module]="params?.module" *ngIf="params?.node?.data?.isRetendered === true && params?.isFirstColumn === true">
        <span class="material-symbols-outlined text-cover">
            currency_exchange
        </span>
    </a>
    <a href="javascript:void(0);" (click)="ediUpdateIcon()" class="text-open mr-2"
        [appPermission]="params?.processEdiTenderPermission" [module]="params?.module"
        *ngIf="params?.node?.data?.ediLoadTenderUpdated === true && params?.isFirstColumn === true">
        <span class="material-symbols-outlined text-primary">
            emergency
        </span>
    </a>
</div>
<div *ngIf="params?.module === this.module.SystemLogs">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Stack Trace" class="text-primary mr-2"
        (click)="edit()" *ngIf="!params?.action">
        <span class="material-symbols-outlined">
            visibility
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Inner Exception" class="text-primary mr-2"
        (click)="editInnerException()" *ngIf="!params?.action">
        <span class="material-symbols-outlined">
            visibility
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Pay Load" class="text-primary mr-2"
        (click)="editPayLoad()" *ngIf="!params?.action">
        <span class="material-symbols-outlined">
            visibility
        </span>
    </a>
</div>
<div *ngIf="params?.module === this.module.TrackingBoard">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Quick View" class="text-primary mr-2"
        (click)="loadQuickView()" [appPermission]="permission.QuickView" [module]="module.TrackingBoard">
        <span class="material-symbols-outlined">
            saved_search
        </span>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Snooze" class="text-primary mr-2" (click)="snooze()"
        [module]="params?.module" [appPermission]="params?.snoozePermission">
        <span class="material-symbols-outlined">
            snooze
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="High Visibility" class="text-primary mr-2"
        (click)="highVisibility()" [module]="params?.module" [appPermission]="params?.highVisibilityPermission">
        <span class="material-symbols-outlined">
            visibility
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Manage Checkcall"
        class="text-primary mr-2 position-relative" (click)="manageCheckCall()" [module]="params?.module"
        [appPermission]="params?.manageCheckCallPermission">
        <span class="material-symbols-outlined position-absolute text-primary pointer-events-none"
            style="top:-2px;left: -2px;font-size: 16px;">
            check_small
        </span>
        <span class="material-symbols-outlined text-primary">
            phone_enabled
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Manage Incidents" class="text-primary mr-2"
        (click)="manageIncidents()" [module]="params?.module" [appPermission]="params?.manageIncidentPermission">
        <span class="material-symbols-outlined">
            report
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.PreAR">
    <a *ngIf="params?.onEditClick" href="javascript:void(0);" tooltipPosition="left" [module]="params?.module"
        [appPermission]="params?.editLoad" pTooltip="Edit" class="text-primary mr-2" (click)="editRecord()">
        <span class="material-symbols-outlined">
            edit_square
        </span>
    </a>
    <a href="javascript:void(0);" *ngIf="hasImplicitePermission(params,params.documentPermission)"
        tooltipPosition="left" pTooltip="Documents" class="text-onhold mr-2" (click)="viewDocuments()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>
    <a *ngIf="params?.onRevertClick" href="javascript:void(0);" tooltipPosition="left" [module]="params?.module"
        [appPermission]="params?.revertReviewForAR" pTooltip="Review" class="text-released mr-2" (click)="revert()">
        <span class="material-symbols-outlined">
            rate_review
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Invoice" [module]="params?.module"
        [appPermission]="params?.createInvoice" class="text-pickup mr-2" (click)="viewInvoice()"
        *ngIf="params.data && params.data.isInvoiceReady">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" [module]="params?.module"
        [appPermission]="params?.revertLoadFromPreAR" pTooltip="Revert" class="text-warning mr-2"
        (click)="onARLoadRevert()">
        <span class="material-symbols-outlined">
            undo
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.ARAging  && params.data">
    <a href="javascript:void(0);" *ngIf="hasImplicitePermission(params,params.documentPermission)"
        tooltipPosition="left" pTooltip="Documents" class="text-onhold mr-2" (click)="viewDocuments()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Invoice" class="text-pickup mr-2"
        (click)="viewInvoice()" [module]="params?.module" [appPermission]="params?.viewInvoicePermission">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>
    <a href="javascript:void(0);" *ngIf="hasImplicitePermission(params,params.hasSyncARPaymentStatusPermission)"
        tooltipPosition="left" pTooltip="Sync Payment" class="text-warning mr-2" (click)="syncPaymentClick()">
        <span class="material-symbols-outlined">
            sync
        </span>
    </a>

</div>

<div *ngIf="params?.module === this.module.ARClose  && params.data">
    <a href="javascript:void(0);" *ngIf="hasImplicitePermission(params,params.documentPermission)"
        tooltipPosition="left" pTooltip="Documents" class="text-onhold mr-2" (click)="viewDocuments()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Invoice" [module]="params?.module"
        [appPermission]="params?.viewInvoicePermission" class="text-pickup mr-2" (click)="viewInvoice()">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.PreAP && params.data">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" [module]="params?.module"
        [appPermission]="params?.editLoad" class="text-primary mr-2" (click)="onApEdit()">
        <span class="material-symbols-outlined">
            edit_square
        </span>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left"
        *ngIf="hasImplicitePermission(params,params.documentPermission)" pTooltip="Documents" class="text-onhold mr-2"
        (click)="onApDocument()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>
    <a *ngIf="params?.onRevertClick" href="javascript:void(0);" tooltipPosition="left" [module]="params?.module"
        [appPermission]="params?.revertReviewForAR" pTooltip="Review" class="text-released mr-2" (click)="onApRevert()">
        <span class="material-symbols-outlined">
            rate_review
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Bill" class="text-open mr-2"
        (click)="onViewerClick()" [appPermission]="params?.createBillManually" [module]="params?.module">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" [module]="params?.module"
        [appPermission]="params?.revertLoadFromPreAP" pTooltip="Revert" class="text-warning mr-2"
        (click)="onAPLoadRevert()">
        <span class="material-symbols-outlined">
            undo
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.APAging  && params.data">
    <a href="javascript:void(0);" tooltipPosition="left"
        *ngIf="hasImplicitePermission(params,params.documentPermission)" pTooltip="Documents" class="text-onhold mr-2"
        (click)="onApDocument()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Bill" [module]="params?.module"
        [appPermission]="params?.viewBillPermission" class="text-pickup mr-2" (click)="onViewerClick()">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>

    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Sync Payment" class="text-warning mr-2"
        (click)="syncPaymentClick()" *ngIf="hasImplicitePermission(params,params.hasSyncAPPaymentStatusPermission)">
        <span class="material-symbols-outlined">
            sync
        </span>
    </a>
</div>
<div *ngIf="params?.module === this.module.APClose && params?.node?.data">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Documents"
        *ngIf="hasImplicitePermission(params,params.documentPermission)" class="text-onhold mr-2"
        (click)="onApDocument()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Bill" [module]="params?.module"
        [appPermission]="params?.viewBillPermission" class="text-pickup mr-2" (click)="onViewerClick()">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>


</div>
<div *ngIf="params?.module === this.module.EDIManagement">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="View" class="text-primary mr-2"
        (click)="viewEDIPayload()" *ngIf="(params?.permissions?.viewEdiExchange)">
        <span class="material-symbols-outlined">
            visibility
        </span>
    </a>
</div>



<div *ngIf="params?.module === this.module.AuditReview && params.data">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" [module]="params?.module"
        [appPermission]="params?.permission" class="text-primary mr-2" (click)="onAuditReviewEdit()">
        <span class="material-symbols-outlined">
            edit_square
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Documents" [module]="params?.module"
        [appPermission]="params?.documentPermission" class="text-onhold mr-2" (click)="onAuditReviewDocument()"
        *ngIf="isViewLoadDocumentsPermitted()">
        <span class="material-symbols-outlined">
            description
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Release for billing" [module]="params?.module"
        [appPermission]="params?.releaseLoad" class="text-primary mr-2" (click)="onRelease()">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>

</div>

<div *ngIf="params?.module === this.module.NotificationManagement">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2" (click)="edit()">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>
</div>

<div *ngIf="params?.action === 'facility-list'">
    <a href="javascript:void(0);" tooltipPosition="left" class="text-primary mr-2"
        pTooltip="{{params.node?.data?.status === facilityStatus.Active || params.node?.data?.status === facilityStatus.Pending ? facilityButtonText.Inactivate:facilityButtonText.Activate}}"
        (click)="onFacilityStatusChange()" [module]="module.FacilityProfile"
        [appPermission]="permission.InactiveActiveFacility">
        <span class="material-symbols-outlined me-1"
            [ngClass]="{'text-danger':params.node?.data?.status === facilityStatus.Active || params.node?.data?.status === facilityStatus.Pending,'text-primary':params.node?.data?.status === facilityStatus.Inactive}">
            {{params.node?.data?.status === facilityStatus.Active || params.node?.data?.status ===
            facilityStatus.Pending
            ? 'remove_circle_outline':'check_circle'}}
        </span>
    </a>
</div>

<div *ngIf="params?.action === 'facility-customer-list'">
    <a *ngIf="params?.canEditCustomer" href="javascript:void(0);" tooltipPosition="left"
        pTooltip="{{listActionType.Edit}}" class="text-primary mr-2" (click)="onFacilityCustomerEdit()">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>
    <a *ngIf="params?.canActivateInactivateCustomer" href="javascript:void(0);" tooltipPosition="left"
        class="text-primary mr-2"
        pTooltip="{{params.node?.data?.isActive ? facilityButtonText.Inactivate:facilityButtonText.Activate}}"
        (click)="onFacilityCustomerStatusChange()">
        <span class="material-symbols-outlined me-1"
            [ngClass]="{'text-danger':params.node?.data?.isActive,'text-primary':!params.node?.data?.isActive}">
            {{params.node?.data?.isActive ? 'remove_circle_outline':'check_circle'}}
        </span>
    </a>
</div>

<div>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Download"
        class="btn btn-action btn-link text-cover mr-1" (click)="downloadReportDocument()"
        *ngIf="params?.action === 'report-document'" [appPermission]="params?.downloadPermission"
        [module]="params?.module">
        <span class="material-symbols-outlined">
            download
        </span>
    </button>
</div>

<div>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Assign"
        class="btn btn-action btn-link p-0 text-released mr-3"
        *ngIf="params?.action === 'laneHistory' && params?.assignPermitted" (click)="assignCapacity()"
        [disabled]="params?.isDisabled || params?.isOpenLoadInReview">
        <span class="material-symbols-outlined">
            fact_check
        </span>
    </button>
</div>

<div *ngIf="params?.module === this.module.PreferredLanes">
    <a *ngIf="params?.node?.data?.status === activeInactiveStatus.Active" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2" (click)="edit()">
        <span class="material-symbols-outlined">
            edit
        </span>
    </a>
    <a *ngIf="params?.node?.data?.status === activeInactiveStatus.Active" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Load Match" class="text-primary mr-2" (click)="onViewLoadClick()">
        <span class="material-symbols-outlined">
            all_match
        </span>
    </a>
    <a *ngIf="params?.node?.data?.status === activeInactiveStatus.Expired" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Revnew" class="text-primary mr-2" (click)="renewCarrierPreferredLane()">
        <span class="material-symbols-outlined">
            event_repeat
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left"
        *ngIf="params?.node?.data?.status !== activeInactiveStatus.Expired"
        pTooltip="{{params?.node?.data?.status === activeInactiveStatus.Active? activeInactiveStatus.Inactivate:activeInactiveStatus.Activate}}"
        [ngClass]="{'text-danger':params?.node?.data?.status === activeInactiveStatus.Active,'text-success':params?.node?.data?.istatus === activeInactiveStatus.Inactivate}"
        class="mr-2" (click)="updateStatus()">
        <span *ngIf="params?.node?.data?.status === activeInactiveStatus.Active; else activeNode"
            class="material-symbols-outlined">remove_circle_outline</span>
        <ng-template #activeNode>
            <span class="material-symbols-outlined">
                check_circle
            </span>
        </ng-template>
    </a>

</div>

<div *ngIf="params?.module === this.module.Award">
    <!-- Pending [Bids]-->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Bids" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Approve" class="text-primary mr-2"
        (click)="onUpdateAwardStatus(awardStatusAction.Pending)" [appPermission]="permission.ApproveRejectBids"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardListing">
        <span class="material-symbols-outlined">
            check_circle
        </span>
    </a>

    <!-- Lost [Bids]-->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Bids" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Reject" class="text-danger mr-2"
        (click)="onUpdateAwardStatus(awardStatusAction.Lost)" [appPermission]="permission.ApproveRejectBids"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardListing">
        <span class="material-symbols-outlined">
            cancel
        </span>
    </a>

    <!-- Approve [Pending]-->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Pending" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Approve" class="text-primary mr-2"
        (click)="onUpdateAwardStatus(awardStatusAction.Approve)" [appPermission]="permission.ApproveRejectAward"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardProfile">
        <span class="material-symbols-outlined">
            check_circle
        </span>
    </a>

    <!-- reject [pending]-->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Pending" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Reject" class="text-danger mr-2"
        (click)="onUpdateAwardStatus(awardStatusAction.Reject)" [appPermission]="permission.ApproveRejectAward"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardProfile">
        <span class="material-symbols-outlined">
            cancel
        </span>
    </a>

    <!-- resubmit [rejected]-->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Rejected" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Resubmit" class="text-secondary mr-2"
        (click)="onUpdateAwardStatus(awardStatusAction.Resubmit)"
        [appPermission]="params?.callerScreen === callerScreen.CustomerProfile ? permission.ResubmitAwardOnCustomerProfile : permission.ResubmitAward"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardProfile">
        <span class="material-symbols-outlined">
            sync
        </span>
    </a>

    <!-- Active [Inactive]-->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Inactive" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Activate" class="text-secondary mr-2"
        (click)="onUpdateAwardStatus(awardStatusAction.Activate)" [appPermission]="permission.ActiveInactiveAward"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardProfile">
        <span class="material-symbols-outlined">
            check_circle
        </span>
    </a>

    <!-- inactive [Validated, Active]-->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Validated || params?.node?.data?.awardStatus === awardListStatus.Active"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Inactivate" class="text-danger mr-2"
        (click)="onUpdateAwardStatus(awardStatusAction.Inactivate)" [appPermission]="permission.ActiveInactiveAward"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardProfile">
        <span class="material-symbols-outlined">
            do_not_disturb_on
        </span>
    </a>

    <!-- Submit capacity -->
    <a *ngIf="params?.node?.data?.awardStatus === awardListStatus.Validated || params?.node?.data?.awardStatus === awardListStatus.Active"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Submit Capacity" class="text-primary mr-2"
        (click)="openAddCapacityDialog()" [appPermission]="permission.SubmitCapacityOnAward"
        [module]="params?.callerScreen===callerScreen.CustomerProfile ? module.CustomerProfile : module.AwardProfile">
        <span class="material-symbols-outlined">
            list_alt_add
        </span>
    </a>
</div>

<div *ngIf="params?.action === 'capacity-list'">
    <a *ngIf="params?.node?.data?.capacityStatus=== capacityStatus.Active || params?.node?.data?.capacityStatus === capacityStatus.Awarded"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Inactivate" class="text-danger mr-2"
        (click)="onUpdateCapcityStatus(capcityStatusAction.Inactivate)"
        [appPermission]="params?.callerScreen===callerScreen.CarrierProfile ? permission.ActiveInactiveCapacityOnCarrierProfile:permission.ActiveInactiveCapacity"
        [module]="params?.callerScreen===callerScreen.CarrierProfile ? module.CarrierProfile : module.CapacityProfile">
        <span class="material-symbols-outlined">
            do_not_disturb_on
        </span>
    </a>

    <a *ngIf="params?.node?.data?.capacityStatus=== capacityStatus.Inactive" href="javascript:void(0);"
        tooltipPosition="left" pTooltip="Activate" class="text-secondary mr-2"
        (click)="onUpdateCapcityStatus(capcityStatusAction.Activate)"
        [appPermission]="params?.callerScreen===callerScreen.CarrierProfile ? permission.ActiveInactiveCapacityOnCarrierProfile : permission.ActiveInactiveCapacity"
        [module]="params?.callerScreen===callerScreen.CarrierProfile ? module.CarrierProfile : module.CapacityProfile">
        <span class="material-symbols-outlined">
            check_circle
        </span>
    </a>

    <a *ngIf="params?.callerScreen !== callerScreen.CarrierProfile" href="javascript:void(0);" tooltipPosition="left"
        pTooltip="Clone Capacity" class="text-primary mr-2" [appPermission]="permission.CloneCapacity"
        [module]="module.CapacityProfile" (click)="openCloneCapacityDialog()">
        <span class="material-symbols-outlined">
            file_copy
        </span>
    </a>
</div>

<div *ngIf="params?.action === 'award-waterfall-capacity' && params?.canRemoveWaterfallCapacity">
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Remove"
        class="btn btn-action btn-link p-0 text-danger mr-1" [disabled]="!params?.isAwardEdit"
        (click)="onRemoveWaterfallCapacity()">
        <span class="material-symbols-outlined text-danger">
            delete
        </span>
    </button>
</div>

<div *ngIf="params?.action === 'award-submitted-capacity'">
    <a *ngIf="params?.canApproveRejectCapacity && params?.node?.data?.status === awardSubmittedCapacityStatus.Pending && params?.approveAvailable"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Approve" class="text-primary mr-2"
        (click)="onApproveSubmittedCapacity()">
        <span class="material-symbols-outlined">
            check_circle
        </span>
    </a>

    <a *ngIf="params?.canApproveRejectCapacity && params?.node?.data?.status === awardSubmittedCapacityStatus.Pending"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Reject" class="text-danger mr-2"
        (click)="onRejectSubmittedCapacity()">
        <span class="material-symbols-outlined">
            cancel
        </span>
    </a>

    <a *ngIf="params?.node?.data?.hasResubmitCapacityPermission && params?.node?.data?.status === awardSubmittedCapacityStatus.Rejected"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Resubmit" class="text-secondary mr-2"
        (click)="onResubmitSubmittedCapacity()">
        <span class="material-symbols-outlined text-black">
            sync
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.CustomerAccessorial">
    <a *ngIf="((params?.permissions?.canViewCustomAccessorial && params?.permissions?.canEditCustomAccessorial)  || params?.permissions?.hasImplicitPermission)"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit" class="text-primary mr-2" (click)="edit()">
        <span class="material-symbols-outlined">
            edit_square
        </span>
    </a>

    <a *ngIf="((params?.permissions?.canActiveInactiveCustomAccessorial && params?.permissions?.canViewCustomAccessorial)  || params?.permissions?.hasImplicitPermission)"
        href="javascript:void(0);" tooltipPosition="left"
        pTooltip="{{params?.node?.data?.isActive || params?.node?.data?.active ? activeInactiveStatus.Inactivate:activeInactiveStatus.Activate}}"
        [ngClass]="{'text-danger':params?.node?.data?.isActive,'text-success':!params?.node?.data?.isActive}"
        class="mr-2" (click)="updateStatus()">
        <span *ngIf="params?.node?.data?.isActive; else activeNode"
            class="material-symbols-outlined">remove_circle_outline</span>
        <ng-template #activeNode>
            <span class="material-symbols-outlined">
                check_circle
            </span>
        </ng-template>
    </a>
</div>

<div *ngIf="params?.action === 'award-potential-capacity' && params?.canNotifyRep">
    <button
        [disabled]="params?.node?.data?.isSubmittedCapacity && (params?.node?.data?.capacityStatus === awardSubmittedCapacityStatus.Pending || params?.node?.data?.capacityStatus === awardSubmittedCapacityStatus.Approved)"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Notify Rep"
        class="btn btn-action btn-link p-0 text-primary mr-2" (click)="onNotifyRep()">
        <span class="material-symbols-outlined">
            notification_important
        </span>
    </button>
</div>

<div *ngIf="params?.action === 'capacity-potential-award' && params?.canSubmitCapacityOnPotentialAwards">
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Submit Capacity"
        class="btn btn-action btn-link p-0 text-primary mr-2"
        [disabled]="(params?.node?.data?.awardStatus !== awardStatus.Validated && params?.node?.data?.awardStatus !== awardStatus.Active) || params?.node?.data?.isSubmittedCapacity"
        (click)="onSubmitCapacityOnPotentialAward()">
        <span class="material-symbols-outlined">
            quick_reference
        </span>
    </button>
</div>

<div *ngIf="params?.action === 'fsc-action'">
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Download"
        class="btn btn-action btn-link text-cover mr-1" (click)="downloadManageFuelSurchargeDocument()"
        [appPermission]="params?.downloadPermission" [module]="params?.module">
        <span class="material-symbols-outlined">
            download
        </span>
    </button>
</div>

<div *ngIf="params?.action === 'manage-carrier-fsc'">
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Download"
        class="btn btn-action btn-link text-cover mr-1" (click)="downloadDocument()">
        <span class="material-symbols-outlined">
            download
        </span>
    </button>
</div>

<div>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Download"
        class="btn btn-action btn-link text-cover mr-1" (click)="downloadBidDocument()"
        *ngIf="params?.action === 'bid-document'">
        <span class="material-symbols-outlined">
            system_update_alt
        </span>
    </button>
</div>

<div *ngIf="params?.module === this.module.CheckCall">
    <a *ngIf="params?.node?.data?.ediIntegrationLogId && params?.node?.data?.ediIntegrationLogId > 0"
        href="javascript:void(0);" tooltipPosition="left" pTooltip="Resend" class="text-primary mr-2"
        (click)="onResendCheckCallClick()">
        <span class="material-symbols-outlined">
            redo
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.CustomerProfile ">
    <a *ngIf="(params?.permissions?.viewInvoicePermission  || params?.permissions?.hasImplicitPermission)"
        (click)="viewInvoice()">
        <span class="material-symbols-outlined">
            request_quote
        </span>
    </a>
</div>

<div *ngIf="params?.module === this.module.ReviewCarrier">
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Approve" class="text-primary mr-2"
        [appPermission]="params?.approveRejectPermission" [module]="params?.module" (click)="approveRequest()"
        *ngIf="params?.action === 'load-review-carrier'">
        <span class="material-symbols-outlined text-success">
            check_circle
        </span>
    </a>
    <a href="javascript:void(0);" tooltipPosition="left" pTooltip="Reject" class="text-danger mr-2"
        [appPermission]="params?.approveRejectPermission" [module]="params?.module" (click)="rejectRequest()"
        *ngIf="params?.action === 'load-review-carrier'">
        <span class="material-symbols-outlined text-danger">
            cancel
        </span>
    </a>
</div>

<div>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Edit"
        class="btn btn-action btn-link p-0 text-primary mr-2" (click)="editFleet()"
        *ngIf="params?.action === 'fleet-size-configuration' && !params?.data?.isEditing">
        <span class="material-symbols-outlined">
            edit
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Save"
        class="btn btn-action btn-link p-0 text-primary mr-2" (click)="saveFleet()"
        *ngIf="params?.action === 'fleet-size-configuration' && params?.data?.isEditing">
        <span class="material-symbols-outlined">
            save
        </span>
    </button>
    <button href="javascript:void(0);" tooltipPosition="left" pTooltip="Cancel"
        class="btn btn-action btn-link p-0 text-primary mr-2" (click)="cancelFleet()"
        *ngIf="params?.action === 'fleet-size-configuration' && params?.data?.isEditing">
        <span class="material-symbols-outlined text-danger">
            cancel
        </span>
    </button>
</div>