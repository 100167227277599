<div [formGroup]="formName">
    <label for="{{ elementId }}" [ngClass]="{'disable':control.disabled}">
        {{ hideLabel ? '' : label }}
        <em *ngIf="isRequired && !hideLabel" class="asterisk"></em>
        <em *ngIf="iTagData !== ''" class="ml-2 pi pi-info-circle" tooltipEvent="hover|focus" tabindex="0"
            [attr.aria-label]="iTagData">
        </em>
    </label>
    <span class="p-input-icon-right d-block">
        <p-inputNumber *ngIf="!isCurrencyMode" class="form-control-inputnumber {{ className }}"
            [attr.autofocus]="isAutoFocus" [inputId]="elementId" [max]="max" [min]="min" name="{{ controlName }}"
            autocomplete="new-password" data-placement="bottom" data-trigger="manual" [placeholder]="placeholder"
            [formControlName]="controlName" [attr.disabled]="disabled ? '' : null"
            [minFractionDigits]="minFractionDigits" [attr.aria-required]="isRequired ? 'true' : null"
            [attr.aria-invalid]="isValid" [mode]="mode" [attr.aria-describedby]="'err-' + elementId" (blur)="onBlur()"
            [readonly]="readOnly" [locale]="locale" (onInput)="onInput()"></p-inputNumber>

        <p-inputNumber *ngIf="isCurrencyMode && !isNegative" class="form-control-inputnumber {{ className }}" [mode]="mode"
            [attr.autofocus]="isAutoFocus" [inputId]="elementId" [currency]="currency" [max]="max" [min]="min"
            name="{{ controlName }}" autocomplete="new-password" data-placement="bottom" data-trigger="manual"
            [placeholder]="placeholder" [formControlName]="controlName" [attr.disabled]="disabled ? '' : null"
            [attr.aria-required]="isRequired ? 'true' : null" [attr.aria-invalid]="isValid"
            [attr.aria-describedby]="'err-' + elementId" (onBlur)="onBlur()" [readonly]="readOnly" [locale]="locale"
            (onInput)="onInput()"></p-inputNumber>

        <p-inputNumber *ngIf="isCurrencyMode && isNegative" class="form-control-inputnumber {{ className }}" [mode]="mode"
            [attr.autofocus]="isAutoFocus" [inputId]="elementId" [currency]="currency" [max]="max"
            name="{{ controlName }}" autocomplete="new-password" data-placement="bottom" data-trigger="manual"
            [placeholder]="placeholder" [formControlName]="controlName" [attr.disabled]="disabled ? '' : null"
            [attr.aria-required]="isRequired ? 'true' : null" [attr.aria-invalid]="isValid"
            [attr.aria-describedby]="'err-' + elementId" (onBlur)="onBlur()" [readonly]="readOnly" 
            [locale]="locale" (onInput)="onInput()"></p-inputNumber>
    </span>

    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</div>