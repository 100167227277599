import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { ResponseModel } from '@app/core/models/api.response.model';
import { SelectListModel } from '@app/core/models/select.list.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations';
import { LocationModel, SelectListLocationItem } from './location.model';
import { ZipCodeResponseModel } from '@app/core/models/zipcode.model';
import { Endpoint } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';

@Injectable()
export class LocationService {
  administrationEndpoint: string;
  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.administrationEndpoint = this.appConfigService.getEndpoint(Endpoint.Administration)
  }

  getLocations(): Observable<ResponseModel<LocationModel[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.location.getLocationList}`;
    return this.apiClient.get<ResponseModel<LocationModel[]>>(url);
  }

  getLocationSelectList(locationId?: string): Observable<ResponseModel<SelectListLocationItem[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.location.getLocationSelectList}`;
    url = url + (locationId ? `?locationId=${locationId}` : ``);
    return this.apiClient.get<ResponseModel<SelectListLocationItem[]>>(url);
  }

  createLocation(payload: LocationModel): Observable<ResponseModel<LocationModel>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.location.createLocation}`;
    return this.apiClient.post<LocationModel, ResponseModel<LocationModel>>(url, payload);
  }

  updateLocation(payload: LocationModel): Observable<ResponseModel<LocationModel>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.location.updateLocation}`;
    return this.apiClient.put<LocationModel, ResponseModel<LocationModel>>(url, payload);
  }

  updateLocationStatus(isActive: boolean, locationId: number): Observable<ResponseModel<LocationModel>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.location.updateLocationStatus}`;
    url = String.format(url, isActive, locationId, LoginHelper.GetUserDetail().userId);
    return this.apiClient.put<undefined, ResponseModel<LocationModel>>(url);
  }

  getZipCodeSelectList(payload: GridFilterRequestModel, isDistinct = false): Observable<ResponseModel<ZipCodeResponseModel[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.zipCode.getZipCodeSelectList}`;
    url = String.format(url, isDistinct);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<ZipCodeResponseModel[]>>(url, payload);
  }

  getTimezoneSelectList(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.administrationEndpoint}${API_ROUTES.administration.location.getTimezoneSelectList}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }
}
