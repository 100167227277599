<div [formGroup]="formName">
    <label *ngIf="!hideLabel" for="{{ elementId }}" [ngClass]="{'disable':control.disabled}">
        {{ label }}
        <em *ngIf="isRequired" class="asterisk"></em><em *ngIf="iTagData !== ''" class="ml-2 pi pi-info-circle"
            tooltipEvent="hover|focus" tabindex="0" [attr.aria-label]="iTagData">
        </em>
    </label>
    <textarea rows="{{rows}}" cols="50" class="form-control resize-none {{ className }}" [id]="elementId"
        [maxlength]="maxLength" data-placement="bottom" data-trigger="manual" [placeholder]="placeholder"
        [formControlName]="controlName" [attr.disabled]="disabled ? '' : null" [attr.autofocus]="isAutoFocus"
        [attr.aria-describedby]="'err-' + elementId" (blur)="onBlur()">
    </textarea>

    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</div>