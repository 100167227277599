import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styles: [
  ]
})
export class ErrorMessageComponent {
  @Input() control!: AbstractControl;
  @Input() label!: string;
  @Input() elementId!: string;

  get errorMessage(): string | null {
    if (this.control) {
      for (const propertyName in this.control.errors) {
        if (
          Object.prototype.hasOwnProperty.call(this.control.errors, propertyName) &&
          this.control.dirty
        ) {
          return ValidationService.getMessage(
            propertyName,
            this.control.errors[propertyName],
            this.label
          );
        }
      }
    }
    return null;
  }
}
