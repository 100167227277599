import { Constants } from "./constants";
import { APIVersion } from "./enums";

export const API_ROUTES = {
    administration: {
        accessorial: {
            getAccessorialList: `/${APIVersion.V1}/Accessorial/List`,
            getAccessorialById: `/${APIVersion.V1}/Accessorial/Get?accessorialId={0}`,
            getAccessorialSelectList: `/${APIVersion.V1}/Accessorial/SelectList`,
            getAccessorialFilterSelectList: `/${APIVersion.V1}/Accessorial/SelectList?filterText={0}&accessorialId={1}`,
            createAccessorial: `/${APIVersion.V1}/Accessorial/Create`,
            updateAccessorial: `/${APIVersion.V1}/Accessorial/Update`,
            updateAccessorialStatus: `/${APIVersion.V1}/Accessorial/UpdateStatus?isActive={0}&accessorialId={1}&modifiedBy={2}`,
            getAccessorialListUseInPurchaseJournal: `/${APIVersion.V1}/Accessorial/SelectListUseInPurchaseJournal?accessorialId={0}`,
            getCustomerAccessorialFilterSelectList: `/${APIVersion.V1}/Accessorial/SelectList?filterText={0}&accessorialId={1}&isCustomAccessorialTypeAhead=true`,
            getNetSuiteItems: `/${APIVersion.V1}/Accessorial/SelectNetSuiteLineItemList`,
        },
        department: {
            getDepartmentList: `/${APIVersion.V1}/Department/List`,
            getDepartmentById: `/${APIVersion.V1}/Department/Get?departmentId={0}`,
            getDepartmentSelectList: `/${APIVersion.V1}/Department/SelectList`,
            createDepartment: `/${APIVersion.V1}/Department/Create`,
            updateDepartment: `/${APIVersion.V1}/Department/Update`,
            updateDepartmentStatus: `/${APIVersion.V1}/Department/UpdateStatus?isActive={0}&departmentId={1}&modifiedBy={2}`,
        },
        equipment: {
            getEquipmentList: `/${APIVersion.V1}/Equipment/List`,
            getEquipmentById: `/${APIVersion.V1}/Equipment/Get?equipmentId={0}`,
            getEquipmentSelectList: `/${APIVersion.V1}/Equipment/SelectList`,
            createEquipment: `/${APIVersion.V1}/Equipment/Create`,
            updateEquipment: `/${APIVersion.V1}/Equipment/Update`,
            updateEquipmentStatus: `/${APIVersion.V1}/Equipment/UpdateStatus?isActive={0}&equipmentId={1}&modifiedBy={2}`,
            getDatEquipmentSelectList: `/${APIVersion.V1}/Equipment/DatEquipmentSelectList?datEquipmentId={0}`,
            getDatEquipmentCategorySelectList: `/${APIVersion.V1}/Equipment/DatEquipmentCategorySelectList?datEquipmentCategoryId={0}`,
            getParadeEquipmentSelectList: `/${APIVersion.V1}/Equipment/ParadeEquipmentSelectList?paradeEquipmentId={0}`,
            getMcroPointEquipmentSelectList: `/${APIVersion.V1}/Equipment/MacroPointEquipmentSelectList?macroPointEquipmentId={0}`
        },
        location: {
            getLocationList: `/${APIVersion.V1}/Location/List`,
            getLocationById: `/${APIVersion.V1}/Location/Get?locationId={0}`,
            getLocationSelectList: `/${APIVersion.V1}/Location/SelectList`,
            createLocation: `/${APIVersion.V1}/Location/Create`,
            updateLocation: `/${APIVersion.V1}/Location/Update`,
            updateLocationStatus: `/${APIVersion.V1}/Location/UpdateStatus?isActive={0}&locationId={1}&modifiedBy={2}`,
            getTimezoneSelectList: `/${APIVersion.V1}/Timezone/SelectList`,
        },
        configuration: {
            getConfiguration: `/${APIVersion.V1}/ConfigurationDetail/List?moduleId={0}`,
            updateConfiguration: `/${APIVersion.V1}/ConfigurationDetail/Update`,
            getConfigurationValue: `/${APIVersion.V1}/ConfigurationDetail/Get`,
            syncPaymentTermsOrLineItems: `/${APIVersion.V1}/ConfigurationDetail/SyncPaymentTermsOrLineItems?isPaymentTerms={0}`,
            getConfigurationCache: `/${APIVersion.V1}/ConfigurationDetail/GetConfigurationCache`
        },
        module: {
            getModuleList: `/${APIVersion.V1}/Module/List`,
        },
        zipCode: {
            getCurrentDateTimeByCityState: `/${APIVersion.V1}/ZipCode/GetCurrentDateTimeByCityState?city={0}&state={1}`,
            getCTDateTimeByCityState: `/${APIVersion.V1}/ZipCode/GetCTDateTimeByCityState?city={0}&state={1}&dateTime={2}`,
            getZipCodeSelectList: `/${APIVersion.V1}/ZipCode/SelectList?isDistinct={0}`
        },
        region: {
            getRegionSelectList: `/${APIVersion.V1}/Region/SelectList`
        },
        exception: {
            getExceptionList: `/${APIVersion.V1}/Exception/List`
        },
        notification: {
            getNotificationList: `/${APIVersion.V1}/Notification/List`,
            updateNotification: `/${APIVersion.V1}/Notification/Update`,
            addViewListActivityLog: `/${APIVersion.V1}/Notification/AddViewListActivityLog`,
            getNotificationContent: `/${APIVersion.V1}/Notification/Get?code={0}`,
        },
        reportDocument: {
            getDocumentTypeSelectList: `/${APIVersion.V1}/ReportDocument/DocumentTypeSelectList`,
            uploadDocument: `/${APIVersion.V1}/ReportDocument/UploadDocument`,
            downloadDocument: `/${APIVersion.V1}/ReportDocument/DownloadDocument?documentPath={0}&documentId={1}`,
            getDocumentList: `/${APIVersion.V1}/ReportDocument/DocumentList`,
            addViewActivityLog: `/${APIVersion.V1}/ReportDocument/AddViewActivityLog`,
        },
        manageFuelSurchargeDocument: {
            downloadDocument: `/${APIVersion.V1}/ManageFuelSurcharge/Download?documentId={0}`,
            getDocumentList: `/${APIVersion.V1}/ManageFuelSurcharge/List`,
            uploadDocument: `/${APIVersion.V1}/ManageFuelSurcharge/Upload`,
            getFuelSurchargeRateDetailFromAdministration: `/${APIVersion.V1}/ManageFuelSurcharge/GetFuelSurchargeRateDetail?eiaRate={0}`,
        }
    },
    identity: {
        sso: {
            login: `/${APIVersion.V1}/sso/login`,
            getToken: `/${APIVersion.V1}/sso/token?email={0}`,
            logout: `/${APIVersion.V1}/sso/logout`,
            refreshToken: `/${APIVersion.V1}/${Constants.refreshTokenURL}?userId={0}`,
        },
        role: {
            getRoleList: `/${APIVersion.V1}/Role/List`,
            getRoleById: `/${APIVersion.V1}/Role/Get?roleId={0}`,
            getRoleSelectList: `/${APIVersion.V1}/Role/SelectList`,
            createRole: `/${APIVersion.V1}/Role/Create`,
            updateRole: `/${APIVersion.V1}/Role/Update`,
            updateRoleStatus: `/${APIVersion.V1}/Role/UpdateStatus?isActive={0}&roleId={1}&modifiedBy={2}`
        },
        roleLandingPage: {
            getRoleLandingPageSelectList: `/${APIVersion.V1}/RoleLandingPage/SelectList`,
        },
        permission: {
            getPermissionList: `/${APIVersion.V1}/permission/List`,
        },
        rolePermission: {
            getRolePermissions: `/${APIVersion.V1}/RolePermission/Get?roleId={0}`,
            createRolePermission: `/${APIVersion.V1}/RolePermission/Create`,
        },
        user: {
            getUserList: `/${APIVersion.V1}/User/List`,
            getUserById: `/${APIVersion.V1}/User/Get?userId={0}`,
            createUser: `/${APIVersion.V1}/User/Create`,
            updateUser: `/${APIVersion.V1}/User/Update`,
            updateUserStatus: `/${APIVersion.V1}/User/UpdateStatus?isActive={0}&userId={1}&modifiedBy={2}`,
            getUserByDepartmentCode: `/${APIVersion.V1}/User/GetUserByDepartmentCode?departmentCode={0}&userIds={1}`,
            getUserByDepartmentCodeTypeAhead: `/${APIVersion.V1}/User/GetUserByDepartmentCodeTypeAhead?departmentCode={0}&userName={1}`,
            getDatUserName: `/${APIVersion.V1}/User/GetDatUserName?userId={0}`,
        },
        userPermission: {
            getUserDetail: `/${APIVersion.V1}/UserPermission/Get`,
        },
        userViews: {
            getViewSelectList: `/${APIVersion.V1}/UserView/SelectList?module={0}&callerScreen={0}`,
            createView: `/${APIVersion.V1}/UserView/Create?callerScreen={0}`,
            updateView: `/${APIVersion.V1}/UserView/Update?callerScreen={0}`,
            deleteView: `/${APIVersion.V1}/UserView/Delete?userViewId={0}&callerScreen={0}`,
            checkDuplicateViewName: `/${APIVersion.V1}/UserView/UserViewNameExists?userViewName={0}&userViewId={1}&module={2}`,
            getUserViews: `/${APIVersion.V1}/UserView/List?roleID={0}&callerScreen={1}`,
            assignUserViews: `/${APIVersion.V1}/UserView/AssignViews?roleId={0}`,
        },
        userNotification: {
            getUserNotificationList: `/${APIVersion.V1}/UserNotification/UserNotificationList`,
            getUnreadUserNotificationCount: `/${APIVersion.V1}/UserNotification/GetUnreadUserNotificationCount?userId={0}`,
            updateUserNotification: `/${APIVersion.V1}/UserNotification/Update`,
        },
        userScoreCard: {
            getSalesScoreCard: `/${APIVersion.V1}/UserScoreCard/GetSalesScoreCard?userId={0}`,
        },
    },
    customer: {
        getCustomerList: `/${APIVersion.V1}/Customer/List`,
        getCustomerById: `/${APIVersion.V1}/Customer/Get?customerId={0}`,
        createCustomer: `/${APIVersion.V1}/Customer/Create`,
        updateCustomer: `/${APIVersion.V1}/Customer/Update`,
        checkDuplicateCustomerCode: `/${APIVersion.V1}/Customer/CheckDuplicateCustomerCode?customerCode={0}&customerId={1}`,
        updateCustomerStatus: `/${APIVersion.V1}/Customer/UpdateStatus?isActivateStatus={0}&customerId={1}&reasonId={2}`,
        updateCustomerProfileStatus: `/${APIVersion.V1}/Customer/Profile/UpdateStatus?isActivateStatus={0}&customerId={1}&reasonId={2}`,
        getContactTierSelectList: `/${APIVersion.V1}/ContactTier/SelectList`,
        getAccountOwnerSelectList: `/${APIVersion.V1}/User/SelectList`,
        getDepartmentSelectList: `/${APIVersion.V1}/Department/SelectList`,
        getCustomerTierSelectList: `/${APIVersion.V1}/CustomerTier/SelectList`,
        getIndustryTypePageSelectList: `/${APIVersion.V1}/Type/SelectList`,
        getTitleSelectList: `/${APIVersion.V1}/Title/SelectList`,
        getCustomerSelectList: `/${APIVersion.V1}/Customer/SelectList?customerName={0}`,
        getCustomerDetail: `/${APIVersion.V1}/Customer/GetCustomerDetail?customerId={0}`,
        updateStakeholder: `/${APIVersion.V1}/CustomerStakeholder/Update`,
        getReasonList: `/${APIVersion.V1}/Reason/SelectList`,
        checkCustomerCredit: `/${APIVersion.V1}/CreditRequest/CheckCreditRequestDetails?customerId={0}`,
        creditRequest: `/${APIVersion.V1}/CreditRequest/Create`,
        getCreditRequestList: `/${APIVersion.V1}/CreditRequest/List`,
        updateCreditRequestStatus: `/${APIVersion.V1}/CreditRequest/UpdateStatus`,
        getAccountingCustomerById: `/${APIVersion.V1}/CustomerAccounting/GetByCustomerId?customerId={0}`,
        getCustomerListByName: `/${APIVersion.V1}/Customer/SelectList?customerName={0}`,
        checkDuplicateCustomerName: `/${APIVersion.V1}/Customer/CheckDuplicateCustomerName?customerName={0}&customerId={1}`,
        getCustomerStatusSelectList: `/${APIVersion.V1}/CustomerStatus/SelectList`,
        updateCustomerCredit: `/${APIVersion.V1}/CustomerAccounting/CustomerCreditDetail/Update?customerId={0}`,
        getCustomerDocumentConfiguration: `/${APIVersion.V1}/Config/Document`,
        getCustomerDocumentList: `/${APIVersion.V1}/CustomerDocument/List?customerId={0}`,
        getCustomerDocumentTypeList: `/${APIVersion.V1}/DocumentType/SelectList`,
        downloadCustomerDocument: `/${APIVersion.V1}/CustomerDocument/Download?documentId={0}`,
        deleteCustomerDocument: `/${APIVersion.V1}/CustomerDocument/Delete?documentId={0}&customerId={1}`,
        uploadCustomerDocument: `/${APIVersion.V1}/CustomerDocument/Upload`,
        uploadFuelSurchargeDocument: `/${APIVersion.V1}/CustomerFuelSurchargeDocument/Upload`,
        downloadFuelSurchargeDocument: `/${APIVersion.V1}/CustomerFuelSurchargeDocument/Download?documentId={0}&customerId={1}`,
        getCustomerFuelSurchargeList: `/${APIVersion.V1}/CustomerFuelSurchargeDocument/List?customerId={0}`,
        updateManageFuelSurcharge: `/${APIVersion.V1}/Customer/UpdateManageFuelSurcharge?customerId={0}&canManageFuelSurcharge={1}`,
        lockCustomer: `/${APIVersion.V1}/CustomerProfile/Lock?customerId={0}`,
        unlockCustomer: `/${APIVersion.V1}/CustomerProfile/Unlock?customerId={0}&isBroadCastOnly={1}&unlockTimeInSec={2}&isCancelButtonPressed={3}&lockedBy={4}`,
        customerLockDetails: `/${APIVersion.V1}/CustomerProfile/GetLockDetails?customerId={0}`,
        getCustomerRequirements: `/${APIVersion.V1}/CustomerRequirements/Get?customerId={0}`,
        checkCustomerExists: `/${APIVersion.V1}/Customer/GlobalSearch?customerDetail={0}`,
        getActivityTypeSelectList: `/${APIVersion.V1}/ActivityType/SelectList`,
        getCustomerAuditLogList: `/${APIVersion.V1}/Audit/Search`,
        addActivityLog: `/${APIVersion.V1}/Customer/AddActivityLog?customerId={0}&tabName={1}`,
        addDocumentLog: `/${APIVersion.V1}/CustomerDocument/AddAuditLog?customerId={0}&documentId={1}`,
        getCustomerDetailForLoad: `/${APIVersion.V1}/Customer/GetCustomerByIdForLoad?customerId={0}`,
        getAccountingCustomerByIdForLoad: `/${APIVersion.V1}/CustomerAccounting/GetByCustomerIdForLoad?customerId={0}`,
        getCustomerScoreCard: `/${APIVersion.V1}/Customer/GetCustomerScorecard?customerId={0}`,
        updateLinehaulAndFuelSurcharge: `/${APIVersion.V1}/CustomerAccessorial/UpdateLinehaulAndFuelSurcharge?linehaul={0}&fuelSurcharge={1}&customerId={2}&isAddButtonPressed={3}`,
        addCustomerAccessorial: `/${APIVersion.V1}/CustomerAccessorial/AddCustomerAccessorial`,
        updateCustomerAccessorial: `/${APIVersion.V1}/CustomerAccessorial/UpdateCustomerAccessorial`,
        getCustomerAccessorialList: `/${APIVersion.V1}/CustomerAccessorial/GetAllCustomerAccessorial`,
        updateCustomAccessorialStatus: `/${APIVersion.V1}/CustomerAccessorial/UpdateCustomerAccessorialStatus?customerAccessorialId={0}&customerId={1}&isActive={2}`,
        checkDuplicateCustomerAccessorialCustomName: `/${APIVersion.V1}/CustomerAccessorial/CheckDuplicateAccessorialCustomName?customName={0}&customerId={1}&customerAccessorialId={2}`,
        checkDuplicateCustomerAccessorialNameCode: `/${APIVersion.V1}/CustomerAccessorial/CheckDuplicateAccessorialMapping?accessorialId={0}&name={1}&code={2}&customerId={3}&customerAccessorialId={4}`,
        checkDuplicateBreakthroughId: `/${APIVersion.V1}/CustomerRequirements/CheckDuplicateBreakthroughId?customerRequirementsId={0}&breakthroughId={1}`,
        customerActivity: {
            getCustomerActivityList: `/${APIVersion.V1}/CustomerActivity/List?customerId={0}`,
            createCustomerActivity: `/${APIVersion.V1}/CustomerActivity/Create`,
            updateCustomerActivity: `/${APIVersion.V1}/CustomerActivity/Update`,
            deleteCustomerActivity: `/${APIVersion.V1}/CustomerActivity/Delete?activityId={0}&customerId={1}`,
        },
        customerFuelSurchargeDocument: {
            getFuelSurchargeRateDetail: `/${APIVersion.V1}/CustomerFuelSurchargeDocument/GetFuelSurchargeRateDetail?customerId={0}&eiaRate={1}`,
        },
        sendExportCsvFilePayloadToServiceBus: `/${APIVersion.V1}/Customer/SendExportCsvFilePayloadToServiceBus`,
        getEDIDetailsByCustomerId: `/${APIVersion.V1}/Customer/GetEDIDetailsByCustomerId?customerId={0}`,
    },
    customerContact: {
        getCustomerContactList: `/${APIVersion.V1}/CustomerContact/GetByCustomerId?customerId={0}`,
        createCustomerContact: `/${APIVersion.V1}/CustomerContact/Create`,
        updateCustomerContact: `/${APIVersion.V1}/CustomerContact/Update`,
    },
    carrier: {
        getCarrierList: `/${APIVersion.V1}/Carrier/List`,
        createCarrier: `/${APIVersion.V1}/Carrier/Create`,
        updateCarrier: `/${APIVersion.V1}/Carrier/Update`,
        getPreviewData: `/${APIVersion.V1}/Carrier/PreviewData?docketNumber={0}&dotNumber={1}`,
        getCarrierById: `/${APIVersion.V1}/Carrier/Get?carrierId={0}`,
        getCarrierTierList: `/${APIVersion.V1}/CarrierTier/SelectList`,
        getCarrierOwnershipList: `/${APIVersion.V1}/CarrierOwnership/SelectList`,
        getUserList: `/${APIVersion.V1}/User/SelectList`,
        getCarrierContactTierList: `/${APIVersion.V1}/CarrierContactTier/SelectList`,
        getCarrierContactTitleList: `/${APIVersion.V1}/CarrierContactTitle/SelectList`,
        getCarrierContactDepartmentList: `/${APIVersion.V1}/CarrierContactDepartment/SelectList`,
        getCarrierEmailList: `/${APIVersion.V1}/Carrier/CarrierEmailList?carrierId={0}`,
        inviteCarrier: `/${APIVersion.V1}/Carrier/InviteCarrier`,
        getCarrierRejectReasonList: `/${APIVersion.V1}/CarrierRejectReason/SelectList`,
        rejectCarrier: `/${APIVersion.V1}/Carrier/RejectCarrier?carrierId={0}&reasonId={1}`,
        getComplianceDataByCarrierId: `/${APIVersion.V1}/Carrier/ComplianceData?carrierId={0}`,
        getCarrierComplianceDocument: `/${APIVersion.V1}/Carrier/GetComplianceDocument?documentDetail={0}`,
        syncCarrierDataByCarrierId: `/${APIVersion.V1}/Carrier/SyncCarrierData?carrierId={0}`,
        approveCarrier: `/${APIVersion.V1}/Carrier/ApproveCarrier?carrierId={0}`,
        carrierSelectList: `/${APIVersion.V1}/Carrier/CarrierPreview/SelectList`,
        getCarrierInsurance: `/${APIVersion.V1}/Carrier/CarrierInsurance?carrierId={0}`,
        getCarriersById: `/${APIVersion.V1}/Carrier/CarrierList?{0}`,
        getCarrierStatusList: `/${APIVersion.V1}/CarrierStatus/SelectList`,
        getCarrierDNUReason: `/${APIVersion.V1}/CarrierDNUReason/SelectList`,
        markDNUActiveCarrierStatusFromProfile: `/${APIVersion.V1}/Carrier/profile/UpdateStatus?carrierId={0}&status={1}&reasonId={2}`,
        markDNUActiveCarrierStatus: `/${APIVersion.V1}/Carrier/UpdateStatus?carrierId={0}&status={1}&reasonId={2}`,
        lockCarrier: `/${APIVersion.V1}/CarrierProfile/Lock?carrierId={0}`,
        unlockCarrier: `/${APIVersion.V1}/CarrierProfile/Unlock?carrierId={0}&isBroadcastOnly={1}&unlockTimeInSec={2}&isCancelButtonPressed={3}&lockedBy={4}`,
        getCarrierLockDetails: `/${APIVersion.V1}/CarrierProfile/GetLockDetail?carrierId={0}`,
        getCarrierCapacity: `/${APIVersion.V1}/CarrierCapacity/List`,
        checkDuplicateCarrierCapacity: `/${APIVersion.V1}/CarrierCapacity/CheckDuplicate`,
        createCarrierCapacity: `/${APIVersion.V1}/CarrierCapacity/Create`,
        updateCarrierCapacity: `/${APIVersion.V1}/CarrierCapacity/Update`,
        getActivityTypeSelectList: `/${APIVersion.V1}/ActivityType/SelectList`,
        getCarrierScoreCard: `/${APIVersion.V1}/Carrier/GetCarrierScorecard?carrierId={0}`,
        getCarrierScoreCardKPIRule: `/${APIVersion.V1}/Carrier/GetCarrierScorecardKPIRule`,
        uploadFuelSurchargeDocument: `/${APIVersion.V1}/CarrierFuelSurchargeDocument/Upload`,
        downloadFuelSurchargeDocument: `/${APIVersion.V1}/CarrierFuelSurchargeDocument/Download?documentId={0}&carrierId={1}`,
        getCarrierFuelSurchargeList: `/${APIVersion.V1}/CarrierFuelSurchargeDocument/List?carrierId={0}`,
        updateManageFuelSurcharge: `/${APIVersion.V1}/Carrier/UpdateManageFuelSurcharge?carrierId={0}&canManageFuelSurcharge={1}`,
        factoringCompany: {
            getFactoringCompanyList: `/${APIVersion.V1}/FactoringCompany/SelectList/FactoringCompany`,
            getFactoringCompanyPaymentModeList: `/${APIVersion.V1}/FactoringCompanyPaymentMode/SelectList/PaymentMode`,
            getCarrierPaymentTermList: `/${APIVersion.V1}/CarrierPaymentTerm/SelectList`,
        },
        checkCarrierExists: `/${APIVersion.V1}/Carrier/GlobalSearch?carrierDetail={0}`,
        getFactoringAssociatedCarrier: `/${APIVersion.V1}/CarrierAccounting/CarrierCount?factoringCompanyId={0}`,
        deleteCarrierCapacity: `/${APIVersion.V1}/CarrierCapacity/Delete?carrierCapacityId={0}&carrierId={1}`,
        getCarrierAuditLogList: `/${APIVersion.V1}/Audit/Search`,
        getSalesTouchPoint: `/${APIVersion.V1}/SalesAudit/Search`,
        addActivityLog: `/${APIVersion.V1}/Carrier/AddActivityLog?carrierId={0}&tabName={1}`,
        getCarrierServiceList: `/${APIVersion.V1}/CarrierServices/SelectList`,
        document: {
            getCarrierDocumentTypeList: `/${APIVersion.V1}/DocumentType/SelectList`,
            getCarrierDocumentList: `/${APIVersion.V1}/CarrierDocument/List?carrierId={0}`,
            getCarrierDocumentConfiguration: `/${APIVersion.V1}/Config/Document`,
            uploadCarrierDocument: `/${APIVersion.V1}/CarrierDocument/Upload`,
            deleteCarrierDocument: `/${APIVersion.V1}/CarrierDocument/Delete?documentId={0}&carrierId={1}`,
            downloadCarrierDocument: `/${APIVersion.V1}/CarrierDocument/Download?documentId={0}`,
            addDocumentLog: `/${APIVersion.V1}/CarrierDocument/AddAuditLog?carrierId={0}&documentId={1}`,
        },
        stakeholder: {
            updateStakeholder: `/${APIVersion.V1}/CarrierStakeholder/Update`,
            getStakeholder: `/${APIVersion.V1}/CarrierStakeholder/Get?carrierId={0}`,
        },
        manuallyOnboard: `/${APIVersion.V1}/Carrier/ManuallyOnboard?carrierId={0}&isManuallyOnboarded={1}`,
        carrierActivity: {
            getCarrierActivityList: `/${APIVersion.V1}/CarrierActivity/List?carrierId={0}`,
            createCarrierActivity: `/${APIVersion.V1}/CarrierActivity/Create`,
            updateCarrierActivity: `/${APIVersion.V1}/CarrierActivity/Update`,
            deleteCarrierActivity: `/${APIVersion.V1}/CarrierActivity/Delete?activityId={0}&carrierId={1}`
        },
        preferredLane: {
            getPreferredLaneCapacity: `/${APIVersion.V1}/CarrierPreferredLane/List`,
            createCarrierPreferredLane: `/${APIVersion.V1}/CarrierPreferredLane/Create`,
            updateCarrierPreferredLane: `/${APIVersion.V1}/CarrierPreferredLane/Update`,
            checkDuplicatePreferredLane: `/${APIVersion.V1}/CarrierPreferredLane/CheckDuplicate`,
            updatePreferredLaneStatus: `/${APIVersion.V1}/CarrierPreferredLane/UpdateStatus?carrierPreferredLaneId={0}&carrierId={1}&isActive={2}`,
            renewCarrierPreferredLane: `/${APIVersion.V1}/CarrierPreferredLane/Renew?carrierPreferredLaneId={0}&carrierId={1}&validityDate={2}&validityDateTimeCT={3}`,
        },
        carrierFuelSurchargeDocument: {
            getFuelSurchargeRateDetail: `/${APIVersion.V1}/CarrierFuelSurchargeDocument/GetFuelSurchargeRateDetail?carrierId={0}&eiaRate={1}`,
        },
        sendExportCsvFilePayloadToServiceBus: `/${APIVersion.V1}/Carrier/SendExportCsvFilePayloadToServiceBus`
    },
    load: {
        loadBOL: {
            getLoadBOLList: `/${APIVersion.V1}/LoadBOL/List?callerScreen={0}`,
            createLoadBOL: `/${APIVersion.V1}/LoadBOL/Create`,
            updateLoadBOL: `/${APIVersion.V1}/LoadBOL/Update`,
            deleteLoadBOL: `/${APIVersion.V1}/LoadBOL/Delete?loadBOLId={0}`,
        },
        quantityType: {
            getQuantityTypeSelectList: `/${APIVersion.V1}/QuantityType/SelectList?quantityTypeId={0}`
        },
        pieceType: {
            getPieceTypeSelectList: `/${APIVersion.V1}/PieceType/SelectList?pieceTypeId={0}`
        },
        loadCheckCall: {
            createLoadCheckCall: `/${APIVersion.V1}/LoadCheckCall/Create?callerScreen={0}`,
            revertLoadCheckCall: `/${APIVersion.V1}/LoadCheckCall/Delete?loadDetailId={0}&callerScreen={1}`,
            resendCheckCall: `/${APIVersion.V1}/LoadCheckCall/ResendCheckCallToEDI?ediIntegrationLogId={0}&loadDetailId={1}&callerScreen={2}`,
            revertAllLoadCheckCall: `/${APIVersion.V1}/LoadCheckCall/DeleteAll?loadDetailId={0}&callerScreen={1}`,
            getLoadCheckCalls: `/${APIVersion.V1}/LoadCheckCall/List?callerScreen={0}`,
            getLoadCheckCallSelectList: `/${APIVersion.V1}/LoadCheckCall/SelectList?loadCheckCallStatusId={0}`,
            validateStatus: `/${APIVersion.V1}/LoadCheckCall/ValidateStatus?loadDetailId={0}&loadCheckCallStatusId={1}`,
            getLoadCheckCallEventUpdates: `/${APIVersion.V1}/LoadCheckCall/GetLoadCheckCallEventUpdates?callerScreen={0}`,

        },
        parade: {
            pararePostToggleVisibility: `/${APIVersion.V1}/Parade/PararePostToggleVisibility?loadDetailId={0}&showOnParadeLoadBoard={1}`,
            getCapacityFromParade: `/${APIVersion.V1}/Parade/GetCapacityFromParade?loadDetailId={0}&isAutoSync={1}`
        },
        rateConfirmation:
        {
            getRateConfirmationList: `/${APIVersion.V1}/LoadRateConfirmation/List?loadDetailId={0}`,
            getLoadCarriersList: `/${APIVersion.V1}/LoadRateConfirmation/GetLoadCarrierList?loadDetailId={0}`,
            createRateConfirmation: `/${APIVersion.V1}/LoadRateConfirmation/Create`,
            getCarrierDispatcherList: `/${APIVersion.V1}/Carrier/GetCarrierDispatcherList?carrierId={0}`,
            getRateConfirmation: `/${APIVersion.V1}/LoadRateConfirmation/Get?loadDetailId={0}&loadRateConfirmationGUID={1}&encryptedEmail={2}`,
            updateRateConfirmationEsignDetails: `/${APIVersion.V1}/LoadRateConfirmation/UpdateRateConfirmationEsignDetails`,
            getLoadRateConfirmationList: `/${APIVersion.V1}/LoadRateConfirmation/List?loadDetailId={0}`,
            rateConfirmationAuditLogView: `/${APIVersion.V1}/LoadRateConfirmation/AuditLogEntryOnRateConfirmationPdfView?loadRateConfirmationId={0}`,
            rateConfirmationUserViewActivityLog: `/${APIVersion.V1}/LoadRateConfirmation/AddUserViewRateConfirmationActivityLog?loadDetailId={0}`
        },
        loadDetail: {
            createLoad: `/${APIVersion.V1}/LoadDetail/Create`,
            updateLoad: `/${APIVersion.V1}/LoadDetail/Update?callerScreen={0}`,
            getLoadModeSelectList: `/${APIVersion.V1}/LoadMode/SelectList?loadModeId={0}`,
            getLoadTypeSelectList: `/${APIVersion.V1}/LoadType/SelectList?loadTypeId={0}`,
            getLoadTarpSizeSelectList: `/${APIVersion.V1}/TarpSize/SelectList?tarpSizeId={0}`,
            getReferenceNumberTypeSelectList: `/${APIVersion.V1}/ReferenceNumberType/SelectList?referenceNumberTypeTypeIds={0}`,
            getActivityTypeSelectList: `/${APIVersion.V1}/ActivityType/SelectList`,
            loadNumberExists: `/${APIVersion.V1}/LoadDetail/LoadNumberExists?loadDetailId={0}`,
            getLoadDetailList: `/${APIVersion.V1}/LoadDetail/List`,
            getLoadStatusSummary: `/${APIVersion.V1}/LoadDetail/GetLoadStatusSummary?includeCancelled={0}&includeClosed={1}`,
            lockLoad: `/${APIVersion.V1}/LoadDetail/LockLoad?loadDetailId={0}&userId={1}`,
            unlockLoad: `/${APIVersion.V1}/LoadDetail/UnLockLoad?loadDetailId={0}&userId={1}&unlock={2}&callerScreen={3}`,
            getLoadLockDetails: `/${APIVersion.V1}/LoadDetail/GetLoadLockDetails?loadDetailId={0}`,
            getLoadDetail: `/${APIVersion.V1}/LoadDetail/Get?loadDetailId={0}&callerScreen={1}`,
            getLoadStatusSelectList: `/${APIVersion.V1}/LoadDetail/LoadStatusSelectList`,
            getLoadCancelReasonSelectList: `/${APIVersion.V1}/LoadCancel/GetReasons?reasonType={0}`,
            cancelLoadDetails: `/${APIVersion.V1}/LoadCancel/Cancel?loadDetailId={0}&loadStatusReasonId={1}&loadStatusComment={2}`,
            getCustomerLoadCount: `/${APIVersion.V1}/LoadCustomer/GetCustomerLoadCount?customerId={0}`,
            getTotalCustomerRateForActiveLoads: `/${APIVersion.V1}/LoadDetail/GetTotalCustomerRateForActiveLoads?customerId={0}&loadDetailId={1}`,
            validateReleaseForBilling: `/${APIVersion.V1}/LoadDetail/ValidateLoadToReleaseForBilling?loadDetailId={0}`,
            getLoadInfo: `/${APIVersion.V1}/LoadDetail/GetLoadDetailsForPurchaseJournal?loadDetailId={0}`,
            updateLoadStatus: `/${APIVersion.V1}/LoadDetail/UpdateLoadStatus?loadDetailId={0}&loadStatus={1}`,
            setLoadHighVisibility: `/${APIVersion.V1}/LoadDetail/SetHighVisibility?loadDetailId={0}&highVisibility={1}`,
            getLoadTimeLine: `/${APIVersion.V1}/LoadDetail/GetLoadTimeline?loadDetailId={0}`,
            setLoadBoardAuditLog: `/${APIVersion.V1}/LoadDetail/AuditLogForLoadBoardList`,
            addAuditLog: `/${APIVersion.V1}/LoadDetail/AddViewActivityLog?subModule={0}&sectionName={1}&loadDetailId={2}`,
            updateLoadStatusAndCarrierRep: `/${APIVersion.V1}/LoadDetail/UpdateLoadDetailAndLoadStakeholderEntity`,
            getLoadStakeHolderCarrierOpsRep: `/${APIVersion.V1}/LoadDetail/GetLoadStakeHolderCarrierOpsRep?loadDetailId={0}`,
            getLoadDetailsForBilling: `/${APIVersion.V1}/LoadDetail/GetLoadDetailsForBilling?loadDetailId={0}&carrierId={1}`,
            viewLoadTrailAuditLog: `/${APIVersion.V1}/LoadDetail/ViewLoadTrailAuditLog?loadDetailId={0}`,
            getOpenIncidentCount: `/${APIVersion.V1}/LoadDetail/GetOpenIncidentCount?loadDetailId={0}`,
            geTruckMatchCount: `/${APIVersion.V1}/LoadDetail/GetTruckMatchCount?loadDetailId={0}`,
            viewLoadProfileAuditLog: `/${APIVersion.V1}/LoadDetail/ViewLoadProfileAuditLog?loadDetailId={0}`,
            sendMessageToLoadIntegrationTopic: `/${APIVersion.V1}/LoadDetail/SendMessageToLoadIntegrationTopic?loadDetailId={0}&loadEvent={1}`,
            getLoadRadiusSelectList: `/${APIVersion.V1}/LoadRadius/SelectList`,
            getLoadStopByLoadDetailId: `/${APIVersion.V1}/LoadDetail/GetLoadStopByLoadDetailId?loadDetailId={0}`,
            cloneLoad: `/${APIVersion.V1}/LoadDetail/Clone`,
            bulkReleaseLoad: `/${APIVersion.V1}/LoadDetail/BulkReleaseLoad`,
            validateLoadForBulkRelease: `/${APIVersion.V1}/LoadDetail/ValidateLoadForBulkRelease`,
            globalSearchTypeAhead: `/${APIVersion.V1}/LoadDetail/GlobalSearchTypeAhead`,
            exportLoadListingToCSV: `/${APIVersion.V1}/LoadDetail/ExportToCSV`,
            runLoadQueueOrchestrator: `/${APIVersion.V1}/LoadDetail/RunLoadQueueOrchestrator?loadDetailId={0}`,
            addViewQuickViewAuditLog: `/${APIVersion.V1}/LoadDetail/addViewQuickViewAuditLog?loadDetailId={0}&callerScreen={1}`
        },
        loadCarrier: {
            getReasons: `/${APIVersion.V1}/LoadCarrier/GetReasons?reasonType={0}`,
            getTrackBy: `/${APIVersion.V1}/LoadCarrier/GetTrackBy`,
            bounceLoad: `/${APIVersion.V1}/LoadCarrier/BounceLoad`,
            recoverLoad: `/${APIVersion.V1}/LoadCarrier/RecoverLoad?loadDetailId={0}&loadCarrierId={1}&loadCarrierName={2}`,
            getLoadCarrierList: `/${APIVersion.V1}/LoadCarrier/SelectList?loadDetailId={0}`
        },
        facility: {
            getFacilitySelectList: `/${APIVersion.V1}/LoadFacility/SelectList?code={0}&name={1}&pageSize={2}`
        },
        truckMatch: {
            getLoadHistoryList: `/${APIVersion.V1}/TruckMatch/GetTruckHistory`,
            getTMSTruckList: `/${APIVersion.V1}/TruckMatch/TMSTruck`,
            updateTMSTruckStatus: `/${APIVersion.V1}/TruckMatch/UpdateStatus`,
            getFilteredTruckMatchResultList: `/${APIVersion.V1}/TruckMatch/GetFilteredTruckMatchResultList`,
            updateTruckMatchResultStatus: `/${APIVersion.V1}/TruckMatch/UpdateTruckMatchResultStatus`,
            truckMatchActivityLog: `/${APIVersion.V1}/TruckMatch/AddActivityLogForLoadHistory?loadDetailId={0}`,
            truckMatchGetPreferredLaneCapacity: `/${APIVersion.V1}/TruckMatch/GetPreferredLaneCapacity`,
            truckMatchAddUpdateLoadPreferredLane: `/${APIVersion.V1}/TruckMatch/AddUpdateLoadPreferredLane`
        },
        loadCarrierBid: {
            create: `/${APIVersion.V1}/LoadCarrierBid/Create`,
            approve: `/${APIVersion.V1}/LoadCarrierBid/Approve`,
            reject: `/${APIVersion.V1}/LoadCarrierBid/Reject`,
            getReasons: `/${APIVersion.V1}/LoadCarrierBid/GetReasons`,
            list: `/${APIVersion.V1}/LoadCarrierBid/List`,
            getApprovedBidAmount: `/${APIVersion.V1}/LoadCarrierBid/ApprovedBidAmount?loadDetailId={0}&loadCarrierId={1}`,
            auditLogForAssignCapacity: `/${APIVersion.V1}/LoadCarrierBid/AuditLogForAssignCapacity?loadDetailId={0}&carrierCapacity={1}&carrierBidId={2}`,
        },
        document: {
            uploadDocument: `/${APIVersion.V1}/LoadDocument/UploadDocument?callerScreen={0}`,
            getDocumentTypeSelectList: `/${APIVersion.V1}/LoadDocument/DocumentTypeSelectList`,
            getDocumentConfigurationDetails: `/${APIVersion.V1}/LoadDocument/DocumentConfigurationDetails`,
            getDocumentList: `/${APIVersion.V1}/LoadDocument/DocumentList?loadDetailId={0}&callerScreen={1}`,
            deleteDocument: `/${APIVersion.V1}/LoadDocument/DeleteDocument?documentId={0}&documentPath={1}&callerScreen={2}&loadDetailId={3}`,
            downloadDocument: `/${APIVersion.V1}/LoadDocument/DownloadDocument?documentPath={0}&documentId={1}&loadDetailId={2}&callerScreen={3}`,
            viewDocumentAuditLog: `/${APIVersion.V1}/LoadDocument/ViewDocumentAuditLog?documentId={0}`,
            updateDocument: `/${APIVersion.V1}/LoadDocument/UpdateDocument?callerScreen={0}`
        },
        hereMap: {
            getLoadStopMileage: `/${APIVersion.V1}/HereMap/GetMiles`
        },
        loadIncident: {
            getIncidentStatusTypeSelectList: `/${APIVersion.V1}/LoadIncident/IncidentStatusTypeSelectList`,
            getIncidentTypeByIncidentStatusType: `/${APIVersion.V1}/LoadIncident/GetIncidentTypeByIncidentStatusType?loadIncidentStatusTypeId={0}`,
            getLoadIncidentList: `/${APIVersion.V1}/LoadIncident/List?callerScreen={0}`,
            createLoadIncident: `/${APIVersion.V1}/LoadIncident/Create?callerScreen={0}`,
            updateLoadIncident: `/${APIVersion.V1}/LoadIncident/Update?callerScreen={0}`,
            getResolutionReasonSelectList: `/${APIVersion.V1}/LoadIncident/GetIncidentResolutionReasonSelectList?incidentResolutionReasonId={0}`,
            resolveLoadIncident: `/${APIVersion.V1}/LoadIncident/ResolveLoadIncident?callerScreen={0}`,
        },
        dat: {
            loadPost: `/${APIVersion.V1}/Dat/Post`,
            loadUnPost: `/${APIVersion.V1}/Dat/Unpost?loadDetailId={0}`,
            getCapacityFromDat: `/${APIVersion.V1}/Dat/GetCapacityFromDat?loadDetailId={0}&isAutoSync={1}`
        },
        ediLoadTender: {
            getEdiLoadTenderList: `/${APIVersion.V1}/EdiLoadTender/List`,
            getReasons: `/${APIVersion.V1}/EDILoadTender/EdiRejectReasonSelectList`,
            rejectEdiLoadTender: `/${APIVersion.V1}/EDILoadTender/RejectEdiLoadTender`,
            acceptEdiLoadTender: `/${APIVersion.V1}/EDILoadTender/AcceptEdiLoadTender`,
            getEdiExchangeHistory: `/${APIVersion.V1}/EDILoadTender/GetEdiExchangeHistory`,
            assignEdiLoadTender: `/${APIVersion.V1}/EDILoadTender/AsignEidLoadTender`,
            getEdiLoadTenderUpdate: `/${APIVersion.V1}/EDILoadTender/GetEDITenderUpdates?ediLoadTenderId={0}`,
            addViewActivityLog: `/${APIVersion.V1}/EDILoadTender/AddViewActivityLog`,
            getEDIShipmentStatusReasonSelectList: `/${APIVersion.V1}/EDILoadTender/EDIShipmentStatusReasonSelectList`,
            ediBulkAccept: `/${APIVersion.V1}/EDILoadTender/BulkAccept`,
        },
        trackingBoard: {
            getTrackingBoardList: `/${APIVersion.V1}/TrackingBoard/List`,
            getTrackingBoardSelectList: `/${APIVersion.V1}/TrackingBoard/SelectList`,
            setTrackingBoardAuditLog: `/${APIVersion.V1}/TrackingBoard/AuditLogForTrackingBoardList`
        },
        loadSnooze: {
            createLoadSnooze: `/${APIVersion.V1}/LoadSnooze/Create`,
            getSnoozedLoadDetail: `/${APIVersion.V1}/LoadSnooze/GetLoadSnoozeDetail?loadDetailId={0}`,
        },
        lanePricing: {
            getLoadLanePricingList: `/${APIVersion.V1}/LoadLanePricing/List?loadDetailId={0}`,
        },
        auditLog: {
            getLoadAuditLogList: `/${APIVersion.V1}/AuditLog/List?loadDetailId={0}`,
        },
        carrierLoadBoard: {
            getCarrierLoadBoard: `/${APIVersion.V1}/CarrierLoadBoard/List`,
        },
        loadAccounting: {
            updateLoadCustomerRates: `/${APIVersion.V1}/LoadAccounting/UpdateLoadCustomerRates`,
            voidLoadCustomerRates: `/${APIVersion.V1}/LoadAccounting/VoidLoadCustomerRates`,
            updateLoadCarrierRates: `/${APIVersion.V1}/LoadAccounting/UpdateLoadCarrierRates`,
            voidLoadCarrierRates: `/${APIVersion.V1}/LoadAccounting/VoidLoadCarrierRates`,
            voidOriginalARInvoice: `/${APIVersion.V1}/LoadAccounting/VoidOriginalARInvoice`,
        },
        loadCustomer: {
            getLoadCustomerDetail: `/${APIVersion.V1}/LoadCustomer/GetLoadCustomerDetail?loadDetailId={0}`,
        },
        loadActivity: {
            getLoadActivityList: `/${APIVersion.V1}/LoadActivity/List?loadDetailId={0}`,
            createLoadActivity: `/${APIVersion.V1}/LoadActivity/Create`,
            updateLoadActivity: `/${APIVersion.V1}/LoadActivity/Update`,
            deleteLoadActivity: `/${APIVersion.V1}/LoadActivity/Delete?loadActivityId={0}`,
        },
        loadQueue: {
            create: `/${APIVersion.V1}/LoadQueue/Create`,
            update: `/${APIVersion.V1}/LoadQueue/Update`,
            createLoadQueueDetail: `/${APIVersion.V1}/LoadQueue/CreateLoadQueueDetail`,
            updateLoadQueueDetail: `/${APIVersion.V1}/LoadQueue/UpdateLoadQueueDetail`,
            getLoadQueueDetailList: `/${APIVersion.V1}/LoadQueue/GetLoadQueueDetailList?loadDetailId={0}`,
            sendNotification: `/${APIVersion.V1}/LoadQueue/SendNotification`,
            burstQueue: `/${APIVersion.V1}/LoadQueue/Burst?loadDetailId={0}`,
            sendNotificationToFirstQueueUser: `/${APIVersion.V1}/LoadQueue/SendNotificationToFirstQueueUser?loadDetailId={0}`
        },
        macroPoint :{
            getCapacityFromMacopoint : `/${APIVersion.V1}/MacroPoint/GetCapacityFromMacroPoint?loadDetailId={0}&isAutoSync={1}`
        },
    },
    accounting: {
        purchaseJournal: {
            getPurchaseJournalList: `/${APIVersion.V1}/PurchaseJournal/List`,
            createPurchaseJournal: `/${APIVersion.V1}/PurchaseJournal/CreatePurchaseJournal?callerScreen={0}`,
            updatePurchaseJournal: `/${APIVersion.V1}/PurchaseJournal/UpdatePurchaseJournal`,
            voidPurchaseJournal: `/${APIVersion.V1}/PurchaseJournal/InactivePurchaseJournal?purchaseJournalId={0}`,
            getPurchaseJournalListForLoad: `/${APIVersion.V1}/PurchaseJournal/GetPurchaseJournalListForLoad?loadDetailId={0}`,
            purchaseJournalPaymentSync: `/${APIVersion.V1}/PurchaseJournal/PurchaseJournalPaymentSync`,
            addViewActivityLog: `/${APIVersion.V1}/PurchaseJournal/AddViewActivityLog?sectionName={0}`,
        },
        accountReceivable: {
            getAccountReceivableList: `/${APIVersion.V1}/AccountReceivable/List`,
            getAccountReceivableAgingList: `/${APIVersion.V1}/AccountReceivable/AccountReceivableAgingList`,
            getAccountReceivableClosedList: `/${APIVersion.V1}/AccountReceivable/ClosedList`,
            getRevertReasonList: `/${APIVersion.V1}/AccountReceivable/SelectRevertReasonList`,
            revertRecord: `/${APIVersion.V1}/AccountReceivable/Update`,
            getAccountReceivablePreARSummary: `/${APIVersion.V1}/AccountReceivable/GetAccountReceivablePreARSummary`,
            getAccountReceivableARAgingSummary: `/${APIVersion.V1}/AccountReceivable/GetAccountReceivableARAgingSummary`,
            createAccountReceivable: `/${APIVersion.V1}/AccountReceivable/Create`,
            generateAccountReceivableInvoice: `/${APIVersion.V1}/AccountReceivable/GenerateAccountReceivableInvoice`,
            createAccountReceivableInvoice: `/${APIVersion.V1}/AccountReceivable/CreateAccountReceivableInvoice`,
            addAdditionalInvoice: `/${APIVersion.V1}/AccountReceivable/AddAdditionalAccountReceivableInvoice`,
            voidAccountReceivableInvoice: `/${APIVersion.V1}/AccountReceivable/VoidAccountReceivableInvoice`,
            generateMultipleAccountReceivableInvoice: `/${APIVersion.V1}/AccountReceivable/GenerateMultipleAccountReceivableInvoice`,
            generateSingleAccountReceivableInvoice: `/${APIVersion.V1}/AccountReceivable/GenerateSingleAccountReceivableInvoice`,
            getAccountReceivableInvoiceDetails: `/${APIVersion.V1}/AccountReceivable/GetInvoiceDetails?accountReceivableId={0}&isARClosedScreen={1}&customerId={2}`,
            updateAccountReceivableDetails: `/${APIVersion.V1}/AccountReceivable/UpdateAccountReceivableDetails`,
            downloadInvoicePDF: `/${APIVersion.V1}/AccountReceivable/DownloadInvoicePDF?documentPath={0}&accountReceivableInvoiceId={1}&loadDetailId={2}`,
            accountReceivableInvoicePaymentSync: `/${APIVersion.V1}/AccountReceivable/AccountReceivableInvoicePaymentSync`,
            resendFailInvoice: `/${APIVersion.V1}/AccountReceivable/ResendFailInvoice`,
            accountReceivableAllInvoicePaymentSync: `/${APIVersion.V1}/AccountReceivable/AccountReceivableAllInvoicePaymentSync`,
            addViewActivityLog: `/${APIVersion.V1}/AccountReceivable/AddViewActivityLog?sectionName={0}`,
            generateAccountReceivableAgingReport: `/${APIVersion.V1}/AccountReceivable/GenerateAccountReceivableAgingReport`,
            resendInvoiceToCustomer: `/${APIVersion.V1}/AccountReceivable/ResendInvoiceToCustomer`,
            resendInvoiceToEDI: `/${APIVersion.V1}/AccountReceivable/resendInvoiceToEDI`,
            exportToCSV: `/${APIVersion.V1}/AccountReceivable/ExportToCSV`
        },
        factoringCompany: {
            getFactoringCompanyList: `/${APIVersion.V1}/FactoringCompany/List`,
            createFactoringCompany: `/${APIVersion.V1}/FactoringCompany/Create`,
            updateFactoringCompany: `/${APIVersion.V1}/FactoringCompany/Update`,
            updateFactoringCompanyStatus: `/${APIVersion.V1}/FactoringCompany/UpdateStatus`,
            getFactoringCompany: `/${APIVersion.V1}/FactoringCompany/Get?factoringCompanyId={0}`,
            factoringCompanyValidations: {
                email: `/${APIVersion.V1}/FactoringCompany/EmailExists?email={0}&factoringCompanyId={1}`,
                name: `/${APIVersion.V1}/FactoringCompany/NameExists?name={0}&factoringCompanyId={1}`,
                businessName: `/${APIVersion.V1}/FactoringCompany/BusinessNameExists?businessName={0}&factoringCompanyId={1}`,
            },
            addViewListActivityLog: `/${APIVersion.V1}/FactoringCompany/AddViewListActivityLog`,
            addViewActivityLog: `/${APIVersion.V1}/FactoringCompany/AddViewActivityLog/?factoringCompanyId={0}`
        },
        accountPayable: {
            getAccountPayableList: `/${APIVersion.V1}/AccountPayable/List`,
            getAccountPayableAgingList: `/${APIVersion.V1}/AccountPayable/GetAccountPayableAgingList`,
            getAccountPayableClosingList: `/${APIVersion.V1}/AccountPayable/GetAccountPayableClosingList`,
            getAccountPayablePreAPSummary: `/${APIVersion.V1}/AccountPayable/GetAccountPayablePreAPSummary`,
            getAccountPayableAPAgingSummary: `/${APIVersion.V1}/AccountPayable/GetAccountPayableAPAgingSummary`,
            getRevertReasonList: `/${APIVersion.V1}/AccountPayable/SelectRevertReasonList`,
            createAccountPayable: `/${APIVersion.V1}/AccountPayable/Create`,
            revertRecord: `/${APIVersion.V1}/AccountPayable/Update`,
            updateAccountPayableDetails: `/${APIVersion.V1}/AccountPayable/UpdateAccountPayableDetails`,
            addAdditionalBill: `/${APIVersion.V1}/AccountPayable/AddAdditionalAccountPayableBill`,
            voidAccountPayableBill: `/${APIVersion.V1}/AccountPayable/VoidAccountPayableBill`,
            resendFailBill: `/${APIVersion.V1}/AccountPayable/ResendFailBill`,
            accountPayableAllBillPaymentSync: `/${APIVersion.V1}/AccountPayable/AccountPayableAllBillPaymentSync`,
            accountPayableManualBillPaymentSync: `/${APIVersion.V1}/AccountPayable/AccountPayableManualBillPaymentSync`,
            getAccountPayableBillDetails: `/${APIVersion.V1}/AccountPayable/GetBillDetails?accountPayableId={0}&isAPClosedScreen={1}&loadDetailId={2}&isAuditLog={3}`,
            addViewActivityLog: `/${APIVersion.V1}/AccountPayable/AuditLogForAccountPayableList?sectionName={0}`,
            exportToCSV: `/${APIVersion.V1}/AccountPayable/ExportToCSV`,
            checkAPStatusByLoadDetailId: `/${APIVersion.V1}/AccountPayable/CheckAPStatusByLoadDetailId?loadDetailId={0}&carrierId={1}`,
        },
        auditReview: {
            getAuditReviewList: `/${APIVersion.V1}/AuditReview/List`,
            release: `/${APIVersion.V1}/AuditReview/Release`,
            getReviewCount: `/${APIVersion.V1}/AuditReview/GetReviewCount?loadDetailId={0}`,
            addViewActivityLog: `/${APIVersion.V1}/AuditReview/AddViewActivityLog?sectionName={0}`,
        },
        paymentTerm: {
            getPaymentTermSelectList: `/${APIVersion.V1}/PaymentTerm/SelectList`,
        },
        revertRelease: {
            validateLoadForRevertRelease: `/${APIVersion.V1}/RevertRelease/ValidateLoadForRevertRelease?loadDetailId={0}&callerScreen={1}`,
            revertReleasedLoad: `/${APIVersion.V1}/RevertRelease/RevertReleasedLoad?loadDetailId={0}&callerScreen={1}`
        }
    },
    facility: {
        facilityList: `/${APIVersion.V1}/Facility/List`,
        facilityTypeSelectList: `/${APIVersion.V1}/FacilityType/SelectList?facilityTypeId={0}`,
        facilityCreate: `/${APIVersion.V1}/Facility/Create?callerScreen={0}`,
        facilityUpdate: `/${APIVersion.V1}/Facility/Update`,
        facilityTypeAhead: `/${APIVersion.V1}/Facility/TypeAhead`,
        facilityDetail: `/${APIVersion.V1}/Facility/Get?facilityDetailId={0}&callerScreen={1}&facilityCode={2}`,
        facilityStatusReasonSelectList: `/${APIVersion.V1}/Facility/FacilityStatusReasonSelectList`,
        facilityStatusUpdate: `/${APIVersion.V1}/Facility/UpdateStatus`,
        facilityLockDetail: `/${APIVersion.V1}/Facility/GetLockDetails?facilityDetailId={0}`,
        facilityLock: `/${APIVersion.V1}/Facility/Lock?facilityDetailId={0}&userId={1}`,
        facilityUnlock: `/${APIVersion.V1}/Facility/Unlock?facilityDetailId={0}&userId={1}`,
        facilityCodeExist: `/${APIVersion.V1}/Facility/FacilityCodeExists?facilityCode={0}&facilityDetailId={1}`,
        facilityUniqueCode: `/${APIVersion.V1}/Facility/GenerateUniqueFacilityCode?name={0}&city={1}&state={2}`,
        facilityCustomer: {
            facilityCustomerList: `/${APIVersion.V1}/FacilityCustomer/List`,
            facilityCustomerUpdateStatus: `/${APIVersion.V1}/FacilityCustomer/UpdateStatus?isActive={0}&facilityCustomerId={1}&modifiedBy={2}`,
            facilityCustomerCreate: `/${APIVersion.V1}/FacilityCustomer/Create`,
            facilityCustomerUpdate: `/${APIVersion.V1}/FacilityCustomer/Update`,
        },
        facilityDepartment: {
            facilityDepartmentSelectList: `/${APIVersion.V1}/FacilityDepartment/SelectList?facilityDepartmentId={0}`,
        },
        facilityAddressSelectList: `/${APIVersion.V1}/Facility/GetAddressSelectList?searchAddress={0}`,
        facilityProfileAuditLog: `/${APIVersion.V1}/Facility/ViewFacilityProfileAuditLog?facilityDetailId={0}`,
        facilityAddressExists: `/${APIVersion.V1}/Facility/FacilityAddressExists?facilityDetailId={0}`,
        facilityListAuditLog: `/${APIVersion.V1}/Facility/FacilityListViewActivityLog`,
        getFacilityAuditLogList: `/${APIVersion.V1}/FacilityAuditLog/List`,
        addViewActivityLog: `/${APIVersion.V1}/Facility/AddViewActivityLog?subModule={0}&sectionName={1}&facilityDetailId={2}`,
        getFacilityScoreCardDetail: `/${APIVersion.V1}/Facility/GetFacilityScoreCardDetail?facilityDetailId={0}`,
    },
    award: {
        awardType: {
            awardTypeSelectList: `/${APIVersion.V1}/AwardType/SelectList?awardTypeId={0}`,
        },
        awardLaneType: {
            awardLaneTypeSelectList: `/${APIVersion.V1}/AwardLaneType/SelectList?awardLaneTypeId={0}`,
        },
        capacityDetail: {
            capacityCreate: `/${APIVersion.V1}/CapacityDetail/Create?callerScreen={0}`,
            capacityUpdate: `/${APIVersion.V1}/CapacityDetail/Update`,
            capacityLock: `/${APIVersion.V1}/CapacityDetail/Lock?capacityDetailId={0}&userId={1}`,
            capacityUnlock: `/${APIVersion.V1}/CapacityDetail/Unlock?capacityDetailId={0}&userId={1}`,
            capacityLockDetails: `/${APIVersion.V1}/CapacityDetail/GetLockDetails?capacityDetailId={0}`,
            capacityDetail: `/${APIVersion.V1}/CapacityDetail/Get?capacityDetailId={0}`,
            getCapacityList: `/${APIVersion.V1}/CapacityDetail/List?callerScreen={0}`,
            addViewListActivityLog: `/${APIVersion.V1}/CapacityDetail/AddListViewActivityLog`,
            capacityProfileAuditLog: `/${APIVersion.V1}/CapacityDetail/ViewCapacityProfileAuditLog?capacityDetailId={0}`,
            capacityUniqueIdTypeAhead: `/${APIVersion.V1}/CapacityDetail/CapacityUniqueIdTypeAhead`,
            createAwardCapacity: `/${APIVersion.V1}/CapacityDetail/CreateAwardCapacity?callerScreen={0}`,
            getSubmittedCapacityList: `/${APIVersion.V1}/CapacityDetail/GetSubmittedCapacityList`,
            capacityPotentialAwardList: `/${APIVersion.V1}/CapacityDetail/GetCapacityPotentialAwardList?includeEquipment={0}`,
            awardCapacityExists: `/${APIVersion.V1}/CapacityDetail/AwardCapacityExists?capacityDetailId={0}&awardDetailId={1}`,
            capacityUpdateStatus: `/${APIVersion.V1}/CapacityDetail/UpdateCapacityStatus?callerScreen={0}`,
            cloneCapacity: `/${APIVersion.V1}/CapacityDetail/CloneCapacity?callerScreen={0}`,
        },
        loadHistory: {
            capacityLaneHistoryList: `/${APIVersion.V1}/LoadHistory/CapacityLaneList`,
            addLaneHistoryListAuditLog: `/${APIVersion.V1}/LoadHistory/AddLaneHistoryListViewActivityLog?detailId={0}&callerScreen={1}`,
            getCapacityMarketList: `/${APIVersion.V1}/LoadHistory/CapacityMarketList?capacityDetailId={0}`,
            awardLaneHistoryList: `/${APIVersion.V1}/LoadHistory/AwardLaneList`,
            addMarketHistoryListAuditLog: `/${APIVersion.V1}/LoadHistory/AddMarketHistoryListViewActivityLog?detailId={0}&callerScreen={1}`,
            awardMarketHistoryList: `/${APIVersion.V1}/LoadHistory/AwardMarketList`,
        },
        awardDetail: {
            awardCreate: `/${APIVersion.V1}/AwardDetail/Create`,
            awardUpdate: `/${APIVersion.V1}/AwardDetail/Update`,
            awardLockDetail: `/${APIVersion.V1}/AwardDetail/GetLockDetails?awardDetailId={0}`,
            awardLock: `/${APIVersion.V1}/AwardDetail/Lock?awardDetailId={0}&userId={1}`,
            awardUnlock: `/${APIVersion.V1}/AwardDetail/Unlock?awardDetailId={0}&userId={1}`,
            getAwardDetail: `/${APIVersion.V1}/AwardDetail/Get?awardDetailId={0}`,
            getApprovedWaterfallCapacity: `/${APIVersion.V1}/AwardDetail/GetApprovedWaterfallCapacity?awardDetailId={0}`,
            getSubmittedWaterfallCapacity: `/${APIVersion.V1}/AwardDetail/GetSubmittedWaterfallCapacity`,
            addViewWaterfallCapacityActivityLog: `/${APIVersion.V1}/AwardDetail/AddViewWaterfallCapacityActivityLog?awardDetailId={0}`,
            awardTruckMatchList: `/${APIVersion.V1}/AwardDetail/GetAwardTruckMatchList`,
            awardProfileAuditLog: `/${APIVersion.V1}/AwardDetail/ViewAwardProfileAuditLog?awardDetailId={0}`,
            awardStatusReasonSelectList: `/${APIVersion.V1}/AwardDetail/AwardStatusReasonSelectList?reasonType={0}`,
            awardUpdateStatus: `/${APIVersion.V1}/AwardDetail/UpdateAwardStatus?callerScreen={0}`,
            auditLogViewStakeholdersAwardProfile: `/${APIVersion.V1}/AwardDetail/ViewStakeholdersAwardProfileAuditLog?awardDetailId={0}`,
            getAwardList: `/${APIVersion.V1}/AwardDetail/List`,
            addViewActivityLog: `/${APIVersion.V1}/AwardDetail/AddListViewActivityLog`,
            awardPotentialCapacityList: `/${APIVersion.V1}/AwardDetail/GetAwardPotentialCapacityList?includeEquipment={0}`,
            updateSubmittedCapacityStatus: `/${APIVersion.V1}/AwardDetail/UpdateSubmittedCapacityStatus`,
            notifyRep: `/${APIVersion.V1}/AwardDetail/SendInAppNotificationForPotentialCapacity`,
        },
        auditLog: {
            getAuditLogList: `/${APIVersion.V1}/AuditLog/List?callerScreen={0}`,
            addViewActivityLog: `/${APIVersion.V1}/AuditLog/AddViewActivityLog?subModule={0}&sectionName={1}&detailId={2}&callerScreen={3}`,
        },
        bidDocument: {
            downloadBidDocument: `/${APIVersion.V1}/BidDocument/Download?bidDocumentId={0}&downloadProcessedFile={1}`,
            getBidDocumentList: `/${APIVersion.V1}/BidDocument/List`,
            uploadBidDocument: `/${APIVersion.V1}/BidDocument/Upload`
        },
        awardYield: {
            getAwardYieldList: `/${APIVersion.V1}/AwardYield/List`
        }
    }
};
