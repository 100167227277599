import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Constants } from '@app/core/constants/constants';
import { ActivityType, Module, PermissionCode, PermissionModuleCode } from '@app/core/constants/enums';
import { FormHelper, SpaceValidator } from '@app/core/helpers/form.helper';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { SelectListModel } from '@app/core/models/select.list.model';
import { ToasterService } from '@app/core/services/toaster.service';
import { CarrierService } from '@app/modules/carrier/carrier.service';
import { CustomerService } from '@app/modules/customer/customer.service';
import { LoadService } from '@app/modules/load/load.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { VirtualScroller } from 'primeng/virtualscroller';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ActivityComponent implements OnInit {
  @ViewChild('vs') vs!: VirtualScroller;
  activitySearchForm!: FormGroup;
  activityForm!: FormGroup;
  activities: any[] = [];
  filteredActivities: any[] = [];
  virtualActivities!: any[];
  activityTypes: SelectListModel[] = [];
  searchActivityTypes: SelectListModel[] = [];
  noRecords = Constants.noRecordFound;
  dateFormat = Constants.dateTimeFormat;
  scrollCount = 0;
  noActivityFound = Constants.noActivityFound;
  activitySaveInProgress = false;
  @Input() entityId!: number;
  @Input() module!: Module;
  @Input() manageCustomerActivity = false;
  @Input() manageCarrierActivity = false;
  @Output() activitySaved = new EventEmitter<boolean>();
  @Input() isPopUp = false;
  @Input() manageActivityonload = true;
  oldActivity = '';
  constructor(
    private readonly fb: FormBuilder,
    private readonly loadService: LoadService,
    private readonly customerService: CustomerService,
    private readonly carrierService: CarrierService,
    private readonly toasterService: ToasterService,
    private readonly confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    if (!isNaN(this.entityId)) {
      this.getActivityTypes();
      this.initSearchForm();
      this.initActivityForm();
      this.getActivities();
    }
  }

  getCurrentUserId(): number {
    return LoginHelper.GetUserDetail()?.userId;
  }

  getActivityControl(control: string): AbstractControl {
    return this.activityForm.controls[control];
  }

  getActivitySearchControl(control: string): AbstractControl {
    return this.activitySearchForm.controls[control];
  }

  setDefaultActivityType() {
    const activityType = this.activityTypes.find(x => x.text === ActivityType.General);
    activityType && this.activityForm.controls['activityTypeId'].patchValue(activityType.value);
  }

  initSearchForm(): void {
    this.activitySearchForm = this.fb.group({
      activityType: new FormControl(null),
      search: new FormControl(null),
    });

    if (!this.manageActivityonload) {
      this.activitySearchForm.disable();
    }

    this.getActivitySearchControl('activityType').valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.getActivitySearchControl('activityType').patchValue(value);
      this.filterActivities();
    });

    this.getActivitySearchControl('search').valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value?.length >= 3 || value === '') {
        this.getActivitySearchControl('search').patchValue(value);
        this.filterActivities();
      }
    });
  }

  initActivityForm(): void {
    this.activityForm = this.fb.group({
      activityTypeId: new FormControl(null, {
        validators: [
          Validators.required
        ]
      }),
      activity: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(500),
          SpaceValidator()
        ]
      }),
      createdBy: LoginHelper.GetUserDetail().userId,
      loadDetailId: this.entityId,
      customerId: this.entityId,
      carrierId: this.entityId
    });
    this.setFormFieldControls(this.activityForm);
  }

  setFormFieldControls(formName: FormGroup, hasImplicitPermission = true): void {
    switch (this.module) {
      case Module.Customer:
        FormHelper.EnableDisableFormControls(formName, (this.manageCustomerActivity || hasImplicitPermission));
        break;
      case Module.Carrier:
        FormHelper.EnableDisableFormControls(formName, (this.manageCarrierActivity || hasImplicitPermission));
        break;
      case Module.Load:
        FormHelper.EnableDisableFormControls(formName, (this.manageActivityonload));
        break;
      default:
        break;
    }
  }

  getActivityTypes(): void {
    switch (this.module) {
      case Module.Load:
        this.loadService.getActivitySelectList().subscribe((response) => {
          if (response.data?.length) {
            this.activityTypes = response.data;
            this.setDefaultActivityType();
            this.setSearchActivityTypes(response.data);
          }
        });
        break;
      case Module.Customer:
        this.customerService.getActivitySelectList().subscribe((response) => {
          if (response.data?.length) {
            this.activityTypes = response.data;
            this.setDefaultActivityType();
            this.setSearchActivityTypes(response.data);
          }
        });
        break;
      case Module.Carrier:
        this.carrierService.getActivitySelectList().subscribe((response) => {
          if (response.data?.length) {
            this.activityTypes = response.data;
            this.setDefaultActivityType();
            this.setSearchActivityTypes(response.data);
          }
        });
        break;
      default:
        break;
    }
  }

  setSearchActivityTypes(activityTypes: SelectListModel[]): void {
    this.searchActivityTypes = JSON.parse(JSON.stringify(activityTypes));
    this.searchActivityTypes = this.searchActivityTypes?.map(item => {
      if (item.text === Constants.pleaseSelectDefaultText) {
        item.text = Constants.documentTypeAll;
      }
      return item;
    });
  }

  filterActivities(): void {
    const activityType = parseInt(this.activitySearchForm.value.activityType);
    const searchString = this.activitySearchForm.value.search?.trim()?.toLowerCase();

    if (this.module === Module.Customer || this.module === Module.Carrier) {
      const allActivities = this.activities.map(item => {
        return {
          ...item,
          activityType: item.activityType?.name,
          activityTypeId: item.activityType?.activityTypeId,
          isActivityEdit: false
        };
      });
      this.filteredActivities = allActivities.filter(activity => {
        const hasMatchingType = isNaN(activityType) || activity.activityTypeId === activityType;
        const hasMatchingSearch = !searchString ||
          activity.activity?.toLowerCase().includes(searchString) ||
          activity.activityType?.toLowerCase().includes(searchString) ||
          activity.createdByUser?.toLowerCase().includes(searchString);
        return hasMatchingType && hasMatchingSearch;
      });
    } else {
      this.filteredActivities = this.activities.filter(activity => {
        const hasMatchingType = isNaN(activityType) || activity.activityTypeId === activityType;
        const hasMatchingSearch = !searchString ||
          activity.activity.toLowerCase().includes(searchString) ||
          activity.activityType.toLowerCase().includes(searchString) ||
          activity.createdByUser.toLowerCase().includes(searchString);
        return hasMatchingType && hasMatchingSearch;
      });
    }
    this.virtualActivities = Array.from(this.filteredActivities);
  }

  getActivities(): void {
    switch (this.module) {
      case Module.Load:
        if (this.manageActivityonload) {
          this.loadService.getLoadActivities(this.entityId).subscribe((response) => {
            if (response.data || response.httpStatus === HttpStatusCode.Ok) {
              this.activities = response.data || [];
              this.virtualActivities = Array.from({ length: this.activities.length });
              this.filterActivities();
            }
            if (response.httpStatus === HttpStatusCode.Forbidden) {
              this.setFormFieldControls(this.activityForm, false);
            }
          });
        }
        break;
      case Module.Customer:
        if (this.manageCustomerActivity) {
          this.customerService.getCustomerActivities(this.entityId).subscribe((response) => {
            if (response.data || response.httpStatus === HttpStatusCode.Ok) {
              this.activities = response.data || [];
              this.virtualActivities = Array.from({ length: this.activities.length });
              this.filterActivities();
            }
            if (response.httpStatus === HttpStatusCode.Forbidden) {
              this.setFormFieldControls(this.activityForm, false);
            }
          });
        } else {
          this.setFormFieldControls(this.activityForm, false);
        }
        break;
      case Module.Carrier:
        if (this.manageCarrierActivity) {
          this.carrierService.getCarrierActivities(this.entityId).subscribe((response) => {
            if (response.data || response.httpStatus === HttpStatusCode.Ok) {
              this.activities = response.data || [];
              this.virtualActivities = Array.from({ length: this.activities.length });
              this.filterActivities();
            }
            if (response.httpStatus === HttpStatusCode.Forbidden) {
              this.setFormFieldControls(this.activityForm, false);
            }
          });
        } else {
          this.setFormFieldControls(this.activityForm, false);
        }
        break;
      default:
        break;
    }
  }

  lazyLoadActivities(event: LazyLoadEvent): void {
    const loadedActivities = this.filteredActivities.slice(event.first, event.last);
    this.virtualActivities.splice(event.first || 0, event.rows || 5, ...loadedActivities);
    this.scrollCount === 0 && this.scrollTopActivity();
    event.forceUpdate && event.forceUpdate();
  }

  scrollTopActivity(): void {
    this.vs?.scrollToIndex(this.virtualActivities?.length - 1, 'smooth');
    this.scrollCount++;
  }

  save(event: KeyboardEvent): void {
    this.scrollCount = 0;
    if (event.keyCode === 13 && !this.activitySaveInProgress) {
      this.activitySaveInProgress = true;
      const activity: string = this.activityForm.value.activity;
      if (activity.startsWith('\n')) {
        this.activityForm.controls['activity'].patchValue(null);
      }
      if (activity.endsWith('\n')) {
        this.activityForm.controls['activity'].patchValue(activity.replace('\n', ''));
      }
      if (this.activityForm.invalid) {
        FormHelper.MarkFormGroupDirty(this.activityForm);
        return;
      }

      if (this.module === Module.Load) {
        this.loadService.createLoadActivity(this.activityForm.value).subscribe((response) => {
          if (response.data) {
            this.getActivities();
            this.activityForm.reset();
            this.activityForm.controls['loadDetailId'].patchValue(this.entityId);
            this.activityForm.controls['createdBy'].patchValue(LoginHelper.GetUserDetail().userId);
            this.setDefaultActivityType();
            this.activitySaved.emit(true);
            this.activitySaveInProgress = false;
          }
        });
      }
      if (this.module === Module.Customer) {
        this.customerService.createCustomerActivity(this.activityForm.value).subscribe((response) => {
          if (response.data) {
            this.getActivities();
            this.activityForm.reset();
            this.activityForm.controls['customerId'].patchValue(this.entityId);
            this.activityForm.controls['createdBy'].patchValue(LoginHelper.GetUserDetail().userId);
            this.setDefaultActivityType();
            this.activitySaved.emit(true);
            this.activitySaveInProgress = false;
          }
        });
      }
      if (this.module === Module.Carrier) {
        this.carrierService.createCarrierActivity(this.activityForm.value).subscribe((response) => {
          if (response.data) {
            this.getActivities();
            this.activityForm.reset();
            this.activityForm.controls['carrierId'].patchValue(this.entityId);
            this.activityForm.controls['createdBy'].patchValue(LoginHelper.GetUserDetail().userId);
            this.setDefaultActivityType();
            this.activitySaved.emit(true);
            this.activitySaveInProgress = false;
          }
        });
      }
    }
  }

  onActivityEdit(activity: any): void {
    activity.isActivityEdit = true;
    this.oldActivity = JSON.parse(JSON.stringify(activity.activity));
  }

  onActivitySave(activity: any): void {
    const carrierCustomerPayload = {
      carrierActivityId: activity.carrierActivityId,
      carrierId: 0,
      customerActivityId: activity.customerActivityId,
      customerId: 0,
      activity: activity.activity,
      activityTypeId: activity.activityTypeId
    };
    switch (this.module) {
      case Module.Load:
        this.loadService.updateLoadActivity(JSON.parse(JSON.stringify(activity))).subscribe((response) => {
          if (response.data) {
            this.toasterService.showSuccessMessage(response.userMessage);
            activity.isActivityEdit = false;
            this.oldActivity = '';
          }
        });
        break;
      case Module.Carrier:
        carrierCustomerPayload.carrierId = this.entityId;
        this.carrierService.updateCarrierActivity(JSON.parse(JSON.stringify(carrierCustomerPayload))).subscribe((response) => {
          if (response.data) {
            this.toasterService.showSuccessMessage(response.userMessage);
            activity.isActivityEdit = false;
            this.oldActivity = '';
          }
        });
        break;
      case Module.Customer:
        carrierCustomerPayload.customerId = this.entityId;
        this.customerService.updateCustomerActivity(JSON.parse(JSON.stringify(carrierCustomerPayload))).subscribe((response) => {
          if (response.data) {
            this.toasterService.showSuccessMessage(response.userMessage);
            activity.isActivityEdit = false;
            this.oldActivity = '';
          }
        });
        break;
      default:
        break;
    }
  }

  onActivityEditCancel(activity: any): void {
    activity.isActivityEdit = false;
    activity.activity = JSON.parse(JSON.stringify(this.oldActivity));
    this.oldActivity = '';
  }

  onActivityDelete(activity: any): void {
    this.confirmationService.confirm({
      message: Constants.deleteActivityConfirmation,
      header: Constants.confirmationPopupHeader,
      icon: Constants.updateStatusDialogIcon,
      accept: () => {

        switch (this.module) {
          case Module.Load:
            this.loadService.deleteLoadActivity(activity).subscribe((response) => {
              if (response.data) {
                this.toasterService.showSuccessMessage(response.userMessage);
                this.getActivities();
              }
            });
            break;
          case Module.Carrier:
            activity.carrierId = this.entityId;
            this.carrierService.deleteCarrierActivity(activity).subscribe((response) => {
              if (response.data) {
                this.toasterService.showSuccessMessage(response.userMessage);
                this.getActivities();
              }
            });
            break;
          case Module.Customer:
            activity.customerId = this.entityId;
            this.customerService.deleteCustomerActivity(activity).subscribe((response) => {
              if (response.data) {
                this.toasterService.showSuccessMessage(response.userMessage);
                this.getActivities();
              }
            });
            break;
          default:
            break;
        }
      }
    });
  }

  isEditDeleteActivityPermitted(): boolean {
    if (this.module === Module.Load) {
      return LoginHelper.IsModuleAccessible(PermissionModuleCode.LoadProfile, PermissionCode.EditDeleteActivityNotes);
    } else if (this.module === Module.Customer) {
      return LoginHelper.IsModuleAccessible(PermissionModuleCode.CustomerProfile, PermissionCode.EditAndDeleteActivityNotes);
    } else if (this.module === Module.Carrier) {
      return LoginHelper.IsModuleAccessible(PermissionModuleCode.CarrierProfile, PermissionCode.EditAndDeleteActivityNotes);
    } else {
      return true;
    }
  }
}
