import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { LoginHelper } from '../helpers/login.helper';
import { LoadQuickViewSubjectModel } from '@app/modules/load/load.model';
import { CallerScreen } from '../constants/enums';

@Injectable()
export class CoreService {
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isFormDirty = false;
  isLoadCreate = false;
  private quickLoadViewSubject: BehaviorSubject<LoadQuickViewSubjectModel> = new BehaviorSubject<LoadQuickViewSubjectModel>({
    loadDetailId: 0,
    visible: false,
    callerScreen: CallerScreen.LoadBoard
  });
  constructor(
    private readonly cookieService: CookieService,
    private readonly appConfigService: AppConfigService,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) { }

  setTitle(title: string) {
    this.titleSubject.next(title);
  }

  getTitle() {
    return this.titleSubject.asObservable();
  }

  logOutUser(): void {
    this.authenticationService.logOut().subscribe((response) => {
      if (response.data) {
        localStorage.clear();
        LoginHelper.User = JSON.parse('null');
        sessionStorage.clear();
        this.cookieService.deleteAll('/', this.appConfigService.cookieDomain ?? undefined);
        this.router.navigate(['/login']);
      }
    });
  }

  setQuickLoadView(quickView: LoadQuickViewSubjectModel): void {
    this.quickLoadViewSubject.next(quickView);
  }

  getQuickLoadView(): Observable<LoadQuickViewSubjectModel> {
    return this.quickLoadViewSubject.asObservable();
  }
}
