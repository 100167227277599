<div [formGroup]="formName" class="position-relative">
    <label [ngClass]="{'disable':control.disabled}" *ngIf="isShowLabel" for='{{elementId}}'>{{ label }}<em
            *ngIf="isRequired" class="asterisk"></em><em *ngIf="iTagData !== ''" class="ml-2 pi pi-info-circle"
            tooltipEvent="hover|focus" tabindex="0" [attr.aria-label]="iTagData"></em></label>

    <span class="material-symbols-outlined icon-dropdown d-none">
        expand_more
    </span>

    <p-dropdown *ngIf="!multipleSelection && isPrimeNgDropdown &&  !isDropdownInPopup && !isServerSideFilter"
        [options]="options" [inputId]="elementId" data-placement="top" data-trigger="manual" [optionLabel]="optionLabel"
        [optionValue]="optionValue" [formControlName]="controlName" [styleClass]="styleClass"
        [overlayOptions]="{baseZIndex:9999999}" appendTo="body" [showClear]="showClear" [filter]="filter"
        [resetFilterOnHide]="resetFilterOnHide" (onChange)="onSelectChanged($event)">
    </p-dropdown>

    <p-dropdown *ngIf="!multipleSelection && isPrimeNgDropdown && isDropdownInPopup && !isServerSideFilter"
        [options]="options" [inputId]="elementId" data-placement="top" data-trigger="manual"
        [scrollHeight]="scrollHeight" (onChange)="onSelectChanged($event)" [overlayOptions]="{baseZIndex:9999999}"
        appendTo="body" [styleClass]="styleClass" [name]="controlName" [resetFilterOnHide]="resetFilterOnHide"
        [virtualScroll]="virtualScroll" [itemSize]="itemSize" [filter]="true" [showClear]="showClear"
        [autofocus]="autofocus ? true : false" placeholder="{{defaultText}}" [formControlName]="controlName"
        [disabled]="disabled ? true : false" [attr.aria-describedby]="'err-' + elementId" [tabIndex]="tabIndex">
    </p-dropdown>

    <p-dropdown #dropref *ngIf="!multipleSelection && !isPrimeNgDropdown &&  !isDropdownInPopup && isServerSideFilter"
        [options]="options" [inputId]="elementId" data-placement="top" data-trigger="manual" [optionLabel]="optionLabel"
        [optionValue]="optionValue" [scrollHeight]="scrollHeight + 'px'" (onChange)="onSelectChanged($event)"
        (onHide)="onHideEvent($event)" [styleClass]="styleClass" [name]="controlName"
        [resetFilterOnHide]="resetFilterOnHide" [virtualScroll]="virtualScroll" [itemSize]="itemSize" [filter]="filter"
        [showClear]="showClear" [autofocus]="autofocus ? true : false" placeholder="{{defaultText}}"
        [formControlName]="controlName" [disabled]="disabled ? true : false"
        [attr.aria-describedby]="'err-' + elementId" [tabIndex]="tabIndex" [overlayOptions]="{baseZIndex:9999999}"
        appendTo="body">
    </p-dropdown>

    <p-multiSelect *ngIf="multipleSelection" [options]="options" [inputId]="elementId" data-placement="top"
        [styleClass]="styleClass" [name]="controlName" [filter]="filter" [resetFilterOnHide]="resetFilterOnHide"
        [defaultLabel]="multiSelectDefaultLabel" data-trigger="manual" (onChange)="onMultiSelectChanged($event)"
        [showHeader]="showHeader" [formControlName]="controlName" [panelStyle]="{minWidth:'12em'}"
        [maxSelectedLabels]="maxSelectedLabels" [disabled]="disabled ? true : false"
        [attr.aria-describedby]="'err-' + elementId" [optionLabel]="optionLabel" [optionValue]="optionValue"
        display="chip" placeholder="{{defaultText}}" [overlayOptions]="{baseZIndex:9999999}" appendTo="body"
        [selectionLimit]="selectionLimit">
    </p-multiSelect>
    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</div>