import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Constants } from '../constants/constants';
import { ToastMessageTitle, ToastMessageType } from '../constants/enums';

@Injectable()
export class ToasterService {

  constructor(private readonly messageService: MessageService) { }

  showSuccessMessage(message: string, life = Constants.toastTimeOut): void {
    this.clearToasterMessage();
    this.messageService.add({
      severity: ToastMessageType.Success,
      summary: ToastMessageTitle.Success,
      detail: message,
      life,
    });
  }

  showInfoMessage(message: string, life = Constants.toastTimeOut): void {
    this.clearToasterMessage();
    this.messageService.add({ severity: ToastMessageType.Information, detail: message, life });
  }

  showWarnMessage(message: string, life = Constants.toastTimeOut): void {
    this.messageService.add({
      severity: ToastMessageType.Warning,
      summary: ToastMessageTitle.Warning,
      detail: message,
      life,
    });
  }

  showWarnMessageWithTitle(message: string, title: string, life = Constants.toastTimeOut): void {
    this.clearToasterMessage();
    this.messageService.add({
      severity: ToastMessageType.Warning,
      summary: title,
      detail: message,
      life,
    });
  }

  showErrorMessage(message: string, life = Constants.toastTimeOut): void {
    this.clearToasterMessage();
    this.messageService.add({
      severity: ToastMessageType.Error,
      summary: ToastMessageTitle.Error,
      detail: message,
      life,
    });
  }

  showCustomMessage(message: string, title: ToastMessageTitle, severity: ToastMessageType, life = Constants.toastTimeOut): void {
    this.clearToasterMessage();
    this.messageService.add({
      severity: severity || ToastMessageType.Information,
      summary: title || ToastMessageTitle.Information,
      detail: message,
      life,
    });
  }

  clearToasterMessage(): void {
    this.messageService.clear();
  }
}
