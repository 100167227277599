import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResolveIncidentComponent } from '@app/modules/load/load-edit/load-incident/resolve-incident/resolve-incident.component';
import { CountdownModule } from 'ngx-countdown';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FileUploadModule } from 'primeng/fileupload';
import { ActivityComponent } from './components/activity/activity.component';
import { AssignViewsComponent } from './components/assign-views/assign-views.component';
import { AutocompleteControlComponent } from './components/autocomplete-control/autocomplete-control.component';
import { CheckboxControlComponent } from './components/checkbox-control/checkbox-control.component';
import { DocViewerPopupComponent } from './components/doc-viewer-popup/doc-viewer-popup.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { InputControlComponent } from './components/input-control/input-control.component';
import { InputMaskControlComponent } from './components/input-mask-control/input-mask-control.component';
import { InputNumberControlComponent } from './components/input-number-control/input-number-control.component';
import { InputPasswordControlComponent } from './components/input-password-control/input-password-control.component';
import { IntegrationSyncDetailComponent } from './components/integration-sync-detail/integration-sync-detail.component';
import { ListActionsComponent } from './components/list-actions/list-actions.component';
import { SelectControlComponent } from './components/select-control/select-control.component';
import { TextAreaControlComponent } from './components/text-area-control/text-area-control.component';
import { UserNotificationListComponent } from './components/user-notification-list/user-notification-list.component';
import { UserScoreCardComponent } from './components/user-score-card/user-score-card.component';
import { MatchStatusDirective } from './directives/match-status.directive';
import { PermissionDirective } from './directives/permission.directive';
import { DecimalFormatPipe } from './pipes/decimal-format.pipe';
import { MaskDatPhoneNumberPipe } from './pipes/mask-dat-phone-number.pipe';
import { MaskHidePipe } from './pipes/mask-hide.pipe';
import { MaskPhoneNumberPipe } from './pipes/mask-phone-number.pipe';
import { NameInitialPipe } from './pipes/name-initial.pipe';
import { PadNumberWithZeroPipe } from './pipes/pad-number-with-zero.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { PrimeNgModule } from './prime-ng.module';
import { UserViewAddEditComponent } from './user-view-add-edit/user-view-add-edit.component';
import { DecimalPrecision2Pipe } from './pipes/decimal-precision2.pipe';
import { CustomerScorecardComponent } from './components/customer-scorecard/customer-scorecard.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { FSCDisplayComponent } from './components/fsc-display/fsc-display.component';
import { UserNotificationComponent } from './components/user-notification-list/user-notification/user-notification.component';
import { EditDocumentComponent } from '@app/modules/load/load-edit/load-documents/edit-document/edit-document.component';

const components = [
  ListActionsComponent,
  InputControlComponent,
  InputNumberControlComponent,
  TextAreaControlComponent,
  ErrorMessageComponent,
  CheckboxControlComponent,
  SelectControlComponent,
  InputMaskControlComponent,
  ActivityComponent,
  DocViewerPopupComponent,
  AutocompleteControlComponent,
  ResolveIncidentComponent,
  UserViewAddEditComponent,
  AssignViewsComponent,
  UserScoreCardComponent,
  UserNotificationListComponent,
  InputPasswordControlComponent,
  IntegrationSyncDetailComponent,
  CustomerScorecardComponent,
  AuditLogComponent,
  FSCDisplayComponent,
  UserNotificationComponent,
  EditDocumentComponent
];
const directives = [
  PermissionDirective,
  MatchStatusDirective
];
const pipes = [
  YesNoPipe,
  MaskPhoneNumberPipe,
  NameInitialPipe,
  MaskHidePipe,
  PadNumberWithZeroPipe,
  DecimalFormatPipe,
  MaskDatPhoneNumberPipe,
  DecimalPrecision2Pipe
];
const modules = [
  CommonModule,
  PrimeNgModule,
  ReactiveFormsModule,
  FormsModule,
  AgGridModule,
  CountdownModule,
  FileUploadModule,
  NgxDocViewerModule
];

ModuleRegistry.registerModules([
  InfiniteRowModelModule,
  ClientSideRowModelModule,
  SideBarModule,
  ColumnsToolPanelModule,
  ServerSideRowModelModule,
  MasterDetailModule,
  SetFilterModule
]);

@NgModule({
  declarations: [components, directives, pipes],
  imports: modules,
  exports: [
    modules,
    components,
    directives,
    pipes
  ],
  providers: [DatePipe, YesNoPipe, MaskPhoneNumberPipe, NameInitialPipe, PadNumberWithZeroPipe, DecimalFormatPipe, MaskDatPhoneNumberPipe]
})
export class SharedModule { }
