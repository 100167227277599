import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {

  transform(value: number): string {
    if (isNaN(value) || value == null) {
      value = 0;
    }

    let formattedValue = value.toFixed(2);
    if (value % 1 === 0) {
      // If the number is an integer, return it as an integer     
      formattedValue = `${value.toString()}`;
    } else {
      // If the number has decimal places, display them      
      formattedValue = `${value.toFixed(2)}`;
    }
    const parts = formattedValue.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `$${parts.join('.')}`;   
  }
}
