import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormHelper } from '@app/core/helpers/form.helper';

@Component({
  selector: 'app-autocomplete-control',
  templateUrl: './autocomplete-control.component.html',
  styles: [
  ]
})
export class AutocompleteControlComponent {
  @Input() formGroup!: FormGroup;
  @Input() control!: AbstractControl;
  @Input() controlName!: string;
  @Input() elementId!: string;
  @Input() label!: string;
  @Input() optionField!: string;
  @Input() valueField!: string;
  @Input() suggestions: any[] = [];
  @Input() placeholder = '';
  @Input() multiple = false;
  @Input() minLength = 3;
  @Output() selected = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  formHelper = FormHelper;
  @Input() optionFields: string[] = [];
  get isRequired(): boolean {
    if (!this.control) return false;

    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : null;
    if (validator && validator['required']) {
      return true;
    } else {
      return false;
    }
  }

  searchItem(event: any): void {
    const searchString = event.query?.trim();
    searchString && this.search.emit(searchString);
  }

  selectItem(selected: any): void {
    this.selected.emit(selected);
  }
}
