import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-mask-control',
  templateUrl: './input-mask-control.component.html',
  styles: [],
})
export class InputMaskControlComponent {
  @Input() formName!: FormGroup;
  @Input() control!: AbstractControl;
  @Input() controlName!: string;
  @Input() mask!: string;
  @Input() label!: string;
  @Input() hideLabel!: boolean;
  @Input() elementId!: string;
  @Input() inputType = 'text';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() className?: string = '';
  @Input() tabIndex!: number;
  @Input() isAutoFocus = false;
  @Input() iTagData = '';
  @Output() blurEvent = new EventEmitter<string>();
  get isRequired(): boolean {
    if (!this.control) return false;

    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : null;
    if (validator && validator['required']) {
      return true;
    } else {
      return false;
    }
  }

  get isValid(): boolean | null {
    if (!this.control) return false;

    return this.control.status.toLowerCase() === 'invalid' ? true : null;
  }

  onBlur() {
    const value = this.control.value && this.control.value.trim();
    this.blurEvent.emit(value);
  }
}
