<div style="width:900px;height:auto;" *ngIf="!isRateConPdf">
    <ngx-doc-viewer [url]="documentPath" [viewer]="viewer" style="width:900px;height:75vh;">
    </ngx-doc-viewer>
</div>
<div *ngIf="isRateConPdf">
    <p-messages severity="info">
        <ng-template pTemplate>
            <div class="ml-2">Rate Confirmation PDF formation will take some time. Please try after sometime.</div>
        </ng-template>
    </p-messages>
</div>