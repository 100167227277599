import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalPrecision2'
})
export class DecimalPrecision2Pipe implements PipeTransform {

  transform(value: number): string {
    if (isNaN(value) || value == null) {
      value = 0;
    }

    const formattedValue = value.toFixed(2);
    const parts = formattedValue.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${parts.join('.')}`;
  }

}
