import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskPhoneNumber'
})
export class MaskPhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length === 10) {
      const areaCode = value.substring(0, 3);
      const firstPart = value.substring(3, 6);
      const secondPart = value.substring(6, 10);
      return `(${areaCode}) ${firstPart} ${secondPart}`;
    } else {
      return value;
    }
  }

}
