<div [appPermission]="permission.ViewAllViews" [module]="module.ViewManagement"> 
    <div class="mb-4" [appPermission]="permission.AssignView" [module]="module.ViewManagement">
        <h4 class="text-left mb-3">Loadboard</h4>
        <div class="field-checkbox mb-3 d-flex align-items-center" *ngFor="let view of userListViewModel">
            <input class="checkbox" [id]="'checkbox-' + view.userViewId" type="checkbox" [value]="view"
                [checked]="view.roleID==roleId" (change)="handleViewChange($event,view,'LoadBoard')">
            <label for="checkbox" class="mb-0 text-black">{{view.viewName}}</label>
        </div>
    </div>
</div>
<div [appPermission]="permission.ViewAllTrackingBoardViews" [module]="module.TrackingBoard">
    <div class="mb-4" [appPermission]="permission.AssignTrackingBoardView" [module]="module.TrackingBoard">
        <h4 class="text-left mb-3">Tracking Board</h4>
        <div class="field-checkbox mb-3 d-flex align-items-center" *ngFor="let view of userListTrackingBoardViewModel">
            <input class="checkbox" [id]="'checkbox-' + view.userViewId" type="checkbox" [value]="view"
                [checked]="view.roleID==roleId" (change)="handleViewChange($event,view, 'TrackingBoard')">
            <label for="checkbox" class="mb-0 text-black">{{view.viewName}}</label>
        </div>
    </div>
</div>