import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { Constants } from '@app/core/constants/constants';
import { Endpoint } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { ResponseModel } from '@app/core/models/api.response.model';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { SelectListModel } from '@app/core/models/select.list.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable, map } from 'rxjs';
import { String } from 'typescript-string-operations';
import { FacilityCustomerListDetailModel, FacilityCustomerRequestModel, FacilityDetailModel, FacilityListModel, FacilityLockDetailModel, FacilityScoreCardModel, HereMapAddressItemModel, UpdateFacilityStatusModel } from './facility.model';
import { FacilityResponseModel } from '../load/load.model';
import { AuditLogListModel, LazyListAuditLogsListModel } from '@app/shared/components/audit-log/audit-log.model';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  facilityEndpoint!: string;

  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.facilityEndpoint = this.appConfigService.getEndpoint(Endpoint.Facility);
  }

  getFacilityList(payload: GridFilterRequestModel): Observable<ResponseModel<FacilityListModel>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<FacilityListModel>>(url, payload);
  }

  getFacilityTypeSelectList(facilityTypeId = ''): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityTypeSelectList}`;
    url = String.format(url, facilityTypeId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data || [];
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  createFacility(payload: FacilityDetailModel, callerScreen: string): Observable<ResponseModel<FacilityDetailModel>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityCreate}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<FacilityDetailModel, ResponseModel<FacilityDetailModel>>(url, payload);
  }

  editFacility(payload: FacilityDetailModel): Observable<ResponseModel<FacilityDetailModel>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityUpdate}`;
    return this.apiClient.post<FacilityDetailModel, ResponseModel<FacilityDetailModel>>(url, payload);
  }

  getFacility(facilityDetailId: number, callerScreen: string, facilityCode: string | null = null): Observable<ResponseModel<FacilityDetailModel>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityDetail}`;
    url = String.format(url, facilityDetailId, callerScreen, facilityCode || '');
    return this.apiClient.get<ResponseModel<FacilityDetailModel>>(url);
  }

  getFacilitySelectList(payload: GridFilterRequestModel): Observable<ResponseModel<FacilityResponseModel[]>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityTypeAhead}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<FacilityResponseModel[]>>(url, payload);
  }

  getFacilityStatusReasons(facilityTypeId = ''): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityStatusReasonSelectList}`;
    url = String.format(url, facilityTypeId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data || [];
        selectList.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  updateFacilityStatus(payload: UpdateFacilityStatusModel): Observable<ResponseModel<number>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityStatusUpdate}`;
    return this.apiClient.post<UpdateFacilityStatusModel, ResponseModel<number>>(url, payload);
  }

  getFacilityCustomerList(payload: GridFilterRequestModel): Observable<ResponseModel<FacilityCustomerListDetailModel>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityCustomer.facilityCustomerList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<FacilityCustomerListDetailModel>>(url, payload);
  }

  updateFacilityCustomerStatus(isActive: boolean, facilityCustomerId: number): Observable<ResponseModel<number>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityCustomer.facilityCustomerUpdateStatus}`;
    url = String.format(url, isActive, facilityCustomerId, LoginHelper.GetUserDetail().userId);
    return this.apiClient.post<undefined, ResponseModel<number>>(url);
  }

  createUpdateFacilityCustomer(payload: FacilityCustomerRequestModel, isUpdate = false): Observable<ResponseModel<FacilityCustomerListDetailModel>> {
    const url = isUpdate ?
      `${this.facilityEndpoint}${API_ROUTES.facility.facilityCustomer.facilityCustomerUpdate}` :
      `${this.facilityEndpoint}${API_ROUTES.facility.facilityCustomer.facilityCustomerCreate}`;
    return this.apiClient.post<FacilityCustomerRequestModel, ResponseModel<FacilityCustomerListDetailModel>>(url, payload);
  }

  getFacilityLockDetail(facilityDetailId: number): Observable<ResponseModel<FacilityLockDetailModel>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityLockDetail}`;
    url = String.format(url, facilityDetailId);
    return this.apiClient.get<ResponseModel<FacilityLockDetailModel>>(url);
  }

  lockFacility(facilityDetailId: number, userId: number): Observable<ResponseModel<FacilityLockDetailModel>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityLock}`;
    url = String.format(url, facilityDetailId, userId);
    return this.apiClient.post<undefined, ResponseModel<FacilityLockDetailModel>>(url);
  }

  unlockFacility(facilityDetailId: number, userId: number): Observable<ResponseModel<string>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityUnlock}`;
    url = String.format(url, facilityDetailId, userId);
    return this.apiClient.post<undefined, ResponseModel<string>>(url);
  }

  facilityCodeExist(facilityCode: string, facilityDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityCodeExist}`;
    url = String.format(url, facilityCode, facilityDetailId);
    return this.apiClient.get<ResponseModel<boolean>>(url);
  }

  generateUniqueFacilityCode(facilityName: string, city: string, state: string, facilityDetailId: number): Observable<ResponseModel<string>> {
    const cleanedFacilityName = facilityName.replace(/[^a-zA-Z0-9]/g, '');
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityUniqueCode}`;
    url = String.format(url, cleanedFacilityName, city, state, facilityDetailId);
    return this.apiClient.get<ResponseModel<string>>(url);
  }

  getFacilityDepartmentSelectList(facilityDepartmentId = ''): Observable<ResponseModel<SelectListModel[]>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityDepartment.facilityDepartmentSelectList}`;
    url = String.format(url, facilityDepartmentId || '');
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url);
  }

  getAddressSelectList(payload: string): Observable<ResponseModel<HereMapAddressItemModel[]>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityAddressSelectList}`;
    url = String.format(url, payload ? payload : '');
    return this.apiClient.get<ResponseModel<HereMapAddressItemModel[]>>(url);
  }

  facilityProfileAuditLog(facilityDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityProfileAuditLog}`;
    url = String.format(url, facilityDetailId);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  facilityAddressExist(facilityAddress: HereMapAddressItemModel, facilityDetailId = 0): Observable<ResponseModel<boolean>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityAddressExists}`;
    url = String.format(url, facilityDetailId);
    return this.apiClient.post<HereMapAddressItemModel, ResponseModel<boolean>>(url, facilityAddress);
  }

  facilityListAuditLog(): Observable<ResponseModel<boolean>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityListAuditLog}`;
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  getFacilityAuditLogs(payload: GridFilterRequestModel): Observable<ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.getFacilityAuditLogList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>>(url, payload);
  }

  addActivityLog(subModule: string, sectionName: string, loadDetailId: number): Observable<ResponseModel<any>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.addViewActivityLog}`;
    url = String.format(url, encodeURIComponent(subModule), encodeURIComponent(sectionName), loadDetailId);
    return this.apiClient.post<any, any>(url);
  }

  getFacilityScoreCardDetail(facilityDetailId: number): Observable<ResponseModel<FacilityScoreCardModel>> {
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.getFacilityScoreCardDetail}`;
    url = String.format(url, facilityDetailId);
    return this.apiClient.get<ResponseModel<FacilityScoreCardModel>>(url);
  }

  getFacilityAdvanceSearchSelectList(payload: GridFilterRequestModel): Observable<ResponseModel<FacilityResponseModel[]>> {
    const url = `${this.facilityEndpoint}${API_ROUTES.facility.facilityAdvanceSearchTypeAhead}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<FacilityResponseModel[]>>(url, payload);
  }

  AddFacilityZoomCallActivityLog(facilityDetailId: number, phoneNumber: string): Observable<ResponseModel<any>>{
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.addFacilityZoomCallActivityLog}`;
    url = String.format(url, phoneNumber, facilityDetailId);
    return this.apiClient.post<any, any>(url);
  }

  addFacilityZoomCallActivityLog(facilityDetailId: number, phoneNumber: string): Observable<ResponseModel<any>>{
    let url = `${this.facilityEndpoint}${API_ROUTES.facility.addFacilityZoomCallActivityLog}`;
    url = String.format(url, phoneNumber, facilityDetailId);
    return this.apiClient.post<any, any>(url);
  }
}