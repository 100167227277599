import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CapacityStatusUpdateModel } from '../../capacity.model';
import { SelectListModel } from '@app/core/models/select.list.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CapacityService } from '../../capacity.service';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { FormHelper } from '@app/core/helpers/form.helper';
import { CallerScreen } from '@app/core/constants/enums';

@Component({
  selector: 'app-capacity-validate',
  templateUrl: './capacity-validate.component.html',
  styles: [
  ]
})
export class CapacityValidateComponent {
  capacityStatusUpdateForm!: FormGroup;
  capacityUpdateStatusRequest!: CapacityStatusUpdateModel;
  capacityStatusReasonList: SelectListModel[] = [];
  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private readonly dialogConfiguration: DynamicDialogConfig,
    private readonly service: CapacityService
  ) {
    this.capacityStatusReasonList = this.dialogConfiguration.data?.capcityStatusReasonList;
    this.initForm();
  }

  initForm(): void {
    this.capacityStatusUpdateForm = this.fb.group({
      capacityDetailId: new FormControl(this.dialogConfiguration.data?.capacityDetailId),
      capacityStatusReasonId: new FormControl(null, { validators: [Validators.required] }),
      capacityStatusReasonName: new FormControl(null),
      capacityStatus: new FormControl(this.dialogConfiguration.data?.capacityStatusAction),
      modifiedBy: new FormControl(LoginHelper.GetUserDetail().userId),
      modifiedByUser: new FormControl(LoginHelper.GetUserDetail().userName),
    });
  }

  getControl(control: string): AbstractControl {
    return this.capacityStatusUpdateForm.controls[control];
  }

  onReasonSelect(selected: SelectListModel) {
    const statusReason = this.capacityStatusReasonList.find((d) => d.value === selected.value);
    if (statusReason) {
      this.getControl('capacityStatusReasonName').patchValue(statusReason.text);
    }
  }

  submit(): void {
    if (this.capacityStatusUpdateForm.invalid) {
      FormHelper.MarkFormGroupDirty(this.capacityStatusUpdateForm);
      return;
    }
    const capcityUpdateStatusRequest = this.capacityStatusUpdateForm.getRawValue();
    this.service.updateCapacityStatus(capcityUpdateStatusRequest, this.dialogConfiguration.data?.callerScreen).subscribe((response) => {
      if (response.data) {
        this.dialogRef.close(response);
      }
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getSubmitButtonText(): string {
    return this.dialogConfiguration.data?.capacityStatusAction;
  }

}
