<form class="form-horizontal" focusInvalidInput novalidate autocomplete="off" [formGroup]="resolveIncidentForm">
    <h3 class="fw-normal border-bottom border-light p-3 mb-0">{{incidentSliderTitle}}</h3>
    <div class="p-3" *ngIf="!isResolved">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-3">
                    <app-input-number-control [formName]="resolveIncidentForm" elementId="resolutionAmount"
                        [control]="frm['resolutionAmount']" controlName="resolutionAmount" label="Impact Amount ($)"
                        mode="currency" currency="USD" [isCurrencyMode]="true" placeholder="Impact Amount ($)">
                    </app-input-number-control>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3 resolve-drop">
                    <app-select-control [formName]="resolveIncidentForm" [options]="loadIncidentResolutionReasons"
                        elementId="loadIncidentResolutionReasonId" controlName="loadIncidentResolutionReasonId"
                        [control]="frm['loadIncidentResolutionReasonId']" [multipleSelection]="false" [filter]="false"
                        [showClear]="false" [isPrimeNgDropdown]="true" [isServerSideFilter]="false" [isShowLabel]="true"
                        label="Reason">
                    </app-select-control>
                </div>
            </div>
        </div>
        <div class="mb-0 flex-grow-1">
            <app-text-area-control label="Comment" [formName]="resolveIncidentForm" elementId="resolutionNote"
                styleClass="w-100" controlName="resolutionNote" [control]="frm['resolutionNote']" placeholder="Comment">
            </app-text-area-control>
        </div>
        <div class="d-flex gap-3 border-top border-light pt-3 gap-3 justify-content-end">
            <button class="btn btn-primary" (click)="resolve()">Resolve</button>
            <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
        </div>
    </div>
    <div class="p-3" *ngIf="isResolved">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-3">
                    <label [ngClass]="{'disable':frm['resolutionAmount'].disabled}">
                        Impact Amount ($)
                    </label>
                    <div class="custom-label">
                        {{frm['resolutionAmount'].getRawValue() | decimalFormat}}
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label [ngClass]="{'disable':frm['loadIncidentResolutionReasonId'].disabled}">
                        Reason
                    </label>
                    <div class="custom-label">
                        {{getReason(frm['loadIncidentResolutionReasonId'].getRawValue())}}
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-0 flex-grow-1">
            <div class="mb-3">
                <label [ngClass]="{'disable':frm['resolutionNote'].disabled}">
                    Comment
                </label>
                <div class="custom-label">
                    {{frm['resolutionNote'].getRawValue()}}
                </div>
            </div>
        </div>
    </div>
</form>