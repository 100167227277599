import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';

import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabMenuModule } from 'primeng/tabmenu';
import { CardModule } from 'primeng/card';
import { ProgressBarModule } from 'primeng/progressbar';
import { MegaMenuModule } from 'primeng/megamenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SidebarModule } from 'primeng/sidebar';
import { PaginatorModule } from 'primeng/paginator';
import { StepsModule } from 'primeng/steps';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessagesModule } from 'primeng/messages';
import { CarouselModule } from 'primeng/carousel';

const modules = [
  ProgressSpinnerModule,
  AccordionModule,
  CheckboxModule,
  ConfirmDialogModule,
  DialogModule,
  DropdownModule,
  EditorModule,
  InputNumberModule,
  InputSwitchModule,
  InputTextModule,
  PanelModule,
  RadioButtonModule,
  TableModule,
  TooltipModule,
  ToastModule,
  DynamicDialogModule,
  InputMaskModule,
  TabViewModule,
  MultiSelectModule,
  TabMenuModule,
  CardModule,
  ProgressBarModule,
  MegaMenuModule,
  AutoCompleteModule,
  SidebarModule,
  PaginatorModule,
  StepsModule,
  VirtualScrollerModule,
  CalendarModule,
  FileUploadModule,
  OverlayPanelModule,
  MessagesModule,
  CarouselModule
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [ConfirmationService, DialogService, DynamicDialogConfig, MessageService]
})
export class PrimeNgModule { }
