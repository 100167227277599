import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { LoadBoLViewModelForExipredRateCon } from "@app/modules/load/load-edit/load-bol/load-bol.model";
import { Constants } from "../constants/constants";
import { CityStateZipSearchKey, FacilitySearchKey, FilterFieldType, FilterOperationType } from "../constants/enums";
import { GridFilterRequestModel } from "../models/list-filter.model";
import { ZipCodeResponseModel } from "../models/zipcode.model";

export class FormHelper {
    // Enable or disable specific form control of formGroup
    public static EnableDisableFormControl(formGroup: FormGroup, enable: boolean, controlName: string): void {
        enable ? formGroup.controls[controlName].enable() : formGroup.controls[controlName].disable();
    }

    // Mark formGroup as Dirty. So we can display validation on submit.
    public static MarkFormGroupDirty(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
            formGroup.controls[key].markAsDirty();
            formGroup.controls[key].markAsTouched();
        });
    }

    // Enable or disable all form controls of formGroup and add fields that can be skipped
    public static EnableDisableFormControls(formGroup: FormGroup, isEditEnabled: boolean, skipFields?: string[]): void {
        Object.keys(formGroup.controls).forEach((key) => {
            if (!skipFields || !skipFields.includes(key)) {
                const control: AbstractControl = formGroup.controls[key];
                isEditEnabled ? control.enable() : control.disable();
            }
        });
    }

    // Validates whether a given website URL is valid or not.
    public static WebSiteValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const regexp = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
            const isValid = control.value ? regexp.test(control.value) : true;
            return isValid ? null : { invalidwebsite: true };
        };
    }

    // Duplicate customer code validation.
    public static MarkAsDuplicateCode(): ValidatorFn {
        return (): ValidationErrors | null => {
            return { duplicatename: true };
        };
    }

    // Format Size    
    public static formatSizeUnit(formGroup: FormGroup, event: any, field: string) {
        let inputValue = event.target.value.replace(/[^0-9]/g, '');
        if (inputValue != "") {
            inputValue = Number(inputValue.replaceAll(',', ''));
            formGroup.controls[field].setValue(inputValue.toLocaleString());
        }
        else {
            formGroup.controls[field].setValue('');
        }
    }

    public static formatSizeUnitDecimal(formGroup: FormGroup, event: any, field: string) {
        // Allow only numbers and the decimal point
        let inputValue = event.target.value.replace(/[^0-9.]/g, '');

        // Ensure only one decimal point is present
        const parts = inputValue.split('.');
        if (parts.length > 2) {
            inputValue = parts[0] + '.' + parts.slice(1).join('');
        }

        if (inputValue !== "") {
            // Separate integer and decimal parts
            let [integerPart, decimalPart] = inputValue.split('.');

            // Restrict decimal part to 2 places
            if (decimalPart !== undefined) {
                decimalPart = decimalPart.substring(0, 2);
            }

            // Convert integer part to number and format with commas
            integerPart = Number(integerPart.replace(/,/g, '')).toLocaleString();

            // Combine integer and decimal parts back together
            inputValue = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;

            // Set formatted value back to the form control
            formGroup.controls[field].setValue(inputValue);
        } else {
            formGroup.controls[field].setValue('');
        }
    }

    // Convert string to date
    public static convertDate(date: string) {
        if (date === '' || date === null) {
            return null;
        }
        if (date.includes('T')) {
            date = date.slice(0, date.indexOf('T'));
        }
        const [year, month, day] = date.split('-');
        return new Date(Number(year), Number(month) - 1, Number(day));
    }

    // Convert string to time
    public static convertTime(time: string) {
        if (time === '' || time === null) {
            return null;
        }
        const timeParts = time.split(":");
        return timeParts[0] + timeParts[1];
    }
    public static combineDateAndTime(date: string, time: string) {
        const dateAndTime = new Date(date);
        if (time)
            dateAndTime.setHours(parseInt(time.split(':')[0]), parseInt(time.split(':')[1]));
        return dateAndTime;
    }

    // Calculate Total Customer/Carrier rate
    public static calculateTotalRate(forms: FormGroup[], rateType: string): number {
        let totalRate = 0;
        forms.forEach((form) => {
            const allAccessorialRates = FormHelper.calculateTotalAccessorialRate(form, rateType);
            const lineHaulChargesRate = parseFloat(form?.controls[`lineHaulCharges${rateType}`].getRawValue());
            const fuelSurchargesRate = parseFloat(form?.controls[`fuelSurcharges${rateType}`].getRawValue());
            totalRate += (allAccessorialRates || 0) + (lineHaulChargesRate || 0) + (fuelSurchargesRate || 0);
        });
        return totalRate;
    }

    // Calculate Total Accessorial rate
    public static calculateTotalAccessorialRate(carrierForm: FormGroup, rateKey: string): number {
        rateKey = rateKey === 'CustomerRate' ? 'customerRate' : 'carrierRate';
        const accessorialsValue = carrierForm?.controls['loadAccessorials']?.getRawValue().filter((acc: any) => acc.isDeleted === false && acc.accessorialId != null && acc.accessorialId > 0);
        return accessorialsValue.reduce((sum: number, currentObj: any) =>
            sum + parseFloat(currentObj[rateKey] || 0), 0
        );
    }
    //Copy sorce object value to target object which properties are match
    public static copyPropertiesRecursively(source: any, target: any): any {
        const copiedTarget: any = {};
        for (const key in source) {
            if (key in target) {
                if (Array.isArray(source[key]) && Array.isArray(target[key])) {
                    // Handle arrays of objects
                    copiedTarget[key] = source[key].map((sourceItem: any, index: number) => {
                        if (index < target[key].length) {
                            return this.copyPropertiesRecursively(sourceItem, target[key][index]);
                        } else {
                            return this.createMatchingObject(sourceItem, target[key][0]);
                        }
                    });
                } else if (typeof source[key] === 'object' && typeof target[key] === 'object') {
                    // Handle nested objects
                    copiedTarget[key] = this.copyPropertiesRecursively(source[key], target[key]);
                } else {
                    // Copy non-object properties
                    copiedTarget[key] = source[key] != null ? source[key] : null;
                }
            }
        }
        return copiedTarget;
    }
    //Copy sorce object value to target object which properties are match for multiple child object 
    public static createMatchingObject(source: any, template: any): any {
        const matchingObject: any = {};
        const copiedTarget: any = {};
        for (const key in template) {
            if (key in source) {
                // matchingObject[key] = source[key];
                if (key in template) {
                    if (Array.isArray(source[key]) && Array.isArray(template[key])) {
                        // Handle arrays of objects
                        matchingObject[key] = source[key].map((sourceItem: any, index: number) => {
                            if (index < template[key].length) {
                                return this.copyPropertiesRecursively(sourceItem, template[key][index]);
                            } else {
                                return this.createMatchingObject(sourceItem, template[key][0]);
                            }
                        });
                    } else if (typeof source[key] === 'object' && typeof template[key] === 'object') {
                        // Handle nested objects
                        matchingObject[key] = this.copyPropertiesRecursively(source[key], template[key]);
                    } else {
                        // Copy non-object properties
                        matchingObject[key] = source[key];
                    }
                }
            }
        }
        return matchingObject;
    }

    // Validates whether a given control contains HTML Characters or not.
    public static noMarkupValidator(control: any) {
        if (/<\/?[a-z][\s\S]*>/i.test(control.value)) {
            return { htmlCharacters: true };
        }
        return null;
    }

    // To check whether the form control is required or not
    public static isRequired(control: any): boolean {
        if (!control) return false;
        const validator = control.validator
            ? control.validator({} as AbstractControl)
            : null;
        if (validator && validator['required']) {
            return true;
        } else {
            return false;
        }
    }

    // Patch value of autocomplete text boxes if value not selected from suggestion.
    public static PatchAutoCompleteTextBox(formGroup: any, controlName: string): void {
        if (typeof formGroup.controls[controlName]?.getRawValue() !== 'object') {
            formGroup.controls[controlName]?.setValue(null);
        }
    }

    // Get Zip Code request payload for city state and zip autocomplete list
    public static GetTypeAheadRequestPayload(searchString: string, searchType: string, selectedStateCity?: ZipCodeResponseModel, sortOrderField?: string): GridFilterRequestModel {
        const payload: GridFilterRequestModel = {
            pageNumber: 1,
            pageSize: Constants.typeAheadSelectListSize,
            sortOrders: [
                {
                    sortOrderField: sortOrderField || searchType,
                    sortOrderType: 'asc'
                }],
            filters: [{
                fieldName: searchType,
                fieldType: FilterFieldType.Text,
                operation: searchType === CityStateZipSearchKey.State ? FilterOperationType.Equals : FilterOperationType.StartsWith,
                valueOne: searchString,
                valueTwo: ''
            }]
        };
        if (selectedStateCity != null && selectedStateCity != undefined && selectedStateCity.city !== '') {
            payload.filters?.push(
                {
                    fieldName: CityStateZipSearchKey.City,
                    fieldType: FilterFieldType.Text,
                    operation: FilterOperationType.Equals,
                    valueOne: selectedStateCity.city || null,
                    valueTwo: ''
                })
        }
        return payload;
    }

    // Set controls validators
    public static SetControlValidators(formGroup: FormGroup, controlName: string, validators: ValidatorFn[], emitEvent = true): void {
        formGroup.controls[controlName].setValidators(validators);
        formGroup.controls[controlName].updateValueAndValidity({ emitEvent: emitEvent });
    }

    // Get Facility request payload for facility autocomplete list
    public static GetFacilityRequestPayload(searchString: string, searchType: FacilitySearchKey): GridFilterRequestModel {
        const payload: GridFilterRequestModel = {
            pageNumber: 1,
            pageSize: Constants.typeAheadSelectListSize,
            sortOrders: [
                {
                    sortOrderField: searchType,
                    sortOrderType: 'asc'
                }],
            filters: [{
                fieldName: searchType,
                fieldType: FilterFieldType.Text,
                operation: FilterOperationType.StartsWith,
                valueOne: searchString,
                valueTwo: ''
            }]
        };
        return payload;
    }


    public static integerValidator(control: any) {
        if (control.value % 1 !== 0) {
            return { htmlCharacters: true };
        }
        return null;
    }

    public static NegativeAccessorialValidator(control: any) {
        if (control.value < 0) {
            return { negativeAccessorial: true };
        }
        return null;
    }

    public static CustomerNegativeRateValidator(control: any) {
        return { customerNegativeRate: true };
    }

    public static CarrierNegativeRateValidator(control: any) {
        return { carrierNegativeRate: true };
    }

    // Calculate rate per mile
    public static calculateRpmCharge(form: FormGroup, rateType: string, mileage: number | null): number | null {
        const rate = parseFloat(form?.controls[rateType].getRawValue());
        let totalRate = 0;
        if (!Number(mileage) || !rate) {
            if (Number(mileage) && rate == 0) {
                return totalRate;
            }
            else {
                return null;
            }
        }

        totalRate = Number((rate / Number(mileage)).toFixed(2));
        // Return null if totalRate is zero
        if (totalRate === 0) {
            return null;
        }
        return totalRate;
    }

    // Calculate line haul charges or fuel surcharges
    public static calculateCharge(form: FormGroup, rateType: string, mileage: number | null): number | null {
        const rate = parseFloat(form?.controls[rateType].getRawValue());
        if (!Number(mileage) || !rate) {
            return null;
        }
        let totalRate = 0;
        totalRate = Number((rate * Number(mileage)).toFixed(2));
        // Return null if totalRate is zero
        if (totalRate === 0) {
            return null;
        }
        return totalRate;
    }

    // Calculate All in rate
    public static calculateAllInRate(forms: FormGroup[], rateType: string): number | null {
        let allInRate = 0;
        forms.forEach((form) => {
            const lineHaulChargesRate = parseFloat(form?.controls[`lineHaulCharges${rateType}`].getRawValue());
            const fuelSurchargesRate = parseFloat(form?.controls[`fuelSurcharges${rateType}`].getRawValue());
            allInRate += (lineHaulChargesRate || 0) + (fuelSurchargesRate || 0);
        });
        return allInRate;
    }

    public static ReplacePlaceholder(template: string, placeholder: any, value: any) {
        const regex = new RegExp(`{{${placeholder}}}`, 'g');
        return template.replace(regex, value);
    }
}

// Duplicate name validation.
export function DuplicateNameValidator(itemList: any[], nameProperty: string, editObj?: any, idProperty?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const name: string = control.value;
        if (editObj && idProperty) {
            if (name) {
                const entity = itemList?.find(x => x[nameProperty]?.toLowerCase().trim() === name?.toLowerCase().trim());
                const isNameExist = entity && entity[idProperty] !== editObj[idProperty];
                return isNameExist ? { duplicatename: true } : null;
            }
            return null;
        } else {
            const isNameExist = itemList?.find(x => x[nameProperty]?.toLowerCase().trim() === name?.toLowerCase().trim());
            return isNameExist ? { duplicatename: true } : null;
        }
    };
}

// Email Validation
export function EmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const regexp = new RegExp(
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        const isValid = control.value ? regexp.test(control.value) : true;
        return isValid ? null : { invalidEmail: true };
    };
}

export function MultipleEmailsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null; // handle empty input
        }

        // Split the input value by commas
        const emails = control.value.split(',');

        // Regular expression to validate each email
        const emailRegex = new RegExp(
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        // Check each email in the array
        for (let email of emails) {
            email = email.trim(); // trim whitespace
            if (!emailRegex.test(email)) {
                return { invalidAdditionalRecipents: true };
            }
        }

        return null; // all emails are valid
    };
}

export function EmailOrWebsiteValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const regexp = new RegExp(
            /^[a-zA-Z0-9_!#$%&'*+=?`{|}~^-]+(?:\.[a-zA-Z0-9_!#$%&'*+=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$|www\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/
        );
        const isValid = control.value ? regexp.test(control.value) : true;
        return isValid ? null : { invalidEmail: true };
    };
}

// only space Validation
export function SpaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || control.value.trim() === '') {
            return { required: true };
        }
        return null;
    };
}

// Required field if other control has value
export function DependentRequiredValidator(fieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value && control.parent?.get(fieldName)?.value) {
            return { required: true };
        }
        return null;
    };
}

//Replace char in phone number
export function RemovePhoneFormatting(phone: string): string {
    return phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
}

//Check the Current value is string or not
export function isString(value: any) {
    return typeof value === 'string' || value instanceof String;
}
// Alphanumeric Validation
export function AlphaNumericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const regexp = new RegExp(/^[a-z\d]+$/i);
        const isValid = control.value ? regexp.test(control.value) : true;
        return isValid ? null : { invalidalphanumeric: true };
    };
}

export function HtmlJunkValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const regexp = new RegExp(/^[^<>]+$/i);
        const isValid = control.value ? regexp.test(control.value) : true;
        return isValid ? null : { invalidHtml: true };
    };
}

// amount Validation
export function AmountValidator(creditLimitMaxValue = Constants.creditLimitMaxValue): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isValid = control.value > creditLimitMaxValue ? false : true;
        return isValid ? null : { invalidamount: creditLimitMaxValue };
    };
}

// number length Validation
export function NumberLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let valueAsString: string = control.value?.toString();
        valueAsString = valueAsString?.split('.')[0];
        const isValid = valueAsString && valueAsString.replace(/[.,]/g, '').length > maxLength ? false : true;
        return isValid ? null : {
            maxlength: {
                requiredLength: maxLength,
                actualLength: valueAsString ? valueAsString.replace(/[.,]/g, '').length : 0
            }
        };
    };
}

//Roman To int convert
export function ConvertRomanToInt(s: string): number {
    const map = new Map([
        ['I', 1],
        ['IV', 4],
        ['V', 5],
        ['IX', 9],
        ['X', 10],
        ['XL', 40],
        ['L', 50],
        ['XC', 90],
        ['C', 100],
        ['CD', 400],
        ['D', 500],
        ['CM', 900],
        ['M', 1000]
    ]);
    let result = 0;

    for (let i = 0; i < s.length; i++) {
        const twoSymbols = map.get(s[i] + s[i + 1]);
        const oneSymbol = map.get(s[i]);
        if (twoSymbols) {
            i += 1; // skip iteration of next symbol
        }
        result += twoSymbols || oneSymbol || 0;
    }

    return result;
}

// Typeahead max length validation.
export function TypeAheadMaxLengthValidation(fieldName: string, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value) {
            if (typeof (value) === 'string') {
                return value.length > maxLength ? { maxlength: true } : null;
            } else {
                return value[fieldName].length > maxLength ? { maxlength: true } : null;
            }
        }
        return null;
    };
}

export function TimeValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value) {
            if (value.length == 4) {
                const hours = value.slice(0, 2);
                const minutes = value.slice(2);
                if (!(parseInt(hours) >= 0 && parseInt(hours) <= Constants.hoursValidationMax && parseInt(minutes) >= 0 && parseInt(minutes) <= Constants.minutesValidationMax)) {
                    return { invalidTime: true };
                }
            }
            if (value.length > 0 && value.length < 4) {
                return { invalidTime: true };
            }
        }
        return null;
    };
}

export function getDefaultLoadBolViewModel(): LoadBoLViewModelForExipredRateCon {
    return {
        pieceTypeId: '',
        pieceCount: 0,
        quantityTypeId: '',
        quantity: 0,
        eDIReferenceNumber: 0,
        weight: 0,
        class: '',
        length: 0,
        height: 0,
        description: ''
    };
}

export function dateRangeValidator(date: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value) {
            if (!control.value) return null;
            const dateValue = new Date(control.value);
            dateValue.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);
            if (date && dateValue < date) {
                return { invalidDate: true };
            }
        }
        return null;
    }
}

export function StartDateValidator(endDateControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const startDateValue = control.getRawValue();
        const endDateValue = control.parent?.getRawValue()[endDateControl];
        const formGroup = control.parent as FormGroup;
        if (!endDateValue) {
            return null;
        }
        if (startDateValue && endDateValue) {
            if (new Date(startDateValue) > new Date(endDateValue)) {
                return { invalidStartDate: true };
            }
            if (new Date(startDateValue) < new Date(endDateValue)) {
                formGroup?.controls[endDateControl].setErrors(null);
                return null;
            }
        }
        return null;
    }
}
export function EndDateValidator(startDateControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const startDateValue = control?.parent?.getRawValue()[startDateControl];
        const endDateValue = control?.getRawValue();
        const formGroup = control.parent as FormGroup;
        if (!startDateValue) {
            return null;
        }
        if (startDateValue && endDateValue) {
            if (new Date(endDateValue) < new Date(startDateValue)) {
                return { invalidEndDate: true };
            }
            if (new Date(endDateValue) > new Date(startDateValue)) {
                formGroup?.controls[startDateControl].setErrors(null);
                return null;
            }
        }
        return null;
    }
}
export function StartTimeValidator(control: AbstractControl, endTimeControl: string): ValidatorFn {
    return (): ValidationErrors | null => {
        const startTimeValue = control?.getRawValue();
        const endTimeValue = control?.parent?.getRawValue()[endTimeControl]?.substring(0, 5)?.replace(':', '');
        const formGroup = control.parent as FormGroup;

        if (startTimeValue) {
            if (startTimeValue.length == 4) {
                const hours = startTimeValue.slice(0, 2);
                const minutes = startTimeValue.slice(2);
                if (!(parseInt(hours) >= 0 && parseInt(hours) <= Constants.hoursValidationMax && parseInt(minutes) >= 0 && parseInt(minutes) <= Constants.minutesValidationMax)) {
                    return { invalidTime: true };
                }
            }
            if (startTimeValue.length > 0 && startTimeValue.length < 4) {
                return { invalidTime: true };
            }
        }
        if (!endTimeValue) {
            return null;
        }
        if (startTimeValue && endTimeValue) {
            if (startTimeValue >= endTimeValue) {
                return { invalidStartTime: true };
            }
            if (startTimeValue < endTimeValue) {
                formGroup?.controls[endTimeControl].setErrors(null);
                if (endTimeValue) {
                    if (endTimeValue.length == 4) {
                        const hours = endTimeValue.slice(0, 2);
                        const minutes = endTimeValue.slice(2);
                        if (!(parseInt(hours) >= 0 && parseInt(hours) <= Constants.hoursValidationMax && parseInt(minutes) >= 0 && parseInt(minutes) <= Constants.minutesValidationMax)) {
                            formGroup?.controls[endTimeControl].setErrors({ invalidTime: true });
                        } else {
                            formGroup?.controls[endTimeControl].setErrors(null);
                        }
                    }
                    if (endTimeValue.length > 0 && endTimeValue.length < 4) {
                        formGroup?.controls[endTimeControl].setErrors({ invalidTime: true });
                    }
                }
                return null;
            }
        }
        return null;
    }
}
export function EndTimeValidator(control: AbstractControl, startTimeControl: string): ValidatorFn {
    return (): ValidationErrors | null => {
        const startTimeValue = control?.parent?.getRawValue()[startTimeControl]?.substring(0, 5)?.replace(':', '');
        const endTimeValue = control?.getRawValue();
        const formGroup = control.parent as FormGroup;

        if (endTimeValue) {
            if (endTimeValue.length == 4) {
                const hours = endTimeValue.slice(0, 2);
                const minutes = endTimeValue.slice(2);
                if (!(parseInt(hours) >= 0 && parseInt(hours) <= Constants.hoursValidationMax && parseInt(minutes) >= 0 && parseInt(minutes) <= Constants.minutesValidationMax)) {
                    return { invalidTime: true };
                }
            }
            if (endTimeValue.length > 0 && endTimeValue.length < 4) {
                return { invalidTime: true };
            }
        }
        if (!startTimeValue) {
            return null;
        }
        if (startTimeValue && endTimeValue) {
            if (endTimeValue <= startTimeValue) {
                return { invalidEndTime: true };
            }
            if (endTimeValue > startTimeValue) {
                formGroup?.controls[startTimeControl].setErrors(null);
                if (startTimeValue) {
                    if (startTimeValue.length == 4) {
                        const hours = startTimeValue.slice(0, 2);
                        const minutes = startTimeValue.slice(2);
                        if (!(parseInt(hours) >= 0 && parseInt(hours) <= Constants.hoursValidationMax && parseInt(minutes) >= 0 && parseInt(minutes) <= Constants.minutesValidationMax)) {
                            formGroup?.controls[startTimeControl].setErrors({ invalidTime: true });
                        } else {
                            formGroup?.controls[startTimeControl].setErrors(null);
                        }
                    }
                    if (startTimeValue.length > 0 && startTimeValue.length < 4) {
                        formGroup?.controls[startTimeControl].setErrors({ invalidTime: true });
                    }
                }
                return null;
            }
        }
        return null;
    }
}




