import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserSession } from '@app/core/constants/enums';
import { CookieService } from 'ngx-cookie-service';
import { ToasterService } from '../services/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private readonly toastService: ToasterService
  ) { }

  canActivate(): boolean {
    const loginSuccess = this.cookieService.get(UserSession.LoginSuccessCookie);
    const loginFailure = this.cookieService.get(UserSession.LoginFailureCookie);

    if (loginSuccess) {
      this.router.navigate(['/administration']);
      return false;
    } else {
      if (loginFailure) {
        this.toastService.showWarnMessage(loginFailure);
      }
      return true;
    }
  }
}
