
export interface SelectListView {
    text: string
    value: string
    isActive: boolean
    isDefault: boolean
    createdBy: number
    modifiedBy: number
    viewName: string
    viewCriteria: string,
    userViewId: number,
    createdDate: string;
}
export interface UserViewModel {
    viewName: string
    viewCriteria: any
    isActive: boolean
    isDefault: boolean
    createdBy: number
    modifiedBy: number,
    module: string;
    createdDate: string;
    userViewId: number
}

export const defaultSelectListView: SelectListView = {
    text: '',
    value: '',
    isActive: true,
    isDefault: true,
    createdBy: 0,
    modifiedBy: 0,
    viewName: '',
    viewCriteria: '',
    userViewId: 0,
    createdDate: ''
};
export interface UserListViewModel {
    userViewId: number;
    viewName: string;
    viewCriteria: string;
    isActive: boolean;
    isDefault: boolean;
    createdBy: number;
    modifiedBy: number;
    module: string;
    createdDate: string;
    roleID: number;
}
export interface RoleView {
    userViewId: number;
    roleId: number;
    createdBy: number;
    module?: string;
}