import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CapacityHelper } from '../capacity.helper';

@Component({
  selector: 'app-capacity-schedule-item',
  templateUrl: './capacity-schedule-item.component.html',
  styles: [
  ]
})
export class CapacityScheduleItemComponent {
  @Input() capacityScheduleForm!: FormGroup;
  @Input() displayCapacity = true;

  getControl(control: string): AbstractControl {
    return this.capacityScheduleForm.controls[control];
  }

  weekDayValueChange(): void {
    CapacityHelper.EnableDisableCapacityScheduleForm(this.capacityScheduleForm);
  }
}
