<app-checkbox-control *ngIf="!displayCapacity" [formName]="capacityScheduleForm"
    [control]="getControl('capacityAvailable')" controlName="capacityAvailable"
    elementId="{{getControl('weekDay').getRawValue()}}" label="{{getControl('weekDay').getRawValue()}}"
    (checkboxChangeEvent)="weekDayValueChange()"
    [ngClass]="{'checkbox-h5':getControl('capacityAvailable').getRawValue()}"></app-checkbox-control>
<div *ngIf="displayCapacity">
    <app-input-number-control [formName]="capacityScheduleForm" [control]="getControl('capacityPerDay')"
        inputType="number" controlName="capacityPerDay" label="{{getControl('weekDay').getRawValue()}}"
        placeholder="Total Capacity" [isCurrencyMode]="false" [minFractionDigits]="0"
        [labelClass]="getControl('capacityAvailable').getRawValue()?'checkbox-h5':''"
        [class]="getControl('weekDay').getRawValue()?'checkbox-h5':''">
    </app-input-number-control>
</div>