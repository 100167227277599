<form class="form-horizontal" focusInvalidInput novalidate autocomplete="off" [formGroup]="capacityStatusUpdateForm">
    <div class="row">
        <div class="col-md-12">
            <div class="mb-4">
                <app-select-control [formName]="capacityStatusUpdateForm" elementId="capacityStatusReasonId"
                    [options]="capacityStatusReasonList" [multipleSelection]="false" [filter]="false" [showClear]="false"
                    [isPrimeNgDropdown]="true" [isServerSideFilter]="false" styleClass="w-100"
                    [control]="getControl('capacityStatusReasonId')" optionLabel="text" optionValue="value"
                    controlName="capacityStatusReasonId" [isShowLabel]="true" label="Reason"
                    (selectChangeEvent)="onReasonSelect($event)">
                </app-select-control>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-right pb-0">
                <button type="button" class="btn btn-primary mr-2" (click)="submit()">{{getSubmitButtonText()}}</button>
                <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</form>