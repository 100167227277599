import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormGroup
} from '@angular/forms';

@Component({
  selector: 'app-text-area-control',
  templateUrl: './text-area-control.component.html',
  styles: [],
})
export class TextAreaControlComponent {
  @Input() formName!: FormGroup;
  @Input() control!: AbstractControl;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() hideLabel!: boolean;
  @Input() maxLength!: number;
  @Input() minlength!: number;
  @Input() elementId!: string;
  @Input() inputType = 'text';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() className = '';
  @Input() tabIndex!: number;
  @Input() isAutoFocus = false;
  @Input() iTagData = '';
  @Input() rows = 4;

  @Output() blurEvent = new EventEmitter<string>();

  get isRequired(): boolean {
    if (!this.control) return false;

    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : null;
    if (validator && validator['required']) {
      return true;
    } else {
      return false;
    }
  }

  onBlur() {
    const value = this.control.value && this.control.value.trim();
    this.control.setValue(value);
    this.blurEvent.emit(value);
  }
}
