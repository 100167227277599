<div [formGroup]="formName">
    <label for="{{ elementId }}" [ngClass]="{'disable':control.disabled}">
        {{ label }}
        <em *ngIf="isRequired" class="asterisk"></em>
        <em *ngIf="iTagData !== ''" class="ml-2 pi pi-info-circle" tooltipEvent="hover|focus" tabindex="0"
            [attr.aria-label]="iTagData">
        </em>
    </label>
    <span class="p-input-icon-right d-block">
        <input class="form-control {{ className }}" [attr.autofocus]="isAutoFocus" [type]="inputType" [id]="elementId"
            [maxlength]="maxLength" [minlength]="minLength" [tabIndex]="tabIndex" name="{{ controlName }}"
            autocomplete="new-password" data-placement="bottom" data-trigger="manual" [placeholder]="placeholder"
            [formControlName]="controlName" [attr.disabled]="disabled ? '' : null"
            [attr.aria-required]="isRequired ? 'true' : null" [attr.aria-invalid]="isValid"
            [attr.aria-describedby]="'err-' + elementId" (blur)="onBlur()" (input)="onInput()" (keyup)="onKeyup($event)"
            [style.padding-right]="'1.6rem'" />
        <a href="javascript:void(0);" class="mr-3 position-absolute text-dark" style="right:-6px; top:6px;"
            (click)="togglePasswordVisibility(showPassword)" [ngClass]="{'disable':control.disabled}"
            [ngStyle]="{'pointer-events': control.disabled ? 'none' : 'all'}">
            <span *ngIf="showPassword else visibleNode" class="material-symbols-outlined" title="Hide"
                style="font-size: 14px;">visibility_off</span>
            <ng-template #visibleNode>
                <span class="material-symbols-outlined" style="font-size: 14px;" title="Show">
                    visibility
                </span>
            </ng-template>
        </a>
    </span>

    <app-error-message [control]="control" [label]="label" [elementId]="elementId"></app-error-message>
</div>