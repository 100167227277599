import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PermissionCode, PermissionModuleCode, UserViews } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { RoleView, UserListViewModel } from '@app/shared/user-view-add-edit/user-view.model';
import { UserviewService } from '@app/shared/user-view-add-edit/user-view.service';

@Component({
  selector: 'app-assign-views',
  templateUrl: './assign-views.component.html',
  styles: [
  ]
})
export class AssignViewsComponent implements OnInit {
  visible: boolean = false;
  asignViewsForm!: FormGroup;
  userListViewModel: UserListViewModel[] = [];
  userListTrackingBoardViewModel: UserListViewModel[] = [];
  roleViewRequest: RoleView[] = []
  permission = PermissionCode;
  module = PermissionModuleCode;
  callerScreen= '';
  @Input() roleId!: number;
  @Output() saveRoleView = new EventEmitter<any>();
  @Output() disableAssignView = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private readonly service: UserviewService,
  ) {
  }
  ngOnInit(): void {
    this.getUserViews();
  }
  getUserViews(): void {
    if(LoginHelper.IsModuleAccessible(PermissionModuleCode.ViewManagement, PermissionCode.ViewAllViews)
    && LoginHelper.IsModuleAccessible(PermissionModuleCode.TrackingBoard, PermissionCode.ViewAllTrackingBoardViews)){
      this.callerScreen = '';
    }
    else if(LoginHelper.IsModuleAccessible(PermissionModuleCode.ViewManagement, PermissionCode.ViewAllViews))
      this.callerScreen = UserViews.LoadBoard;
    else if(LoginHelper.IsModuleAccessible(PermissionModuleCode.TrackingBoard, PermissionCode.ViewAllTrackingBoardViews))
      this.callerScreen = UserViews.TrackingBoard;
    else
      this.callerScreen = '';
    
      this.service.getUserViews(this.roleId,this.callerScreen).subscribe((response) => {
        if (response.data) {
          this.userListViewModel = response.data.filter((x:any)=>x.module==UserViews.LoadBoard);
          this.userListTrackingBoardViewModel = response.data.filter((x:any)=>x.module==UserViews.TrackingBoard);
          if(LoginHelper.IsModuleAccessible(PermissionModuleCode.ViewManagement, PermissionCode.AssignView)){
            this.userListViewModel.forEach(pg => {
              if (pg.roleID == this.roleId) {
                this.roleViewRequest.push({
                  userViewId: pg.userViewId,
                  roleId: this.roleId,
                  createdBy: LoginHelper.GetUserDetail().userId,
                  module: UserViews.LoadBoard
                });
              }
              if (this.roleViewRequest.length > 0) {
    
                this.disableAssignView.emit(false);
              }
              else {
    
                this.disableAssignView.emit(true);
              }
            });
          }
          if(LoginHelper.IsModuleAccessible(PermissionModuleCode.TrackingBoard, PermissionCode.AssignTrackingBoardView)){
            this.userListTrackingBoardViewModel.forEach(pg => {
              if (pg.roleID == this.roleId) {
                this.roleViewRequest.push({
                  userViewId: pg.userViewId,
                  roleId: this.roleId,
                  createdBy: LoginHelper.GetUserDetail().userId,
                  module: UserViews.TrackingBoard
                });
              }
              if (this.roleViewRequest.length > 0) {
    
                this.disableAssignView.emit(false);
              }
              else {
    
                this.disableAssignView.emit(true);
              }
            });
          }      
        }
      });
  }
  showDialog() {
    this.visible = true;
  }
  handleViewChange(event: any, view: UserListViewModel, module:string): void {
    const existIndex = this.roleViewRequest.findIndex(x => x.userViewId === view.userViewId);
    const checkbox = document.getElementById("checkbox-" + view.userViewId) as HTMLInputElement;
    if (checkbox.checked) {
      if (existIndex === -1) {
        this.roleViewRequest.push({
          userViewId: view.userViewId,
          roleId: this.roleId,
          createdBy: LoginHelper.GetUserDetail().userId,
          module:module == UserViews.TrackingBoard?UserViews.TrackingBoard:UserViews.LoadBoard
        });
      }
    } else {
      if (existIndex !== -1) {
        this.roleViewRequest.splice(existIndex, 1);
      }
    }
    if (this.roleViewRequest.length > 0) {

      this.disableAssignView.emit(false);
    }
    else {

      this.disableAssignView.emit(true);
    }
    this.saveRoleView.emit(this.roleViewRequest);
  }
}
