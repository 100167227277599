import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfigModel } from '../models/app.config.model';
import { ApiClientService } from './api-client.service';
import { LicenseManager } from '@ag-grid-enterprise/core';

@Injectable()
export class AppConfigService {
  production!: boolean;
  byPassEmail!: string;
  cookieDomain!: string;
  appConfig!: AppConfigModel;
  instrumentationKey!: string;
  agGridLicenseKey!: string;

  constructor(private readonly apiClient: ApiClientService) { }

  async getAppConfig(): Promise<void> {
    try {
      const response = await this.apiClient.get<AppConfigModel>(environment.appConfigURL);
      if (response) {
        this.appConfig = await firstValueFrom(response);
        this.production = this.appConfig.production;
        this.byPassEmail = this.appConfig.byPassEmail;
        this.cookieDomain = this.appConfig.cookieDomain;
        this.instrumentationKey = this.appConfig.instrumentationKey;
        this.agGridLicenseKey = this.appConfig.agGridLicenseKey;
        if (this.production) {
          console.log = function () { /* Keep it empty */ };
          LicenseManager.setLicenseKey(this.agGridLicenseKey);
        }
      } else {
        console.error('Failed to fetch AppConfig. Response is undefined.');
      }
    } catch (error) {
      console.error('Error fetching AppConfig:', error);
    }
  }

  getEndpoint(service: string): string {
    return `${this.appConfig?.apiBaseUrl}${this.appConfig?.endpoints[service]}`;
  }
}
