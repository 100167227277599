import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { CallerScreen, Endpoint } from '@app/core/constants/enums';
import { ResponseModel } from '@app/core/models/api.response.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable } from 'rxjs';
import { CapacityDetailModel, CapacityListModel, CapacityLockDetailModel, CapacityPotentialAwardModel, CapacityStatusUpdateModel, CreateAwardCapacityModel, SubmittedCapacityModel } from './capacity.model';
import { String } from 'typescript-string-operations';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';
import { LazyList } from '@app/core/models/lazy-list.response.model';
import { LoadHistoryModel } from '@app/modules/award/award.model';
import { AuditLogListModel, LazyListAuditLogsListModel } from '@app/shared/components/audit-log/audit-log.model';

@Injectable({
  providedIn: 'root'
})
export class CapacityService {
  awardEndpoint!: string;
  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.awardEndpoint = this.appConfigService.getEndpoint(Endpoint.Award);
  }

  createCapacity(payload: CapacityDetailModel, callerScreen: string): Observable<ResponseModel<CapacityDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityCreate}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<CapacityDetailModel, ResponseModel<CapacityDetailModel>>(url, payload);
  }

  updateCapacity(payload: CapacityDetailModel): Observable<ResponseModel<CapacityDetailModel>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityUpdate}`;
    return this.apiClient.post<CapacityDetailModel, ResponseModel<CapacityDetailModel>>(url, payload);
  }

  getCapacityDetail(capacityDetailId: number): Observable<ResponseModel<CapacityDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityDetail}`
    url = String.format(url, capacityDetailId);
    return this.apiClient.get<ResponseModel<CapacityDetailModel>>(url);
  }

  getCapacityLockDetail(facilityDetailId: number): Observable<ResponseModel<CapacityLockDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityLockDetails}`;
    url = String.format(url, facilityDetailId);
    return this.apiClient.get<ResponseModel<CapacityLockDetailModel>>(url);
  }

  lockCapacity(capacityDetailId: number, userId: number): Observable<ResponseModel<CapacityLockDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityLock}`;
    url = String.format(url, capacityDetailId, userId);
    return this.apiClient.post<undefined, ResponseModel<CapacityLockDetailModel>>(url);
  }

  unlockCapacity(capacityDetailId: number, userId: number): Observable<ResponseModel<string>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityUnlock}`;
    url = String.format(url, capacityDetailId, userId);
    return this.apiClient.post<undefined, ResponseModel<string>>(url);
  }

  getCapacityList(payload: GridFilterRequestModel, callerScreen: string): Observable<ResponseModel<CapacityListModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.getCapacityList}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<CapacityListModel>>(url, payload);
  }

  addViewListActivityLog(): Observable<ResponseModel<any>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.addViewListActivityLog}`;
    return this.apiClient.post<any, any>(url);
  }

  addCapacityProfileAuditLog(capacityDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityProfileAuditLog}`;
    url = String.format(url, capacityDetailId);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  getCapacityUniqueIdTypeAhead(payload: GridFilterRequestModel): Observable<ResponseModel<CapacityDetailModel[]>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityUniqueIdTypeAhead}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<CapacityDetailModel[]>>(url, payload);
  }

  createAwardCapacity(payload: CapacityDetailModel | CreateAwardCapacityModel, callerScreen: string): Observable<ResponseModel<CapacityDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.createAwardCapacity}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<CapacityDetailModel | CreateAwardCapacityModel, ResponseModel<CapacityDetailModel>>(url, payload);
  }

  getSubmittedCapacityList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<SubmittedCapacityModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.getSubmittedCapacityList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<SubmittedCapacityModel[]>>>(url, payload);
  }

  getCapacityLaneHistoryList(payload: GridFilterRequestModel): Observable<ResponseModel<LazyList<LoadHistoryModel[]>>> {
    const url = `${this.awardEndpoint}${API_ROUTES.award.loadHistory.capacityLaneHistoryList}`;
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<LoadHistoryModel[]>>>(url, payload);
  }

  getPotentialAwardList(payload: GridFilterRequestModel, isIncludeEquipment = true): Observable<ResponseModel<LazyList<CapacityPotentialAwardModel[]>>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityPotentialAwardList}`;
    url = String.format(url, isIncludeEquipment);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyList<CapacityPotentialAwardModel[]>>>(url, payload);
  }

  addLaneHistoryListAuditLog(capacityDetailId: number, callerScreen = CallerScreen.CapacityProfile): Observable<ResponseModel<boolean>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.loadHistory.addLaneHistoryListAuditLog}`;
    url = String.format(url, capacityDetailId, callerScreen);
    return this.apiClient.post<undefined, ResponseModel<boolean>>(url);
  }

  awardCapacityExists(capacityDetailId: number, awardDetailId: number): Observable<ResponseModel<boolean>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.awardCapacityExists}`;
    url = String.format(url, capacityDetailId, awardDetailId);
    return this.apiClient.get<ResponseModel<boolean>>(url);
  }

  getCapacityAuditLogs(payload: GridFilterRequestModel, callerScreen = CallerScreen.CapacityProfile): Observable<ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.auditLog.getAuditLogList}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<LazyListAuditLogsListModel<AuditLogListModel[]>>>(url, payload);
  }

  updateCapacityStatus(payload: CapacityStatusUpdateModel, callerScreen: string): Observable<ResponseModel<number>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.capacityUpdateStatus}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<CapacityStatusUpdateModel, ResponseModel<number>>(url, payload);
  }

  addViewActivityLog(subModule: string, sectionName: string, capacityDetailId: number, callerScreen = CallerScreen.CapacityProfile): Observable<ResponseModel<any>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.auditLog.addViewActivityLog}`;
    url = String.format(url, encodeURIComponent(subModule), encodeURIComponent(sectionName), capacityDetailId, callerScreen);
    return this.apiClient.post<any, any>(url);
  }

  cloneCapacity(payload: CapacityDetailModel, callerScreen: string): Observable<ResponseModel<CapacityDetailModel>> {
    let url = `${this.awardEndpoint}${API_ROUTES.award.capacityDetail.cloneCapacity}`;
    url = String.format(url, callerScreen);
    return this.apiClient.post<CapacityDetailModel, ResponseModel<CapacityDetailModel>>(url, payload);
  }
}
